import React from 'react';
import './Loading.scss';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';

const Loading = () => {
  return (
    <div id="loading-container" className="flex align-center justify-center">
      <h1 id="loading-header">
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          // size="lg"
          // onClick={() => {
          //   toggleNavMenu();
          // }}
          style={{
            color: 'var(--blueDocspera)',
            marginRight: '10px',
            marginTop: '20px',
          }}
        />
      </h1>
    </div>
  );
};

export default Loading;
