import React, {useContext} from 'react';
import {Context as GlobalContext} from '../context/GlobalContext';
import './Alert.scss';

const Alert = () => {
  const {state: globalState, closeAlert} = useContext(GlobalContext);

  return (
    <>
      <AlertOverlay />
      <div id="alert">
        {globalState.alertTitle && <h1>{globalState.alertTitle}</h1>}
        <div>
          {globalState.alertBody && <div>{globalState.alertBody}</div>}

          <div id="alert-btn-container" className="flex justify-end">
            {globalState.alertCancelText && (
              <button
                type="button"
                className="btn-primary close-alert"
                onClick={() => {
                  if (globalState.alertCancelHandler) {
                    globalState.alertCancelHandler();
                  } else {
                    closeAlert();
                  }
                }}>
                {globalState.alertCancelText}
              </button>
            )}
            {globalState.alertOKText && globalState.alertOKHandler && (
              <button
                type="button"
                className="btn-success"
                onClick={() => globalState.alertOKHandler()}>
                {globalState.alertOKText}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const AlertOverlay = () => {
  return <div id="alert-overlay"></div>;
};

export default Alert;
