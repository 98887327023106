import createDataContext from './createDataContext';
import instance from '../dataAccess/docspera';

let initialState = {
  isLoading: false,
  cases: [],
  weekCases: [],
  printCases: [],
  pendingCases: [],
  // getCasesAPI / All Cases
  physician: null,
  search: null,
  location: null,
  time: {
    start: '',
    end: '',
  },
  type: '',
  sort_by: 'start_time',
  // Edit Notes
  notesEditted: false,
  // getPendingCasesAPI
  monthToggle: false,
  owner_id: 0,
  year_month: null, // 'YYYYMM'
  patient_readiness: 'All',
  // Physician's Schedule
  physiciansCases: [],
  //Availability Times
  availabilityTimes: null,
  availabilityCalendar: null,
  //Rebook Patients
  date: null,
  rebookData: null,
  checklistsModal: null,
};

const practiceDashboardReducer = (state, action) => {
  switch (action.type) {
    case 'SetLoading': {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case 'SetCases': {
      return {
        ...state,
        cases: action.payload,
        isLoading: false,
      };
    }
    case 'SetWeekCases': {
      return {
        ...state,
        weekCases: action.payload,
        isLoading: false,
      };
    }
    case 'SetPrintCases': {
      return {
        ...state,
        printCases: action.payload,
      };
    }
    case 'SetNotesEditted':
      return {
        ...state,
        notesEditted: action.payload,
        isLoading: false,
      };
    case 'SetSettings': {
      return {
        ...state,
        settings: action.payload,
        isLoading: false,
      };
    }
    case 'SetPendingCases': {
      return {
        ...state,
        pendingCases: action.payload,
        isLoading: false,
      };
    }
    case 'SetPhysiciansSchedule':
      return {
        ...state,
        physiciansCases: action.payload,
        isLoading: false,
      };
    case 'SetAvailabilityTimes': {
      return {
        ...state,
        availabilityTimes: action.payload,
        isLoading: false,
      };
    }
    case 'SetAvailabilityCalendar': {
      return {
        ...state,
        availabilityCalendar: action.payload,
        isLoading: false,
      };
    }
    case 'SetRebookData': {
      return {
        ...state,
        rebookData: action.payload,
        isLoading: false,
      };
    }
    case 'SetMonthToggle': {
      return {
        ...state,
        monthToggle: action.payload,
      };
    }
    case 'SetTimeFilter':
      return {
        ...state,
        time: action.payload,
      };
    case 'SetDate': {
      return {
        ...state,
        date: action.payload,
      };
    }
    case 'UpdateFilterFieldValue':
      if (action.payload.key !== null) {
        return {
          ...state,
          [action.payload.key]: action.payload.value,
        };
      }
      return state;
    case 'GetChecklists':
      if (action.payload) {
        return {
          ...state,
          checklistsModal: action.payload,
        };
      }
      return state;
    case 'ClearChecklistsModal':
      return {
        ...state,
        checklistsModal: null,
      };
    case 'Error':
      // console.log('error :: ', action.payload)
      return {
        ...state,
        errorMessage: action.payload,
        statusMessage: '',
      };
    default:
      return state;
  }
};

const getCases =
  dispatch =>
  async (url, time, type, location, physician, search, sort_by, offset) => {
    dispatch({type: 'SetLoading', payload: true});
    if (!url) {
      return dispatch({type: 'Error', payload: 'Error from getCases: no url'});
    }
    let request = {
      filter: {
        time: {
          start: parseInt(time.start),
          end: parseInt(time.end),
        },
        type: type ? type : null,
        surgery_location: location,
        physician: physician,
        search: search,
        paginate: {
          limit: 10,
          offset: offset,
        },
        sort_by: sort_by,
      },
    };
    try {
      const response = await instance.post(url, request);
      if (!response) {
        return dispatch({type: 'Error', payload: `no response from ${url}`});
      } else {
        if (response.data) {
          dispatch({type: 'SetCases', payload: response.data});
        } else {
          console.log({response}, '==> all cases');
          return dispatch({
            type: 'Error',
            payload: 'Could not parse getCases from API.',
          });
        }
      }
    } catch (error) {
      return dispatch({type: 'Error', payload: error.message});
    }
  };

const updateCaseAttachments =
  (dispatch, state) => (event_id, updatedAttachements) => {
    const updatedCases = state.cases.cases.map(rowCase => {
      if (rowCase.event_id === event_id) {
        rowCase.attachments = updatedAttachements;
        return rowCase;
      }
      return rowCase;
    });

    const cases = {...state.cases, cases: updatedCases};

    dispatch({type: 'SetCases', payload: cases});
  };

const getWeekCases =
  dispatch =>
  async (url, time, type, location, physician, search, sort_by, offset) => {
    dispatch({type: 'SetLoading', payload: true});
    if (!url) {
      return dispatch({type: 'Error', payload: 'Error from getCases: no url'});
    }
    let request = {
      filter: {
        time: {
          start: parseInt(time.start),
          end: parseInt(time.end),
        },
        type: type ? type : null,
        surgery_location: location,
        physician: physician,
        search: search,
        paginate: {
          limit: 300,
          offset: offset,
        },
        sort_by: sort_by,
      },
    };
    try {
      const response = await instance.post(url, request);
      if (!response) {
        return dispatch({type: 'Error', payload: `no response from ${url}`});
      } else {
        if (response.data) {
          dispatch({type: 'SetWeekCases', payload: response.data});
        } else {
          // console.log({ response }, "==> week's cases")
          return dispatch({
            type: 'Error',
            payload: 'Could not parse getCases from API.',
          });
        }
      }
    } catch (error) {
      return dispatch({type: 'Error', payload: error.message});
    }
  };

const getPrintCases =
  dispatch =>
  async (
    url,
    time,
    type,
    location,
    physician,
    search,
    sort_by,
    offset,
    limit,
  ) => {
    if (!url) {
      return dispatch({type: 'Error', payload: 'Error from getCases: no url'});
    }
    let request = {
      filter: {
        time: {
          start: parseInt(time.start),
          end: parseInt(time.end),
        },
        type: type ? type : null,
        surgery_location: location,
        physician: physician,
        search: search,
        paginate: {
          limit: limit ? limit : 10,
          offset: offset,
        },
        sort_by: sort_by,
      },
    };
    try {
      const response = await instance.post(url, request);
      if (!response) {
        return dispatch({type: 'Error', payload: `no response from ${url}`});
      } else {
        if (response.data) {
          dispatch({type: 'SetPrintCases', payload: response.data});
        } else {
          return dispatch({
            type: 'Error',
            payload: 'Could not parse getCases from API.',
          });
        }
      }
    } catch (error) {
      return dispatch({type: 'Error', payload: error.message});
    }
  };

const editNotes = dispatch => async (url, eventId, notes) => {
  dispatch({type: 'SetLoading', payload: true});

  if (!url) {
    return dispatch({type: 'Error', payload: 'Error from editNotes: no url'});
  }
  let request = {
    event_id: eventId,
    notes: notes,
  };
  try {
    const response = await instance.post(url, request);
    if (!response) {
      return dispatch({type: 'Error', payload: `no response from ${url}`});
    } else {
      if (response.data) {
        dispatch({type: 'SetNotesEditted', payload: response.data});
      } else {
        return dispatch({
          type: 'Error',
          payload: 'Could not parse editNotes from API.',
        });
      }
    }
  } catch (error) {
    return dispatch({type: 'Error', payload: error.message});
  }
};

const setNotesEditted = dispatch => async boolean => {
  dispatch({type: 'SetNotesEditted', payload: boolean});
};

const getSettings = dispatch => async url => {
  dispatch({type: 'SetLoading', payload: true});
  if (!url) {
    return dispatch({type: 'Error', payload: 'Error from getSettings: no url'});
  }
  try {
    const request = {
      practice: null,
    };

    const response = await instance.post(url, request);
    if (!response) {
      return dispatch({type: 'Error', payload: `no response from ${url}`});
    } else {
      if (response.data) {
        dispatch({type: 'SetSettings', payload: response.data});
      } else {
        return dispatch({
          type: 'Error',
          payload: 'Could not parse getSettings from API.',
        });
      }
    }
  } catch (error) {
    return dispatch({type: 'Error', payload: error.message});
  }
};

const setRights = dispatch => async (url, physicians) => {
  dispatch({type: 'SetLoading', payload: true});
  if (!url) {
    return dispatch({type: 'Error', payload: 'Error from setRights: no url'});
  }
  const request = {
    physicians: physicians,
  };
  try {
    const response = await instance.post(url, request);
    if (!response) {
      return dispatch({type: 'Error', payload: `no response from ${url}`});
    } else {
      if (response.data) {
        // dispatch({ type: '???', payload: response.data })
      } else {
        return dispatch({
          type: 'Error',
          payload: 'Could not parse setRights from API.',
        });
      }
    }
  } catch (error) {
    return dispatch({type: 'Error', payload: error.message});
  }
};

const getPendingCases =
  dispatch => async (url, owner_id, year_month, patient_readiness, offset) => {
    dispatch({type: 'SetLoading', payload: true});
    if (!url) {
      return dispatch({
        type: 'Error',
        payload: 'Error from getPendingCases: no url',
      });
    }
    const request = {
      owner_id: parseInt(owner_id),
      year_month: year_month,
      patient_readiness: patient_readiness,
      paginate: {
        limit: 10,
        offset: offset,
      },
    };
    try {
      const response = await instance.post(url, request);
      if (!response) {
        return dispatch({type: 'Error', payload: `no response from ${url}`});
      } else {
        if (response.data) {
          dispatch({type: 'SetPendingCases', payload: response.data});
        } else {
          return dispatch({
            type: 'Error',
            payload: 'Could not parse getPendingCases from API.',
          });
        }
      }
    } catch (error) {
      return dispatch({type: 'Error', payload: error.message});
    }
  };

const getPhysiciansSchedule = dispatch => async (url, time, physician) => {
  dispatch({type: 'SetLoading', payload: true});
  if (!url) {
    return dispatch({
      type: 'Error',
      payload: 'Error from getPhysiciansSchedule: no url',
    });
  }
  const request = {
    filter: {
      time: time,
      physician: physician,
    },
  };
  try {
    const response = await instance.post(url, request);
    if (!response) {
      return dispatch({type: 'Error', payload: `no response from ${url}`});
    } else {
      if (response.data) {
        dispatch({
          type: 'SetPhysiciansSchedule',
          payload: response.data.schedule,
        });
      } else {
        return dispatch({
          type: 'Error',
          payload: 'Could not parse getPhysiciansSchedule from API.',
        });
      }
    }
  } catch (error) {
    return dispatch({type: 'Error', payload: error.message});
  }
};

const editEvents = dispatch => async (url, physician, events) => {
  dispatch({type: 'SetLoading', payload: true});
  if (!url) {
    return dispatch({type: 'Error', payload: 'Error from editEvents: no url'});
  }
  const request = {
    physician: physician,
    events: events,
  };
  try {
    const response = await instance.post(url, request);
    if (!response) {
      return dispatch({type: 'Error', payload: `no response from ${url}`});
    } else {
      if (response.data) {
        // console.log('response.data from editEvents', response.data)
        // dispatch({ type: 'SetPhysiciansSchedule', payload: response.data.schedule })
      } else {
        return dispatch({
          type: 'Error',
          payload: 'Could not parse editEvents from API.',
        });
      }
    }
  } catch (error) {
    return dispatch({type: 'Error', payload: error.message});
  }
};

const getAvailabilityTimes = dispatch => async (url, physician) => {
  // physician: '1, 2, 3', could be comma deliniated
  dispatch({type: 'SetLoading', payload: true});
  if (!url) {
    return dispatch({
      type: 'Error',
      payload: 'Error from getAvailabilityTimes: no url',
    });
  }
  const request = {
    user_ids: physician.toString(),
  };
  try {
    const response = await instance.post(url, request);
    if (!response) {
      return dispatch({type: 'Error', payload: `no response from ${url}`});
    } else {
      if (response.data) {
        dispatch({
          type: 'SetAvailabilityTimes',
          payload: response.data.availability[0].times,
        });
      } else {
        return dispatch({
          type: 'Error',
          payload: 'Could not parse getAvailabilityTimes from API.',
        });
      }
    }
  } catch (error) {
    return dispatch({type: 'Error', payload: error.message});
  }
};

const setAvailabilityTimes = dispatch => async (url, physician, times) => {
  dispatch({type: 'SetLoading', payload: true});
  if (!url) {
    return dispatch({
      type: 'Error',
      payload: 'Error from setAvailabilityTimes: no url',
    });
  }
  const request = {
    user_id: physician,
    times: times,
  };
  try {
    const response = await instance.post(url, request);
    if (!response) {
      return dispatch({type: 'Error', payload: `no response from ${url}`});
    } else {
      if (response.data) {
        dispatch({
          type: 'SetAvailabilityTimes',
          payload: response.data.availability.times,
        });
      } else {
        return dispatch({
          type: 'Error',
          payload: 'Could not parse setAvailabilityTimes from API.',
        });
      }
    }
  } catch (error) {
    return dispatch({type: 'Error', payload: error.message});
  }
};

const getAvailabilityCalendar =
  dispatch => async (url, start, end, physician) => {
    // physicianIds: '1, 2, 3'
    dispatch({type: 'SetLoading', payload: true});
    if (!url) {
      return dispatch({
        type: 'Error',
        payload: 'Error from getAvailabilityCalendar: no url',
      });
    }
    const request = {
      start: start,
      end: end,
      user_id: parseInt(physician),
    };
    try {
      const response = await instance.post(url, request);
      if (!response) {
        return dispatch({type: 'Error', payload: `no response from ${url}`});
      } else {
        if (response.data) {
          dispatch({
            type: 'SetAvailabilityCalendar',
            payload: response.data.dates,
          });
        } else {
          return dispatch({
            type: 'Error',
            payload: 'Could not parse getAvailabilityCalendar from API.',
          });
        }
      }
    } catch (error) {
      return dispatch({type: 'Error', payload: error.message});
    }
  };

const setAvailabilityCalendar = dispatch => async (url, physician, dates) => {
  dispatch({type: 'SetLoading', payload: true});
  if (!url) {
    return dispatch({
      type: 'Error',
      payload: 'Error from setAvailabilityCalendar: no url',
    });
  }
  const request = {
    user_id: parseInt(physician),
    dates: dates,
  };
  try {
    const response = await instance.post(url, request);
    if (!response) {
      return dispatch({type: 'Error', payload: `no response from ${url}`});
    } else {
      if (response.data) {
        // console.log(response.data)
        dispatch({
          type: 'SetAvailabilityCalendar',
          payload: response.data.success,
        });
      } else {
        return dispatch({
          type: 'Error',
          payload: 'Could not parse setAvailabilityCalendar from API.',
        });
      }
    }
  } catch (error) {
    return dispatch({type: 'Error', payload: error.message});
  }
};

const getRebookData =
  dispatch => async (url, date, physicianId, type, filter) => {
    dispatch({type: 'SetLoading', payload: true});

    if (!url) {
      return dispatch({
        type: 'Error',
        payload: 'Error from getRebookData: no url',
      });
    }
    const request = {
      date: date,
      physician_id: physicianId,
      type: type,
      filter: filter,
    };
    try {
      const response = await instance.post(url, request);
      if (!response) {
        return dispatch({type: 'Error', payload: `no response from ${url}`});
      } else {
        if (response.data) {
          dispatch({type: 'SetRebookData', payload: response.data});
        } else {
          return dispatch({
            type: 'Error',
            payload: 'Could not parse getRebookData from API.',
          });
        }
      }
    } catch (error) {
      return dispatch({type: 'Error', payload: error.message});
    }
  };

const changeTimes = dispatch => async (url, event_id, time) => {
  dispatch({type: 'SetLoading', payload: true});

  if (!url) {
    return dispatch({type: 'Error', payload: 'Error from changeTimes: no url'});
  }
  const request = {
    event_id: event_id,
    time: time,
  };
  try {
    const response = await instance.post(url, request);
    if (!response) {
      return dispatch({type: 'Error', payload: `no response from ${url}`});
    } else {
      if (response.data) {
        dispatch({type: 'SetLoading', payload: false});
      } else {
        return dispatch({
          type: 'Error',
          payload: 'Could not parse changeTimes from API.',
        });
      }
    }
  } catch (error) {
    return dispatch({type: 'Error', payload: error.message});
  }
};

const setTimeFilter = dispatch => async (startDate, endDate) => {
  dispatch({
    type: 'SetTimeFilter',
    payload: {start: startDate, end: endDate},
  });
};

const setDate = dispatch => async date => {
  //Rebook Date --> 20201212
  dispatch({type: 'SetDate', payload: date});
};

const setMonthToggle = dispatch => async boolean => {
  dispatch({type: 'SetMonthToggle', payload: boolean});
};

const updateFilterFieldValue = dispatch => (key, value) => {
  const payload = {
    key: key || null,
    value: value,
  };
  return dispatch({type: 'UpdateFilterFieldValue', payload: payload});
};

const getChecklists = dispatch => async (url, event_id) => {
  if (!url) {
    return dispatch({
      type: 'Error',
      payload: 'Error from getChecklists: no url',
    });
  }

  const request = {
    event_id: event_id, //must be AlomEncoded
  };

  try {
    const response = await instance.post(url, request);
    if (!response) {
      return dispatch({type: 'Error', payload: `no response from ${url}`});
    } else {
      if (response && response.status === 200 && response.data) {
        dispatch({type: 'GetChecklists', payload: response.data});
      } else {
        return dispatch({
          type: 'Error',
          payload: 'Could not parse checklists from API.',
        });
      }
    }
  } catch (error) {
    return dispatch({type: 'Error', payload: error.message});
  }
};

const clearChecklistsModal = dispatch => async () => {
  return dispatch({type: 'ClearChecklistsModal'});
};

const updateChecklistItemStatus =
  dispatch =>
  async (
    allChecklists,
    inputChecklistItem,
    newValue,
    calendarUpdateChecklistURL,
    eventId,
  ) => {
    if (allChecklists && inputChecklistItem) {
      const updatedChecklistItem = {
        ...inputChecklistItem,
        status: newValue,
      };

      const newChecklists = allChecklists.map(checklistGroup => {
        return {
          ...checklistGroup,
          checklist: checklistGroup.checklist.map(checklistItem => {
            if (updatedChecklistItem.id === checklistItem.id) {
              return updatedChecklistItem;
            }
            return checklistItem;
          }),
        };
      });

      if (calendarUpdateChecklistURL && eventId) {
        // call status update API
        let checklistStatusArray = [];

        newChecklists.forEach(checklistGroup => {
          checklistGroup.checklist.forEach(checklistItem => {
            checklistStatusArray.push({
              id: checklistItem.id,
              status: checklistItem.status,
            });
          });
        });

        const request = {
          event_id: parseInt(eventId),
          checklists: checklistStatusArray,
        };

        try {
          return await instance.post(calendarUpdateChecklistURL, request);
        } catch (error) {
          return dispatch({type: 'Error', payload: error.message});
        }
      }
      return;
    }
    return;
  };

export const {Provider, Context} = createDataContext(
  practiceDashboardReducer,
  {
    getCases,
    getWeekCases,
    getPrintCases,
    editNotes,
    setNotesEditted,
    getSettings,
    setRights,
    getPendingCases,
    getPhysiciansSchedule,
    editEvents,
    getAvailabilityTimes,
    setAvailabilityTimes,
    getAvailabilityCalendar,
    setAvailabilityCalendar,
    getRebookData,
    changeTimes,
    setMonthToggle,
    setTimeFilter,
    setDate,
    updateFilterFieldValue,
    getChecklists,
    clearChecklistsModal,
    updateCaseAttachments,
    updateChecklistItemStatus,
  },
  initialState,
);
