import createDataContext from './createDataContext';
import instance from '../dataAccess/docspera';
import {
  attendeesHash,
  billingCodesHash,
  locationsHash,
  preOpChecklistsHash,
  proceduresHash,
} from '../dataAccess/preferencesHash';
import {Base64} from 'js-base64';

const initialState = {
  isEventSettingsOpen: false,
  defaultVisibleTab: null,
  icd10: [],
  cpt: [],
  locations: [],
  procedures: [],
  // attendees: null,
  providers: [],
  reps: [],
  checklists: [],
  errorMessage: '',
};

const eventSettingsReducer = (state, action) => {
  switch (action.type) {
    case 'OpenEventSettings':
      return {
        ...state,
        isEventSettingsOpen: true,
        defaultVisibleTab: action.payload.defaultVisibleTab,
        icd10: action.payload.icd10,
        cpt: action.payload.cpt,
        locations: action.payload.locations,
        procedures: action.payload.procedures,
        providers: action.payload.providers,
        reps: action.payload.reps,
        checklists: action.payload.checklists.map(checklistGroup => {
          let checklistObjTemp = {
            id: checklistGroup.id,
            name: checklistGroup.name,
            checklist:
              checklistGroup.checklist.length > 0
                ? checklistGroup.checklist.map(checklistItem => {
                    return {
                      id: checklistItem.id,
                      name: checklistItem.name,
                    };
                  })
                : [
                    {
                      id: Date.now(),
                      name: '',
                    },
                  ],
          };

          if (checklistGroup.default && checklistGroup.default === 1) {
            checklistObjTemp.default = 1;
          }

          return checklistObjTemp;
        }),
      };
    case 'CloseEventSettings':
      return {
        ...state,
        isEventSettingsOpen: false,
      };
    case 'SetEventSettings':
      return {
        ...state,
        icd10: action.payload.icd10,
        cpt: action.payload.cpt,
        locations: action.payload.locations,
        procedures: action.payload.procedures,
        providers: action.payload.providers,
        reps: action.payload.reps,
        checklists: action.payload.checklists,
      };
    case 'SetProviders':
      return {
        ...state,
        providers: action.payload.providers,
      };
    case 'AddProvider':
      if (action.payload.id) {
        const providerAlreadyExists = state.providers.some(element => {
          return element.toString() === action.payload.id.toString();
        });

        if (providerAlreadyExists) {
          return state;
        } else {
          return {
            ...state,
            providers: [...state.providers, action.payload.id],
          };
        }
      }
      return state;
    case 'DeleteProvider':
      return {
        ...state,
        providers: state.providers.filter(
          providerId => providerId != action.payload,
        ),
      };
    case 'SetReps':
      return {
        ...state,
        reps: action.payload.reps,
      };
    case 'AddRep':
      if (action.payload.id) {
        const repAlreadyExists = state.reps.some(element => {
          return element.id === action.payload.id;
        });

        if (repAlreadyExists) {
          return state;
        } else {
          return {
            ...state,
            reps: [...state.reps, action.payload.id],
          };
        }
      }
      return state;
    case 'DeleteRep':
      return {
        ...state,
        reps: state.reps.filter(repId => repId != action.payload),
      };
    case 'AddBillingCode':
      if (action.payload) {
        if (action.payload.type && action.payload.type === 'icd') {
          const icdAlreadyExists = state.icd10.some(element => {
            return element.c === action.payload.c;
          });

          if (icdAlreadyExists) {
            return state;
          } else {
            const codeObj = {
              c: action.payload.c,
              d: action.payload.d,
            };

            return {
              ...state,
              icd10: [...state.icd10, codeObj],
            };
          }
        } else if (action.payload.type && action.payload.type === 'cpt') {
          const cptAlreadyExists = state.cpt.some(element => {
            return element.c === action.payload.c;
          });

          if (cptAlreadyExists) {
            return state;
          } else {
            const codeObj = {
              c: action.payload.c,
              d: action.payload.d,
            };

            return {
              ...state,
              cpt: [...state.cpt, codeObj],
            };
          }
        }
      }
      return state;
    case 'DeleteBillingCode':
      if (action.payload) {
        if (action.payload && action.payload.type === 'icd') {
          return {
            ...state,
            icd10: state.icd10.filter(code => code.c !== action.payload.code),
          };
        } else if (action.payload && action.payload.type === 'cpt') {
          return {
            ...state,
            cpt: state.cpt.filter(code => code.c !== action.payload.code),
          };
        }
      }
      return state;
    case 'UpdateLocation':
      if (action.payload) {
        return {
          ...state,
          locations: state.locations.map(location => {
            if (location.id == action.payload.id) {
              return action.payload;
            }
            return location;
          }),
        };
      }
      return state;
    case 'AddLocation':
      if (action.payload) {
        const newLocation = {
          id: action.payload,
          new: true,
          location: '',
          careteam: '',
          source: null,
        };

        return {
          ...state,
          locations: [...state.locations, newLocation],
        };
      }
      return state;
    case 'DeleteLocation':
      if (action.payload) {
        return {
          ...state,
          locations: state.locations.filter(
            location => location.id != action.payload,
          ),
        };
      }
      return state;
    case 'UpdateProcedure':
      if (action.payload) {
        return {
          ...state,
          procedures: state.procedures.map(procedure => {
            if (procedure.id == action.payload.id) {
              return action.payload;
            }
            return procedure;
          }),
        };
      }
      return state;
    case 'AddProcedure':
      if (action.payload) {
        const newProcedure = {
          id: action.payload,
          new: true,
          name: '',
          reps: '',
          providers: '',
          duration: 0,
          icd10: '',
          cpt: '',
          setup: '',
          devices: [],
        };

        return {
          ...state,
          procedures: [...state.procedures, newProcedure],
        };
      }
      return state;
    case 'DeleteProcedure':
      if (action.payload) {
        return {
          ...state,
          procedures: state.procedures.filter(
            procedure => procedure.id != action.payload,
          ),
        };
      }
      return state;
    case 'AddChecklistGroup':
      if (action.payload) {
        const newChecklistGroup = {
          id: action.payload,
          new: true,
          name: '',
          checklist: [
            {
              id: action.payload,
              name: '',
            },
          ],
        };

        return {
          ...state,
          checklists: [...state.checklists, newChecklistGroup],
        };
      }
      return state;
    case 'DeleteChecklistGroup':
      if (action.payload) {
        return {
          ...state,
          checklists: state.checklists.filter(
            checklist => checklist.id != action.payload,
          ),
        };
      }
      return state;
    case 'UpdateChecklistGroup':
      if (action.payload) {
        return {
          ...state,
          checklists: state.checklists.map(checklist => {
            if (checklist.id == action.payload.id) {
              return action.payload;
            }
            return checklist;
          }),
        };
      }
      return state;
    case 'SetDefaultChecklistGroup':
      if (action.payload.checked === true) {
        return {
          ...state,
          checklists: state.checklists.map(checklistGroup => {
            if (checklistGroup.id == action.payload.id) {
              return {
                ...checklistGroup,
                default: 1,
              };
            }
            return {
              ...checklistGroup,
              default: null,
            };
          }),
        };
      } else if (action.payload.checked === false) {
        return {
          ...state,
          checklists: state.checklists.map(checklistGroup => {
            return {
              ...checklistGroup,
              default: null,
            };
          }),
        };
      }
      return state;
    case 'ClearEventSettings':
      return initialState;
    case 'Error':
      return {
        ...state,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

const openEventSettings =
  dispatch => async (url, all, userId, defaultVisibleTab) => {
    if (isNaN(userId)) {
      return dispatch({
        type: 'Error',
        payload: 'Error from openEventSettings: userId is NaN',
      });
    }
    if (!url) {
      return dispatch({
        type: 'Error',
        payload: 'Error from openEventSettings: no url',
      });
    }

    const request = {
      all: all ? all : 0,
      id: userId,
      preferences: [
        locationsHash,
        billingCodesHash,
        proceduresHash,
        preOpChecklistsHash,
        attendeesHash,
      ],
    };

    if (request.preferences.length) {
      try {
        const response = await instance.post(url, request);

        if (!response.data) {
          return dispatch({
            type: 'Error',
            payload: `no response from ${url}`,
          });
        } else {
          if (
            response.data &&
            response.data.preferences &&
            response.data.preferences.length
          ) {
            let payload = {
              defaultVisibleTab: defaultVisibleTab
                ? defaultVisibleTab
                : 'procedures',
              icd10: [],
              cpt: [],
              locations: [],
              procedures: [],
              providers: [],
              reps: [],
              checklists: [],
            };

            response.data.preferences.forEach(preference => {
              const key = Object.keys(preference)[0];
              const value = preference[key];

              switch (key) {
                case billingCodesHash:
                  const billingCodes = JSON.parse(Base64.decode(value))
                    ? JSON.parse(Base64.decode(value))
                    : {};
                  payload.icd10 =
                    billingCodes.icd10 && billingCodes.icd10.length
                      ? billingCodes.icd10
                      : [];
                  payload.cpt =
                    billingCodes.cpt && billingCodes.cpt.length
                      ? billingCodes.cpt
                      : [];
                  break;
                case locationsHash:
                  const parsedLocations = JSON.parse(Base64.decode(value))
                    ? JSON.parse(Base64.decode(value))
                    : null;
                  payload.locations =
                    Array.isArray(parsedLocations) && parsedLocations.length
                      ? parsedLocations
                      : [];
                  break;
                case proceduresHash:
                  const parsedProcedures = JSON.parse(Base64.decode(value))
                    ? JSON.parse(Base64.decode(value))
                    : null;
                  payload.procedures =
                    parsedProcedures && parsedProcedures.procedures
                      ? parsedProcedures.procedures
                      : [];
                  break;
                case attendeesHash:
                  payload.providers = value ? value.providers.split(',') : [];
                  payload.reps = value ? value.reps.split(',') : [];
                  break;
                case preOpChecklistsHash:
                  payload.checklists = JSON.parse(Base64.decode(value))
                    ? JSON.parse(Base64.decode(value))
                    : [];
                  break;
              }
            });

            return dispatch({type: 'OpenEventSettings', payload: payload});
          } else {
            return dispatch({
              type: 'Error',
              payload: 'Could not parse Event Settings from API.',
            });
          }
        }
      } catch (error) {
        return dispatch({type: 'Error', payload: error.message});
      }
    }
    return;
  };

const closeEventSettings = dispatch => () => {
  return dispatch({type: 'CloseEventSettings'});
};

const getPreferences =
  dispatch => async (url, all, userId, preferencesHashArray) => {
    if (isNaN(userId)) {
      return dispatch({
        type: 'Error',
        payload: 'Error from getPreferences: invalid user ID',
      });
    }
    if (!url) {
      return dispatch({
        type: 'Error',
        payload: 'Error from getPreferences: no url',
      });
    }

    const request = {
      all: all ? all : 0,
      id: userId,
      preferences:
        preferencesHashArray && preferencesHashArray.length
          ? preferencesHashArray
          : [],
    };

    if (request.preferences.length) {
      try {
        const response = await instance.post(url, request);

        if (!response.data) {
          return dispatch({
            type: 'Error',
            payload: `no response from ${url}`,
          });
        } else {
          if (
            response.data &&
            response.data.preferences &&
            response.data.preferences.length
          ) {
            let payload = {
              icd10: [],
              cpt: [],
              locations: [],
              procedures: [],
              providers: [],
              reps: [],
              checklists: [],
            };

            response.data.preferences.forEach(preference => {
              const key = Object.keys(preference)[0];
              const value = preference[key];

              switch (key) {
                case billingCodesHash:
                  const billingCodes = JSON.parse(Base64.decode(value))
                    ? JSON.parse(Base64.decode(value))
                    : {};
                  payload.icd10 =
                    billingCodes.icd10 && billingCodes.icd10.length
                      ? billingCodes.icd10
                      : [];
                  payload.cpt =
                    billingCodes.cpt && billingCodes.cpt.length
                      ? billingCodes.cpt
                      : [];
                  break;
                case locationsHash:
                  const parsedLocations = JSON.parse(Base64.decode(value))
                    ? JSON.parse(Base64.decode(value))
                    : null;
                  payload.locations =
                    Array.isArray(parsedLocations) && parsedLocations.length
                      ? parsedLocations
                      : [];
                  break;
                case proceduresHash:
                  const parsedProcedures = JSON.parse(Base64.decode(value))
                    ? JSON.parse(Base64.decode(value))
                    : null;
                  payload.procedures =
                    parsedProcedures && parsedProcedures.procedures
                      ? parsedProcedures.procedures
                      : [];
                  break;
                case attendeesHash:
                  payload.providers = value ? value.providers.split(',') : [];
                  payload.reps = value ? value.reps.split(',') : [];
                  break;
                case preOpChecklistsHash:
                  payload.checklists = JSON.parse(Base64.decode(value))
                    ? JSON.parse(Base64.decode(value))
                    : [];
                  break;
              }
            });

            return dispatch({type: 'SetEventSettings', payload: payload});
          } else {
            return dispatch({
              type: 'Error',
              payload: 'Could not parse Preferences from API.',
            });
          }
        }
      } catch (error) {
        return dispatch({type: 'Error', payload: error.message});
      }
    }
    return;
  };

const setProviders = dispatch => providersArray => {
  return dispatch({type: 'SetProviders', payload: providersArray});
};

const setReps = dispatch => repsArray => {
  return dispatch({type: 'SetReps', payload: repsArray});
};

const addProvider = dispatch => provider => {
  const payload = {
    id: typeof provider.id !== 'undefined' ? provider.id : '',
    name: typeof provider.name !== 'undefined' ? provider.name : '',
    specialty:
      typeof provider.specialty !== 'undefined' ? provider.specialty : '',
    avatar: typeof provider.avatar !== 'undefined' ? provider.avatar : '',
  };

  return dispatch({type: 'AddProvider', payload: payload});
};

const deleteProvider = dispatch => id => {
  return dispatch({type: 'DeleteProvider', payload: id});
};

const addRep = dispatch => rep => {
  const payload = {
    id: typeof rep.id !== 'undefined' ? rep.id : '',
    name: typeof rep.name !== 'undefined' ? rep.name : '',
    company: typeof rep.company !== 'undefined' ? rep.company : '',
    avatar: typeof rep.avatar !== 'undefined' ? rep.avatar : '',
  };

  return dispatch({type: 'AddRep', payload: payload});
};

const deleteRep = dispatch => id => {
  return dispatch({type: 'DeleteRep', payload: id});
};

const addBillingCode = dispatch => (type, code) => {
  const payload = {
    type: type,
    c: code.code,
    d: code.description,
  };

  return dispatch({type: 'AddBillingCode', payload: payload});
};

const deleteBillingCode = dispatch => (type, code) => {
  const payload = {
    type: type,
    code: code,
  };

  return dispatch({type: 'DeleteBillingCode', payload: payload});
};

const addLocation = dispatch => async newLocationId => {
  await dispatch({type: 'AddLocation', payload: newLocationId});
  return;
};

const deleteLocation = dispatch => locationId => {
  return dispatch({type: 'DeleteLocation', payload: locationId});
};

const updateLocationName = dispatch => (location, newName) => {
  const payload = {
    ...location,
    location: newName || '',
  };

  return dispatch({type: 'UpdateLocation', payload: payload});
};

const addProviderToLocation = dispatch => (provider, source, location) => {
  let careteamArray = location.careteam ? location.careteam.split(',') : [];

  if (!careteamArray.includes(provider.id.toString())) {
    careteamArray.push(provider.id ? provider.id.toString() : '');
  }
  careteamArray = careteamArray.join(',');

  const payload = {
    ...location,
    careteam: careteamArray,
  };

  return dispatch({type: 'UpdateLocation', payload: payload});
};

const deleteProviderFromLocation = dispatch => (location, providerId) => {
  let careteamArray = location.careteam ? location.careteam.split(',') : [];
  careteamArray = careteamArray.filter(item => item != providerId).join(',');

  const payload = {
    id: location.id,
    location: location.location,
    source: location.source,
    careteam: careteamArray,
  };

  return dispatch({type: 'UpdateLocation', payload: payload});
};

const addProcedure = dispatch => async newProcedureId => {
  await dispatch({type: 'AddProcedure', payload: newProcedureId});
  return;
};

const deleteProcedure = dispatch => procedureId => {
  return dispatch({type: 'DeleteProcedure', payload: procedureId});
};

const updateProcedureField = dispatch => (procedure, key, newValue) => {
  if (procedure && key) {
    procedure = {
      ...procedure,
      [key]: newValue,
    };

    return dispatch({type: 'UpdateProcedure', payload: procedure});
  }
  return;
};

const addDeviceToProcedure = dispatch => procedure => {
  if (procedure) {
    let devicesArray =
      procedure.devices && procedure.devices.length ? procedure.devices : [];
    devicesArray.push({
      id: Date.now(),
      name: '',
    });

    procedure = {
      ...procedure,
      devices: devicesArray,
    };

    return dispatch({type: 'UpdateProcedure', payload: procedure});
  }
  return;
};

const deleteDeviceFromProcedure = dispatch => (procedure, inputDeviceId) => {
  const devicesArray = procedure.devices.filter(
    device => device.id != inputDeviceId,
  );

  procedure = {
    ...procedure,
    devices: devicesArray,
  };

  return dispatch({type: 'UpdateProcedure', payload: procedure});
};

const addProviderToProcedure =
  dispatch => (provider, source, location, procedure) => {
    let providersArray = procedure.providers
      ? procedure.providers.split(',')
      : [];

    if (!providersArray.includes(provider.id.toString())) {
      providersArray.push(provider.id ? provider.id.toString() : '');
    }

    providersArray = providersArray.join(',');

    procedure = {
      ...procedure,
      providers: providersArray,
    };

    return dispatch({type: 'UpdateProcedure', payload: procedure});
  };

const deleteProviderFromProcedure = dispatch => (procedure, providerId) => {
  let providersArray = procedure.providers
    ? procedure.providers.split(',')
    : [];
  providersArray = providersArray.filter(item => item != providerId).join(',');

  procedure = {
    ...procedure,
    providers: providersArray,
  };

  return dispatch({type: 'UpdateProcedure', payload: procedure});
};

const addBillingCodeToProcedure = dispatch => (codeType, code, procedure) => {
  if (codeType === 'icd') {
    let newArray = procedure.icd10 ? procedure.icd10.split(', ') : [];
    newArray.push(code.code);
    procedure.icd10 = newArray.join(', ');
  }

  if (codeType === 'cpt') {
    let newArray = procedure.cpt ? procedure.cpt.split(', ') : [];
    newArray.push(code.code);
    procedure.cpt = newArray.join(', ');
  }

  return dispatch({type: 'UpdateProcedure', payload: procedure});
};

const deleteBillingCodeFromProcedure =
  dispatch => (codeType, code, procedure) => {
    if (codeType === 'icd') {
      procedure.icd10 = procedure.icd10
        .split(', ')
        .filter(icdCode => icdCode !== code)
        .join(', ');
    }

    if (codeType === 'cpt') {
      procedure.cpt = procedure.cpt
        .split(', ')
        .filter(cptCode => cptCode !== code)
        .join(', ');
    }

    return dispatch({type: 'UpdateProcedure', payload: procedure});
  };

const addRepToProcedure = dispatch => (rep, source, location, procedure) => {
  let repsArray = procedure.reps ? procedure.reps.split(',') : [];
  repsArray.push(rep.id ? rep.id.toString() : '');
  repsArray = repsArray.join(',');

  procedure = {
    ...procedure,
    reps: repsArray,
  };

  return dispatch({type: 'UpdateProcedure', payload: procedure});
};

const deleteRepFromProcedure = dispatch => (procedure, repId) => {
  let repsArray = procedure.reps ? procedure.reps.split(',') : [];
  repsArray = repsArray.filter(item => item != repId).join(',');

  procedure = {
    ...procedure,
    reps: repsArray,
  };

  return dispatch({type: 'UpdateProcedure', payload: procedure});
};

const updateProcedureDevice =
  dispatch => (procedure, inputDevice, newValue) => {
    if (procedure && inputDevice) {
      const updatedDevice = {
        ...inputDevice,
        name: newValue,
      };

      procedure = {
        ...procedure,
        devices: procedure.devices.map(device => {
          if (updatedDevice.id === device.id) {
            return updatedDevice;
          }
          return device;
        }),
      };

      return dispatch({type: 'UpdateProcedure', payload: procedure});
    }
    return;
  };

const addChecklistGroup = dispatch => async newChecklistGroupId => {
  await dispatch({type: 'AddChecklistGroup', payload: newChecklistGroupId});
  return;
};

const deleteChecklistGroup = dispatch => checklistGroupId => {
  return dispatch({type: 'DeleteChecklistGroup', payload: checklistGroupId});
};

const updateChecklistGroupField =
  dispatch => (checklistGroup, key, newValue) => {
    if (checklistGroup && key) {
      checklistGroup = {
        ...checklistGroup,
        [key]: newValue,
      };

      return dispatch({
        type: 'UpdateChecklistGroup',
        payload: checklistGroup,
      });
    }
    return;
  };

const addChecklistItem = dispatch => (checklistGroup, checklistItemId) => {
  if (checklistGroup) {
    let checklistItemsArray =
      checklistGroup.checklist && checklistGroup.checklist.length
        ? checklistGroup.checklist
        : [];
    checklistItemsArray.push({
      id: checklistItemId,
      name: '',
      status: 'TBD',
    });

    checklistGroup = {
      ...checklistGroup,
      checklist: checklistItemsArray,
    };

    return dispatch({type: 'UpdateChecklistGroup', payload: checklistGroup});
  }
  return;
};

const updateChecklistItem =
  dispatch => (checklistGroup, inputChecklistItem, newValue) => {
    if (checklistGroup && inputChecklistItem) {
      const updatedChecklistItem = {
        ...inputChecklistItem,
        name: newValue,
      };

      checklistGroup = {
        ...checklistGroup,
        checklist: checklistGroup.checklist.map(checklistItem => {
          if (updatedChecklistItem.id === checklistItem.id) {
            return updatedChecklistItem;
          }
          return checklistItem;
        }),
      };

      return dispatch({
        type: 'UpdateChecklistGroup',
        payload: checklistGroup,
      });
    }
    return;
  };

const deleteChecklistItem =
  dispatch => (checklistGroup, inputChecklistItemId) => {
    const checklistItemsArray = checklistGroup.checklist.filter(
      checklistItem => checklistItem.id != inputChecklistItemId,
    );

    checklistGroup = {
      ...checklistGroup,
      checklist: checklistItemsArray,
    };

    return dispatch({type: 'UpdateChecklistGroup', payload: checklistGroup});
  };

const setDefaultChecklistGroup = dispatch => (checklistGroupId, newValue) => {
  if (checklistGroupId) {
    const payload = {
      id: checklistGroupId,
      checked: newValue,
    };

    return dispatch({type: 'SetDefaultChecklistGroup', payload: payload});
  }
  return;
};

const saveEventSettings = dispatch => async (url, inputObj, userId) => {
  if (isNaN(userId)) {
    return;
  }
  if (!url) {
    return dispatch({
      type: 'Error',
      payload: 'Error from saveEventSettings: no url',
    });
  }

  if (inputObj && userId) {
    const preferencesHashArr = [
      billingCodesHash,
      locationsHash,
      preOpChecklistsHash,
      attendeesHash,
      proceduresHash,
    ];
    const requestPayload = hash => {
      let proceduresObj;

      switch (hash) {
        case billingCodesHash:
          const billingCodesObj = {
            icd10: inputObj.icd10,
            cpt: inputObj.cpt,
          };

          return {
            id: userId,
            preferences: [
              {
                [billingCodesHash]: Base64.encode(
                  JSON.stringify(billingCodesObj),
                ),
              },
            ],
          };
        case locationsHash:
          const locationsObj = {
            user_id: userId,
            locations: inputObj.locations
              .filter(location => {
                if (!location.location) {
                  return false;
                }
                return true;
              })
              .map(location => {
                return {
                  id: location.new ? '' : location.id,
                  location: location.location,
                  careteam: location.careteam,
                };
              }),
          };

          return {
            id: userId,
            preferences: [
              {
                [locationsHash]: Base64.encode(JSON.stringify(locationsObj)),
              },
            ],
          };
        case proceduresHash:
          proceduresObj = {
            user_id: userId,
            procedures: inputObj.procedures
              .filter(procedure => {
                if (!procedure.name) {
                  return false;
                }
                return true;
              })
              .map(procedure => {
                return {
                  id: procedure.new ? '' : procedure.id.toString(),
                  cpt: procedure.cpt,
                  duration: procedure.duration,
                  icd10: procedure.icd10,
                  name: procedure.name,
                  providers: procedure.providers,
                  reps: procedure.reps,
                  setup: procedure.setup,
                  devices: procedure.devices.map(device => {
                    return {
                      name: device.name ? device.name : '',
                    };
                  }),
                };
              }),
          };

          return {
            id: userId,
            preferences: [
              {
                [proceduresHash]: Base64.encode(JSON.stringify(proceduresObj)),
              },
            ],
          };
        case attendeesHash:
          const attendeesArr = [];

          inputObj.providers.forEach(provider => {
            attendeesArr.push(provider);
          });

          inputObj.reps.forEach(rep => {
            attendeesArr.push(rep);
          });

          return {
            id: userId,
            preferences: [
              {
                [attendeesHash]: attendeesArr.join(','),
              },
            ],
          };
        case preOpChecklistsHash:
          const checklistObj = inputObj.checklists.map(checklistGroup => {
            let checklistObjTemp = {
              id: checklistGroup.new ? '' : checklistGroup.id.toString(),
              name: checklistGroup.name
                ? checklistGroup.name
                : 'Unnamed Checklist Group',
              checklist: checklistGroup.checklist.map(checklistItem => {
                return {
                  name: checklistItem.name
                    ? checklistItem.name
                    : 'Unnamed Checklist Item',
                };
              }),
            };

            if (checklistGroup.default && checklistGroup.default === 1) {
              checklistObjTemp.default = 1;
            }

            return checklistObjTemp;
          });

          return {
            id: userId,
            preferences: [
              {
                [preOpChecklistsHash]: Base64.encode(
                  JSON.stringify(checklistObj),
                ),
              },
            ],
          };
        default:
          return null;
      }
    };

    preferencesHashArr.forEach(async hash => {
      if (requestPayload(hash)) {
        try {
          return await instance.post(url, requestPayload(hash));
        } catch (error) {
          return dispatch({type: 'Error', payload: error.message});
        }
      }
    });
  }
  return;
};

const clearEventSettings = dispatch => async () => {
  return dispatch({type: 'ClearEventSettings'});
};

export const {Provider, Context} = createDataContext(
  eventSettingsReducer,
  {
    openEventSettings,
    closeEventSettings,
    getPreferences,
    setProviders,
    addProvider,
    deleteProvider,
    setReps,
    addRep,
    deleteRep,
    addBillingCode,
    deleteBillingCode,
    addLocation,
    deleteLocation,
    updateLocationName,
    addProviderToLocation,
    deleteProviderFromLocation,
    addProcedure,
    deleteProcedure,
    updateProcedureField,
    addDeviceToProcedure,
    updateProcedureDevice,
    deleteDeviceFromProcedure,
    addProviderToProcedure,
    deleteProviderFromProcedure,
    addBillingCodeToProcedure,
    deleteBillingCodeFromProcedure,
    addRepToProcedure,
    deleteRepFromProcedure,
    addChecklistGroup,
    deleteChecklistGroup,
    updateChecklistGroupField,
    addChecklistItem,
    updateChecklistItem,
    deleteChecklistItem,
    setDefaultChecklistGroup,
    saveEventSettings,
    clearEventSettings,
  },
  initialState,
);
