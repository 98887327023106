export const showHideClinicalHash = 'b9e816d56f8d58fe134538b94d5c5916ebcabeff';
export const scheduleNotificationsHash =
  'af88821d9160e2f0eb7d08a4d6480128267d8fbf';
export const autoDeleteMessagesHash =
  '34634aaaa2f41630b49609139eba7713fbd8581b';
export const emailNotificationHash = 'ca9571de7e179527ee155ac844ea00c30ae5778d';
export const dailyDigestEmailHash = '2171fcef47026db6d0b6a45d2f30d5dfe921708b';
export const billingCodesHash = '1e79b8eebea616548eb818afe1e0a7c0ed1009f5';
export const locationsHash = '26af1c5b64d9c1a622bba9b4c7131c0ec272ba77';
export const proceduresHash = '47bdbb2173433290ad926a5a9bfc80d6d1d9b7f0';
export const attendeesHash = '95b7b705a38cb8e9cd529ca2dc7c0c799fb4bbf1';
export const preOpChecklistsHash = 'c5e50762858d6292c14357f29313cf0cb961d792';
