import React, {useContext, useEffect, useState} from 'react';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import Docbox from '../../Docbox';
import EventSettings from '../../EventSettings';
import {triggerOpenDocbox} from '../../../libraries/util';
import {AlomEncodeType2} from '../../../libraries/helpers';
import {Context as AuthContext} from '../../../context/AuthContext';
import {Context as DocboxContext} from '../../../context/DocboxContext';
import {Context as GlobalContext} from '../../../context/GlobalContext';
import {Context as PDContext} from '../../../context/PracticeDashboardContext';
import {getUserPreferencesURI} from '../../../dataAccess/apiEndpoints';
import {Context as EventSettingsContext} from '../../../context/EventSettingsContext';
import {
  attendeesHash,
  billingCodesHash,
  locationsHash,
  preOpChecklistsHash,
  proceduresHash,
} from '../../../dataAccess/preferencesHash';

import './CalendarPlugin.scss';

const CalendarPlugin = ({
  events,
  workingHours,
  rescheduledEvents,
  setRescheduledEvents,
}) => {
  const {state: pdState} = useContext(PDContext);
  const {state: docboxState, openDocbox} = useContext(DocboxContext);
  const {state: authState} = useContext(AuthContext);
  const {state: eventSettingsState, getPreferences} =
    useContext(EventSettingsContext);
  const {state: globalState} = useContext(GlobalContext);
  const [formattedEvents, setFormattedEvents] = useState();

  const calendarRef = React.createRef();

  useEffect(() => {
    if (pdState.physician && globalState.config.ABS_BASE_URL) {
      const preferencesHashArray = [
        locationsHash,
        billingCodesHash,
        proceduresHash,
        preOpChecklistsHash,
        attendeesHash,
      ];
      const url = `${globalState.config.ABS_BASE_URL}${getUserPreferencesURI}`;
      getPreferences(url, 0, pdState.physician, preferencesHashArray);
    }
  }, [pdState.physician, globalState.config.ABS_BASE_URL]);

  useEffect(() => {
    const findTypeStyles = type => {
      switch (type) {
        case 'Surgery':
          return 'surgery';
        case 'Clinic':
          return 'clinical';
        case 'Vacation':
          return 'vacation';
        case 'Conference':
          return 'conference';
        default:
          return;
      }
    };

    //format events
    if (events) {
      let newArray = [];
      events.forEach(event => {
        newArray.push({
          id: AlomEncodeType2(event.id),
          title: event.case,
          start: new Date(
            moment(event.time.start, 'YYYYMMDDHHmmss'),
          ).toISOString(),
          end: new Date(moment(event.time.end, 'YYYYMMDDHHmmss')).toISOString(),
          extendedProps: {
            location: event.surgery_location,
            duration: event.time.length,
            patientName: event.patient.name,
            type: event.type,
          },
          classNames: ['event', findTypeStyles(event.type)],
        });
      });
      setFormattedEvents(newArray);
    }
  }, [events]);

  useEffect(() => {
    // When user selects a date from CalendarPicker
    let calendarApi = calendarRef.current.getApi();
    if (pdState.time.start) {
      let parsableDate = new Date(moment(pdState.time.start)).toISOString();
      calendarApi.gotoDate(parsableDate);
    }
  }, [pdState.time]);

  const handleEventDrop = e => {
    let index = rescheduledEvents.findIndex(function (o) {
      return o.event_id === e.event._def.publicId;
    });
    if (index !== -1) rescheduledEvents.splice(index, 1);
    let alteredEvent = {
      event_id: e.event._def.publicId,
      starttime: `${moment(e.event.start).format('YYYYMMDDHHmmss')}`,
      endtime: `${moment(e.event.end).format('YYYYMMDDHHmmss')}`,
    };
    setRescheduledEvents(prevState => [...prevState, alteredEvent]);
  };

  const handleEventClick = clickInfo => {
    // const mode = 'View'
    const eventId = clickInfo.event._def.publicId;
    console.log({eventId});
    triggerOpenDocbox(eventId);
    // const viewAsUserId = authState.userId ? authState.userId : null
    // if (viewAsUserId && globalState.config.ABS_BASE_URL) {
    //   const url = `${globalState.config.ABS_BASE_URL}${viewCaseURI}`
    //   openDocbox(mode, eventId, url, viewAsUserId)
    // }
  };

  const findBGColor = eventInfo => {
    switch (eventInfo.event._def.extendedProps.type) {
      case 'Surgery':
        return 'var(--calendar-surgery)';
      case 'Clinical':
        return 'var(--calendar-clinical)';
      case 'Vacation':
        return 'var(--calendar-vacation)';
      case 'Conference':
        return 'var(--calendar-conference)';
      default:
        return;
    }
  };

  const checkPermissions =
    authState.practicePermissions &&
    authState.practicePermissions[0] &&
    authState.practicePermissions[0].permission > 1;

  function renderEventContent(eventInfo) {
    return (
      <div className="relative">
        {/* Hover Description */}
        {/* <div className='absolute' style={{ backgroundColor: findBGColor(eventInfo) }}>
          <span className='title' style={{ color: 'black' }}>
            {eventInfo.event.title}
          </span>
          <span
            className='time'
            style={{ color: 'black' }}
          >{`${eventInfo.timeText} (${eventInfo.event._def.extendedProps.duration})`}</span>
          <span className='location' style={{ color: 'black' }}>
            {eventInfo.event._def.extendedProps.location.name}
          </span>
          <span className='patient' style={{ color: 'black' }}>
            {eventInfo.event._def.extendedProps.patientName}
          </span>
        </div> */}

        {/* Default Description */}
        <div>
          <span className="title">{eventInfo.event.title}</span>
          <span className="time">{`${eventInfo.timeText} (${eventInfo.event._def.extendedProps.duration})`}</span>
          {eventInfo.event._def.extendedProps.type !== 'Vacation' &&
            eventInfo.event._def.extendedProps.type !== 'Conference' && (
              <span className="location">
                {eventInfo.event._def.extendedProps.location.name}
              </span>
            )}
          <span className="patient">
            {eventInfo.event._def.extendedProps.patientName}
          </span>
        </div>
      </div>
    );
  }

  return (
    <>
      <FullCalendar
        ref={calendarRef}
        plugins={[timeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        dayHeaderFormat={{
          weekday: 'short',
          month: 'short',
          day: '2-digit',
          omitCommas: true,
        }}
        events={formattedEvents}
        eventContent={renderEventContent}
        eventClick={e => handleEventClick(e)}
        allDaySlot={false}
        editable={checkPermissions ? true : false}
        eventStartEditable={checkPermissions ? true : false}
        eventDrop={e => handleEventDrop(e)}
        eventResize={e => handleEventDrop(e)}
        slotDuration="00:15:00"
        slotLabelInterval={'00:30:00'}
        nowIndicator
        scrollTime={moment().subtract(15, 'minutes').format('HH:mm:ss')}
        businessHours={workingHours}
      />
      {docboxState.isDocboxOpen && <Docbox />}
      {eventSettingsState.isEventSettingsOpen && <EventSettings />}
    </>
  );
};

export default CalendarPlugin;
