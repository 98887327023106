import moment from 'moment';
import instance from '../dataAccess/docspera';
import fileUploadInstance from '../dataAccess/fileUpload';
import createDataContext from './createDataContext';
import {
  convertHeight,
  convertInchesToFtIn,
  convertWeight,
  determineAvailabilityHour,
  docboxCaseHasPatient,
  isIndustryRep,
} from '../libraries/helpers';
import {
  determineClosest15MinInterval,
  differenceBtwnTwoIsos,
} from '../libraries/moment';

let initialState = {
  isDocboxOpen: false,
  mode: null,
  modules: null,
  eventId: null,
  calendar: null,
  target: null,
  eventOwnerName: '',
  eventOwnerId: null,
  threadId: null,
  phiConsent: null,
  eventCode: null,
  created: '',
  editor: '',
  edited: '',
  eventType: '',
  caseName: '',
  startDate: '',
  startTime: '',
  endDate: '',
  endTime: '',
  duration: null,
  caseNotes: '',
  schedulingNotes: '',
  setupNotes: '',
  attachments: [],
  location: {
    practiceId: '',
    practice: '',
    address: '',
    building: '',
    room: '',
    status: '',
  },
  locationInput: '',
  patientFirst: '',
  patientLast: '',
  mrn: '',
  dobYear: '',
  dobMonth: '',
  dobDay: '',
  patientStatus: 'Tentative',
  patientId: '',
  arrivalRequest: '',
  patientPhone: '',
  patientMobilePhone: '',
  insuranceProvider01: '',
  insuranceAuthorization01: '',
  insurancePolicy01: '',
  insuranceGroup01: '',
  insuranceCopay01: '',
  insuranceDeductible01: '',
  insuranceType01: '',
  insuranceProvider02: '',
  insuranceAuthorization02: '',
  insurancePolicy02: '',
  insuranceGroup02: '',
  insuranceCopay02: '',
  insuranceDeductible02: '',
  insuranceType02: '',
  gender: '',
  height: 0,
  heightInput01: '',
  heightInput02: '',
  weight: 0,
  weightInput01: '',
  heightUSCustomary: 0,
  weightUSCustomary: 0,
  units: 'US Customary',
  ethnicity: '',
  email: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  emergencyName01: '',
  emergencyPhone01: '',
  emergencyName02: '',
  emergencyPhone02: '',
  allergiesStatus: '',
  allergiesDetails: '',
  anesthesiaStatus: '',
  anesthesiaDetails: '',
  comorbiditiesStatus: '',
  comorbiditiesDetails: '',
  providerNotes: '',
  bpciPatient: 'No',
  bpciId: null,
  devices: [],
  checklistClearedLinked: 'No',
  patientCleared: 'N/A',
  checklists: [],
  icd: [],
  cpt: [],
  ivf: '',
  ebl: '',
  uop: '',
  dictationCode: '',
  postOpNotes: '',
  patientClass: '',
  service: '',
  laterality: '',
  diagnosis: '',
  requestedBy: '',
  requestedPhone: '',
  lengthStay: '',
  admitPriorSurgery: '',
  preadmitReason: '',
  precertStatus: '',
  authorizedFrom: '',
  authorizedTo: '',
  authorizedComments: '',
  historyComments: '',
  reasonCancellation: '',
  rebookDates: [],
  cmsBluebuttonInvitations: [],
  physicalTherapy: [],
  hccReport: {},
  survey: {},
  providers: [],
  reps: [],
  suggestedTimes: [],
  clashes: [],
  showAside: false,
  errorMessage: '',
  statusMessage: '',
};

const getModulesObj = (eventType, docboxMode, userType, licenseObj) => {
  let output = {};
  switch (eventType) {
    case 'Clinic':
      output.brief = 1;
      if (userType !== null && !isIndustryRep(userType)) {
        output.case = {
          checklists: 1,
          equipment: 1,
          files: 1,
          attendees: 1,
        };
      } else {
        output.case = {
          equipment: 1,
          files: 1,
          attendees: 1,
        };
      }
      if (docboxMode && docboxMode === 'View') {
        if (userType !== null && !isIndustryRep(userType)) {
          output.patient = {
            details: 1,
            conditions: 1,
            contacts: 1,
          };
        } else {
          output.patient = {
            details: 1,
          };
        }
      } else {
        if (userType !== null && !isIndustryRep(userType)) {
          output.patient = {
            details: 1,
            conditions: 1,
            contacts: 1,
            availability: 1,
          };
        }
      }
      if (userType !== null && !isIndustryRep(userType)) {
        output.billing = {
          diagnostic_codes: 1,
          insurance: 1,
        };
      }
      output.audit = 1;
      break;
    case 'Conference':
      output.brief = 1;
      output.case = {
        attendees: 1,
      };
      output.audit = 1;
      break;
    case 'Vacation':
      output.brief = 1;
      output.case = 1;
      output.audit = 1;
      break;
    default:
      output.brief = 1;
      if (userType !== null && !isIndustryRep(userType)) {
        output.case = {
          checklists: 1,
          equipment: 1,
          files: 1,
          attendees: 1,
        };
      } else {
        output.case = {
          equipment: 1,
          files: 1,
          attendees: 1,
        };
      }
      if (docboxMode && docboxMode === 'View') {
        if (userType !== null && !isIndustryRep(userType)) {
          output.patient = {
            details: 1,
            conditions: 1,
            contacts: 1,
          };
        } else {
          output.patient = {
            details: 1,
          };
        }
      } else {
        if (userType !== null && !isIndustryRep(userType)) {
          output.patient = {
            details: 1,
            conditions: 1,
            contacts: 1,
            availability: 1,
          };
        }
      }
      if (userType !== null && !isIndustryRep(userType)) {
        output.billing = {
          diagnostic_codes: 1,
          insurance: 1,
        };
      }
      if (userType !== null && !isIndustryRep(userType)) {
        output.post_op = 1;
      }
      output.audit = 1;
  }
  return output;
};

const docboxReducer = (state, action) => {
  switch (action.type) {
    case 'OpenDocbox':
      if (action.payload.mode === 'View') {
        const response = action.payload.response;
        const event = response.event;
        const owner = response.owner;
        const sampleAttachments = [
          {
            comment: '',
            dicom: [],
            id: '31535',
            name: 'pass_and_priv.pdf',
            owner_id: 1883,
            phi: 0,
            thumb: '',
            title: '',
            type: 'PDF',
            url: 'https://docvisor.com/cabinet/preview/1883/30323/3898aaadff5878bd31270d760971f03c.pdf',
            video: [],
          },
          {
            comment: '',
            dicom: [],
            id: '31536',
            name: 'Setting_up_your_VPN_Connection_-_Step_2_of_2_July_2018.docx',
            owner_id: 1883,
            phi: 0,
            thumb:
              'https://docvisor.com/cabinet/t/1883/30323/5f64b31095713831d3786689ebf6bb55.docx',
            title: '',
            type: 'DOCX',
            url: 'https://docvisor.com/cabinet/preview/1883/30323/5f64b31095713831d3786689ebf6bb55.docx',
            video: [],
          },
          {
            id: '31758',
            type: 'Image',
            owner_id: 2185,
            phi: 0,
            name: 'flavor-wheel-en.png',
            url: 'https://docvisor.com/cabinet/preview/2185/31724/e9dd780156548677ef7ae5acb5c3b4ff.jpg',
            thumb:
              'https://docvisor.com/cabinet/t/2185/31724/e9dd780156548677ef7ae5acb5c3b4ff.jpg',
            title: '',
            comment: '',
            video: [],
            dicom: [],
          },
        ];
        const samplePatientIntakeSurvey = {
          status: 0,
          count: 0,
          timestamp: 20210728120000,
        };

        return {
          ...state,
          isDocboxOpen: true,
          mode: action.payload.mode,
          modules: getModulesObj(
            event.event_type,
            action.payload.mode,
            action.payload.userType,
          ),
          eventId: event.event_id,
          calendar: event.calendar,
          target: 'target' in event && event.target === 1 ? 1 : 0,
          eventOwnerId:
            owner.user.id && !isNaN(parseInt(owner.user.id))
              ? parseInt(owner.user.id)
              : null,
          eventCode: event.event_code ? event.event_code : null,
          threadId: event.thread_id ? event.thread_id : null,
          phiConsent: event.phi_consent,
          eventOwnerName: owner.user.name,
          created:
            event.audit.length &&
            typeof event.audit[event.audit.length - 1].date !== 'undefined'
              ? event.audit[event.audit.length - 1].date.toString()
              : '',
          editor:
            event.audit.length &&
            typeof event.audit[0].user.name !== 'undefined'
              ? event.audit[0].user.name
              : '',
          edited:
            event.audit.length && typeof event.audit[0].date !== 'undefined'
              ? event.audit[0].date.toString()
              : '',
          eventType: event.event_type,
          caseName: event.case,
          caseNotes: event.details,
          schedulingNotes: event.notes,
          setupNotes: event.setup,
          attachments: event.attachments ? event.attachments : [],
          // attachments: sampleAttachments,
          providers: event.attendees.providers ? event.attendees.providers : [],
          reps: event.attendees.reps ? event.attendees.reps : [],
          startDate: moment(
            event.time.start.toString(),
            'YYYYMMDDHHmmss',
          ).format('YYYYMMDD'),
          startTime: moment(
            event.time.start.toString(),
            'YYYYMMDDHHmmss',
          ).format('HHmm'),
          endDate: moment(event.time.end.toString(), 'YYYYMMDDHHmmss').format(
            'YYYYMMDD',
          ),
          endTime: moment(event.time.end.toString(), 'YYYYMMDDHHmmss').format(
            'HHmm',
          ),
          duration: differenceBtwnTwoIsos(
            event.time.start.toString(),
            event.time.end.toString(),
            false,
          ),
          location: {
            practiceId: event.location.practice_id,
            practice: event.location.practice,
            address: event.location.address,
            building: event.location.building,
            room: event.location.room,
            status: event.location.status,
          },
          locationInput: event.location.address,
          patientFirst:
            docboxCaseHasPatient(event) && event.patient.name
              ? event.patient.name.first
              : '',
          patientLast:
            docboxCaseHasPatient(event) && event.patient.name
              ? event.patient.name.last
              : '',
          mrn:
            docboxCaseHasPatient(event) && event.patient.mr
              ? event.patient.mr
              : '',
          dobYear:
            docboxCaseHasPatient(event) &&
            event.patient.demographics &&
            event.patient.demographics.dob
              ? event.patient.demographics.dob.slice(0, 4)
              : '',
          dobMonth:
            docboxCaseHasPatient(event) &&
            event.patient.demographics &&
            event.patient.demographics.dob
              ? event.patient.demographics.dob.slice(4, 6)
              : '',
          dobDay:
            docboxCaseHasPatient(event) &&
            event.patient.demographics &&
            event.patient.demographics.dob
              ? event.patient.demographics.dob.slice(6, 8)
              : '',
          patientStatus: docboxCaseHasPatient(event)
            ? event.patient.status
            : '',
          patientId:
            docboxCaseHasPatient(event) && event.patient.patient_id
              ? event.patient.patient_id
              : '',
          arrivalRequest:
            docboxCaseHasPatient(event) && event.patient.arrival_request
              ? event.patient.arrival_request.toString()
              : '',
          patientPhone: docboxCaseHasPatient(event) ? event.patient.phone : '',
          patientMobilePhone: docboxCaseHasPatient(event)
            ? event.patient.mobile_phone
            : '',
          insuranceProvider01:
            docboxCaseHasPatient(event) &&
            event.patient.insurances &&
            event.patient.insurances.length &&
            event.patient.insurances[0]
              ? event.patient.insurances[0].provider
              : '',
          insuranceAuthorization01:
            docboxCaseHasPatient(event) &&
            event.patient.insurances &&
            event.patient.insurances.length &&
            event.patient.insurances[0]
              ? event.patient.insurances[0].auth
              : '',
          insurancePolicy01:
            docboxCaseHasPatient(event) &&
            event.patient.insurances &&
            event.patient.insurances.length &&
            event.patient.insurances[0]
              ? event.patient.insurances[0].policy
              : '',
          insuranceGroup01:
            docboxCaseHasPatient(event) &&
            event.patient.insurances &&
            event.patient.insurances.length &&
            event.patient.insurances[0]
              ? event.patient.insurances[0].group
              : '',
          insuranceCopay01:
            docboxCaseHasPatient(event) &&
            event.patient.insurances &&
            event.patient.insurances.length &&
            event.patient.insurances[0]
              ? event.patient.insurances[0].co_pay
              : '',
          insuranceDeductible01:
            docboxCaseHasPatient(event) &&
            event.patient.insurances &&
            event.patient.insurances.length &&
            event.patient.insurances[0]
              ? event.patient.insurances[0].deductible
              : '',
          insuranceType01:
            docboxCaseHasPatient(event) &&
            event.patient.insurances &&
            event.patient.insurances.length &&
            event.patient.insurances[0]
              ? event.patient.insurances[0].type
              : '',
          insuranceProvider02:
            docboxCaseHasPatient(event) &&
            event.patient.insurances &&
            event.patient.insurances.length &&
            event.patient.insurances[1]
              ? event.patient.insurances[1].provider
              : '',
          insuranceAuthorization02:
            docboxCaseHasPatient(event) &&
            event.patient.insurances &&
            event.patient.insurances.length &&
            event.patient.insurances[1]
              ? event.patient.insurances[1].auth
              : '',
          insurancePolicy02:
            docboxCaseHasPatient(event) &&
            event.patient.insurances &&
            event.patient.insurances.length &&
            event.patient.insurances[1]
              ? event.patient.insurances[1].policy
              : '',
          insuranceGroup02:
            docboxCaseHasPatient(event) &&
            event.patient.insurances &&
            event.patient.insurances.length &&
            event.patient.insurances[1]
              ? event.patient.insurances[1].group
              : '',
          insuranceCopay02:
            docboxCaseHasPatient(event) &&
            event.patient.insurances &&
            event.patient.insurances.length &&
            event.patient.insurances[1]
              ? event.patient.insurances[1].co_pay
              : '',
          insuranceDeductible02:
            docboxCaseHasPatient(event) &&
            event.patient.insurances &&
            event.patient.insurances.length &&
            event.patient.insurances[1]
              ? event.patient.insurances[1].deductible
              : '',
          insuranceType02:
            docboxCaseHasPatient(event) &&
            event.patient.insurances &&
            event.patient.insurances.length &&
            event.patient.insurances[1]
              ? event.patient.insurances[1].type
              : '',
          // insurances: (docboxCaseHasPatient(event) && event.patient.insurances) ? event.patient.insurances : [],
          gender: docboxCaseHasPatient(event)
            ? event.patient.demographics.gender
            : '',
          height:
            docboxCaseHasPatient(event) && event.patient.demographics.height
              ? parseFloat(event.patient.demographics.height)
              : 0,
          heightUSCustomary:
            docboxCaseHasPatient(event) &&
            event.patient.demographics.height !== 0
              ? convertHeight(event.patient.demographics.height)
              : 0,
          heightInput01:
            docboxCaseHasPatient(event) &&
            event.patient.demographics.height !== 0 &&
            convertHeight(event.patient.demographics.height)
              ? convertInchesToFtIn(
                  convertHeight(event.patient.demographics.height),
                )[0].toString()
              : '',
          heightInput02:
            docboxCaseHasPatient(event) &&
            event.patient.demographics.height !== 0 &&
            convertHeight(event.patient.demographics.height)
              ? convertInchesToFtIn(
                  convertHeight(event.patient.demographics.height),
                )[1].toString()
              : '',
          weight:
            docboxCaseHasPatient(event) && event.patient.demographics.weight
              ? parseFloat(
                  parseFloat(event.patient.demographics.weight).toFixed(2),
                )
              : 0,
          weightUSCustomary:
            docboxCaseHasPatient(event) &&
            event.patient.demographics.weight !== 0
              ? convertWeight(event.patient.demographics.weight)
              : 0,
          weightInput01:
            docboxCaseHasPatient(event) &&
            event.patient.demographics.weight !== 0
              ? convertWeight(event.patient.demographics.weight).toString()
              : '',
          ethnicity: docboxCaseHasPatient(event)
            ? event.patient.demographics.ethnicity
            : '',
          email:
            docboxCaseHasPatient(event) && event.patient.supplemental
              ? event.patient.supplemental.email
              : '',
          addressLine1:
            docboxCaseHasPatient(event) && event.patient.supplemental
              ? event.patient.supplemental.home.line_1
              : '',
          addressLine2:
            docboxCaseHasPatient(event) && event.patient.supplemental
              ? event.patient.supplemental.home.line_2
              : '',
          city:
            docboxCaseHasPatient(event) && event.patient.supplemental
              ? event.patient.supplemental.home.city
              : '',
          state:
            docboxCaseHasPatient(event) && event.patient.supplemental
              ? event.patient.supplemental.home.state
              : '',
          zip:
            docboxCaseHasPatient(event) && event.patient.supplemental
              ? event.patient.supplemental.home.zip
              : '',
          country:
            docboxCaseHasPatient(event) && event.patient.supplemental
              ? event.patient.supplemental.home.country
              : '',
          emergencyName01:
            docboxCaseHasPatient(event) &&
            event.patient.supplemental &&
            event.patient.supplemental.emergency.length &&
            typeof event.patient.supplemental.emergency[0] != 'undefined'
              ? event.patient.supplemental.emergency[0].name
              : '',
          emergencyPhone01:
            docboxCaseHasPatient(event) &&
            event.patient.supplemental &&
            event.patient.supplemental.emergency.length &&
            typeof event.patient.supplemental.emergency[0] != 'undefined'
              ? event.patient.supplemental.emergency[0].phone
              : '',
          emergencyName02:
            docboxCaseHasPatient(event) &&
            event.patient.supplemental &&
            event.patient.supplemental.emergency.length &&
            typeof event.patient.supplemental.emergency[1] != 'undefined'
              ? event.patient.supplemental.emergency[1].name
              : '',
          emergencyPhone02:
            docboxCaseHasPatient(event) &&
            event.patient.supplemental &&
            event.patient.supplemental.emergency.length &&
            typeof event.patient.supplemental.emergency[1] != 'undefined'
              ? event.patient.supplemental.emergency[1].phone
              : '',
          allergiesStatus:
            docboxCaseHasPatient(event) && event.patient.supplemental
              ? event.patient.supplemental.medical.conditions.allergies.status
              : '',
          allergiesDetails:
            docboxCaseHasPatient(event) &&
            event.patient.supplemental &&
            event.patient.supplemental.medical.conditions.allergies.details
              ? event.patient.supplemental.medical.conditions.allergies.details
              : '',
          anesthesiaStatus:
            docboxCaseHasPatient(event) && event.patient.supplemental
              ? event.patient.supplemental.medical.conditions.anesthesia.status
              : '',
          anesthesiaDetails:
            docboxCaseHasPatient(event) &&
            event.patient.supplemental &&
            event.patient.supplemental.medical.conditions.anesthesia.details
              ? event.patient.supplemental.medical.conditions.anesthesia.details
              : '',
          comorbiditiesStatus:
            docboxCaseHasPatient(event) && event.patient.supplemental
              ? event.patient.supplemental.medical.conditions.comorbidities
                  .status
              : '',
          comorbiditiesDetails:
            docboxCaseHasPatient(event) &&
            event.patient.supplemental &&
            event.patient.supplemental.medical.conditions.comorbidities.details
              ? event.patient.supplemental.medical.conditions.comorbidities
                  .details
              : '',
          providerNotes:
            docboxCaseHasPatient(event) && event.patient.supplemental
              ? event.patient.supplemental.notes
              : '',
          bpciPatient:
            docboxCaseHasPatient(event) &&
            event.patient.bpci_id &&
            event.patient.bpci_id !== null &&
            parseInt(event.patient.bpci_id) > 0
              ? '1'
              : '0',
          bpciId:
            docboxCaseHasPatient(event) &&
            event.patient.bpci_id &&
            event.patient.bpci_id !== null
              ? parseInt(event.patient.bpci_id)
              : 0,
          devices: event.equipment.devices.length
            ? event.equipment.devices
            : [],
          checklistClearedLinked:
            event.checklist_cleared_linked || state.checklistClearedLinked,
          patientCleared: event.patient_cleared || state.patientCleared,
          checklists: event.checklists ? event.checklists : [],
          // checklist: (event.checklist) ? event.checklist : {}, //deprecated checklist obj
          icd: event.codes.icd.length ? event.codes.icd : [],
          cpt: event.codes.cpt.length ? event.codes.cpt : [],
          ivf: event.post_op.ivf ? event.post_op.ivf : '',
          ebl: event.post_op.ebl ? event.post_op.ebl : '',
          uop: event.post_op.uop ? event.post_op.uop : '',
          dictationCode: event.post_op.dictation_code
            ? event.post_op.dictation_code
            : '',
          postOpNotes: event.post_op.notes ? event.post_op.notes : '',
          patientClass: event.patient_class,
          service: event.service,
          laterality: event.laterality,
          diagnosis: event.diagnosis,
          requestedBy: event.requested.by,
          requestedPhone: event.requested.phone,
          lengthStay: event.length_stay !== null ? event.length_stay : '',
          admitPriorSurgery:
            event.admit_prior_surgery !== null ? event.admit_prior_surgery : '',
          preadmitReason: event.preadmit_reason,
          precertStatus: event.precert_status,
          authorizedFrom:
            event.authorized.from !== null
              ? event.authorized.from.toString().slice(0, 8)
              : '',
          authorizedTo:
            event.authorized.to !== null
              ? event.authorized.to.toString().slice(0, 8)
              : '',
          authorizedComments: event.authorized.comments,
          historyComments: event.history_comments,
          reasonCancellation:
            typeof event.reason_cancellation !== 'undefined'
              ? event.reason_cancellation
              : '',
          rebookDates: event.rebook_dates ? event.rebook_dates : [],
          cmsBluebuttonInvitations:
            event.cms_bluebutton_invitations &&
            event.cms_bluebutton_invitations.length
              ? event.cms_bluebutton_invitations
              : [],
          physicalTherapy:
            event.physical_therapy && event.physical_therapy.length
              ? event.physical_therapy
              : [],
          hccReport: event.hcc_report ? event.hcc_report : {},
          survey: event.survey ? event.survey : {},
          // survey: samplePatientIntakeSurvey
        };
      } else {
        return {
          ...state,
          isDocboxOpen: true,
          mode: action.payload.mode,
          eventOwnerId: action.payload.eventOwnerId,
          eventOwnerName: action.payload.eventOwnerName,
          startDate: action.payload.startDate,
          startTime: action.payload.startTime,
          endDate: action.payload.endDate,
          endTime: action.payload.endTime,
          duration: action.payload.duration,
          modules: getModulesObj(
            'Surgery',
            action.payload.mode,
            action.payload.userType,
          ),
        };
      }
    case 'CloseDocbox':
      return initialState;
    case 'UpdateDocboxMode':
      return {
        ...state,
        mode: action.payload.mode,
        modules: getModulesObj(
          action.payload.eventType,
          action.payload.mode,
          action.payload.userType,
        ),
      };
    case 'UpdateEventType':
      if (action.payload.eventType) {
        return {
          ...state,
          eventType: action.payload.eventType,
          modules: getModulesObj(
            action.payload.eventType,
            null,
            action.payload.userType,
          ),
        };
      }
      return state;
    case 'UpdateFormFieldValue':
      if (action.payload.key !== null) {
        return {
          ...state,
          [action.payload.key]: action.payload.value,
        };
      }
      return state;
    case 'AddProvider':
      const providerAlreadyExists = state.providers.some(element => {
        return element.id === action.payload.id;
      });

      if (providerAlreadyExists) {
        return state;
      } else {
        return {
          ...state,
          providers: [...state.providers, action.payload],
        };
      }
    case 'DeleteProvider':
      return {
        ...state,
        providers: state.providers.filter(
          provider => provider.id !== action.payload,
        ),
      };
    case 'AddRep':
      const repAlreadyExists = state.reps.some(element => {
        return element.id === action.payload.id;
      });

      if (repAlreadyExists) {
        return state;
      } else {
        return {
          ...state,
          reps: [...state.reps, action.payload],
        };
      }
    case 'DeleteRep':
      return {
        ...state,
        reps: state.reps.filter(rep => rep.id !== action.payload),
      };
    case 'AddDevice':
      return {
        ...state,
        devices: [...state.devices, action.payload],
      };
    case 'UpdateDevice':
      let targetIndex = null;
      const newDevices = [...state.devices];

      for (let i = 0; i < state.devices.length; i++) {
        if (state.devices[i].id === action.payload.id) {
          targetIndex = i;
          break;
        }
      }

      if (targetIndex !== null && targetIndex >= 0) {
        newDevices.splice(targetIndex, 1, action.payload);

        return {
          ...state,
          devices: newDevices,
        };
      } else {
        return state;
      }
    case 'DeleteDevice':
      return {
        ...state,
        devices: state.devices.filter(device => device.id !== action.payload),
      };
    case 'AddChecklistGroup':
      const checklistAlreadyExists = state.checklists.some(element => {
        return element.original_id === action.payload.id;
      });

      if (checklistAlreadyExists) {
        return state;
      } else {
        return {
          ...state,
          checklists: [...state.checklists, action.payload],
        };
      }
    case 'DeleteChecklistGroup':
      if (action.payload) {
        return {
          ...state,
          checklists: state.checklists.filter(
            checklist => checklist.id != action.payload,
          ),
        };
      }
      return state;
    case 'UpdateAllChecklistGroups':
      if (action.payload) {
        return {
          ...state,
          checklists: action.payload,
        };
      }
      return state;
    case 'UpdateChecklistGroup':
      if (action.payload) {
        return {
          ...state,
          checklists: state.checklists.map(checklist => {
            if (checklist.id == action.payload.id) {
              return action.payload;
            }
            return checklist;
          }),
        };
      }
      return state;
    case 'SetDocboxActiveNavItem':
      return {
        ...state,
        activeNavItem: action.payload,
      };
    case 'UpdateHeight':
      return {
        ...state,
        heightInput01: action.payload.heightInput01,
        heightInput02: action.payload.heightInput02,
        heightUSCustomary: action.payload.heightUSCustomary,
        height: action.payload.height,
      };
    case 'UpdateWeight':
      return {
        ...state,
        weightInput01: action.payload.weightInput01,
        weightUSCustomary: action.payload.weightUSCustomary,
        weight: action.payload.weight,
      };
    case 'UpdateUnits':
      return {
        ...state,
        units: action.payload.units,
        height: action.payload.height,
        heightInput01: action.payload.heightInput01,
        heightInput02: action.payload.heightInput02,
        heightUSCustomary: action.payload.heightUSCustomary,
        weight: action.payload.weight,
        weightInput01: action.payload.weightInput01,
        weightUSCustomary: action.payload.weightUSCustomary,
      };
    case 'UpdateBPCIPatient':
      return {
        ...state,
        bpciPatient: action.payload.bpciPatient,
        bpciId: action.payload.bpciId,
      };
    case 'UpdateTimeAndDuration':
      return {
        ...state,
        startDate: action.payload.startDate,
        startTime: action.payload.startTime,
        endDate: action.payload.endDate,
        endTime: action.payload.endTime,
        duration: action.payload.duration,
      };
    case 'UpdateTime':
      return {
        ...state,
        startDate: action.payload.startDate,
        startTime: action.payload.startTime,
        endDate: action.payload.endDate,
        endTime: action.payload.endTime,
      };
    case 'UpdateRebookDates':
      return {
        ...state,
        rebookDates: action.payload,
      };
    case 'SelectMyProcedure':
      const endDate = moment(state.startDate + state.startTime, 'YYYYMMDDHHmm')
        .add(action.payload.duration, 'minutes')
        .format('YYYYMMDD');
      const endTime = moment(state.startDate + state.startTime, 'YYYYMMDDHHmm')
        .add(action.payload.duration, 'minutes')
        .format('HHmm');

      // remove providers that came from a procedure and no other source
      let newProvidersArray = state.providers.filter(provider => {
        let fromProcedure = false;
        let fromAnotherSource = false;
        for (const [key, value] of Object.entries(provider.source)) {
          if (key === 'procedure' && value !== 0) {
            fromProcedure = true;
          }
          if (key !== 'procedure' && value === 1) {
            fromAnotherSource = true;
          }
        }
        if (fromProcedure && !fromAnotherSource) {
          return false;
        }
        return true;
      });

      // add new providers
      if (Array.isArray(action.payload.providers)) {
        action.payload.providers.forEach(provider => {
          const providerAlreadyExists = newProvidersArray.some(element => {
            return element.id === provider.id;
          });
          if (!providerAlreadyExists) {
            newProvidersArray.push(provider);
          }
        });
      }

      // remove reps that came from a procedure and no other source
      let newRepsArray = state.reps.filter(rep => {
        let fromProcedure = false;
        let fromAnotherSource = false;
        for (const [key, value] of Object.entries(rep.source)) {
          if (key === 'procedure' && value !== 0) {
            fromProcedure = true;
          }
          if (key !== 'procedure' && value === 1) {
            fromAnotherSource = true;
          }
        }
        if (fromProcedure && !fromAnotherSource) {
          return false;
        }
        return true;
      });

      // add new reps
      if (Array.isArray(action.payload.reps)) {
        action.payload.reps.forEach(rep => {
          const repAlreadyExists = newRepsArray.some(element => {
            return element.id === rep.id;
          });
          if (!repAlreadyExists) {
            newRepsArray.push(rep);
          }
        });
      }

      return {
        ...state,
        caseName: action.payload.caseName,
        duration: action.payload.duration,
        setupNotes: action.payload.setupNotes,
        cpt: action.payload.cpt,
        icd: action.payload.icd,
        devices: action.payload.devices,
        endDate: endDate,
        endTime: endTime,
        providers: newProvidersArray,
        reps: newRepsArray,
      };
    case 'SelectMyLocation':
      // remove providers that came from a location and no other source
      let newProvidersLocationArray = state.providers.filter(provider => {
        let fromLocation = false;
        let fromAnotherSource = false;
        for (const [key, value] of Object.entries(provider.source)) {
          if (key === 'location' && value !== 0) {
            fromLocation = true;
          }
          if (key !== 'location' && value === 1) {
            fromAnotherSource = true;
          }
        }
        if (fromLocation && !fromAnotherSource) {
          return false;
        }
        return true;
      });

      // add new providers
      if (Array.isArray(action.payload.providers)) {
        action.payload.providers.forEach(provider => {
          const providerAlreadyExists = newProvidersLocationArray.some(
            element => {
              return element.id === provider.id;
            },
          );
          if (!providerAlreadyExists) {
            newProvidersLocationArray.push(provider);
          }
        });
      }

      return {
        ...state,
        locationInput: action.payload.locationInput,
        providers: newProvidersLocationArray,
      };
    case 'AddBillingCode':
      if (action.payload) {
        if (action.payload.type && action.payload.type === 'icd') {
          const icdAlreadyExists = state.icd.some(element => {
            return element == action.payload.code;
          });

          if (icdAlreadyExists) {
            return state;
          } else {
            return {
              ...state,
              icd: [...state.icd, action.payload.code],
            };
          }
        } else if (action.payload.type && action.payload.type === 'cpt') {
          const cptAlreadyExists = state.cpt.some(element => {
            return element === action.payload.code;
          });

          if (cptAlreadyExists) {
            return state;
          } else {
            return {
              ...state,
              cpt: [...state.cpt, action.payload.code],
            };
          }
        }
      }
      return state;
    case 'DeleteBillingCode':
      if (action.payload) {
        if (action.payload && action.payload.type === 'icd') {
          return {
            ...state,
            icd: state.icd.filter(code => code !== action.payload.code),
          };
        } else if (action.payload && action.payload.type === 'cpt') {
          return {
            ...state,
            cpt: state.cpt.filter(code => code !== action.payload.code),
          };
        }
      }
      return state;
    case 'SetSuggestedTimes':
      if (action.payload && Array.isArray(action.payload)) {
        return {
          ...state,
          suggestedTimes: action.payload,
        };
      }
      return state;
    case 'AddAttachment':
      const attachmentAlreadyExists = state.attachments.some(element => {
        return element.id === action.payload.id;
      });

      if (attachmentAlreadyExists) {
        return state;
      } else {
        return {
          ...state,
          attachments: [...state.attachments, action.payload],
        };
      }
    case 'DeleteAttachment':
      if (action.payload) {
        return {
          ...state,
          attachments: state.attachments.filter(
            attachment => attachment.id != action.payload,
          ),
        };
      }
      return state;
    case 'UpdateFilePHI':
      if (action.payload) {
        return {
          ...state,
          attachments: state.attachments.map(attachment => {
            if (attachment.id == action.payload.id) {
              return action.payload;
            }
            return attachment;
          }),
        };
      }
      return state;
    case 'SetShowDocboxAside':
      if (action.payload) {
        return {
          ...state,
          showAside: action.payload,
        };
      }
      return state;
    case 'GetClashes':
      if (action.payload) {
        return {
          ...state,
          clashes: action.payload,
        };
      }
      return state;
    case 'error':
      console.log('error :: ', action.payload);
      return {
        ...state,
        errorMessage: action.payload,
        statusMessage: '',
      };
    default:
      return state;
  }
};

const openDocbox =
  dispatch =>
  async (
    mode,
    eventId,
    url,
    targetUserId,
    targetOwnerName,
    loggedInUserType,
    start,
    end,
    eventType,
    returnData = false,
  ) => {
    if (mode === 'Create') {
      const startDateTime = start
        ? determineClosest15MinInterval(start)
        : determineClosest15MinInterval(moment().format('YYYYMMDDHHmmss'));
      const endDateTime = end
        ? determineClosest15MinInterval(end)
        : determineClosest15MinInterval(
            moment(startDateTime).add(1, 'hours').format('YYYYMMDDHHmmss'),
          );
      const startDate = moment(startDateTime, 'YYYYMMDDHHmmss').format(
        'YYYYMMDD',
      );
      const startTime = moment(startDateTime, 'YYYYMMDDHHmmss').format('HHmm');
      const endDate = moment(endDateTime, 'YYYYMMDDHHmmss').format('YYYYMMDD');
      const endTime = moment(endDateTime, 'YYYYMMDDHHmmss').format('HHmm');
      const payload = {
        mode: mode,
        eventId: eventId ? eventId : null,
        eventOwnerId: targetUserId || null,
        eventOwnerName: targetOwnerName ? targetOwnerName : '',
        userType: loggedInUserType !== null ? loggedInUserType : null,
        startDate: startDate,
        startTime: startTime,
        endDate: endDate,
        endTime: endTime,
        duration: differenceBtwnTwoIsos(startDateTime, endDateTime, false),
      };
      const eventTypePayload = {
        eventType: 'Surgery',
        userType: loggedInUserType !== null ? loggedInUserType : null,
      };

      //open docbox dispatch
      dispatch({type: 'OpenDocbox', payload: payload});
      //update event type dispatch
      dispatch({type: 'UpdateEventType', payload: eventTypePayload});
      //add device dispatch
      dispatch({
        type: 'AddDevice',
        payload: {id: Date.now(), name: '', size: ''},
      });
      return;
    }
    if (mode === 'View') {
      if (eventId && !isNaN(parseInt(eventId)) && targetUserId > 0) {
        if (!url) {
          return dispatch({
            type: 'Error',
            payload: 'Error from openDocbox: no url',
          });
        }

        try {
          const request = {
            event_id: eventId,
            view_as: targetUserId,
          };

          const response = await instance.post(url, request);

          if (!response.data) {
            return dispatch({
              type: 'Error',
              payload: `no response from ${url}`,
            });
          } else {
            if (response.data && response.data.event) {
              const payload = {
                mode: mode,
                userType: loggedInUserType !== null ? loggedInUserType : null,
                response: response.data,
              };

              if (returnData) {
                return payload;
              } else {
                //open docbox dispatch
                return dispatch({type: 'OpenDocbox', payload: payload});
              }
            } else {
              return dispatch({
                type: 'Error',
                payload: 'Could not parse Case details from API.',
              });
            }
          }
        } catch (error) {
          return dispatch({
            type: 'Error',
            payload: `Error from openDocbox: ${error.message}`,
          });
        }
      }
      return;
    }
    if (mode === 'Edit') {
      if (eventId && !isNaN(parseInt(eventId)) && targetUserId > 0) {
        if (!url) {
          return dispatch({
            type: 'Error',
            payload: 'Error from openDocbox: no url',
          });
        }

        try {
          const request = {
            event_id: eventId,
            view_as: targetUserId,
          };

          const response = await instance.post(url, request);

          if (!response.data) {
            return dispatch({
              type: 'Error',
              payload: `no response from ${url}`,
            });
          } else {
            if (response.data && response.data.event) {
              const payload = {
                mode: mode,
                userType: loggedInUserType !== null ? loggedInUserType : null,
                response: response.data,
              };

              // open docbox dispatch
              return dispatch({type: 'OpenDocbox', payload: payload});
            } else {
              return dispatch({
                type: 'Error',
                payload: 'Could not parse Case details from API.',
              });
            }
          }
        } catch (error) {
          return dispatch({
            type: 'Error',
            payload: `Error from openDocbox: ${error.message}`,
          });
        }
      } else {
        if (eventType && mode && loggedInUserType !== null) {
          const payload = {
            eventType: eventType,
            userType: loggedInUserType,
            mode: mode,
          };
          return dispatch({type: 'UpdateDocboxMode', payload: payload});
        }
        return;
      }
    }
  };

const closeDocbox = dispatch => async () => {
  return dispatch({type: 'CloseDocbox'});
};

const updateEventType = dispatch => (eventType, userType) => {
  const payload = {
    eventType: eventType,
    userType: userType,
  };
  return dispatch({type: 'UpdateEventType', payload: payload});
};

const updateFormFieldValue = dispatch => (key, value) => {
  const payload = {
    key: key || null,
    value: value,
  };
  return dispatch({type: 'UpdateFormFieldValue', payload: payload});
};

const addProvider = dispatch => (attendee, source) => {
  const payload = {
    id: attendee.id,
    name: attendee.name,
    specialty: attendee.specialty,
    avatar: attendee.avatar,
    source: source,
  };
  return dispatch({type: 'AddProvider', payload: payload});
};

const deleteProvider = dispatch => id => {
  return dispatch({type: 'DeleteProvider', payload: id});
};

const addRep = dispatch => (attendee, source) => {
  const payload = {
    id: attendee.id,
    name: attendee.name,
    company: attendee.company,
    avatar: attendee.avatar,
    source: source,
  };
  return dispatch({type: 'AddRep', payload: payload});
};

const deleteRep = dispatch => id => {
  return dispatch({type: 'DeleteRep', payload: id});
};

const addDevice = dispatch => (id, name, size) => {
  const payload = {
    id: id ? id : Date.now(),
    name: name ? name : '',
    size: size ? size : '',
  };
  return dispatch({type: 'AddDevice', payload: payload});
};

const updateDevice = dispatch => (id, name, size) => {
  const payload = {
    id: id,
    name: name,
    size: size,
  };
  return dispatch({type: 'UpdateDevice', payload: payload});
};

const deleteDevice = dispatch => id => {
  return dispatch({type: 'DeleteDevice', payload: id});
};

const addChecklistGroup = dispatch => async checklistGroup => {
  const payload = {
    id: checklistGroup && checklistGroup.id ? checklistGroup.id : Date.now(),
    name: checklistGroup && checklistGroup.name ? checklistGroup.name : '',
    new: true,
    checklist:
      checklistGroup && checklistGroup.checklist
        ? checklistGroup.checklist.map(checklistItem => {
            return {
              id: checklistItem.id ? checklistItem.id : Date.now(),
              name: checklistItem.name ? checklistItem.name : '',
              new: true,
              status: 'TBD',
            };
          })
        : [
            {
              id: Date.now(),
              name: '',
              new: true,
              status: 'TBD',
            },
          ],
    original_id: checklistGroup && checklistGroup.id ? checklistGroup.id : 0,
  };
  return dispatch({type: 'AddChecklistGroup', payload: payload});
};

const deleteChecklistGroup = dispatch => async checklistGroupId => {
  await dispatch({type: 'DeleteChecklistGroup', payload: checklistGroupId});
  return;
};

const updateChecklistGroupField =
  dispatch => (checklistGroup, key, newValue) => {
    if (checklistGroup && key) {
      checklistGroup = {
        ...checklistGroup,
        [key]: newValue,
      };

      return dispatch({
        type: 'UpdateChecklistGroup',
        payload: checklistGroup,
      });
    }
    return;
  };

const addChecklistItem = dispatch => (checklistGroup, checklistItemId) => {
  if (checklistGroup) {
    let checklistItemsArray =
      checklistGroup.checklist && checklistGroup.checklist.length
        ? checklistGroup.checklist
        : [];
    checklistItemsArray.push({
      id: checklistItemId,
      name: '',
      status: 'TBD',
      new: true,
    });

    checklistGroup = {
      ...checklistGroup,
      checklist: checklistItemsArray,
    };

    return dispatch({type: 'UpdateChecklistGroup', payload: checklistGroup});
  }
  return;
};

const updateChecklistItem =
  dispatch => (checklistGroup, inputChecklistItem, newValue) => {
    if (checklistGroup && inputChecklistItem) {
      let updatedChecklistItem = null;

      if (newValue !== undefined && newValue !== null) {
        updatedChecklistItem = {
          ...inputChecklistItem,
          name: newValue,
        };
      } else {
        updatedChecklistItem = inputChecklistItem;
      }

      checklistGroup = {
        ...checklistGroup,
        checklist: checklistGroup.checklist.map(checklistItem => {
          if (updatedChecklistItem.id === checklistItem.id) {
            return updatedChecklistItem;
          }
          return checklistItem;
        }),
      };

      return dispatch({
        type: 'UpdateChecklistGroup',
        payload: checklistGroup,
      });
    }
    return;
  };

const updateChecklistItemStatus =
  dispatch =>
  async (
    allChecklists,
    inputChecklistItem,
    newValue,
    calendarUpdateChecklistURL,
    eventId,
  ) => {
    if (allChecklists && inputChecklistItem) {
      // update reducer
      const updatedChecklistItem = {
        ...inputChecklistItem,
        status: newValue,
      };

      const newChecklists = allChecklists.map(checklistGroup => {
        return {
          ...checklistGroup,
          checklist: checklistGroup.checklist.map(checklistItem => {
            if (updatedChecklistItem.id === checklistItem.id) {
              return updatedChecklistItem;
            }
            return checklistItem;
          }),
        };
      });

      dispatch({type: 'UpdateAllChecklistGroups', payload: newChecklists});

      if (calendarUpdateChecklistURL && eventId) {
        // call status update API
        let checklistStatusArray = [];

        newChecklists.forEach(checklistGroup => {
          checklistGroup.checklist.forEach(checklistItem => {
            checklistStatusArray.push({
              id: checklistItem.id,
              status: checklistItem.status,
              // updated: moment()
            });
          });
        });

        const request = {
          event_id: parseInt(eventId),
          checklists: checklistStatusArray,
        };
        try {
          return await instance.post(calendarUpdateChecklistURL, request);
        } catch (error) {
          return dispatch({type: 'Error', payload: error.message});
        }
      }
      return;
    }
    return;
  };

const deleteChecklistItem =
  dispatch => (checklistGroup, inputChecklistItemId) => {
    const checklistItemsArray = checklistGroup.checklist.filter(
      checklistItem => checklistItem.id != inputChecklistItemId,
    );

    checklistGroup = {
      ...checklistGroup,
      checklist: checklistItemsArray,
    };

    return dispatch({type: 'UpdateChecklistGroup', payload: checklistGroup});
  };

const linkChecklistsToPatientReadiness =
  dispatch => (newLinkedValue, inputChecklistsArr) => {
    if (newLinkedValue) {
      if (newLinkedValue === 'Yes') {
        const payload = {
          key: 'checklistClearedLinked',
          value: 'Yes',
        };
        return dispatch({type: 'UpdateFormFieldValue', payload: payload});
      }
      if (newLinkedValue === 'No') {
        const payload1 = {
          key: 'checklistClearedLinked',
          value: 'No',
        };
        const payload2 = {
          key: 'patientCleared',
          value: 'N/A',
        };
        dispatch({type: 'UpdateFormFieldValue', payload: payload1});
        dispatch({type: 'UpdateFormFieldValue', payload: payload2});
        return;
      }
    }

    if (inputChecklistsArr && Array.isArray(inputChecklistsArr)) {
      if (inputChecklistsArr.length > 0) {
        const payload = {
          key: 'checklistClearedLinked',
          value: 'Yes',
        };
        return dispatch({type: 'UpdateFormFieldValue', payload: payload});
      } else {
        const payload1 = {
          key: 'checklistClearedLinked',
          value: 'No',
        };
        const payload2 = {
          key: 'patientCleared',
          value: 'N/A',
        };
        dispatch({type: 'UpdateFormFieldValue', payload: payload1});
        dispatch({type: 'UpdateFormFieldValue', payload: payload2});
        return;
      }
      return;
    }
    return;
  };

const didPatientClearLinkedChecklists = dispatch => inputChecklistsArr => {
  let statusArr = [];

  inputChecklistsArr.forEach(checklistGroup => {
    checklistGroup.checklist.forEach(checklistItem => {
      statusArr.push(checklistItem.status);
    });
  });

  if (
    statusArr.includes('TBD') &&
    !statusArr.includes('Yes') &&
    !statusArr.includes('No')
  ) {
    const payload = {
      key: 'patientCleared',
      value: 'No',
    };
    return dispatch({type: 'UpdateFormFieldValue', payload: payload});
  } else if (
    statusArr.includes('TBD') &&
    (statusArr.includes('Yes') || statusArr.includes('No'))
  ) {
    const payload = {
      key: 'patientCleared',
      value: 'No',
    };
    return dispatch({type: 'UpdateFormFieldValue', payload: payload});
  } else if (
    !statusArr.includes('TBD') &&
    (statusArr.includes('Yes') || statusArr.includes('No'))
  ) {
    const payload = {
      key: 'patientCleared',
      value: 'Yes',
    };
    return dispatch({type: 'UpdateFormFieldValue', payload: payload});
  }
  return;
};

const setDocboxActiveNavItem = dispatch => key => {
  return dispatch({type: 'SetDocboxActiveNavItem', payload: key});
};

const updateHeight = dispatch => (height01, height02, units) => {
  let heightInput01;
  let heightInput02;
  let heightUSCustomary;
  let height;
  let payload;

  if (units === 'Metric') {
    height = height01 && parseFloat(height01) ? parseFloat(height01) : 0;
    payload = {
      heightInput01: height01 !== '' ? height.toString() : '',
      heightInput02: '',
      heightUSCustomary: '',
      height: height,
    };
  } else {
    heightInput01 = height01 && parseInt(height01) ? parseInt(height01) : 0;
    heightInput02 = height02 && parseInt(height02) ? parseInt(height02) : 0;
    heightUSCustomary = heightInput01 * 12 + heightInput02;
    height = convertHeight(heightUSCustomary, 'Metric');
    payload = {
      heightInput01: height01 !== '' ? heightInput01.toString() : '',
      heightInput02: height02 !== '' ? heightInput02.toString() : '',
      heightUSCustomary: heightUSCustomary,
      height: height,
    };
  }

  return dispatch({type: 'UpdateHeight', payload: payload});
};

const updateWeight = dispatch => (weight01, units) => {
  let weightUSCustomary;
  let weight;
  let payload;

  if (units === 'Metric') {
    weight = weight01 && parseFloat(weight01) ? parseFloat(weight01) : 0;
    payload = {
      weightInput01: weight01 !== '' ? weight.toString() : '',
      weightUSCustomary: weight,
      weight: weight,
    };
  } else {
    weightUSCustomary =
      weight01 && parseFloat(weight01) ? parseInt(weight01) : 0;
    weight = convertWeight(weightUSCustomary, 'Metric');
    payload = {
      weightInput01: weight01 !== '' ? weightUSCustomary.toString() : '',
      weightUSCustomary: weight,
      weight: weight,
    };
  }

  return dispatch({type: 'UpdateWeight', payload: payload});
};

const updateUnits = dispatch => (height01, weight01, units) => {
  let height;
  let heightUSCustomary;
  let heightInput01;
  let heightInput02;
  let weight;
  let weightUSCustomary;
  let weightInput01;
  let payload;

  if (units === 'Metric') {
    height = height01 && parseFloat(height01) ? parseFloat(height01) : 0;
    weight =
      weight01 && parseFloat(weight01)
        ? parseFloat(parseFloat(weight01).toFixed(2))
        : 0;
    payload = {
      units: units,
      height: height,
      heightUSCustomary: 0,
      heightInput01: height01 !== '' ? height.toString() : '',
      heightInput02: '',
      weight: weight,
      weightUSCustomary: 0,
      weightInput01: weight01 !== '' ? weight.toString() : '',
    };
  } else {
    height = height01 && parseFloat(height01) ? parseFloat(height01) : 0;
    heightUSCustomary = convertHeight(height) || 0;
    heightInput01 =
      convertInchesToFtIn(convertHeight(height))[0].toString() || '';
    heightInput02 =
      convertInchesToFtIn(convertHeight(height))[1].toString() || '';
    weight = weight01 && parseFloat(weight01) ? parseFloat(weight01) : 0;
    weightUSCustomary = convertWeight(weight) || 0;
    weightInput01 = convertWeight(weight).toString() || '';
    payload = {
      units: units,
      height: height,
      heightUSCustomary: heightUSCustomary,
      heightInput01: heightInput01 ? heightInput01 : '',
      heightInput02: heightInput02 ? heightInput02 : '',
      weight: weight,
      weightUSCustomary: weightUSCustomary,
      weightInput01: weightInput01 ? weightInput01 : '',
    };
  }

  return dispatch({type: 'UpdateUnits', payload: payload});
};

const updateBPCIPatient = dispatch => (inputValue, inputBpciId) => {
  let bpciPatient;
  let bpciId;
  let payload;

  if (inputBpciId === 0) {
    bpciPatient = inputValue;
    bpciId = inputValue === '1' ? 1 : 0;
  } else {
    if (inputValue === '0') {
      bpciPatient = '0';
      bpciId = inputBpciId * -1;
    } else {
      bpciPatient = '1';
      bpciId = Math.abs(inputBpciId);
    }
  }

  payload = {
    bpciPatient: bpciPatient,
    bpciId: bpciId,
  };

  return dispatch({type: 'UpdateBPCIPatient', payload: payload});
};

const updateTimeAndDuration =
  dispatch => (startOrEnd, startDateTime, endDateTime, inputDuration) => {
    let startDate;
    let startTime;
    let endDate;
    let endTime;
    let duration;
    let payload;

    if (startOrEnd) {
      if (startOrEnd === 'Start') {
        // We've manipulated the start time and changing the start time, end time based on event duration
        startDate = moment(startDateTime, 'YYYYMMDDHHmm').format('YYYYMMDD');
        startTime = moment(startDateTime, 'YYYYMMDDHHmm').format('HHmm');
        endDate = moment(startDateTime, 'YYYYMMDDHHmm')
          .add(inputDuration, 'minutes')
          .format('YYYYMMDD');
        endTime = moment(startDateTime, 'YYYYMMDDHHmm')
          .add(inputDuration, 'minutes')
          .format('HHmm');
        duration = inputDuration;
      } else {
        // startOrEnd === 'End'
        // We've manipulated the end time and changing the end time and event duration
        startDate = moment(startDateTime, 'YYYYMMDDHHmm').format('YYYYMMDD');
        startTime = moment(startDateTime, 'YYYYMMDDHHmm').format('HHmm');
        endDate = moment(endDateTime, 'YYYYMMDDHHmm').format('YYYYMMDD');
        endTime = moment(endDateTime, 'YYYYMMDDHHmm').format('HHmm');
        duration = differenceBtwnTwoIsos(startDateTime, endDateTime, false);
      }
    }

    payload = {
      startDate: startDate,
      startTime: startTime,
      endDate: endDate,
      endTime: endTime,
      duration: duration,
    };
    return dispatch({type: 'UpdateTimeAndDuration', payload: payload});
  };

const updateTime = dispatch => (startDateTime, endDateTime) => {
  const startDate = moment(startDateTime, 'YYYYMMDDHHmm').format('YYYYMMDD');
  const startTime = moment(startDateTime, 'YYYYMMDDHHmm').format('HHmm');
  const endDate = moment(endDateTime, 'YYYYMMDDHHmm').format('YYYYMMDD');
  const endTime = moment(endDateTime, 'YYYYMMDDHHmm').format('HHmm');
  const payload = {
    startDate: startDate,
    startTime: startTime,
    endDate: endDate,
    endTime: endTime,
  };

  return dispatch({type: 'UpdateTime', payload: payload});
};

const updateRebookDates = dispatch => datesArray => {
  return dispatch({type: 'UpdateRebookDates', payload: datesArray});
};

const selectMyProcedure = dispatch => procedure => {
  const payload = {
    caseName: procedure.name || '',
    duration: procedure.duration || 60,
    setupNotes: procedure.setup || '',
    cpt: procedure.cpt.split(', '),
    icd: procedure.icd10.split(', '),
    providers: procedure.providers,
    reps: procedure.reps,
    devices: procedure.devices,
  };

  return dispatch({type: 'SelectMyProcedure', payload: payload});
};

const selectMyLocation = dispatch => location => {
  const payload = {
    locationInput: location.location || '',
    providers: location.careteam || [],
  };

  return dispatch({type: 'SelectMyLocation', payload: payload});
};

const addBillingCode = dispatch => (type, code) => {
  const payload = {
    type: type,
    code: code.code,
  };

  return dispatch({type: 'AddBillingCode', payload: payload});
};

const deleteBillingCode = dispatch => (type, code) => {
  const payload = {
    type: type,
    code: code,
  };

  return dispatch({type: 'DeleteBillingCode', payload: payload});
};

const createCalendarEvent = dispatch => async (url, request) => {
  if (!url) {
    return dispatch({
      type: 'Error',
      payload: 'Error from createCalendarEvent: no url',
    });
  }

  if (request) {
    try {
      return await instance.post(url, request);
    } catch (error) {
      return dispatch({type: 'Error', payload: error.message});
    }
  }
  return;
};

const editCalendarEvent = dispatch => async (url, request) => {
  if (!url) {
    return dispatch({
      type: 'Error',
      payload: 'Error from editCalendarEvent: no url',
    });
  }

  if (request) {
    try {
      return await instance.post(url, request);
    } catch (error) {
      return dispatch({type: 'Error', payload: error.message});
    }
  }
  return;
};

/*
  Event ID: AlomEncodedType2 String
  User ID: non-encoded String
*/
const deleteCalendarEvent = dispatch => async (url, eventId, userId) => {
  if (!url) {
    return dispatch({
      type: 'Error',
      payload: 'Error from deleteCalendarEvent: no url',
    });
  }

  if (eventId && userId) {
    const request = {
      events: eventId,
      user: userId,
    };

    try {
      const response = await instance.post(url, request);
      return response;
    } catch (error) {
      return dispatch({type: 'Error', payload: error.message});
    }
  }
  return;
};

const getSuggestedTimes = dispatch => async (url, startDate, userId) => {
  if (!url) {
    return dispatch({
      type: 'Error',
      payload: 'Error from getSuggestedTimes: no url',
    });
  }

  if (startDate && userId) {
    let suggestedTimesOutputArr = [];
    let requestDate = startDate;

    for (let i = 0; i < 10; i++) {
      const request = {
        date: requestDate,
        id: userId,
      };

      try {
        const response = await instance.post(url, request);

        if (!response) {
          return dispatch({
            type: 'Error',
            payload: 'getSuggestedTimes API error',
          });
        } else {
          if (response.data && response.data.availability) {
            const availabilityString = response.data.availability;

            for (let j = 0; j < availabilityString.length; j += 4) {
              if (availabilityString.substring(j, j + 4) == '1111') {
                suggestedTimesOutputArr.push(
                  parseInt(`${requestDate}${determineAvailabilityHour(j)}`),
                );
              }
            }
          } else {
            return dispatch({
              type: 'Error',
              payload:
                'getSuggestedTimes: error reading availability string from response',
            });
          }
        }

        requestDate = parseInt(
          moment(requestDate.toString(), 'YYYYMMDD')
            .add('1', 'days')
            .format('YYYYMMDD'),
        );
      } catch (error) {
        return dispatch({
          type: 'Error',
          payload: `getSuggestedTimes error: ${error.message}`,
        });
      }
    }

    // dispatch to setSuggestedTimes
    return dispatch({
      type: 'SetSuggestedTimes',
      payload: suggestedTimesOutputArr,
    });
  }
  return;
};

const uploadFile = dispatch => async (url, file) => {
  if (!url) {
    return dispatch({
      type: 'Error',
      payload: 'Error from uploadFile: no url',
    });
  }

  if (file) {
    let bodyFormData = new FormData();
    bodyFormData.append('folder', 0);
    bodyFormData.append('hidden', 1);
    bodyFormData.append('img_name', file);

    try {
      return await fileUploadInstance.post(url, bodyFormData);
    } catch (error) {
      return dispatch({type: 'Error', payload: error.message});
    }
  }
  return;
};

const addAttachment = dispatch => async fileObj => {
  return dispatch({type: 'AddAttachment', payload: fileObj});
};

const deleteAttachment = dispatch => async fileId => {
  return dispatch({type: 'DeleteAttachment', payload: fileId});
};

const updateFilePHI = dispatch => async (url, request, fileObj) => {
  if (!url) {
    return dispatch({
      type: 'Error',
      payload: 'Error from updateFilePHI: no url',
    });
  }

  if (fileObj) {
    dispatch({type: 'UpdateFilePHI', payload: fileObj});
  }

  if (url && request) {
    try {
      const response = await instance.post(url, request);
      return response;
    } catch (error) {
      return dispatch({type: 'Error', payload: error.message});
    }
  }
  return;
};

const showDocboxAside = dispatch => inputBoolean => {
  if (typeof inputBoolean === 'boolean') {
    return dispatch({type: 'SetShowDocboxAside', payload: inputBoolean});
  }
  return;
};

const sendPatientIntake = dispatch => async (url, eventId) => {
  if (!url) {
    return dispatch({
      type: 'Error',
      payload: 'Error from sendPatientIntake: no url',
    });
  }
  if (!eventId || (eventId && isNaN(parseInt(eventId)))) {
    return dispatch({
      type: 'Error',
      payload: 'Error from sendPatientIntake: invalid event ID',
    });
  }

  try {
    return await instance.post(url);
  } catch (error) {
    return dispatch({type: 'Error', payload: error.message});
  }
};

const getClashes = dispatch => async (url, ownerId, startTime, endTime) => {
  if (!url) {
    return dispatch({
      type: 'Error',
      payload: 'Error from getClashes: no url',
    });
  }

  if (url && ownerId && startTime && endTime) {
    const request = {
      calendar: ownerId > 0 ? parseInt(ownerId) : ownerId,
      time: {
        start: startTime,
        end: endTime,
      },
    };

    try {
      const response = await instance.post(url, request);

      if (!response.data) {
        return dispatch({type: 'Error', payload: `no response from ${url}`});
      } else {
        if (
          response.data &&
          typeof response.data.clashes != 'undefined' &&
          Array.isArray(response.data.clashes)
        ) {
          return dispatch({
            type: 'GetClashes',
            payload: response.data.clashes,
          });
        }
      }
    } catch (error) {
      return dispatch({type: 'Error', payload: error.message});
    }
  }

  return;
};

export const {Provider, Context} = createDataContext(
  docboxReducer,
  {
    openDocbox,
    closeDocbox,
    updateEventType,
    updateFormFieldValue,
    addProvider,
    deleteProvider,
    addRep,
    deleteRep,
    addDevice,
    updateDevice,
    deleteDevice,
    addChecklistGroup,
    deleteChecklistGroup,
    updateChecklistGroupField,
    addChecklistItem,
    updateChecklistItem,
    updateChecklistItemStatus,
    deleteChecklistItem,
    linkChecklistsToPatientReadiness,
    didPatientClearLinkedChecklists,
    setDocboxActiveNavItem,
    updateHeight,
    updateWeight,
    updateUnits,
    updateBPCIPatient,
    updateTimeAndDuration,
    updateTime,
    updateRebookDates,
    selectMyProcedure,
    selectMyLocation,
    addBillingCode,
    deleteBillingCode,
    createCalendarEvent,
    editCalendarEvent,
    deleteCalendarEvent,
    getSuggestedTimes,
    uploadFile,
    addAttachment,
    deleteAttachment,
    updateFilePHI,
    showDocboxAside,
    sendPatientIntake,
    getClashes,
  },
  initialState,
);
