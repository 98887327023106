import React, {useContext} from 'react';
import './BillingCodeCard.scss';
import {DeleteFieldButton} from '../..';
import {Context as EventSettingsContext} from '../../../../../context/EventSettingsContext';

const BillingCodeCard = ({type, code}) => {
  const {deleteBillingCode} = useContext(EventSettingsContext);

  return (
    <div className={`billing-code-card width100 flex align-center ${type}`}>
      <div className="width20">
        <b>{code.c}</b>
      </div>
      <div className="flex-grow">{code.d}</div>
      <div>
        <DeleteFieldButton handler={() => deleteBillingCode(type, code.c)} />
      </div>
    </div>
  );
};

export default BillingCodeCard;
