export const triggerOpenDocbox = (event_id, targetUserId) => {
  let targetOrigin;
  // LOCAL
  if (window.location.href.includes('http://localhost:3000')) {
    targetOrigin = 'http://localhost:3000';
    // STAGING
  } else if (window.location.href.includes('docvisor')) {
    targetOrigin = 'https://docvisor.com';
    // PRODUCTION
  } else if (window.location.href.includes('docspera')) {
    targetOrigin = 'https://docspera.com';
  } else {
    console.log('no origin to send to');
    return;
  }

  let targetWindow = window.parent;
  if (targetWindow) {
    targetWindow.postMessage(
      {
        type: 'eventView',
        payload: {eventId: event_id, userId: targetUserId},
      },
      targetOrigin,
    );
  }
};

export const triggerCreateDocbox = (
  startDate,
  endDate,
  targetUserId,
  areKeysPressed,
) => {
  let targetOrigin;
  // LOCAL
  if (window.location.href.includes('http://localhost:3000')) {
    targetOrigin = 'http://localhost:3000';
    // STAGING
  } else if (window.location.href.includes('docvisor')) {
    targetOrigin = 'https://docvisor.com';
    // PRODUCTION
  } else if (window.location.href.includes('docspera')) {
    targetOrigin = 'https://docspera.com';
  } else {
    console.log('no origin to send to');
    return;
  }

  let targetWindow = window.parent;
  if (targetWindow) {
    targetWindow.postMessage(
      {
        type: 'eventCreate',
        payload: {
          start: startDate,
          end: endDate,
          userId: targetUserId,
          keysPressed: areKeysPressed,
        },
      },
      targetOrigin,
    );
  }
};

export const triggerEventSettings = userId => {
  let targetOrigin;
  // LOCAL
  if (window.location.href.includes('http://localhost:3000')) {
    targetOrigin = 'http://localhost:3000';
    // STAGING
  } else if (window.location.href.includes('docvisor')) {
    targetOrigin = 'https://docvisor.com';
    // PRODUCTION
  } else if (window.location.href.includes('docspera')) {
    targetOrigin = 'https://docspera.com';
  } else {
    console.log('no origin to send to');
    return;
  }

  let targetWindow = window.parent;
  if (targetWindow) {
    targetWindow.postMessage(
      {type: 'eventSettings', payload: userId},
      targetOrigin,
    );
  }
};

export const triggerOpenICal = userId => {
  let targetOrigin;
  // LOCAL
  if (window.location.href.includes('http://localhost:3000')) {
    targetOrigin = 'http://localhost:3000';
    // STAGING
  } else if (window.location.href.includes('docvisor')) {
    targetOrigin = 'https://docvisor.com';
    // PRODUCTION
  } else if (window.location.href.includes('docspera')) {
    targetOrigin = 'https://docspera.com';
  } else {
    console.log('no origin to send to');
    return;
  }

  let targetWindow = window.parent;
  if (targetWindow) {
    targetWindow.postMessage({type: 'openICal', payload: userId}, targetOrigin);
  }
};

export const triggerEditDocbox = eventId => {
  let targetOrigin;
  // LOCAL
  if (window.location.href.includes('http://localhost:3000')) {
    targetOrigin = 'http://localhost:3000';
    // STAGING
  } else if (window.location.href.includes('docvisor')) {
    targetOrigin = 'https://docvisor.com';
    // PRODUCTION
  } else if (window.location.href.includes('docspera')) {
    targetOrigin = 'https://docspera.com';
  } else {
    console.log('no origin to send to');
    return;
  }

  let targetWindow = window.parent;
  if (targetWindow) {
    targetWindow.postMessage(
      {type: 'eventEdit', payload: eventId},
      targetOrigin,
    );
  }
};

export const triggerOpenThread = threadId => {
  let targetOrigin;
  // LOCAL
  if (window.location.href.includes('http://localhost:3000')) {
    targetOrigin = 'http://localhost:3000';
    // STAGING
  } else if (window.location.href.includes('docvisor')) {
    targetOrigin = 'https://docvisor.com';
    // PRODUCTION
  } else if (window.location.href.includes('docspera')) {
    targetOrigin = 'https://docspera.com';
  } else {
    console.log('no origin to send to');
    return;
  }

  let targetWindow = window.parent;
  if (targetWindow) {
    targetWindow.postMessage(
      {type: 'openThread', payload: threadId},
      targetOrigin,
    );
  }
};

export const sendSharedScheduleId = targetUserId => {
  let targetOrigin;
  // LOCAL
  if (window.location.href.includes('http://localhost:3000')) {
    targetOrigin = 'http://localhost:3000';
    // STAGING
  } else if (window.location.href.includes('docvisor')) {
    targetOrigin = 'https://docvisor.com';
    // PRODUCTION
  } else if (window.location.href.includes('docspera')) {
    targetOrigin = 'https://docspera.com';
  } else {
    console.log('no origin to send to');
    return;
  }

  let targetWindow = window.parent;
  if (targetWindow) {
    targetWindow.postMessage(
      {type: 'setSharedScheduleId', payload: targetUserId},
      targetOrigin,
    );
  }
};
