import React, {useCallback, useEffect} from 'react';
import TimesIcon from '../../../assets/svg-components/TimesIcon';
import './Modal.scss';

const Modal = ({title, content, toggleModal, showtimesIcon = false}) => {
  const KEY_NAME_ESC = 'Escape';
  const KEY_EVENT_TYPE = 'keyup';

  const handleEscKey = useCallback(
    event => {
      if (event.key === KEY_NAME_ESC) {
        toggleModal(false);
      }
    },
    [toggleModal],
  );

  useEffect(() => {
    document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);

    return () => {
      document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
    };
  }, [handleEscKey]);
  return (
    <div
      className="modal-bg"
      onClick={e => {
        e.stopPropagation();
        toggleModal(false);
      }}>
      {showtimesIcon && (
        <TimesIcon className="times-icon" onClick={() => toggleModal(false)} />
      )}
      <div className="modal" onClick={e => e.stopPropagation()}>
        <div className="blue-header">{title && <h4>{title}</h4>}</div>
        {content}
      </div>
    </div>
  );
};

export default Modal;
