import React, {useContext} from 'react';
import {Context as AuthContext} from '../../../context/AuthContext';
import {Context as PDContext} from '../../../context/PracticeDashboardContext';
import {Context as GlobalContext} from '../../../context/GlobalContext';
import {Context as ScheduleContext} from '../../../context/ScheduleContext';
import {Context as EventSettingsContext} from '../../../context/EventSettingsContext';
import {Loading} from '../../ui/atoms';
import EventSettings from '../../EventSettings';
import Table from '../../Table/Table';
import gearIcon from '../../../assets/img/icons/gear.svg';
import AccessRightsToggle from './AccessRightsToggle/AccessRightsToggle';
import './PDSettings.scss';
import {getUserPreferencesURI} from '../../../dataAccess/apiEndpoints';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';

const Preferences = () => {
  const {state: authState} = useContext(AuthContext);
  const {state: pdState} = useContext(PDContext);
  const {state: globalState} = useContext(GlobalContext);
  const {setTargetUserId, setTargetUserName} = useContext(ScheduleContext);
  const {state: eventSettingsState, openEventSettings} =
    useContext(EventSettingsContext);

  const TableConstants = () => {
    return [
      {
        title: 'Practice Members',
        render: rowData => {
          return (
            <div className="practice-members-col">
              {globalState.config.ABS_BASE_URL && (
                <div
                  className="settings-avatar"
                  style={{
                    backgroundImage: `url(${globalState.config.ABS_BASE_URL}/a/${rowData.avatar}_128.jpg)`,
                  }}></div>
              )}
              <div className="text-div">
                <p>{rowData.name}</p>
                <span>{rowData.specialty}</span>
              </div>
            </div>
          );
        },
      },
      {
        title: 'Access Rights',
        render: rowData => {
          if (rowData.rights === 'Admin' && rowData.id === authState.userId) {
            return (
              <div id="velys-admin">
                <span>
                  <FontAwesomeIcon icon={faExclamationTriangle} size="sm" /> You
                  cannot change your own access privileges.
                </span>
                <br></br>
                <small>
                  You have <b>ADMIN</b> access.
                </small>
              </div>
            );
          } else if (rowData.rights === 'Admin') {
            return (
              <div className="multi-admin">
                <AccessRightsToggle rights={rowData.rights} id={rowData.id} />
                <div id="admin-selected">Admin Access</div>
              </div>
            );
          } else {
            return (
              <AccessRightsToggle rights={rowData.rights} id={rowData.id} />
            );
          }
        },
      },
      {
        title: 'Event Settings',
        render: rowData => {
          return (
            <div className="event-settings-col">
              <button
                type="button"
                className="btn-success circle"
                title="Event Settings"
                onClick={() => {
                  setTargetUserName(rowData.name);
                  setTargetUserId(rowData.id);
                  if (globalState.config.ABS_BASE_URL) {
                    const url = `${globalState.config.ABS_BASE_URL}${getUserPreferencesURI}`;
                    openEventSettings(url, 0, rowData.id);
                  }
                }}>
                <img src={gearIcon} alt="Settings" />
              </button>
            </div>
          );
        },
      },
    ];
  };

  const practiceMembersArray = () => {
    let membersArray = [];
    if (
      pdState.settings &&
      pdState.settings.careteam &&
      pdState.settings.careteam.length !== 0
    ) {
      membersArray = [
        ...pdState.settings.physicians,
        ...pdState.settings.careteam,
      ];
    } else {
      membersArray = [...pdState.settings.physicians];
    }
    return membersArray;
  };

  return (
    <div style={{marginLeft: 30, marginTop: 20}}>
      {pdState.settings &&
      pdState.settings.physicians &&
      pdState.settings.physicians.length !== 0 ? (
        <Table
          data={practiceMembersArray()}
          cols={TableConstants()}
          brand="velys"
        />
      ) : (
        <Loading />
      )}
      {eventSettingsState.isEventSettingsOpen && <EventSettings />}
    </div>
  );
};

export default Preferences;
