import {React} from 'react';
import './Toggle.scss';

const Toggle = ({toggled, handler, onText, offText, large}) => {
  return (
    <div
      className={`toggle${toggled ? ' on' : ''} ${large && 'toggle-large'}`}
      onClick={handler}>
      {toggled && <span>{onText}</span>}
      <div></div>
      {!toggled && <span className="offText">{offText}</span>}
    </div>
  );
};

export default Toggle;
