import React, {useContext, useEffect, useState} from 'react';
import moment from 'moment';
import MenuItem from '@mui/material/MenuItem';
import {useTheme} from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import {KeyboardArrowLeft, KeyboardArrowRight} from '@mui/icons-material';
import LastPageIcon from '@mui/icons-material/LastPage';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import {faFemale, faMale} from '@fortawesome/free-solid-svg-icons';

import Docbox from '../../Docbox';
import Modal from '../Modal/Modal';
// import Table from '../../Table/Table'
import {Loading} from '../../ui/atoms';
import EventSettings from '../../EventSettings';
import CalendarPicker from '../../CalendarPicker';
import UploadButton from '../../UploadButton';
import {triggerOpenDocbox} from '../../../libraries/util';
import {AlomEncodeType2} from '../../../libraries/helpers';
import MainDashboardIcon from '../../../assets/img/icons/MainDashboardIcon.svg';
import BackIcon from '../../../assets/img/icons/backIcon.svg';
import ChecklistsModalBody from '../Checklists/ChecklistsModalBody';
import {Context as AuthContext} from '../../../context/AuthContext';
import {Context as DocboxContext} from '../../../context/DocboxContext';
import {Context as GlobalContext} from '../../../context/GlobalContext';
import {Context as ScheduleContext} from '../../../context/ScheduleContext';
import {Context as PDContext} from '../../../context/PracticeDashboardContext';
import {Context as EventSettingsContext} from '../../../context/EventSettingsContext';
import dicomURL from '../../../assets/img/dicom.png';
import unknownURL from '../../../assets/files/file_unknown_128.png';
import docURL from '../../../assets/files/Overlay-DOC-128.png';
import pdfURL from '../../../assets/files/Overlay-PDF-128.png';
import pptURL from '../../../assets/files/Overlay-PPT-128.png';
import rtfURL from '../../../assets/files/Overlay-RTF-128.png';
import zipURL from '../../../assets/files/Overlay-ZIP-128.png';
import xlsURL from '../../../assets/files/Overlay-XLS-128.png';
import txtURL from '../../../assets/files/Overlay-TXT-128.png';
// import phiURL from '../../../assets/files/Overlay-PHI-128.png'
import {
  allCasesURI,
  getChecklistsURI,
  settingsURI,
} from '../../../dataAccess/apiEndpoints';

import './AllCasesRepDB.scss';
import AttachmentIcon from '../../../assets/svg-components/AttachmentRep';

const AllCasesRepDB = () => {
  const {
    getCases,
    editNotes,
    getSettings,
    getWeekCases,
    setTimeFilter,
    getChecklists,
    state: pdState,
    setNotesEditted,
    clearChecklistsModal,
    updateFilterFieldValue,
  } = useContext(PDContext);
  const {state: docboxState, openDocbox} = useContext(DocboxContext);
  const {state: authState} = useContext(AuthContext);
  const {setTargetUserId, setTargetUserName} = useContext(ScheduleContext);
  const {state: eventSettingsState} = useContext(EventSettingsContext);
  const {state: globalState, openAlert, closeAlert} = useContext(GlobalContext);
  const [page, setPage] = useState(0);
  const [innerPage, setInnerPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [currentCase, setCurrentCase] = useState();
  const [currentNotes, setCurrentNotes] = useState();
  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const [imagePopUp, setImagePopUp] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentRowID, setCurrentRowID] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const offset = page * 10;

  useEffect(() => {
    const time = {
      start: moment().startOf('week').format('YYYYMMDD'),
      end: moment().endOf('week').format('YYYYMMDD'),
    };
    setTimeFilter(time.start, time.end);
    const url = `${globalState.config.ABS_BASE_URL}${settingsURI}`;
    getSettings(url);
  }, []);

  useEffect(() => {
    if (pdState.time.start.length !== 0) {
      const url = `${globalState.config.ABS_BASE_URL}${allCasesURI}`;
      getCases(
        url,
        pdState.time,
        pdState.type,
        pdState.location,
        pdState.physician,
        pdState.search,
        pdState.sort_by,
        offset,
      );
    }
  }, [
    pdState.time,
    pdState.type,
    pdState.location,
    pdState.physician,
    pdState.search,
    pdState.sort_by,
    page,
    globalState.config.ABS_BASE_URL,
    docboxState.checklists,
    pdState.checklistsModal,
  ]);

  useEffect(() => {
    setInnerPage(0);
    setPage(0);
  }, [
    pdState.time,
    pdState.type,
    pdState.location,
    pdState.physician,
    pdState.search,
  ]);

  useEffect(() => {
    if (pdState.time.start.length !== 0) {
      const settingsURL = `${globalState.config.ABS_BASE_URL}${settingsURI}`;
      getSettings(settingsURL);
      const url = `${globalState.config.ABS_BASE_URL}${allCasesURI}`;
      getWeekCases(url, pdState.time, null, null, null, null, null, offset);
    }
  }, [pdState.time, docboxState.location]);

  useEffect(() => {
    if (pdState.notesEditted) {
      const url = `${globalState.config.ABS_BASE_URL}${allCasesURI}`;
      getCases(
        url,
        pdState.time,
        pdState.type,
        pdState.surgery_location,
        pdState.physician,
        pdState.search,
        pdState.sort_by,
        offset,
      );
      setNotesEditted(false);
    }
  }, [pdState.notesEditted, globalState.config.ABS_BASE_URL]);

  const handleDateSelect = event => {
    const start = moment(event.target.dataset.date)
      .startOf('week')
      .format('YYYYMMDD');
    const end = moment(event.target.dataset.date)
      .endOf('week')
      .format('YYYYMMDD');
    setTimeFilter(start, end);
  };

  const handleInputChange = (name, value) => {
    if (name === 'physician' || name === 'location') {
      if (value !== 'null') {
        value = parseInt(value);
      }
    }
    if (value === 'null') {
      value = null;
    }
    updateFilterFieldValue(name, value);
  };

  const currentView = globalState.currentView;

  const handleNavLinkClick = inputEndpoint => {
    if (currentView === inputEndpoint) return;
    return window.location.assign(inputEndpoint);
  };

  const handleRowClick = item => {
    console.log({id: item.event_id});
    triggerOpenDocbox(item.event_id);
  };

  const handleAddNotes = (item, e) => {
    e.stopPropagation();
    setNotesModalOpen(true);
    setCurrentCase(item);
    setCurrentNotes(item.notes);
  };

  const onPatientReadinessClick = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    const encodedEventId = AlomEncodeType2(item.event_id);
    if (globalState.config.ABS_BASE_URL && encodedEventId) {
      const url = `${globalState.config.ABS_BASE_URL}${getChecklistsURI}`;
      getChecklists(url, encodedEventId).then(() => {
        console.log('clickked on patient readiness');
        openAlert({
          title: 'Checklists',
          body: <ChecklistsModalBody />,
          cancelText: 'Close',
          cancelHandler: () => {
            clearChecklistsModal();
            closeAlert();
          },
        });
      });
    }
  };

  const weeklyCaseLocations = settingsLocation => {
    //filter surgery locations to current week

    let found = false;
    if (
      pdState.weekCases &&
      pdState.weekCases.cases &&
      Array.isArray(pdState.weekCases.cases) &&
      pdState.weekCases.cases.length > 0
    ) {
      pdState.weekCases.cases.forEach(c => {
        if (
          c &&
          c.surgery_location &&
          c.surgery_location.id &&
          settingsLocation.id === c.surgery_location.id
        ) {
          found = true;
        }
      });
    }
    return found;
  };

  const handleChangePage = (event, newPage) => {
    setInnerPage(newPage);
  };

  const tableHeader = [
    'Surgery Date',
    'Main Physician',
    'Case Name',
    'Surgery Location',
    'Lead Time',
    'Images',
    'Devices Requested',
  ];

  function TablePaginationActions(props) {
    const theme = useTheme();
    const {count, page: innerPage, rowsPerPage, onPageChange} = props;
    const handleFirstPageButtonClick = event => {
      page > 0 && setPage(page - 1);
      onPageChange(event, 0);
    };

    const handleBackButtonClick = event => {
      onPageChange(event, innerPage - 1);
    };

    const handleNextButtonClick = event => {
      onPageChange(event, innerPage + 1);
    };

    const handleLastPageButtonClick = event => {
      page + 1 < numOfPages && setPage(page + 1);
      onPageChange(event, 0);
    };
    const numOfPages = Math.ceil(pdState.cases.paginate.total / 10);
    return (
      <Box sx={{flexShrink: 0, ml: 2.5}}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page">
          {theme.direction === 'rtl' ? (
            <LastPageIcon
              className={`docspera-blue ${page === 0 ? 'disabled-nav' : ''}`}
            />
          ) : (
            <FirstPageIcon
              className={`docspera-blue ${page === 0 ? 'disabled-nav' : ''}`}
            />
          )}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={innerPage === 0}
          aria-label="previous page">
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight
              className={`docspera-blue ${
                innerPage === 0 ? 'disabled-nav' : ''
              }`}
            />
          ) : (
            <KeyboardArrowLeft
              className={`docspera-blue ${
                innerPage === 0 ? 'disabled-nav' : ''
              }`}
            />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={innerPage >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page">
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft
              className={`docspera-blue ${
                innerPage >= Math.ceil(count / rowsPerPage) - 1
                  ? 'disabled-nav'
                  : ''
              }`}
            />
          ) : (
            <KeyboardArrowRight
              className={`docspera-blue ${
                innerPage >= Math.ceil(count / rowsPerPage) - 1
                  ? 'disabled-nav'
                  : ''
              }`}
            />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page + 1 >= numOfPages}
          aria-label="last page">
          {theme.direction === 'rtl' ? (
            <FirstPageIcon
              className={`docspera-blue ${
                page + 1 >= numOfPages ? 'disabled-nav' : ''
              }`}
            />
          ) : (
            <LastPageIcon
              className={`docspera-blue ${
                page + 1 >= numOfPages ? 'disabled-nav' : ''
              }`}
            />
          )}
        </IconButton>
      </Box>
    );
  }

  return (
    <>
      {/* <GlobalNavMenu /> */}
      <div id="rep-cases">
        <div className="content header">
          <form>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                width: '100%',
              }}>
              <div style={{display: 'flex', alignItems: 'flex-start'}}>
                <img
                  src={MainDashboardIcon}
                  alt="dashboard Icon"
                  style={{marginRight: '20px'}}
                />
                <div>
                  <h2
                    className="rep-title"
                    style={{
                      marginRight: '20px',
                      marginTop: '0px',
                      marginBottom: 5,
                    }}>
                    REP DASHBOARD
                  </h2>
                  <p
                    style={{
                      fontSize: '1.2rem',
                      fontWeight: 900,
                      marginTop: '5px',
                      marginBottom: 0,
                    }}>
                    {pdState?.cases?.paginate
                      ? `Case Count: ${pdState?.cases?.paginate?.total}`
                      : 'loading... Cases'}
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: '50%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '10px',
                }}>
                {/* Week: */}
                {/* <div className="labelAndDropdown"> */}
                <CalendarPicker
                  id={'week'}
                  name={'week'}
                  selectable={'week'}
                  value={pdState.time.start}
                  endValue={pdState.time.end}
                  useCase="rep"
                  handler={event => handleDateSelect(event)}
                  style={{width: '80%'}}
                />

                {/* Location */}
                <TextField
                  select
                  id="location"
                  label="Location"
                  name="location"
                  style={{width: '200px', marginRight: '20px'}}
                  SelectProps={{IconComponent: ExpandMoreIcon}}
                  value={pdState.location ? pdState.location : 'null'}
                  onChange={event =>
                    handleInputChange(event.target.name, event.target.value)
                  }>
                  <MenuItem value={'null'}>All</MenuItem>
                  {pdState.settings &&
                    pdState.settings.surgery_locations &&
                    pdState.settings.surgery_locations
                      .filter(l => weeklyCaseLocations(l))
                      .map(location => {
                        return (
                          <MenuItem key={location.id} value={location.id}>
                            {location.name}
                          </MenuItem>
                        );
                      })}
                </TextField>

                {/* Physician */}
                <TextField
                  select
                  id="physician"
                  label="Physician"
                  name="physician"
                  style={{width: '200px'}}
                  SelectProps={{IconComponent: ExpandMoreIcon}}
                  value={pdState.physician ? pdState.physician : 'null'}
                  onChange={event =>
                    handleInputChange(event.target.name, event.target.value)
                  }>
                  <MenuItem value={'null'}>All</MenuItem>
                  {pdState.settings &&
                    pdState.settings.physicians &&
                    pdState.settings.physicians.map(physician => {
                      return (
                        <MenuItem key={physician.id} value={physician.id}>
                          {physician.name}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </div>
            </div>

            {/* Search Bar */}
            {/* REMOVING UNTIL SEARCH FUNCTIONALITY WORKS AGAIN */}
            {/* <div className='input-container'>
              <input
                className='search-input'
                type='text'
                name='search'
                maxLength='256'
                value={pdState.search}
                onChange={(event) => handleInputChange(event.target.name, event.target.value)}
              />
              <img className='search-icon' src={searchIcon} alt='Search' />
            </div> */}
          </form>
        </div>

        <div className="rep-table">
          {pdState.isLoading ? (
            <div style={{height: '90vh', justifyContent: 'center'}}>
              <Loading />
            </div>
          ) : pdState.cases.cases && pdState.cases.cases.length > 0 ? (
            <>
              <Paper
                sx={{width: 'calc(100% - 40px)', margin: '110px auto 0 auto'}}>
                <TableContainer
                  sx={{
                    maxHeight: 'calc(100vh - 170px)',
                    minHeight: 'calc(100vh - 200px)',
                  }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {tableHeader.map((title, idx) => (
                          <TableCell
                            key={idx}
                            style={{
                              fontSize: '16px',
                              fontWeight: 'bold',
                              color: '#0096FA',
                            }}>
                            {title.toUpperCase()}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(rowsPerPage > 0
                        ? pdState.cases.cases.slice(
                            innerPage * rowsPerPage,
                            innerPage * rowsPerPage + rowsPerPage,
                          )
                        : pdState.cases.cases
                      ).map((item, idx) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          onClick={() => handleRowClick(item)}
                          key={idx}>
                          {tableConstants(
                            handleAddNotes,
                            onPatientReadinessClick,
                            imagePopUp,
                            setImagePopUp,
                            currentRowID,
                            setCurrentRowID,
                            modalOpen,
                            setModalOpen,
                            currentImageIndex,
                            setCurrentImageIndex,
                          ).map((col, key) => (
                            <TableCell
                              style={{
                                width: col.customWidth || '140px',
                                fontFamily: 'Rubik',
                              }}
                              key={key}>
                              {col.render(item)}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              <div
                style={{
                  marginTop: 10,
                  paddingRight: 7,
                  background: '#fff',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}>
                <TablePagination
                  count={pdState.cases.cases.length}
                  page={innerPage}
                  className={'pagination'}
                  rowsPerPage={5}
                  rowsPerPageOptions={[]}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  ActionsComponent={TablePaginationActions}
                />
              </div>
            </>
          ) : (
            <div className="no-rep-cases">
              <h3>No Cases Available for your Search Criteria</h3>
              <p>
                Try a different combination of Week, Case Type, Location or
                Physician.
              </p>
            </div>
          )}
        </div>
      </div>
      {docboxState.isDocboxOpen && <Docbox />}
      {eventSettingsState.isEventSettingsOpen && <EventSettings />}
    </>
  );
};

const findGenderIcon = g => {
  if (g === 'F') {
    return faFemale;
  } else {
    return faMale;
  }
};

const findGenderIconColor = g => {
  if (g === 'F') {
    return 'patient-female';
  } else if (g === 'M') {
    return 'patient-male';
  }
};

const getImageURL = (fileName, url) => {
  if (!fileName) return unknownURL;

  fileName = fileName.toLowerCase();
  if (
    fileName.endsWith('.jpg') ||
    fileName.endsWith('.jpeg') ||
    fileName.endsWith('.png')
  )
    return url;
  else if (fileName.endsWith('.doc') || fileName.endsWith('.docx'))
    return docURL;
  else if (fileName.endsWith('.pdf')) return pdfURL;
  else if (fileName.endsWith('.ppt') || fileName.endsWith('.pptx'))
    return pptURL;
  else if (fileName.endsWith('.rft')) return rtfURL;
  else if (fileName.endsWith('.zip')) return zipURL;
  else if (fileName.endsWith('.xls') || fileName.endsWith('.xlsx'))
    return xlsURL;
  else if (fileName.endsWith('.txt')) return txtURL;
  else if (fileName.endsWith('.dcm')) return dicomURL;
  else return unknownURL;
};

const parseStringToTruncate = (str, length = 18) => {
  if (str.length <= length) return str;

  var ELIPSELENGTH = 3,
    charsToShow = length - ELIPSELENGTH,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2);

  return str.substr(0, frontChars) + '...' + str.substr(str.length - backChars);
};

const tableConstants = (
  handleAddNotes,
  onPatientReadinessClick,
  imagePopUp,
  setImagePopUp,
  currentRowID,
  setCurrentRowID,
  modalOpen,
  setModalOpen,
  setCurrentImageIndex,
  currentImageIndex,
) => {
  return [
    {
      title: 'Surgery Date',
      render: rowData => {
        return (
          <div className="">
            <span>
              {moment(rowData.time.start, 'YYYYMMDDHHmmss').format(
                'MMM DD, YYYY',
              )}
            </span>
            <p>
              {moment(rowData.time.start, 'YYYYMMDDHHmmss').format('h:mma')} -{' '}
              {moment(rowData.time.end, 'YYYYMMDDHHmmss').format('h:mma')}
            </p>
          </div>
        );
      },
    },
    {
      title: 'Main Physician',
      render: rowData => {
        return (
          <div className={''}>
            {/* if physician name is a first + last name object */}
            {rowData.physician.name !== undefined &&
              rowData.physician.name.last !== undefined && (
                <span>
                  {rowData.physician.name.last || ''}
                  {rowData.physician.name.first ? ', ' : ''}
                  {rowData.physician.name.first || ''}
                </span>
              )}
            {/* if physician name is a string */}
            {rowData.physician.name !== undefined &&
              rowData.physician.name.last === undefined && (
                <span>{rowData.physician.name}</span>
              )}
            {/* if physician key or physician name key are undefined/null */}
            {(rowData.physician === undefined ||
              rowData.physician === null ||
              rowData.physician.name === undefined ||
              rowData.physician.name === null) && <span>N/A</span>}
          </div>
        );
      },
    },
    {
      title: 'Case Name',
      customWidth: '200px',
      render: rowData => {
        return (
          <div className={'case-td'}>
            <span> {rowData.case}</span>
            <p className="patient-name">{rowData.patient.name}</p>
          </div>
        );
      },
    },
    {
      title: 'Surgery Location',
      render: rowData => {
        return (
          <div className="location-td">
            {rowData.type !== 'Conference' && rowData.type !== 'Vacation' ? (
              <span>{rowData.surgery_location.name}</span>
            ) : (
              <span>N/A</span>
            )}
          </div>
        );
      },
    },
    {
      title: 'Lead Time',
      customWidth: '70px',
      render: rowData => {
        let duration = moment.duration(
          moment(rowData.time.start, 'YYYYMMDDHHmmss').diff(
            moment(
              new Date().toLocaleString('en-US', {
                timeZone: 'America/New_York',
              }),
            ),
          ),
        );

        return (
          <div className="notes-td">
            {Math.round(duration.asDays())}{' '}
            {`day${
              duration.asDays() > 1 || duration.asDays() <= -2 ? 's' : ''
            }`}
          </div>
        );
      },
    },
    {
      title: 'Images',
      customWidth: '70px',
      render: rowData => {
        return (
          <div className="notes-td" style={{position: 'relative'}}>
            {rowData?.attachments?.length > 0 ? (
              <>
                <div>
                  <AttachmentIcon
                    style={{
                      padding: '5px',
                      cursor: 'pointer',
                    }}
                    onClick={e => {
                      e.stopPropagation();
                      setModalOpen(true);
                      setCurrentRowID(rowData.event_id);
                    }}
                  />
                </div>
                {modalOpen && currentRowID === rowData.event_id && (
                  <Modal
                    content={
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          minWidth: '400px',
                          maxWidth: '100vw',
                          height: '450px',
                          maxHeight: '80vh',
                          minHeight: '400px',
                        }}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}>
                          <div
                            style={{
                              display: 'flex',
                              height: 30,
                              width: 'fit-content',
                              cursor: 'pointer',
                              marginLeft: '-5px',
                            }}
                            onClick={() => setModalOpen(false)}>
                            <img
                              src={BackIcon}
                              alt="back icon"
                              height={18}
                              style={{marginTop: 5, marginRight: 10}}
                            />
                            <h4
                              style={{
                                fontWeight: 'bold',
                                fontFamily: 'Rubik',
                                color: '#438FE0',
                              }}>
                              ATTACHMENTS
                            </h4>
                          </div>
                          <UploadButton case={rowData} />
                        </div>
                        <div
                          style={{
                            marginTop: 40,
                            width: '100%',
                            height: '100%',
                            display: 'grid',
                            gridTemplateColumns:
                              'repeat(auto-fill, minmax(120px, 1fr))',
                            gap: '20px',
                            overflowY: 'scroll',
                          }}>
                          {rowData.attachments.map((attachment, idx) => {
                            return (
                              <div
                                key={
                                  attachment.attachment_id
                                    ? attachment.attachment_id
                                    : (attachment.id || 0) + idx
                                }
                                style={{
                                  background: '#438FE0',
                                  height: '60px',
                                  maxHeight: '155px',
                                  minHeight: '120px',
                                  cursor: 'pointer',
                                  maxWidth: '155px',
                                  borderRadius: '5px',
                                  padding: '5px',
                                }}
                                onClick={e => {
                                  window.open(attachment.url, '_blank');
                                }}>
                                <div
                                  style={{
                                    backgroundImage: `url(${getImageURL(
                                      attachment.file_name,
                                      attachment.url,
                                    )})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: '100%',
                                    height: '80%',
                                  }}></div>
                                <p
                                  style={{
                                    color: '#fff',
                                    fontSize: '10px',
                                    fontFamily: 'Rubik',
                                    margin: '5px 0 0 0',
                                  }}
                                  title={attachment.file_name || 'unkown file'}>
                                  {parseStringToTruncate(
                                    attachment.file_name || 'unknown file',
                                  )}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    }
                    toggleModal={setModalOpen}
                  />
                )}
              </>
            ) : (
              <span>None</span>
            )}
          </div>
        );
      },
    },
    {
      title: 'Scheduling Notes & Devices',
      customWidth: '200px',
      render: rowData => {
        return (
          <div>
            <ul
              style={{
                padding: 0,
                paddingLeft: '15px',
                marginTop: 0,
                marginBottom: 0,
                fontSize: '16px',
              }}>
              {rowData?.devices?.map((device, idx) => (
                <li key={idx}>{device.name}</li>
              ))}
            </ul>
          </div>
        );
      },
    },
  ];
};

export default AllCasesRepDB;
