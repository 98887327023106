import React, {useContext, useEffect, useState} from 'react';
import {useIdleTimer} from 'react-idle-timer';
import {Context as AuthContext} from '../context/AuthContext';
import {Context as GlobalContext} from '../context/GlobalContext';
import {buildQueryParams, hasDSLicense} from '../libraries/helpers';

const AutoLogoutTimer = ({ComponentName}) => {
  const {state: authState, logout} = useContext(AuthContext);
  const {
    state: globalState,
    showInactivity,
    setTotalInactiveMinutes,
  } = useContext(GlobalContext);
  const [sessionIdleMinutes, setSessionIdleMinutes] = useState(2);
  const [sessionLogoutMinutes, setSessionLogoutMinutes] = useState(15);

  useEffect(() => {
    if (
      authState.isLoggedIn !== null &&
      authState.isLoggedIn === false &&
      globalState.config.VELYS_LOGIN_URL
    ) {
      window.location.replace(`${globalState.config.VELYS_LOGIN_URL}`);
    }
  }, [authState.isLoggedIn, globalState.config.VELYS_LOGIN_URL]);

  useEffect(() => {
    if (
      globalState.config.SESSION_IDLE_MINUTES &&
      globalState.config.SESSION_IDLE_MINUTES > 0
    ) {
      setSessionIdleMinutes(globalState.config.SESSION_IDLE_MINUTES);
    }
    if (
      globalState.config.SESSION_LOGOUT_MINUTES &&
      globalState.config.SESSION_LOGOUT_MINUTES > 0
    ) {
      setSessionLogoutMinutes(globalState.config.SESSION_LOGOUT_MINUTES);
    }
  }, [globalState.config]);

  useEffect(() => {
    setTotalInactiveMinutes(sessionIdleMinutes + sessionLogoutMinutes);
  }, [sessionIdleMinutes, sessionLogoutMinutes]);

  useEffect(() => {
    let logoutTimer;
    let logoutTimerId;

    // check if this is a named tab, for VELYS - DOC-4414
    let params = {};
    if (window.name) {
      params['cl'] = 'false';
    }

    // since this is an inactivity, append the inactivity param
    params['inactivity'] = 'true';

    let query = buildQueryParams(params);

    if (globalState.isIdle) {
      logoutTimer = () =>
        setTimeout(() => {
          /* Disable logout timer for react docspera  */

          // logout()
          // window.location.replace(`${globalState.config.ABS_BASE_URL}/logout?${query}`)
          return;
        }, 1000 * 60 * sessionLogoutMinutes); // if user is still idle after X minutes, force logout

      logoutTimerId = logoutTimer();
    }

    return () => {
      if (logoutTimerId) {
        clearTimeout(logoutTimerId);
      }
    };
  }, [globalState.isIdle]);

  const handleOnIdle = event => {
    //check license 30 for 'Whitelabel Test Account - Disable Idle Screen & Force Logout'
    if (!hasDSLicense(authState.dsLicenses, 30)) {
      // showInactivity()
    }
  };

  const {getLastActiveTime} = useIdleTimer({
    timeout: 1000 * 60 * sessionIdleMinutes,
    onIdle: handleOnIdle,
    debounce: 100,
    element: document,
  });

  return <ComponentName />;
};

export default AutoLogoutTimer;
