import {getCookie} from '../libraries/helpers';
import axios from 'axios';

const instance = axios.create({
  baseURL: '/',
  withCredentials: true, // todo: make this configurable
  headers: {'Content-Type': 'application/json;charset=utf-8'},
});

const requestHandler = request => {
  request.headers.Authorization = `Bearer ${getCookie('docsperajwt')}`;
  return request;
};

instance.interceptors.request.use(request => requestHandler(request));

async function getSearchResults(queryString, archiveToggle) {
  const archived = archiveToggle ? 1 : 0;
  const url = `/api/events?q=${queryString}&archived=${archived}`;

  return instance
    .get(url)
    .then(response => {
      // process the response
      return new Promise((resolve, reject) => {
        if (response.data) {
          let json = response.data;
          resolve(json);
        } else {
          reject();
        }
      });
    })
    .catch(() => {
      console.log('error'); //.warn .write
    });
}

async function rescheduleApi(events) {
  const url = '/api/v1/intelligent-scheduling/events';
  return instance
  .put(url, {events: events}).then((response) => {
    return response;
  });
}

export {
  getSearchResults,
  rescheduleApi
};
