import React from 'react';

function SurgicalIcon() {
  return (
    <svg viewBox="0 0 54.42 54.42">
      <defs>
        <clipPath id="clip-path" transform="translate(-264.24 -211.25)">
          <circle className="cls-1" cx="291.45" cy="238.46" r="27.21" />
        </clipPath>
      </defs>
      <title>surgical-icon</title>
      <circle fill="#0096FA" cx="27.21" cy="27.21" r="27.21" />
      <circle fill="#0096FA" cx="27.21" cy="27.21" r="27.21" />
      <g className="cls-3">
        <g id="RJdnRH">
          <path
            fill="#fff"
            d="M307.54,249.58c-.66-.1-1.31-.18-2-.29A33.87,33.87,0,0,1,292.53,244c-1.22-.81-2.38-1.76-3.55-2.67a7,7,0,0,1-.88-.92,1.82,1.82,0,0,1-.4-1.59c.05-.32.09-.65.11-1a1.44,1.44,0,0,0-.47-1.24c-1.63-1.49-3.23-3-4.87-4.46s-3.13-2.75-4.69-4.11a1.15,1.15,0,0,1-.08-.1l2.91-1.1c.28-.11.56-.2.83-.32a.32.32,0,0,1,.39.08l7.42,6.6,10.21,9.08,7.84,7,.27.22Z"
            transform="translate(-264.24 -211.25)"
          />
          <path
            fill="#fff"
            d="M281.15,226l-1.55.59c-1.59.6-3.18,1.21-4.78,1.8a.33.33,0,0,1-.29,0c-.69-.57-1.37-1.16-2.09-1.78l1.23-.47,5.15-1.95a.32.32,0,0,1,.38.07c.59.55,1.2,1.08,1.8,1.61Z"
            transform="translate(-264.24 -211.25)"
          />
          <path
            fill="#fff"
            d="M270.56,224.87l1-.39,5.24-2a.37.37,0,0,1,.46.09c.35.33.72.65,1.11,1l-1.8.68-3.9,1.47c-.26.1-.52.2-.78.28a.22.22,0,0,1-.18,0Z"
            transform="translate(-264.24 -211.25)"
          />
          <path
            fill="#fff"
            d="M276.49,221.85l-1.63.62-3.09,1.17c-.57.21-1.15.42-1.72.65a.26.26,0,0,1-.33-.07c-.26-.25-.52-.47-.81-.73l2.93-1.1,3.47-1.32a.31.31,0,0,1,.38.08C275.94,221.38,276.2,221.59,276.49,221.85Z"
            transform="translate(-264.24 -211.25)"
          />
          <path
            fill="#fff"
            d="M274.91,220.45l-2.13.81-4.15,1.57a.49.49,0,0,1-.63-.12,5.94,5.94,0,0,0-.63-.56l1-.4,5.39-2a.32.32,0,0,1,.4.08C274.39,220,274.62,220.2,274.91,220.45Z"
            transform="translate(-264.24 -211.25)"
          />
        </g>
      </g>
    </svg>
  );
}

export default SurgicalIcon;
