import React, {useContext, useState} from 'react';
import moment from 'moment';
import {ArrowContainer, Popover} from 'react-tiny-popover';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleUp,
  faComment,
  faEdit,
  faFemale,
  faMale,
  faMapMarkerAlt,
  faPlane,
  fas,
  faSquareFull,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  determineAgeFromDob,
  differenceBtwnTwoIsos,
  getEventStartToEndTime,
  renderFriendlyDateTime,
  timeAgoFromNow,
} from '../../libraries/moment';
import {
  formatFullName,
  hasDSLicense,
  isIndustryRep,
  isPHIConsented,
} from '../../libraries/helpers';
import './AgendaCard.scss';
import {Context as AuthContext} from '../../context/AuthContext';
import {Context as GlobalContext} from '../../context/GlobalContext';
import {Context as DocboxContext} from '../../context/DocboxContext';
import {Context as ScheduleContext} from '../../context/ScheduleContext';
import {
  agendaURI,
  calendarDeleteURI,
  calendarMonthURI,
  draftsURI,
  sendTelehealthInviteURI,
  viewCaseURI,
} from '../../dataAccess/apiEndpoints';
import {faChartBar} from '@fortawesome/free-regular-svg-icons';
import {
  triggerEditDocbox,
  triggerOpenDocbox,
  triggerOpenThread,
} from '../../libraries/util';

const AgendaCard = props => {
  const {state: authState} = useContext(AuthContext);
  const {
    state: globalState,
    setNotification,
    openAlert,
    closeAlert,
  } = useContext(GlobalContext);
  const {
    state: scheduleState,
    sendTelehealthInvite,
    getAgenda,
    getSharedScheduleDSLicenses,
    getDrafts,
    getMonth,
  } = useContext(ScheduleContext);
  const {openDocbox, deleteCalendarEvent} = useContext(DocboxContext);

  const [showAllAttendees, setShowAllAttendees] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleAllAttendeesClick = event => {
    event.stopPropagation();
    setShowAllAttendees(!showAllAttendees);
  };

  const isPendingCase = props.pending || false;

  const eventType = props.event_type ? props.event_type.toLowerCase() : '';
  const eventTime = isPendingCase
    ? renderFriendlyDateTime(props.start, props.end)
    : getEventStartToEndTime(props.start, props.end);
  const duration = differenceBtwnTwoIsos(props.start, props.end, true);
  const eventIconClass = eventType ? `case-icon ${eventType}` : '';
  const readinessbadgeText = getPatientReadinessText(
    props.patient_readiness,
    props.readiness_percent,
  );
  const readinessBadgeClass = getPatientReadinessClass(
    props.patient_readiness,
    props.readiness_percent,
  )
    ? getPatientReadinessClass(props.patient_readiness, props.readiness_percent)
    : '';
  const readinessBadgeTitle = getPatientReadinessTitle(props.patient_readiness);
  let genderIcon = faMale;
  let genderIconColor = '';
  let genderTitle = 'Gender Unknown';
  let patientName =
    props.patient.name &&
    typeof props.patient.name.first !== 'undefined' &&
    typeof props.patient.name.last !== 'undefined'
      ? formatFullName(props.patient.name.first, props.patient.name.last)
      : '';
  const age = props.patient.dob
    ? `${determineAgeFromDob(props.patient.dob)} y`
    : '';
  const patientStatus = props.patient.patient_status
    ? `Patient ${props.patient.patient_status}`
    : 'Patient Status Unknown';
  const caseOwnerObject =
    props.editors.length > 0 &&
    props.editors.find(e => e.designation === 'Owner');

  // strip patient name if not PHI consented
  if (patientName && !isPHIConsented(props.phi_consent)) {
    patientName = 'Patient Name PHI Protected';
  }

  if (patientName && isIndustryRep(authState.userType)) {
    let patientNameArray = patientName.split(' ');
    let initials = patientNameArray
      .map(name => {
        return `${name.charAt(0)}.`;
      })
      .join(' ');
    patientName = initials;
  }

  // strip patient name if not PHI consented & isIndustryRep
  if (isIndustryRep(authState.userType) && !isPHIConsented(props.phi_consent)) {
    patientName = 'Patient Name PHI Protected';
  }

  // display patient gender, not PHI protected
  if (props.patient.gender) {
    if (props.patient.gender === 'Female') {
      genderIcon = faFemale;
      genderIconColor = 'patient-female';
      genderTitle = 'Female';
    } else if (props.patient.gender === 'Male') {
      genderIconColor = 'patient-male';
      genderTitle = 'Male';
    }
  }

  const openDocboxView = eventId => {
    const mode = 'View';
    const openEventId = eventId ? eventId : '2649852';
    const viewAsUserId = authState.userId ? authState.userId : null;

    if (openEventId && viewAsUserId) {
      const url = `${globalState.config.ABS_BASE_URL}${viewCaseURI}`;
      openDocbox(
        mode,
        openEventId,
        url,
        viewAsUserId,
        null,
        authState.userType,
      );
    }
  };

  const handleAgendaClick = event_id => {
    const viewAsUserId = scheduleState.targetUserId
      ? scheduleState.targetUserId
      : authState.userId;
    triggerOpenDocbox(event_id, viewAsUserId);
  };

  const handleEditCase = event_id => {
    triggerEditDocbox(event_id);
  };

  const handleOpenThread = thread_id => {
    triggerOpenThread(thread_id);
  };

  const showTelehealth = () => {
    //check license 24 for 'Dolby Telehealth User'

    if (scheduleState.sharedScheduleId) {
      return hasDSLicense(scheduleState.sharedScheduleLicenses, 24) &&
        eventType === 'clinic'
        ? true
        : false;
    } else {
      return hasDSLicense(authState.dsLicenses, 24) && eventType === 'clinic'
        ? true
        : false;
    }
  };

  const telehealthLinkExists = () => {
    return props.telehealth &&
      props.telehealth.url !== undefined &&
      props.telehealth.url != ''
      ? true
      : false;
  };

  const handleDelete = event_id => {
    const url = `${globalState.config.ABS_BASE_URL}${calendarDeleteURI}`;
    let userIdForDelete = authState.userId
      ? authState.userId
      : scheduleState.targetUserId;
    deleteCalendarEvent(url, event_id, userIdForDelete.toString()).then(
      response => {
        const getAgendaURL = `${globalState.config.ABS_BASE_URL}${agendaURI}`;
        const getMonthURL = `${globalState.config.ABS_BASE_URL}${calendarMonthURI}`;
        const getDraftsURL = `${globalState.config.ABS_BASE_URL}${draftsURI}`;
        getDrafts(getDraftsURL, scheduleState.targetUserId);
        getAgenda(
          getAgendaURL,
          scheduleState.targetUserId,
          scheduleState.selectedCalendarDate,
        );
        getMonth(
          getMonthURL,
          scheduleState.selectedCalendarMonth,
          scheduleState.targetUserId,
        );
        if (
          response.status === 200 &&
          response.data.deleted &&
          response.data.deleted.length > 0
        ) {
          setNotification('Case deleted successfully.');
        } else {
          const alertBody = () => {
            return (
              <p>
                Please contact{' '}
                <a
                  href="/web/contact"
                  target="_blank"
                  rel="noopener noreferrer">
                  DocSpera Customer Support
                </a>
                .
              </p>
            );
          };

          openAlert({
            title: 'Error Deleting Case',
            body: alertBody(),
            cancelText: 'Close',
          });
        }
      },
    );
  };

  return (
    <div
      className={`agenda-card flex align-stretch ${props.status} ${props.event_type}`}
      data-event-id={props.event_id}
      onClick={() => handleAgendaClick(props.event_id)}>
      {/* Event Type Color Tab */}
      {/* {eventType && <div className={`case-color-tab ${eventType}`}></div>} */}

      {/* Event Type Icon */}
      {/* {
        eventIconClass &&
        <div className="case-icon-container">
          <div className={eventIconClass}></div>
        </div>
      } */}

      <div className="case-body">
        {/* Event Time + Readiness Badge */}
        <div className="agenda-title flex flex-start space-between">
          <p>
            <span className={`case-title ${props.event_type} ${props.status}`}>
              {props.rebook_dates ? (
                <FontAwesomeIcon
                  icon={props.rebook_dates ? faSquareFull : fas}
                  className="rebook-dates"
                />
              ) : (
                ''
              )}
              {eventTime}
            </span>
            <span>({duration})</span>
          </p>
          {eventType === 'surgery'
            ? readinessbadgeText && (
                <div
                  className={`readiness-badge flex align-center ${readinessBadgeClass}`}
                  title={readinessBadgeTitle}>
                  <div
                    className={`readiness-icon ${readinessBadgeClass}`}></div>
                  <p className="ellipsis">{readinessbadgeText}</p>
                </div>
              )
            : eventType !== 'clinic'
            ? eventIconClass && (
                <div className={`case-icon-container`}>
                  <FontAwesomeIcon
                    id="case-icon"
                    icon={eventType === 'vacation' ? faPlane : faChartBar}
                    className={eventIconClass}
                  />
                </div>
              )
            : ''}
        </div>

        {/* Case Name + Case Owner */}
        <div className="event-agenda-content flex flex-center space-between">
          <p className="case-name">{props.case}</p>
          {!isPendingCase &&
          props.event_type !== 'Vacation' &&
          props.event_type !== 'Conference' ? (
            <p
              className={`case-owner${showAllAttendees ? ' open' : ''}`}
              onClick={event => handleAllAttendeesClick(event)}>
              {props.attendees}
              <FontAwesomeIcon
                icon={showAllAttendees ? faAngleUp : faAngleDown}
                className="all-attendees-icon"
                style={{marginLeft: '0.5rem'}}
              />
            </p>
          ) : (
            <p className="case-owner">{props.attendees}</p>
          )}
          {isPendingCase && <p>{props.attendees}</p>}
        </div>

        {/* All Attendees */}
        {!isPendingCase && showAllAttendees && (
          <div className="all-attendees flex align-start space-between">
            <div>
              <h2>Providers</h2>
              <hr />
              <div>
                {props.all_attendees
                  .filter(attendee => attendee.type !== 'Industry')
                  .map((attendee, index) => {
                    return (
                      <p key={index}>
                        {attendee.last}, {attendee.first[0]}
                      </p>
                    );
                  })}
              </div>
            </div>
            <div>
              <h2>Industry Reps</h2>
              <hr />
              <div>
                {props.all_attendees
                  .filter(attendee => attendee.type === 'Industry')
                  .map((attendee, index) => {
                    return (
                      <p key={index}>
                        {attendee.last}, {attendee.first[0]}
                      </p>
                    );
                  })}
              </div>
            </div>
          </div>
        )}

        {/* Patient Details for Surgery & Clinical Cases */}
        {(isPendingCase ||
          eventType === 'surgery' ||
          eventType === 'clinic') && (
          <div className="patient-details flex flex-center align-center space-between">
            <p>
              {patientName && (
                <FontAwesomeIcon
                  icon={genderIcon}
                  className={genderIconColor}
                  title={genderTitle}
                />
              )}
              <span>
                {patientName ? patientName : ''} {age ? age : ''}
              </span>
              <span>{props.patient.mrn ? props.patient.mrn : ''}</span>
            </p>

            {!isPendingCase &&
              !showTelehealth() &&
              props.patient.patient_status && (
                <p className={props.patient.patient_status}>{patientStatus}</p>
              )}

            {isPendingCase && props.updated.time && (
              <p>
                Last edited{' '}
                {timeAgoFromNow(
                  moment(props.updated.time.toString(), 'YYYYMMDDHHmmss'),
                )}
              </p>
            )}
          </div>
        )}

        {/* Print Status */}
        {!isPendingCase &&
          !showTelehealth() &&
          props.patient.patient_status && (
            <p className={`print-status ${props.patient.patient_status}`} >{props.patient.patient_status}</p>
        )}
        
        {/* Location, Telehealth Btn, (delete, edit, msg btns) */}
        <div className="agenda-footer flex flex-center space-between">
          {props.location ? (
            <p className="agenda-location">
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                className="case-location-icon"
                title="Location"
              />
              <span>{props.location || <i>No Location</i>}</span>
            </p>
          ) : props.event_type !== 'Vacation' &&
            props.event_type !== 'Conference' ? (
            <p className="agenda-location">
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                className="case-location-icon"
                title="Location"
              />
            </p>
          ) : (
            <p></p>
          )}

          <div className="flex function-icons">
            {!isPendingCase && showTelehealth() && telehealthLinkExists() && (
              <button
                type="button"
                className="btn-success join-telehealth-btn"
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  if (telehealthLinkExists()) {
                    window.open(
                      props.telehealth.url,
                      '_blank',
                      'noopener, noreferrer',
                    );
                  }
                }}>
                {/* Join Video Conference */}
              </button>
            )}

            {!isPendingCase && showTelehealth() && !telehealthLinkExists() && (
              <button
                type="button"
                className={`btn-primary invite-telehealth-btn ${
                  !props.patient.mobile_phone &&
                  !props.patient.phone &&
                  !props.patient.email
                    ? 'invite-telehealth-disabled'
                    : ''
                }`}
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  if (
                    !props.patient.name.first ||
                    !props.patient.name.last ||
                    !(
                      props.patient.mobile_phone ||
                      props.patient.phone ||
                      props.patient.email
                    )
                  ) {
                    let missingItems = [];
                    if (!props.patient.name.first) {
                      missingItems.push('first name');
                    }
                    if (!props.patient.name.last) {
                      missingItems.push('last name');
                    }
                    if (
                      !(
                        props.patient.mobile_phone ||
                        props.patient.phone ||
                        props.patient.email
                      )
                    ) {
                      missingItems.push('phone number or email');
                    }
                    let alertBody = '';
                    if (missingItems.length > 2) {
                      let last = missingItems.pop();
                      alertBody = missingItems.join(', ');
                      alertBody += ', and ' + last;
                    } else {
                      alertBody = missingItems.join(' and ');
                    }

                    openAlert({
                      title: 'Enable Telehealth for this Case',
                      body: `Update the case with patient ${alertBody}. You may then invite the Patient to a Video Conference.`,
                      cancelText: 'Close',
                    });
                  } else {
                    const uri = sendTelehealthInviteURI.replace(
                      '{eventid}',
                      props.event_id,
                    );
                    const url = `${globalState.config.ABS_BASE_URL}${uri}`;

                    sendTelehealthInvite(url).then(response => {
                      if (
                        response &&
                        response.status &&
                        response.status == 200
                      ) {
                        const getAgendaURL = `${globalState.config.ABS_BASE_URL}${agendaURI}`;
                        setNotification('Invite sent successfully.');
                        getAgenda(
                          getAgendaURL,
                          scheduleState.targetUserId,
                          scheduleState.selectedCalendarDate,
                        );
                      } else {
                        setNotification('Error sending Invite to Patient.');
                      }
                    });
                  }
                }}>
                Invite Telehealth?
              </button>
            )}

            {authState.userType != 2 && (
              <button
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  handleEditCase(props.event_id);
                }}>
                <FontAwesomeIcon
                  icon={faEdit}
                  className="edit-icon"
                  title="Edit-Case"
                />
              </button>
            )}
            {authState.userId == caseOwnerObject.id && (
              <Popover
                style={{zIndex: 120000000, width: '50px'}}
                isOpen={isPopoverOpen}
                positions={['left']}
                padding={10}
                onClickOutside={() => setIsPopoverOpen(false)}
                // ref={clickMeButtonRef} // if you'd like a ref to your popover's child, you can grab one here
                content={({position, childRect, popoverRect}) => (
                  <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor={'white'}
                    arrowSize={10}
                    arrowStyle={{opacity: 1}}
                    className="popover-arrow-container"
                    arrowClassName="popover-arrow">
                    <div
                      id="popover-content"
                      style={{
                        backgroundColor: 'white',
                        opacity: 1,
                        color: 'black',
                      }}
                      onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                      <button
                        onClick={event => {
                          event.preventDefault();
                          event.stopPropagation();
                          setIsPopoverOpen(false);
                        }}>
                        x
                      </button>
                      <p>Do you want to delete this event?</p>
                      <div className="popover-btn-container">
                        <button
                          id="no"
                          onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            setIsPopoverOpen(false);
                          }}>
                          No
                        </button>
                        <button
                          id="yes"
                          onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            handleDelete(props.event_id);
                          }}>
                          Yes
                        </button>
                      </div>
                    </div>
                  </ArrowContainer>
                )}>
                <button
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    setIsPopoverOpen(!isPopoverOpen);
                  }}>
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    className={`trash-icon ${isPopoverOpen ? 'open' : 'close'}`}
                    title="Delete-Case"
                  />
                </button>
              </Popover>
            )}
            {props.thread_id && props.thread_id != '' ? (
              <button
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  handleOpenThread(props.thread_id);
                }}>
                <FontAwesomeIcon
                  icon={faComment}
                  className="msg-icon"
                  title="Msg-Case"
                />
              </button>
            ) : (
              ''
            )}
          </div>
        </div>

        {/* hidden case info (for agenda print view) */}
        <div id="Print_CaseNotes">
          <div className="Agenda_CaseNotesHeader">CASE NOTES</div>
          <div className="Agenda_CaseNotes">{props.case_notes}</div>
        </div>

        <div id="Print_Devices">
          <div className="Agenda_DevicesContainerHeader">DEVICES</div>
          <div className="Agenda_DevicesContainer">
            {props.devices.length > 0 &&
              props.devices.map((device, index) => {
                return (
                  <div className="Agenda_Device" key={index}>
                    {device.name ? device.name : ''}{' '}
                    {device.size ? device.size : ''}
                  </div>
                );
              })}
          </div>
        </div>

        <div id="Print_SchedulingNotes">
          <div className="Agenda_SchedulingNotesHeader">SCHEDULING NOTES</div>
          <div className="Agenda_SchedulingNotes">{props.scheduling_notes}</div>
        </div>
      </div>

      {/* Unconfirmed Invite Marker */}
      {/* {
        (props.status) &&
        <div className={`confirm-tab ${props.status.toLowerCase()} flex align-center`}>
          {
            props.status === 'Unconfirmed' &&
            <p>{props.status}</p>
          }
        </div>
      } */}
    </div>
  );
};

const getPatientReadinessText = (status, percent) => {
  percent = percent ? parseInt(percent) : 0;

  if (status == 'Ready') {
    return 'Patient Ready';
  } else if (status == 'Pending') {
    if (percent === 0) {
      return 'Patient Not Started';
    }
    return 'Patient ' + percent + '% Ready';
  } else if (status == 'N/A') {
    return '';
  }
};

const getPatientReadinessClass = (status, percent) => {
  percent = percent ? parseFloat(percent) : 0;
  if (status == 'Ready') {
    return 'ready';
  } else if (status == 'Pending') {
    if (percent === 0) {
      return 'not-started';
    }
    return 'pending';
  } else if (status == 'Unknown') {
    return '';
  }
  return null;
};

const getPatientReadinessTitle = status => {
  if (status == 'Ready') {
    return 'Patient Is Ready for Surgery';
  } else if (status == 'Pending') {
    return 'Patient Is Not Yet Ready for Surgery';
  } else if (status == 'Unknown') {
    return '';
  }
};

export default AgendaCard;
