import React, {useContext, useEffect} from 'react';
import {Context as AuthContext} from '../context/AuthContext';
import {Context as GlobalContext} from '../context/GlobalContext';
import {Context as ScheduleContext} from '../context/ScheduleContext';
import {Context as DocboxContext} from '../context/DocboxContext';
import {Context as EventSettingsContext} from '../context/EventSettingsContext';
import {Context as ContactsContext} from '../context/ContactsContext';
import {
  AddFieldButton,
  BillingCodeBadge,
  BillingTypeahead,
  DeleteFieldButton,
  Typeahead,
} from './ui/atoms';
import AttachmentCard from './AttachmentCard';
import Dropzone from './Dropzone';
import './DocboxModuleContainer.scss';
import moment from 'moment';
import momentTz from 'moment-timezone';
import {
  determineAgeFromDob,
  differenceBtwnTwoIsos,
  getEventStartToEndTime,
  timeAgoFromNow,
} from '../libraries/moment';
import {
  calculateBMI,
  formatEthnicity,
  formatFullName,
  formatPhoneNumber,
  isBPCIPatient,
  isIndustryRep,
  isPHIConsented,
  parseSanitizedString,
} from '../libraries/helpers';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import {faClock} from '@fortawesome/free-regular-svg-icons';
import CalendarPicker from './CalendarPicker';
import gearIcon from '../assets/img/icons/gear.svg';
import {
  agendaURI,
  attendeeSearchURI,
  billingCodesSearchURI,
  calendarUpdateChecklistURI,
  draftsURI,
  getClashesURI,
  getSubGroupsURI,
  getUserPreferencesURI,
} from '../dataAccess/apiEndpoints';

const DocboxModuleContainer = () => {
  const {state: docboxState, updateFormFieldValue} = useContext(DocboxContext);

  return (
    <>
      <div id="docbox-module-container">
        {docboxState.reasonCancellation && (
          <div
            className="docbox-module-banner"
            style={{background: 'rgba(255, 180, 29, 0.2)'}}>
            <p>Reason for Cancellation:</p>
            {docboxState.mode === 'View' && (
              <p>
                <b>{docboxState.reasonCancellation}</b>
              </p>
            )}

            {docboxState.mode === 'Edit' && (
              <form
                onSubmit={event => {
                  event.preventDefault();
                  return false;
                }}>
                <div className="flex align-center space-between">
                  <div className="width100">
                    <select
                      id="FIELD_reason-cancellation"
                      name="reasonCancellation"
                      value={docboxState.reasonCancellation}
                      onChange={event => {
                        updateFormFieldValue(
                          event.target.name,
                          event.target.value,
                        );
                      }}>
                      <option value="" disabled>
                        SELECT REASON...
                      </option>
                      <option value="Infection">Infection</option>
                      <option value="On the Fence">On the Fence</option>
                      <option value="COVID Positive">COVID Positive</option>
                      <option value="Insurance Auth">Insurance Auth</option>
                      <option value="Medical Clearance">
                        Medical Clearance
                      </option>
                      <option value="Changed Mind">Changed Mind</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
              </form>
            )}
          </div>
        )}
        {Object.keys(docboxState.modules).map((node, index) => {
          return docboxState.modules[node] !== null &&
            docboxState.modules[node] ? (
            <DocboxModule key={index} moduleName={node} />
          ) : null;
        })}
      </div>
    </>
  );
};

const DocboxModule = ({moduleName}) => {
  const {state: authState} = useContext(AuthContext);
  const {state: scheduleState} = useContext(ScheduleContext);
  const {
    state: docboxState,
    addProvider,
    addRep,
    addDevice,
    addChecklistGroup,
    deleteChecklistGroup,
    updateEventType,
    updateFormFieldValue,
    updateHeight,
    updateWeight,
    updateUnits,
    updateBPCIPatient,
    updateTimeAndDuration,
    updateRebookDates,
    selectMyProcedure,
    selectMyLocation,
    addBillingCode,
    deleteBillingCode,
    linkChecklistsToPatientReadiness,
    didPatientClearLinkedChecklists,
    getClashes,
  } = useContext(DocboxContext);
  const {state: eventSettingsState, openEventSettings} =
    useContext(EventSettingsContext);
  const {state: contactsState, getVendorGroups} = useContext(ContactsContext);
  const {
    state: globalState,
    openAlert,
    closeAlert,
    setNotification,
  } = useContext(GlobalContext);

  const states = [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
  ];
  const timeslots = [];
  const dobYears = [];
  for (let i = 0; i < 96; i++) {
    const increment = moment('0000', 'HHmm')
      .add(15 * i, 'minutes')
      .format('HHmm');
    timeslots.push(increment);
  }
  for (let j = 0; j <= 100; j++) {
    const year = moment().subtract(j, 'years').format('YYYY');
    dobYears.push(year);
  }
  const getEventSettingsURL = `${globalState.config.ABS_BASE_URL}${getUserPreferencesURI}`;

  const formatNotes = inputNotes => {
    if (
      inputNotes == 'https://app.box.com/s/z5zutj09gcy85knhnujnfbwnxs9m7pcw'
    ) {
      return (
        <a
          href="https://app.box.com/s/z5zutj09gcy85knhnujnfbwnxs9m7pcw"
          target="_blank"
          rel="noopener noreferrer">
          https://app.box.com/s/z5zutj09gcy85knhnujnfbwnxs9m7pcw
        </a>
      );
    } else {
      // return inputNotes.replace(/\n/g, <br />);
      // return inputNotes.split('\n').join(<br />)
      return inputNotes.split('\n').map((element, index) => {
        return (
          <span key={index}>
            {element}
            <br />
          </span>
        );
      });
    }
  };

  const renderDobDays = (dobMonth, dobYear) => {
    if (dobMonth) {
      let totalDays = null;

      if (dobMonth === '02') {
        totalDays =
          parseInt(dobYear) > 0 && parseInt(dobYear) % 4 !== 0 ? 28 : 29;
      } else if (
        dobMonth === '04' ||
        dobMonth === '06' ||
        dobMonth === '09' ||
        dobMonth === '11'
      ) {
        totalDays = 30;
      } else {
        totalDays = 31;
      }

      if (totalDays) {
        const daysArray = [];

        for (let day = 1; day <= totalDays; day++) {
          daysArray.push(day);
        }

        return daysArray.map((day, index) => {
          const output = day < 10 ? `0${day}` : day.toString();
          return (
            <option key={index} value={output}>
              {day.toString()}
            </option>
          );
        });
      }
    }
    return null;
  };

  const renderName = () => {
    if (isIndustryRep(authState.userType)) {
      if (docboxState.patientFirst || docboxState.patientLast) {
        let initialArray = [];
        if (docboxState.patientFirst) {
          initialArray.push(`${docboxState.patientFirst.charAt(0)}.`);
        }
        if (docboxState.patientLast) {
          initialArray.push(`${docboxState.patientLast.charAt(0)}.`);
        }
        return initialArray.join(' ');
      } else {
        return 'N/A';
      }
    }

    if (
      isPHIConsented(docboxState.phiConsent) &&
      (docboxState.patientFirst || docboxState.patientLast)
    ) {
      return formatFullName(docboxState.patientFirst, docboxState.patientLast);
    } else {
      return 'N/A';
    }
  };

  const handleInputChange = (name, value) => {
    updateFormFieldValue(name, value);
  };

  // In Create/Edit mode, if the start date, start time, end date, or end time change, get calendar clashes
  useEffect(() => {
    if (docboxState.mode !== 'View') {
      const url = `${globalState.config.ABS_BASE_URL}${getClashesURI}`;
      const start = `${docboxState.startDate}${docboxState.startTime}00`;
      const end = `${docboxState.endDate}${docboxState.endTime}00`;
      getClashes(url, docboxState.eventOwnerId, start, end);
    }
  }, [
    docboxState.mode,
    docboxState.startDate,
    docboxState.startTime,
    docboxState.endDate,
    docboxState.endTime,
  ]);

  const checkClash = () => {
    return docboxState.clashes.every(clash => {
      return clash.event_id === docboxState.eventId;
    });
  };

  switch (moduleName) {
    case 'brief':
      if (docboxState.mode === 'View') {
        return (
          <div
            id="docbox-module-brief"
            className="docbox-module docbox-nav-target">
            <h1>Brief</h1>
            <form
              onSubmit={event => {
                event.preventDefault();
                return false;
              }}>
              <h2>Event</h2>
              <section>
                <div className="form-row view">
                  <div className="width30">
                    <label htmlFor="FIELD_event-type">Type</label>
                  </div>
                  <div className="width70">
                    <p id="FIELD_event-type">
                      {docboxState.eventType || <span>N/A</span>}
                    </p>
                  </div>
                </div>

                <div className="form-row view">
                  <div className="width30">
                    <label htmlFor="FIELD_case-name">Case Name</label>
                  </div>
                  <div className="width70">
                    <p id="FIELD_case-name">
                      {docboxState.caseName || <span>N/A</span>}
                    </p>
                  </div>
                </div>

                {docboxState.eventType !== 'Conference' &&
                  docboxState.eventType !== 'Vacation' && (
                    <div className="form-row view">
                      <div className="width30">
                        <label htmlFor="FIELD_location">Location</label>
                      </div>
                      <div className="width70">
                        <p id="FIELD_location">
                          {docboxState.location.address || <span>N/A</span>}
                        </p>
                      </div>
                    </div>
                  )}
              </section>

              <h2>Time</h2>
              <section>
                <div className="form-row view">
                  <div className="width30">
                    <label htmlFor="FIELD_start-date">Start Time</label>
                  </div>
                  <div className="width70">
                    <p id="FIELD_start-date">
                      {`${moment(docboxState.startDate, 'YYYYMMDD').format(
                        'MMM D, YYYY',
                      )}, ${moment(docboxState.startTime, 'HHmm').format(
                        'h:mm A',
                      )}` || <span>N/A</span>}
                    </p>
                  </div>
                </div>

                <div className="form-row view">
                  <div className="width30">
                    <label htmlFor="FIELD_end-date">End Time</label>
                  </div>
                  <div className="width70">
                    <p id="FIELD_end-date">
                      {`${moment(docboxState.endDate, 'YYYYMMDD').format(
                        'MMM D, YYYY',
                      )}, ${moment(docboxState.endTime, 'HHmm').format(
                        'h:mm A',
                      )}` || <span>N/A</span>}
                    </p>
                  </div>
                </div>

                <div className="form-row view">
                  <div className="width30">
                    <label htmlFor="FIELD_duration">Duration</label>
                  </div>
                  <div className="width70">
                    <p id="FIELD_duration">
                      {differenceBtwnTwoIsos(
                        `${docboxState.startDate}${docboxState.startTime}00`,
                        `${docboxState.endDate}${docboxState.endTime}00`,
                        true,
                      ) || 'N/A'}
                    </p>
                  </div>
                </div>
              </section>

              {docboxState.eventType !== 'Conference' &&
                docboxState.eventType !== 'Vacation' && (
                  <>
                    <h2>Patient</h2>
                    <section>
                      <div className="form-row view">
                        <div className="width30">
                          <label htmlFor="FIELD_patient-name">
                            {isIndustryRep(authState.userType)
                              ? "Patient's Initials"
                              : "Patient's Name"}
                          </label>
                        </div>
                        <div className="width70">
                          <p id="FIELD_patient-name">{renderName()}</p>
                        </div>
                      </div>

                      <div className="form-row view">
                        {!isPHIConsented(docboxState.phiConsent) && (
                          <>
                            <div className="width30">
                              <label htmlFor="FIELD_patient-age">Age</label>
                            </div>
                            <div className="width70">
                              <p id="FIELD_patient-age">
                                {docboxState.dobYear &&
                                docboxState.dobMonth &&
                                docboxState.dobDay ? (
                                  `${determineAgeFromDob(
                                    `${docboxState.dobYear}${docboxState.dobMonth}${docboxState.dobDay}`,
                                  )} y`
                                ) : (
                                  <span>N/A</span>
                                )}
                              </p>
                            </div>
                          </>
                        )}

                        {isPHIConsented(docboxState.phiConsent) && (
                          <>
                            <div className="width30">
                              <label htmlFor="FIELD_patient-dob">
                                Date of Birth
                              </label>
                            </div>
                            <div className="width70">
                              <p id="FIELD_patient-dob">
                                {docboxState.dobYear &&
                                docboxState.dobMonth &&
                                docboxState.dobDay ? (
                                  `${moment(
                                    docboxState.dobYear +
                                      docboxState.dobMonth +
                                      docboxState.dobDay,
                                    'YYYYMMDD',
                                  ).format(
                                    'MMM D, YYYY',
                                  )} (${determineAgeFromDob(
                                    `${docboxState.dobYear}${docboxState.dobMonth}${docboxState.dobDay}`,
                                  )} y)`
                                ) : (
                                  <span>N/A</span>
                                )}
                              </p>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="form-row view">
                        <div className="width30">
                          <label htmlFor="FIELD_gender">Gender</label>
                        </div>
                        <div className="width70">
                          <p id="FIELD_gender">
                            {docboxState.gender || <span>N/A</span>}
                          </p>
                        </div>
                      </div>

                      <div className="form-row view">
                        <div className="width30">
                          <label htmlFor="FIELD_mrn">
                            Medical Record Number
                          </label>
                        </div>
                        <div className="width70">
                          <p id="FIELD_mrn">
                            {isPHIConsented(docboxState.phiConsent) &&
                            docboxState.mrn ? (
                              docboxState.mrn
                            ) : (
                              <span>N/A</span>
                            )}
                          </p>
                        </div>
                      </div>

                      <div className="form-row view">
                        <div className="width30">
                          <label htmlFor="FIELD_patient-status">
                            Patient Status
                          </label>
                        </div>
                        <div className="width70">
                          <p id="FIELD_patient-status">
                            {docboxState.patientStatus || <span>N/A</span>}
                          </p>
                        </div>
                      </div>

                      {!isIndustryRep(authState.userType) && (
                        <div className="form-row view">
                          <div className="width30">
                            <label htmlFor="FIELD_arrival-request">
                              Arrival Request
                            </label>
                          </div>
                          <div className="width70">
                            <p id="FIELD_arrival-request">
                              {docboxState.arrivalRequest || <span>TBD</span>}
                            </p>
                          </div>
                        </div>
                      )}

                      <div className="form-row view">
                        <div className="width30">
                          <label htmlFor="FIELD_provider-notes">
                            Provider Notes
                            <br />
                            <b>(Not linked to EMR)</b>
                          </label>
                        </div>
                        <div className="width70">
                          <p id="FIELD_provider-notes">
                            {docboxState.providerNotes ? (
                              formatNotes(docboxState.providerNotes)
                            ) : (
                              <span>N/A</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </section>
                  </>
                )}
            </form>
          </div>
        );
      } else {
        const renderArrivalRequestOptions = startTime => {
          const arrivalOptionsArray = [];
          const parsedStartTime = parseInt(startTime);

          if (parsedStartTime >= 30) {
            let timeString = '0000';

            while (
              parseInt(timeString) <= parsedStartTime - 30 &&
              parseInt(timeString) <= 2345
            ) {
              arrivalOptionsArray.push(timeString);
              timeString = moment(timeString, 'HHmm')
                .add(15, 'minutes')
                .format('HHmm');
            }

            return arrivalOptionsArray.map((timeValue, index) => {
              return (
                <option key={index} value={timeValue}>
                  {moment(timeValue, 'HHmm').format('h:mm a')}
                </option>
              );
            });
          }

          return null;
        };

        return (
          <div
            id="docbox-module-brief"
            className="docbox-module docbox-nav-target">
            <h1>Brief</h1>
            <form
              onSubmit={event => {
                event.preventDefault();
                return false;
              }}>
              <h2>Event</h2>
              <section>
                <div className="form-row">
                  <div className="width50">
                    <label htmlFor="FIELD_event-type">Type</label>
                    {docboxState.mode === 'Create' && (
                      <select
                        id="FIELD_event-type"
                        name="eventType"
                        value={docboxState.eventType}
                        onChange={event =>
                          updateEventType(
                            event.target.value,
                            authState.userType,
                          )
                        }>
                        <option value="Surgery">Surgery</option>
                        <option value="Clinic">Clinical</option>
                        <option value="Conference">
                          Conference (Contactable)
                        </option>
                        <option value="Vacation">
                          Vacation (Not Available)
                        </option>
                      </select>
                    )}
                    {docboxState.mode === 'Edit' && (
                      <input
                        id="FIELD_event-type"
                        type="text"
                        name="eventType"
                        defaultValue={docboxState.eventType}
                        readOnly={true}
                        disabled={true}
                      />
                    )}
                  </div>
                </div>

                <div className="form-row">
                  {docboxState.eventType !== 'Conference' &&
                    docboxState.eventType !== 'Vacation' &&
                    !isIndustryRep(authState.userType) && (
                      <>
                        <div className="width50">
                          <label htmlFor="FIELD_my-procedure">
                            My Procedure
                          </label>
                          <div className="flex align-center space-between">
                            <div
                              className="width90"
                              style={{marginRight: '4px'}}>
                              <select
                                id="FIELD_my-procedure"
                                name="my-procedure"
                                defaultValue=""
                                onChange={event => {
                                  if (
                                    event.target.value &&
                                    event.target.value === 'Add Procedure'
                                  ) {
                                    console.log(event.target.value);
                                  } else if (event.target.value) {
                                    let procedure = null;

                                    for (let index in eventSettingsState.procedures) {
                                      if (
                                        eventSettingsState.procedures[index]
                                          .id == event.target.value
                                      ) {
                                        procedure = {
                                          ...eventSettingsState.procedures[
                                            index
                                          ],
                                        };
                                        break;
                                      }
                                    }

                                    if (procedure) {
                                      const providersArray =
                                        procedure.providers.split(',').length
                                          ? procedure.providers.split(',')
                                          : [];
                                      const repsArray = procedure.reps.split(
                                        ',',
                                      ).length
                                        ? procedure.reps.split(',')
                                        : [];
                                      let providersOutput = [];
                                      let repsOutput = [];

                                      providersArray.forEach(providerId => {
                                        for (let index in contactsState.contacts) {
                                          if (
                                            'id' in
                                              contactsState.contacts[index] &&
                                            contactsState.contacts[index].id ==
                                              providerId
                                          ) {
                                            providersOutput.push(
                                              contactsState.contacts[index],
                                            );
                                            break;
                                          }
                                        }
                                      });

                                      procedure.providers = providersOutput.map(
                                        provider => {
                                          return {
                                            id: provider.id,
                                            name: provider.name,
                                            specialty: provider.specialty,
                                            avatar: provider.avatar,
                                            source: {
                                              manual: 0,
                                              owner: 0,
                                              default: 0,
                                              location: 0,
                                              procedure: procedure.id,
                                              other: 0,
                                            },
                                          };
                                        },
                                      );

                                      repsArray.forEach(repId => {
                                        for (let index in contactsState.contacts) {
                                          if (
                                            'id' in
                                              contactsState.contacts[index] &&
                                            contactsState.contacts[index].id ==
                                              repId
                                          ) {
                                            repsOutput.push(
                                              contactsState.contacts[index],
                                            );
                                            break;
                                          }
                                        }
                                      });

                                      procedure.reps = repsOutput.map(rep => {
                                        return {
                                          id: rep.id,
                                          name: rep.name,
                                          company: rep.company,
                                          avatar: rep.avatar,
                                          source: {
                                            manual: 0,
                                            owner: 0,
                                            default: 0,
                                            location: 0,
                                            procedure: procedure.id,
                                            other: 0,
                                          },
                                        };
                                      });

                                      if (procedure.devices.length) {
                                        procedure.devices =
                                          procedure.devices.map(device => {
                                            return {
                                              ...device,
                                              size: '',
                                            };
                                          });
                                      }

                                      selectMyProcedure(procedure);
                                    }

                                    return;
                                  }
                                }}>
                                <option value="">Select Procedure...</option>
                                {eventSettingsState.procedures.map(
                                  (procedure, index) => {
                                    return (
                                      <option
                                        data-procedure={procedure}
                                        key={index}
                                        value={procedure.id}>
                                        {procedure.name}
                                      </option>
                                    );
                                  },
                                )}
                                {/* <option value="Add Procedure">Add Procedure</option> */}
                              </select>
                            </div>
                            <div className="width10">
                              <button
                                type="button"
                                className="btn-success circle"
                                title="Event Settings"
                                onClick={() => {
                                  if (scheduleState.targetUserId) {
                                    openEventSettings(
                                      getEventSettingsURL,
                                      0,
                                      scheduleState.targetUserId,
                                      'procedures',
                                    );
                                  }
                                }}>
                                <img src={gearIcon} alt="Settings" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                  <div className="width50">
                    <label htmlFor="FIELD_case-name">
                      Case Name{' '}
                      <b>
                        (<strong style={{color: '#f93535'}}>•</strong> Required)
                      </b>
                    </label>
                    <input
                      id="FIELD_case-name"
                      type="text"
                      name="caseName"
                      maxLength="256"
                      placeholder="Case Name..."
                      value={docboxState.caseName}
                      onChange={event =>
                        handleInputChange(event.target.name, event.target.value)
                      }
                    />
                  </div>
                </div>

                {docboxState.eventType !== 'Conference' &&
                  docboxState.eventType !== 'Vacation' && (
                    <div className="form-row">
                      {!isIndustryRep(authState.userType) && (
                        <>
                          <div className="width50">
                            <label htmlFor="FIELD_my-location">
                              My Location
                            </label>
                            <div className="flex align-center space-between">
                              <div
                                className="width90"
                                style={{marginRight: '4px'}}>
                                <select
                                  name="my-location"
                                  id="FIELD_my-location"
                                  defaultValue=""
                                  onChange={event => {
                                    if (
                                      event.target.value &&
                                      event.target.value === 'Add Location'
                                    ) {
                                      console.log(event.target.value);
                                    } else if (event.target.value) {
                                      let location = null;

                                      for (let index in eventSettingsState.locations) {
                                        if (
                                          eventSettingsState.locations[index]
                                            .id == event.target.value
                                        ) {
                                          location = {
                                            ...eventSettingsState.locations[
                                              index
                                            ],
                                          };
                                          break;
                                        }
                                      }

                                      if (location) {
                                        const careteamArray =
                                          location.careteam.split(',').length
                                            ? location.careteam.split(',')
                                            : [];
                                        let careteamOutput = [];

                                        careteamArray.forEach(providerId => {
                                          for (let index in contactsState.contacts) {
                                            if (
                                              'id' in
                                                contactsState.contacts[index] &&
                                              contactsState.contacts[index]
                                                .id == providerId
                                            ) {
                                              careteamOutput.push(
                                                contactsState.contacts[index],
                                              );
                                              break;
                                            }
                                          }
                                        });

                                        location.careteam = careteamOutput.map(
                                          provider => {
                                            return {
                                              id: provider.id,
                                              name: provider.name,
                                              specialty: provider.specialty,
                                              avatar: provider.avatar,
                                              source: {
                                                manual: 0,
                                                owner: 0,
                                                default: 0,
                                                location: location.id,
                                                prcoedure: 0,
                                                other: 0,
                                              },
                                            };
                                          },
                                        );

                                        selectMyLocation(location);
                                      }

                                      return;
                                    }
                                  }}>
                                  <option value="">Select Location...</option>
                                  {eventSettingsState.locations.map(
                                    (location, index) => {
                                      return (
                                        <option key={index} value={location.id}>
                                          {location.location}
                                        </option>
                                      );
                                    },
                                  )}
                                  {/* <option value="Add Location">Add Location</option> */}
                                </select>
                              </div>
                              <div className="width10">
                                <button
                                  type="button"
                                  className="btn-success circle"
                                  title="Event Settings"
                                  onClick={() => {
                                    if (scheduleState.targetUserId) {
                                      openEventSettings(
                                        getEventSettingsURL,
                                        0,
                                        scheduleState.targetUserId,
                                        'locations',
                                      );
                                    }
                                  }}>
                                  <img src={gearIcon} alt="Settings" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="width50">
                        <label htmlFor="FIELD_location">Location</label>
                        <input
                          id="FIELD_location"
                          type="text"
                          name="locationInput"
                          placeholder="Location Name..."
                          value={docboxState.locationInput}
                          onChange={event =>
                            handleInputChange(
                              event.target.name,
                              event.target.value,
                            )
                          }
                        />
                      </div>
                    </div>
                  )}
              </section>

              <h2>Time</h2>
              <section>
                {docboxState.eventType === 'Surgery' &&
                  !isIndustryRep(authState.userType) && (
                    <div className="form-row">
                      <div className="width50">
                        <label htmlFor="FIELD_suggested-time">
                          Suggested Times
                        </label>
                        <select
                          id="FIELD_suggested-time"
                          name="suggested-time"
                          value=""
                          onChange={event => {
                            updateTimeAndDuration(
                              'Both',
                              `${event.target.value}`,
                              moment(event.target.value, 'YYYYMMDDHHmm')
                                .add(1, 'hour')
                                .format('YYYYMMDDHHmm'),
                            );
                          }}>
                          {docboxState.suggestedTimes.length > 0 ? (
                            <>
                              <option value="" disabled>
                                Select a new timeslot...
                              </option>
                              {docboxState.suggestedTimes.map(
                                (datetime, index) => {
                                  return (
                                    <option key={index} value={`${datetime}`}>
                                      {moment(datetime, 'YYYYMMDDHHmm').format(
                                        'ddd, MMM D. h:mm A',
                                      )}
                                    </option>
                                  );
                                },
                              )}
                            </>
                          ) : (
                            <option value="" disabled>
                              No suggested times yet...
                            </option>
                          )}
                        </select>
                      </div>
                    </div>
                  )}

                <div className="form-row">
                  <div className="width50">
                    <div>
                      <label htmlFor="FIELD_start-date">Start Time</label>
                      <CalendarPicker
                        id={'FIELD_start-date'}
                        name={'startDate'}
                        selectable={'day'}
                        value={docboxState.startDate}
                        handler={event => {
                          updateTimeAndDuration(
                            'Start',
                            `${event.target.dataset.date}${docboxState.startTime}`,
                            `${docboxState.endDate}${docboxState.endTime}`,
                            docboxState.duration,
                          );
                        }}
                        style={{
                          width: '98%',
                        }}
                      />
                    </div>
                    <div>
                      <select
                        id="FIELD_start-time"
                        name="startTime"
                        value={docboxState.startTime}
                        onChange={event =>
                          updateTimeAndDuration(
                            'Start',
                            `${docboxState.startDate}${event.target.value}`,
                            `${docboxState.endDate}${docboxState.endTime}`,
                            docboxState.duration,
                          )
                        }>
                        {timeslots.map((timeslot, index) => {
                          return (
                            <option key={index} value={timeslot}>
                              {moment(timeslot, 'HHmm').format('h:mm a')}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="width50">
                    <div>
                      <label htmlFor="FIELD_end-date">End Time</label>
                      <CalendarPicker
                        id={'FIELD_end-date'}
                        name={'endDate'}
                        selectable={'day'}
                        value={docboxState.endDate}
                        handler={event => {
                          updateTimeAndDuration(
                            'End',
                            `${docboxState.startDate}${docboxState.startTime}`,
                            `${event.target.dataset.date}${docboxState.endTime}`,
                            docboxState.duration,
                          );
                        }}
                        style={{
                          width: '98%',
                        }}
                      />
                    </div>
                    <div>
                      <select
                        id="FIELD_end-time"
                        name="endTime"
                        value={docboxState.endTime}
                        onChange={event =>
                          updateTimeAndDuration(
                            'End',
                            `${docboxState.startDate}${docboxState.startTime}`,
                            `${docboxState.endDate}${event.target.value}`,
                            docboxState.duration,
                          )
                        }>
                        {timeslots.map((timeslot, index) => {
                          return (
                            <option key={index} value={timeslot}>
                              {moment(timeslot, 'HHmm').format('h:mm a')}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>

                <div
                  className="form-row side-content"
                  style={{font: 'var(--body-text-small)'}}>
                  Duration:&nbsp;
                  <span id="case-duration">
                    {differenceBtwnTwoIsos(
                      `${docboxState.startDate}${docboxState.startTime}00`,
                      `${docboxState.endDate}${docboxState.endTime}00`,
                      true,
                    ) || 'N/A'}
                  </span>
                </div>

                {docboxState.clashes.length > 0 && !checkClash() && (
                  <div id="calendar-clashes">
                    <h3>
                      Schedule{' '}
                      {docboxState.clashes.length === 1
                        ? 'Conflict'
                        : 'Conflicts'}
                      :
                    </h3>
                    <ul>
                      {docboxState.clashes.map((clashingEvent, index) => {
                        return clashingEvent.event_id !==
                          docboxState.eventId ? (
                          <li>
                            <p>
                              <b>{clashingEvent.case}</b>
                            </p>
                            <p>
                              <FontAwesomeIcon
                                icon={faClock}
                                style={{
                                  marginRight: '1rem',
                                }}
                              />
                              {getEventStartToEndTime(
                                clashingEvent.start,
                                clashingEvent.end,
                              )}{' '}
                              (
                              {differenceBtwnTwoIsos(
                                clashingEvent.start,
                                clashingEvent.end,
                                true,
                              )}
                              )
                            </p>
                          </li>
                        ) : (
                          ''
                        );
                      })}
                    </ul>
                  </div>
                )}
              </section>

              {docboxState.eventType !== 'Conference' &&
                docboxState.eventType !== 'Vacation' &&
                !isIndustryRep(authState.userType) && (
                  <>
                    <h2>Patient</h2>
                    <section>
                      <div>
                        <label
                          htmlFor="FIELD_patient-first-name"
                          className="noflex">
                          Patient's Name
                        </label>
                        <div className="form-row" style={{marginTop: '0'}}>
                          <div className="width50">
                            <input
                              id="FIELD_patient-first-name"
                              type="text"
                              name="patientFirst"
                              placeholder="First..."
                              value={docboxState.patientFirst}
                              onChange={event =>
                                handleInputChange(
                                  event.target.name,
                                  event.target.value,
                                )
                              }
                            />
                          </div>

                          <div className="width50">
                            <input
                              id="FIELD_patient-last-name"
                              type="text"
                              name="patientLast"
                              placeholder="Last..."
                              value={docboxState.patientLast}
                              onChange={event =>
                                handleInputChange(
                                  event.target.name,
                                  event.target.value,
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div>
                        <label
                          htmlFor="FIELD_patient-dob-month"
                          className="noflex">
                          Patient's Date of Birth &nbsp;{' '}
                          <b>
                            Age:{' '}
                            {docboxState.dobYear &&
                            docboxState.dobMonth &&
                            docboxState.dobDay
                              ? determineAgeFromDob(
                                  `${docboxState.dobYear}${docboxState.dobMonth}${docboxState.dobDay}`,
                                )
                              : '?'}
                          </b>
                        </label>
                        <div className="form-row" style={{marginTop: '0'}}>
                          <div className="width33">
                            <select
                              id="FIELD_patient-dob-month"
                              name="dobMonth"
                              value={docboxState.dobMonth}
                              onChange={event =>
                                handleInputChange(
                                  event.target.name,
                                  event.target.value,
                                )
                              }>
                              <option value="">MM...</option>
                              <option value="01">January</option>
                              <option value="02">February</option>
                              <option value="03">March</option>
                              <option value="04">April</option>
                              <option value="05">May</option>
                              <option value="06">June</option>
                              <option value="07">July</option>
                              <option value="08">August</option>
                              <option value="09">September</option>
                              <option value="10">October</option>
                              <option value="11">November</option>
                              <option value="12">December</option>
                            </select>
                          </div>

                          <div className="width33">
                            <select
                              id="FIELD_patient-dob-day"
                              name="dobDay"
                              value={docboxState.dobDay}
                              onChange={event =>
                                handleInputChange(
                                  event.target.name,
                                  event.target.value,
                                )
                              }>
                              <option value="">DD...</option>
                              {renderDobDays(
                                docboxState.dobMonth,
                                docboxState.dobYear,
                              )}
                            </select>
                          </div>

                          <div className="width33">
                            <select
                              id="FIELD_patient-dob-year"
                              name="dobYear"
                              value={docboxState.dobYear}
                              onChange={event => {
                                const year = parseInt(event.target.value);
                                handleInputChange(
                                  event.target.name,
                                  event.target.value,
                                );
                                if (
                                  !moment([year]).isLeapYear() &&
                                  docboxState.dobDay === '29'
                                ) {
                                  handleInputChange('dobDay', '28');
                                }
                              }}>
                              <option value="">YYYY...</option>
                              {dobYears.map((year, index) => {
                                return (
                                  <option key={index} value={year}>
                                    {moment(year, 'YYYY').format('YYYY')}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="width50">
                          <label htmlFor="FIELD_mrn">
                            Medical Record Number
                          </label>
                          <input
                            id="FIELD_mrn"
                            type="text"
                            name="mrn"
                            placeholder="MRN..."
                            value={docboxState.mrn}
                            onChange={event =>
                              handleInputChange(
                                event.target.name,
                                event.target.value,
                              )
                            }
                          />
                        </div>

                        <div className="width50">
                          <label htmlFor="FIELD_gender">Gender</label>
                          <select
                            id="FIELD_gender"
                            name="gender"
                            value={docboxState.gender}
                            onChange={event =>
                              handleInputChange(
                                event.target.name,
                                event.target.value,
                              )
                            }>
                            <option value="">Not Specified</option>
                            <option value="Female">Female</option>
                            <option value="Male">Male</option>
                          </select>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="width50">
                          <label htmlFor="FIELD_patient-status">
                            Patient Status
                          </label>
                          <select
                            id="FIELD_patient-status"
                            name="patientStatus"
                            value={docboxState.patientStatus}
                            onChange={event =>
                              handleInputChange(
                                event.target.name,
                                event.target.value,
                              )
                            }>
                            <option value="" disabled>
                              Status...
                            </option>
                            <option value="Tentative">Tentative</option>
                            <option value="Confirmed">Confirmed</option>
                            <option value="Cancelled">Cancelled</option>
                          </select>
                        </div>

                        {docboxState.mode === 'Edit' && (
                          <div className="width50">
                            <label htmlFor="FIELD_arrival-request">
                              Arrival Request
                            </label>
                            <select
                              id="FIELD_arrival-request"
                              name="arrivalRequest"
                              value={docboxState.arrivalRequest}
                              onChange={event =>
                                handleInputChange(
                                  event.target.name,
                                  event.target.value,
                                )
                              }>
                              <option value="-1">TBD / Not Set</option>
                              {renderArrivalRequestOptions(
                                docboxState.startTime,
                              )}
                            </select>
                          </div>
                        )}
                      </div>

                      <div className="form-row">
                        <div className="width100">
                          <label htmlFor="FIELD_provider-notes">
                            Provider Notes <b>(Not linked to EMR)</b>
                          </label>
                          <textarea
                            id="FIELD_provider-notes"
                            name="providerNotes"
                            placeholder="Diagnosis, additional comments, requests, or procedure information..."
                            value={docboxState.providerNotes}
                            onChange={event =>
                              handleInputChange(
                                event.target.name,
                                event.target.value,
                              )
                            }></textarea>
                        </div>
                      </div>
                    </section>
                  </>
                )}
            </form>
          </div>
        );
      }
    case 'case':
      if (docboxState.mode === 'View') {
        const totalAttendees = (providersArray, repsArray) => {
          return providersArray.length + repsArray.length;
        };
        const totalConfirmed = (providersArray, repsArray) => {
          const attendees = providersArray
            .concat(repsArray)
            .filter(attendee => attendee.status === 'Confirmed');
          return attendees.length;
        };
        const totalPending = (providersArray, repsArray) => {
          const attendees = providersArray
            .concat(repsArray)
            .filter(attendee => attendee.status === 'Unconfirmed');
          return attendees.length;
        };
        const totalDeclined = (providersArray, repsArray) => {
          const attendees = providersArray
            .concat(repsArray)
            .filter(
              attendee =>
                attendee.status === 'Declined' ||
                attendee.status === 'Cancelled',
            );
          return attendees.length;
        };

        return (
          <div
            id="docbox-module-case"
            className="docbox-module docbox-nav-target">
            <h1>Case</h1>
            <form
              onSubmit={event => {
                event.preventDefault();
                return false;
              }}>
              <h2>Notes</h2>
              <section>
                <div className="form-row view">
                  <div className="width30">
                    <label htmlFor="FIELD_case-notes">Case Notes</label>
                  </div>
                  <div className="width70">
                    <p id="FIELD_case-notes">
                      {docboxState.caseNotes ? (
                        formatNotes(docboxState.caseNotes)
                      ) : (
                        <span>N/A</span>
                      )}
                    </p>
                  </div>
                </div>

                {docboxState.eventType !== 'Conference' &&
                  docboxState.eventType !== 'Vacation' && (
                    <>
                      {!isIndustryRep(authState.userType) && (
                        <div className="form-row view">
                          <div className="width30">
                            <label htmlFor="FIELD_scheduling-notes">
                              Scheduling Notes
                              <br />
                              <b>(Viewable by Care Team)</b>
                            </label>
                          </div>
                          <div className="width70">
                            <p id="FIELD_scheduling-notes">
                              {docboxState.schedulingNotes ? (
                                formatNotes(docboxState.schedulingNotes)
                              ) : (
                                <span>N/A</span>
                              )}
                            </p>
                          </div>
                        </div>
                      )}

                      <div className="form-row view">
                        <div className="width30">
                          <label htmlFor="FIELD_setup-notes">Setup Notes</label>
                        </div>
                        <div className="width70">
                          <p id="FIELD_setup-notes">
                            {docboxState.setupNotes ? (
                              formatNotes(docboxState.setupNotes)
                            ) : (
                              <span>N/A</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </>
                  )}
              </section>

              {docboxState.eventType !== 'Conference' &&
                docboxState.eventType !== 'Vacation' && (
                  <>
                    {!isIndustryRep(authState.userType) && (
                      <>
                        <h2
                          id="docbox-submodule-checklists"
                          className="docbox-nav-target">
                          Checklists & Patient Readiness
                        </h2>
                        <section>
                          <div className="form-row view">
                            <div className="width30">
                              <label htmlFor="FIELD_patient-cleared">
                                Patient Cleared for Surgery?
                              </label>
                            </div>
                            <div className="width70 flex align-start">
                              {docboxState.checklistClearedLinked === 'No' && (
                                <div className="width30">
                                  <select
                                    id="FIELD_patient-cleared"
                                    name="patientCleared"
                                    value={docboxState.patientCleared}
                                    onChange={event =>
                                      handleInputChange(
                                        event.target.name,
                                        event.target.value,
                                      )
                                    }>
                                    <option value="N/A">N/A</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                </div>
                              )}
                              {docboxState.checklistClearedLinked === 'Yes' && (
                                <p id="FIELD_patient-cleared">
                                  {docboxState.patientCleared || (
                                    <span>N/A</span>
                                  )}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="form-row view">
                            <div className="width30">
                              <label htmlFor="FIELD_checklists-linked">
                                Link Patient Readiness with Checklists?
                              </label>
                            </div>
                            <div className="width70">
                              <p id="FIELD_checklists-linked">
                                {docboxState.checklistClearedLinked || (
                                  <span>N/A</span>
                                )}
                              </p>
                            </div>
                          </div>

                          <h2>Checklists</h2>
                          <div className="form-row view">
                            <div className="width100">
                              <div id="checklists-container">
                                {docboxState.checklists.length ? (
                                  docboxState.checklists.map(
                                    (checklistGroup, index) => {
                                      return (
                                        <ChecklistGroup
                                          key={index}
                                          index={index + 1}
                                          checklistGroup={checklistGroup}
                                          viewMode={true}
                                        />
                                      );
                                    },
                                  )
                                ) : (
                                  <span>No checklists added yet.</span>
                                )}
                              </div>
                            </div>
                          </div>
                        </section>
                      </>
                    )}

                    <h2
                      id="docbox-submodule-equipment"
                      className="docbox-nav-target">
                      Equipment <span>({docboxState.devices.length})</span>
                    </h2>
                    <section>
                      <div className="form-row view">
                        <div className="width100">
                          <div id="devices-container">
                            {docboxState.devices.length ? (
                              docboxState.devices.map(
                                ({id, name, size}, index) => {
                                  return (
                                    <DeviceRow
                                      key={index}
                                      index={index + 1}
                                      id={id}
                                      name={name}
                                      size={size}
                                      mode={docboxState.mode}
                                    />
                                  );
                                },
                              )
                            ) : (
                              <span>No devices added yet.</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </section>

                    <h2
                      id="docbox-submodule-files"
                      className="docbox-nav-target">
                      Files <span>({docboxState.attachments.length})</span>
                    </h2>
                    <section>
                      <div className="form-row">
                        <div className="width100">
                          <div
                            id="files-container"
                            className="flex space-between wrap">
                            {docboxState.attachments.length ? (
                              docboxState.attachments.map((file, index) => {
                                return (
                                  <AttachmentCard
                                    key={index}
                                    file={file}
                                    allowEdit={false}
                                  />
                                );
                              })
                            ) : (
                              <span>No files added yet.</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </section>
                  </>
                )}

              {docboxState.eventType !== 'Vacation' && (
                <>
                  <h2
                    id="docbox-submodule-attendees"
                    className="docbox-nav-target">
                    {totalAttendees(docboxState.providers, docboxState.reps)}{' '}
                    Attendees
                  </h2>
                  <section>
                    <div className="form-row" style={{paddingBottom: '2rem'}}>
                      <div className="width100">
                        <label htmlFor="providers-container">
                          Providers <b>({docboxState.providers.length})</b>
                        </label>
                        <div
                          id="providers-container"
                          className="flex space-between wrap">
                          {docboxState.providers.length ? (
                            docboxState.providers.map((attendee, index) => {
                              return (
                                <AttendeeCard
                                  key={index}
                                  type={'Provider'}
                                  attendee={attendee}
                                  allowDelete={false}
                                />
                              );
                            })
                          ) : (
                            <span style={{marginTop: '1rem'}}>
                              No providers added yet.
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="form-row" style={{paddingBottom: '2rem'}}>
                      <div className="width100">
                        <label htmlFor="industry-search">
                          Industry Reps <b>({docboxState.reps.length})</b>
                        </label>
                        <div
                          id="industry-container"
                          className="flex space-between wrap">
                          {docboxState.reps.length ? (
                            docboxState.reps.map((attendee, index) => {
                              return (
                                <AttendeeCard
                                  key={index}
                                  type={'Rep'}
                                  attendee={attendee}
                                  allowDelete={false}
                                />
                              );
                            })
                          ) : (
                            <span style={{marginTop: '1rem'}}>
                              No industry reps added yet.
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              )}
            </form>
          </div>
        );
      } else {
        return (
          <div
            id="docbox-module-case"
            className="docbox-module docbox-nav-target">
            <h1>Case</h1>
            <form
              onSubmit={event => {
                event.preventDefault();
                return false;
              }}>
              <h2>Notes</h2>
              <section>
                <div className="form-row">
                  <div className="width100">
                    <label htmlFor="FIELD_case-notes">Case Notes</label>
                    <textarea
                      id="FIELD_case-notes"
                      name="caseNotes"
                      placeholder="Detailed case description..."
                      value={docboxState.caseNotes}
                      onChange={event =>
                        handleInputChange(event.target.name, event.target.value)
                      }></textarea>
                  </div>
                </div>

                {docboxState.eventType !== 'Conference' &&
                  docboxState.eventType !== 'Vacation' && (
                    <>
                      {!isIndustryRep(authState.userType) && (
                        <div className="form-row">
                          <div className="width100">
                            <label htmlFor="FIELD_scheduling-notes">
                              Scheduling Notes <b>(Viewable by Care Team)</b>
                            </label>
                            <textarea
                              id="FIELD_scheduling-notes"
                              name="schedulingNotes"
                              placeholder="Detailed private notes..."
                              value={docboxState.schedulingNotes}
                              onChange={event =>
                                handleInputChange(
                                  event.target.name,
                                  event.target.value,
                                )
                              }></textarea>
                          </div>
                        </div>
                      )}

                      <div className="form-row">
                        <div className="width100">
                          <label htmlFor="FIELD_setup-notes">Setup Notes</label>
                          <input
                            id="FIELD_setup-notes"
                            type="text"
                            name="setupNotes"
                            placeholder="Setup details..."
                            value={docboxState.setupNotes}
                            onChange={event =>
                              handleInputChange(
                                event.target.name,
                                event.target.value,
                              )
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}
              </section>

              {docboxState.eventType !== 'Conference' &&
                docboxState.eventType !== 'Vacation' && (
                  <>
                    {!isIndustryRep(authState.userType) && (
                      <>
                        <h2
                          id="docbox-submodule-checklists"
                          className="docbox-nav-target">
                          Checklists & Patient Readiness
                        </h2>
                        <section>
                          <div className="form-row">
                            <div className="width50">
                              <label htmlFor="FIELD_patient-cleared">
                                Patient Cleared for Surgery?
                              </label>
                              <select
                                id="FIELD_patient-cleared"
                                name="patientCleared"
                                value={docboxState.patientCleared}
                                onChange={event => {
                                  handleInputChange(
                                    event.target.name,
                                    event.target.value,
                                  );
                                  if (
                                    docboxState.checklistClearedLinked === 'Yes'
                                  ) {
                                    handleInputChange(
                                      'checklistClearedLinked',
                                      'No',
                                    );
                                  }
                                }}>
                                <option value="N/A">N/A</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>

                            <div className="width50">
                              <label htmlFor="FIELD_checklists-linked">
                                Link Patient Readiness with Checklists?
                              </label>
                              <select
                                id="FIELD_checklists-linked"
                                name="checklistClearedLinked"
                                value={docboxState.checklistClearedLinked}
                                onChange={event =>
                                  linkChecklistsToPatientReadiness(
                                    event.target.value,
                                    docboxState.checklists,
                                  )
                                }>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="width100">
                              <h3>Checklists</h3>
                              <div id="checklists-container">
                                {docboxState.checklists.length ? (
                                  docboxState.checklists.map(
                                    (checklistGroup, index) => {
                                      return (
                                        <ChecklistGroup
                                          key={index}
                                          index={index + 1}
                                          checklistGroup={checklistGroup}
                                        />
                                      );
                                    },
                                  )
                                ) : (
                                  <span>No checklists added yet.</span>
                                )}
                              </div>
                            </div>
                          </div>

                          <div
                            className="form-row side-content"
                            style={{font: 'var(--body-text-small)'}}>
                            <button
                              type="button"
                              className="btn-success circle"
                              title="Event Settings"
                              style={{marginRight: '1rem'}}
                              onClick={() => {
                                if (scheduleState.targetUserId) {
                                  openEventSettings(
                                    getEventSettingsURL,
                                    0,
                                    scheduleState.targetUserId,
                                    'checklists',
                                  );
                                }
                              }}>
                              <img src={gearIcon} alt="Settings" />
                            </button>
                            <button
                              type="button"
                              className="btn-primary"
                              style={{marginRight: '1rem'}}
                              onClick={() => {
                                if (
                                  docboxState.checklistClearedLinked === 'No' &&
                                  docboxState.checklists.length === 0
                                ) {
                                  linkChecklistsToPatientReadiness('Yes');
                                }
                                addChecklistGroup();
                              }}>
                              Add a Checklist Group
                            </button>
                            <button
                              type="button"
                              className="btn-success"
                              onClick={() => {
                                const alertBody = checklistGroupsArr => {
                                  const checklistAlreadyExists = (
                                    checklistGroupsArr,
                                    defaultChecklistId,
                                  ) => {
                                    return checklistGroupsArr.some(
                                      checklistGroup => {
                                        return (
                                          checklistGroup.original_id.toString() ===
                                          defaultChecklistId
                                        );
                                      },
                                    );
                                  };

                                  return (
                                    <div>
                                      {checklistGroupsArr.map(
                                        (checklistGroup, index) => {
                                          const checklistGroupName =
                                            checklistGroup.name
                                              ? checklistGroup.name
                                              : 'Unnamed Checklist Group';
                                          const checklistIsAdded =
                                            checklistAlreadyExists(
                                              docboxState.checklists,
                                              checklistGroup.id,
                                            );
                                          return (
                                            <div
                                              key={index}
                                              className="flex align-center"
                                              style={{margin: '1rem 0'}}>
                                              <div
                                                className={
                                                  checklistIsAdded
                                                    ? 'icon-added'
                                                    : 'icon-not-added'
                                                }>
                                                <FontAwesomeIcon
                                                  icon={faPlusCircle}
                                                  size="lg"
                                                  style={{
                                                    marginRight: '1rem',
                                                  }}
                                                />
                                              </div>
                                              <p
                                                className="clickable"
                                                style={{
                                                  fontWeight: '600',
                                                  margin: '0',
                                                }}
                                                onClick={event => {
                                                  event.preventDefault();
                                                  event.stopPropagation();

                                                  if (checklistIsAdded) {
                                                    deleteChecklistGroup(
                                                      checklistGroup.id,
                                                    ).then(() => {
                                                      if (
                                                        docboxState.checklistClearedLinked ===
                                                          'Yes' &&
                                                        docboxState.checklists
                                                          .length === 1
                                                      ) {
                                                        linkChecklistsToPatientReadiness(
                                                          'No',
                                                        );
                                                      }
                                                    });
                                                    setNotification(
                                                      `${checklistGroupName} removed from Case.`,
                                                    );
                                                  } else {
                                                    if (
                                                      docboxState.checklistClearedLinked ===
                                                        'No' &&
                                                      docboxState.checklists
                                                        .length === 0
                                                    ) {
                                                      linkChecklistsToPatientReadiness(
                                                        'Yes',
                                                      );
                                                    }
                                                    addChecklistGroup(
                                                      checklistGroup,
                                                    );
                                                    setNotification(
                                                      `${checklistGroupName} added to Case.`,
                                                    );
                                                  }

                                                  return closeAlert();
                                                }}>
                                                {checklistGroupName}
                                              </p>
                                            </div>
                                          );
                                        },
                                      )}
                                    </div>
                                  );
                                };

                                return openAlert({
                                  title: 'Add Pre-Defined Checklist',
                                  body: alertBody(
                                    eventSettingsState.checklists,
                                  ),
                                  cancelText: 'Close',
                                });
                              }}>
                              Add Pre-Defined Checklist
                            </button>
                          </div>
                        </section>
                      </>
                    )}

                    <h2
                      id="docbox-submodule-equipment"
                      className="docbox-nav-target">
                      Equipment
                    </h2>
                    <section>
                      <div className="form-row">
                        <div className="width100">
                          <div id="devices-container">
                            {docboxState.devices.map(
                              ({id, name, size}, index) => {
                                return (
                                  <DeviceRow
                                    key={index}
                                    index={index + 1}
                                    id={id}
                                    name={name}
                                    size={size}
                                    mode={docboxState.mode}
                                  />
                                );
                              },
                            )}
                          </div>
                        </div>
                      </div>

                      <div
                        className="form-row side-content"
                        style={{font: 'var(--body-text-small)'}}>
                        <button
                          type="button"
                          className="btn-success"
                          onClick={() => addDevice()}>
                          Add Device
                        </button>
                      </div>
                    </section>

                    <h2
                      id="docbox-submodule-files"
                      className="docbox-nav-target">
                      Files
                    </h2>
                    <section>
                      <div className="form-row">
                        <Dropzone btnTitle={'Upload from My Computer'} />
                      </div>
                    </section>
                  </>
                )}

              {docboxState.eventType !== 'Vacation' && (
                <>
                  <h2
                    id="docbox-submodule-attendees"
                    className="docbox-nav-target">
                    Attendees
                    <button
                      type="button"
                      className="btn-success circle"
                      title="Event Settings"
                      style={{marginLeft: '1rem'}}
                      onClick={() => {
                        if (scheduleState.targetUserId) {
                          openEventSettings(
                            getEventSettingsURL,
                            0,
                            scheduleState.targetUserId,
                            'attendees',
                          );
                        }
                      }}>
                      <img src={gearIcon} alt="Settings" />
                    </button>
                  </h2>
                  <section>
                    <div className="form-row">
                      <div className="width50">
                        <label htmlFor="provider-search">
                          Providers <b>({docboxState.providers.length})</b>
                        </label>
                        {/* <input type="text" name="provider-search" id="provider-search" placeholder="&#x1F50D; My Physicians / Care Team Members" /> */}
                        <Typeahead
                          uri={attendeeSearchURI}
                          userId={scheduleState.targetUserId}
                          elementId={'provider-typeahead'}
                          placeholder={'My Physicians / Care Team Members'}
                          filter={array =>
                            array.filter(
                              element =>
                                element.type === 'Physician' ||
                                element.type === 'Careteam',
                            )
                          }
                          handleSelection={addProvider}
                        />
                      </div>
                    </div>

                    <div className="form-row" style={{paddingBottom: '2rem'}}>
                      <div className="width100">
                        <div
                          id="providers-container"
                          className="flex space-between wrap">
                          {docboxState.providers.length ? (
                            docboxState.providers.map((attendee, index) => {
                              return (
                                <AttendeeCard
                                  key={index}
                                  type={'Provider'}
                                  attendee={attendee}
                                  ownerId={docboxState.eventOwnerId}
                                  allowDelete={true}
                                />
                              );
                            })
                          ) : (
                            <span style={{marginTop: '1rem'}}>
                              No providers added yet.
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div>
                      <label htmlFor="industry-search" className="noflex">
                        Industry Reps <b>({docboxState.reps.length})</b>
                      </label>
                      <div className="form-row">
                        <div className="width50">
                          <Typeahead
                            uri={attendeeSearchURI}
                            userId={scheduleState.targetUserId}
                            elementId={'rep-typeahead'}
                            placeholder={'My Industry Contacts'}
                            filter={array =>
                              array.filter(
                                element => element.type === 'Industry',
                              )
                            }
                            handleSelection={addRep}
                          />
                        </div>

                        <div className="width50">
                          <button
                            type="button"
                            id="case-vendor-groups"
                            className="btn-success"
                            onClick={() => {
                              const url = `${globalState.config.ABS_BASE_URL}${getSubGroupsURI}`;
                              const alertBody = vendorGroupsArr => {
                                return (
                                  <div>
                                    <p style={{marginTop: '0'}}>
                                      Add industry reps to this Case by
                                      selecting a vendor group.
                                    </p>
                                    {vendorGroupsArr.map(
                                      (vendorGroup, index) => {
                                        return (
                                          <p
                                            key={index}
                                            className="clickable"
                                            style={{fontWeight: '600'}}
                                            onClick={event => {
                                              event.preventDefault();
                                              event.stopPropagation();

                                              vendorGroup.members.forEach(
                                                attendee => {
                                                  const source = {
                                                    default: 0,
                                                    location: 0,
                                                    manual: 0,
                                                    other: 1,
                                                    owner: 0,
                                                    procedure: 0,
                                                  };

                                                  if (
                                                    attendee.type &&
                                                    attendee.type ===
                                                      'Physician'
                                                  ) {
                                                    const provider = {
                                                      id: attendee.id,
                                                      name: formatFullName(
                                                        attendee.name.first,
                                                        attendee.name.last,
                                                      ),
                                                      specialty: '',
                                                      avatar: attendee.avatar,
                                                    };
                                                    addProvider(
                                                      provider,
                                                      source,
                                                    );
                                                  } else if (
                                                    attendee.type &&
                                                    attendee.type === 'Industry'
                                                  ) {
                                                    const rep = {
                                                      id: attendee.id,
                                                      name: formatFullName(
                                                        attendee.name.first,
                                                        attendee.name.last,
                                                      ),
                                                      company: '',
                                                      avatar: attendee.avatar,
                                                    };
                                                    addRep(rep, source);
                                                  }
                                                },
                                              );

                                              return setNotification(
                                                `${
                                                  vendorGroup.practice.name ||
                                                  'Unnamed Vendor Group'
                                                } added to Attendees.`,
                                              );
                                            }}>
                                            {vendorGroup.practice.name ||
                                              'Unnamed Vendor Group'}
                                          </p>
                                        );
                                      },
                                    )}
                                  </div>
                                );
                              };

                              getVendorGroups(
                                url,
                                contactsState.vendorGroups,
                              ).then(vendorGroupsArr => {
                                if (Array.isArray(vendorGroupsArr)) {
                                  openAlert({
                                    title: 'Select from Vendor Groups',
                                    body: alertBody(vendorGroupsArr),
                                    cancelText: 'Close',
                                  });
                                } else {
                                  setNotification(
                                    'Error retrieving vendor groups.',
                                  );
                                }
                                return;
                              });
                            }}>
                            Select from Vendor Groups
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="form-row" style={{paddingBottom: '2rem'}}>
                      <div className="width100">
                        <div
                          id="industry-container"
                          className="flex space-between wrap">
                          {docboxState.reps.length ? (
                            docboxState.reps.map((attendee, index) => {
                              return (
                                <AttendeeCard
                                  key={index}
                                  type={'Rep'}
                                  attendee={attendee}
                                  ownerId={docboxState.eventOwnerId}
                                  allowDelete={true}
                                />
                              );
                            })
                          ) : (
                            <span style={{marginTop: '1rem'}}>
                              No industry reps added yet.
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              )}
            </form>
          </div>
        );
      }
    case 'patient':
      if (docboxState.mode === 'View') {
        const renderHeight = () => {
          return docboxState.heightInput02 === 0 ||
            docboxState.heightInput02 === ''
            ? `${docboxState.heightInput01}'`
            : `${docboxState.heightInput01}' ${docboxState.heightInput02}"`;
        };

        const renderAddress = (line1, line2, city, state, zip) => {
          return (
            <p id="FIELD_patient-address">
              {!line1 && !line2 && !city && !state && !zip && <span>N/A</span>}

              {line1 && line1}
              {line1 && line2 && <br />}
              {line2 && line2}
              {(line1 || line2) && (city || state || zip) && <br />}
              {city && city}
              {city && (state || zip) && ', '}
              {state && state}
              {state && zip && ' '}
              {zip && zip}
            </p>
          );
        };

        return (
          <div
            id="docbox-module-patient"
            className="docbox-module docbox-nav-target">
            <h1>Patient</h1>
            <form
              onSubmit={event => {
                event.preventDefault();
                return false;
              }}>
              <h2 id="docbox-submodule-details" className="docbox-nav-target">
                Supplemental Details
              </h2>
              <section>
                <div className="form-row view">
                  <div className="width30">
                    <label htmlFor="FIELD_height">Height</label>
                  </div>
                  <div className="width70">
                    <p id="FIELD_height">
                      {docboxState.heightUSCustomary ? (
                        renderHeight(docboxState.heightUSCustomary)
                      ) : (
                        <span>N/A</span>
                      )}
                    </p>
                  </div>
                </div>

                <div className="form-row view">
                  <div className="width30">
                    <label htmlFor="FIELD_weight">Weight</label>
                  </div>
                  <div className="width70">
                    <p id="FIELD_weight">
                      {docboxState.weightUSCustomary ? (
                        `${docboxState.weightUSCustomary} lbs`
                      ) : (
                        <span>N/A</span>
                      )}
                    </p>
                  </div>
                </div>

                <div className="form-row view">
                  <div className="width30">
                    <label htmlFor="FIELD_bmi">BMI</label>
                  </div>
                  <div className="width70">
                    <p id="FIELD_bmi">
                      {parseFloat(docboxState.height) &&
                      parseFloat(docboxState.weight)
                        ? calculateBMI(
                            parseFloat(docboxState.height),
                            parseFloat(docboxState.weight),
                          )
                        : 'N/A'}
                    </p>
                  </div>
                </div>

                <div className="form-row view">
                  <div className="width30">
                    <label htmlFor="FIELD_ethnicity">Ethnicity</label>
                  </div>
                  <div className="width70">
                    <p id="FIELD_ethnicity">
                      {docboxState.ethnicity ? (
                        formatEthnicity(docboxState.ethnicity)
                      ) : (
                        <span>N/A</span>
                      )}
                    </p>
                  </div>
                </div>
              </section>

              {!isIndustryRep(authState.userType) && (
                <>
                  <h2
                    id="docbox-submodule-conditions"
                    className="docbox-nav-target">
                    Conditions
                  </h2>
                  <section>
                    <div className="form-row view">
                      <div className="width30">
                        <label htmlFor="FIELD_allergies">Allergies</label>
                      </div>
                      <div className="width70">
                        <p id="FIELD_allergies">
                          {docboxState.allergiesStatus === 'N/A'
                            ? 'Not Specified'
                            : docboxState.allergiesStatus}
                        </p>
                      </div>
                    </div>

                    <div className="form-row view">
                      <div className="width30">
                        <label htmlFor="FIELD_specify-allergies">
                          Specify Allergies
                        </label>
                      </div>
                      <div className="width70">
                        <p id="FIELD_specify-allergies">
                          {docboxState.allergiesDetails &&
                          docboxState.allergiesDetails !== '-1' ? (
                            docboxState.allergiesDetails
                          ) : (
                            <span>N/A</span>
                          )}
                        </p>
                      </div>
                    </div>

                    {docboxState.eventType === 'Surgery' && (
                      <>
                        <div className="form-row view">
                          <div className="width30">
                            <label htmlFor="FIELD_anesthesia">Anesthesia</label>
                          </div>
                          <div className="width70">
                            <p id="FIELD_anesthesia">
                              {docboxState.anesthesiaStatus === 'N/A'
                                ? 'Not Specified'
                                : docboxState.anesthesiaStatus}
                            </p>
                          </div>
                        </div>

                        <div className="form-row view">
                          <div className="width30">
                            <label htmlFor="FIELD_specify-anesthesia">
                              Specify Anesthesia
                            </label>
                          </div>
                          <div className="width70">
                            <p id="FIELD_specify-anesthesia">
                              {docboxState.anesthesiaDetails &&
                              docboxState.anesthesiaDetails !== '-1' ? (
                                docboxState.anesthesiaDetails
                              ) : (
                                <span>N/A</span>
                              )}
                            </p>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="form-row view">
                      <div className="width30">
                        <label htmlFor="FIELD_comorbidities">
                          Comorbidities
                        </label>
                      </div>
                      <div className="width70">
                        <p id="FIELD_comorbidities">
                          {docboxState.comorbiditiesStatus === 'N/A'
                            ? 'Not Specified'
                            : docboxState.comorbiditiesStatus}
                        </p>
                      </div>
                    </div>

                    <div className="form-row view">
                      <div className="width30">
                        <label htmlFor="FIELD_specify-comorbidities">
                          Specify Comorbidities
                        </label>
                      </div>
                      <div className="width70">
                        <p id="FIELD_specify-comorbidities">
                          {docboxState.comorbiditiesDetails &&
                          docboxState.comorbiditiesDetails !== '-1' ? (
                            docboxState.comorbiditiesDetails
                          ) : (
                            <span>N/A</span>
                          )}
                        </p>
                      </div>
                    </div>
                  </section>

                  <h2
                    id="docbox-submodule-contacts"
                    className="docbox-nav-target">
                    Contact
                  </h2>
                  <section>
                    <div className="form-row view">
                      <div className="width30">
                        <label htmlFor="FIELD_patient-email">eMail</label>
                      </div>
                      <div className="width70">
                        <p id="FIELD_patient-email">
                          {docboxState.email || <span>N/A</span>}
                        </p>
                      </div>
                    </div>

                    <div className="form-row view">
                      <div className="width30">
                        <label htmlFor="FIELD_patient-phone">Phone</label>
                      </div>
                      <div className="width70">
                        <p id="FIELD_patient-phone">
                          {docboxState.patientPhone ? (
                            formatPhoneNumber(docboxState.patientPhone)
                          ) : (
                            <span>N/A</span>
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="form-row view">
                      <div className="width30">
                        <label htmlFor="FIELD_patient-mobile">
                          Mobile Phone
                        </label>
                      </div>
                      <div className="width70">
                        <p id="FIELD_patient-mobile">
                          {docboxState.patientMobilePhone ? (
                            formatPhoneNumber(docboxState.patientMobilePhone)
                          ) : (
                            <span>N/A</span>
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="form-row view">
                      <div className="width30">
                        <label htmlFor="FIELD_patient-address">
                          Home Address
                        </label>
                      </div>
                      <div className="width70">
                        {renderAddress(
                          docboxState.addressLine1,
                          docboxState.addressLine2,
                          docboxState.city,
                          docboxState.state,
                          docboxState.zip,
                        )}
                      </div>
                    </div>
                  </section>

                  <h2>Emergency Contact</h2>
                  <section>
                    <div className="form-row view">
                      <div className="width30">
                        <label htmlFor="FIELD_contact-1">Contact 1</label>
                      </div>
                      <div className="width70">
                        <p id="FIELD_contact-1">
                          {docboxState.emergencyName01 || <span>N/A</span>}
                        </p>
                      </div>
                    </div>

                    <div className="form-row view">
                      <div className="width30">
                        <label htmlFor="FIELD_contact-phone-1">Phone 1</label>
                      </div>
                      <div className="width70">
                        <p id="FIELD_contact-phone-1">
                          {docboxState.emergencyPhone01 ? (
                            formatPhoneNumber(docboxState.emergencyPhone01)
                          ) : (
                            <span>N/A</span>
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="form-row view">
                      <div className="width30">
                        <label htmlFor="FIELD_contact-2">Contact 2</label>
                      </div>
                      <div className="width70">
                        <p id="FIELD_contact-2">
                          {docboxState.emergencyName02 || <span>N/A</span>}
                        </p>
                      </div>
                    </div>

                    <div className="form-row view">
                      <div className="width30">
                        <label htmlFor="FIELD_contact-phone-2">Phone 2</label>
                      </div>
                      <div className="width70">
                        <p id="FIELD_contact-phone-2">
                          {docboxState.emergencyPhone02 ? (
                            formatPhoneNumber(docboxState.emergencyPhone02)
                          ) : (
                            <span>N/A</span>
                          )}
                        </p>
                      </div>
                    </div>
                  </section>
                </>
              )}
            </form>
          </div>
        );
      } else {
        if (!isIndustryRep(authState.userType)) {
          return (
            <div
              id="docbox-module-patient"
              className="docbox-module docbox-nav-target">
              <h1>Patient</h1>
              <form
                onSubmit={event => {
                  event.preventDefault();
                  return false;
                }}>
                <h2 id="docbox-submodule-details" className="docbox-nav-target">
                  Supplemental Details
                </h2>
                <section>
                  <div className="form-row">
                    <div className="width50 flex space-between">
                      <div className="width50">
                        <label htmlFor="FIELD_height-1">Height</label>
                        <div className="width50 flex space-between">
                          {docboxState.units === 'US Customary' && (
                            <>
                              <div className="width50">
                                <input
                                  id="FIELD_height-1"
                                  type="number"
                                  min="0"
                                  max="9"
                                  name="heightInput01"
                                  placeholder="ft..."
                                  value={docboxState.heightInput01}
                                  onChange={event => {
                                    updateHeight(
                                      event.target.value,
                                      docboxState.heightInput02,
                                      docboxState.units,
                                    );
                                  }}
                                />
                              </div>

                              <div className="width50">
                                <input
                                  id="FIELD_height-2"
                                  type="number"
                                  min="0"
                                  max="11"
                                  name="heightInput02"
                                  placeholder="in..."
                                  value={docboxState.heightInput02}
                                  onChange={event => {
                                    updateHeight(
                                      docboxState.heightInput01,
                                      event.target.value,
                                      docboxState.units,
                                    );
                                  }}
                                />
                              </div>
                            </>
                          )}
                          {docboxState.units === 'Metric' && (
                            <div className="width100">
                              <input
                                id="FIELD_height-1"
                                type="number"
                                min="0"
                                max="250"
                                name="heightInput01"
                                placeholder="cm..."
                                value={docboxState.height}
                                onChange={event => {
                                  updateHeight(
                                    event.target.value,
                                    '',
                                    docboxState.units,
                                  );
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="width50">
                        <label htmlFor="FIELD_weight-1">Weight</label>
                        {docboxState.units === 'US Customary' && (
                          <input
                            id="FIELD_weight-1"
                            type="number"
                            min="0"
                            max="1000"
                            name="weightInput01"
                            placeholder="lbs..."
                            value={docboxState.weightInput01}
                            onChange={event => {
                              updateWeight(
                                event.target.value,
                                docboxState.units,
                              );
                            }}
                          />
                        )}
                        {docboxState.units === 'Metric' && (
                          <input
                            id="FIELD_weight-1"
                            type="number"
                            min="0"
                            max="450"
                            name="weightInput01"
                            placeholder="kg..."
                            value={docboxState.weightInput01}
                            onChange={event => {
                              updateWeight(
                                event.target.value,
                                docboxState.units,
                              );
                            }}
                          />
                        )}
                      </div>
                    </div>

                    <div className="width50">
                      <label htmlFor="FIELD_units">Units</label>
                      <select
                        id="FIELD_units"
                        name="units"
                        value={docboxState.units}
                        onChange={event => {
                          updateUnits(
                            docboxState.height,
                            docboxState.weight,
                            event.target.value,
                          );
                        }}>
                        <option value="US Customary">US Customary</option>
                        <option value="Metric">Metric</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="width50">
                      <label htmlFor="case-bmi">BMI</label>
                      <p id="case-bmi">
                        {docboxState.height && docboxState.weight
                          ? calculateBMI(
                              parseFloat(docboxState.height),
                              parseFloat(docboxState.weight),
                            )
                          : 'N/A'}
                      </p>
                    </div>

                    <div className="width50">
                      <label htmlFor="FIELD_ethnicity">Ethnicity</label>
                      <select
                        id="FIELD_ethnicity"
                        name="ethnicity"
                        value={docboxState.ethnicity}
                        onChange={event =>
                          handleInputChange(
                            event.target.name,
                            event.target.value,
                          )
                        }>
                        <option value="">Not Specified</option>
                        <option value="AustralisIndigenae/Australoid">
                          Aboriginal Australian
                        </option>
                        <option value="EoropeusAlbescens/Caucasoid">
                          Caucasian / White / Middle Eastern
                        </option>
                        <option value="AfricanusNegreus/Negroid">
                          African / African American / Black
                        </option>
                        <option value="AsiaticusFucus/Mongoloid">
                          Asian / Indian / Eskimo
                        </option>
                        <option value="AsiaticusFucus/Pacificoid">
                          Native Hawaiian / Pacific Islander
                        </option>
                        <option value="AmericanusRubescens">
                          Native American / Alaska Native
                        </option>
                        <option value="AmericanusRubescens/Latinoid">
                          Hispanic / Latino
                        </option>
                        <option value="Multiracial">Multiracial</option>
                        <option value="Unknown">Unknown</option>
                      </select>
                    </div>
                  </div>
                </section>

                <h2
                  id="docbox-submodule-conditions"
                  className="docbox-nav-target">
                  Conditions
                </h2>
                <section>
                  <div className="form-row">
                    <div className="width30">
                      <label htmlFor="FIELD_allergies">Allergies</label>
                      <select
                        id="FIELD_allergies"
                        name="allergiesStatus"
                        value={docboxState.allergiesStatus}
                        onChange={event => {
                          handleInputChange(
                            event.target.name,
                            event.target.value,
                          );
                          if (event.target.value !== 'Yes') {
                            handleInputChange('allergiesDetails', '');
                          }
                        }}>
                        <option value="">Not Specified</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>

                    <div className="width70">
                      <label htmlFor="FIELD_specify-allergies">
                        Specify Allergies
                      </label>
                      <input
                        id="FIELD_specify-allergies"
                        className={
                          docboxState.allergiesStatus !== 'Yes'
                            ? 'disabled'
                            : ''
                        }
                        type="text"
                        name="allergiesDetails"
                        placeholder="List Allergies..."
                        value={docboxState.allergiesDetails}
                        onChange={event =>
                          handleInputChange(
                            event.target.name,
                            event.target.value,
                          )
                        }
                        disabled={
                          docboxState.allergiesStatus === 'Yes' ? false : true
                        }
                      />
                    </div>
                  </div>

                  {docboxState.eventType === 'Surgery' && (
                    <>
                      <div className="form-row">
                        <div className="width30">
                          <label htmlFor="FIELD_anesthesia">Anesthesia</label>
                          <select
                            id="FIELD_anesthesia"
                            name="anesthesiaStatus"
                            value={docboxState.anesthesiaStatus}
                            onChange={event => {
                              handleInputChange(
                                event.target.name,
                                event.target.value,
                              );
                              if (event.target.value !== 'Yes') {
                                handleInputChange('anesthesiaDetails', '');
                              }
                            }}>
                            <option value="">Not Specified</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>

                        <div className="width70">
                          <label htmlFor="FIELD_specify-anesthesia">
                            Specify Other Anesthesia
                          </label>
                          <input
                            id="FIELD_specify-anesthesia"
                            className={
                              docboxState.anesthesiaStatus !== 'Yes'
                                ? 'disabled'
                                : ''
                            }
                            type="text"
                            name="anesthesiaDetails"
                            placeholder="List Other Anesthesia..."
                            value={docboxState.anesthesiaDetails}
                            onChange={event => {
                              handleInputChange(
                                event.target.name,
                                event.target.value,
                              );
                            }}
                            disabled={
                              docboxState.anesthesiaStatus === 'Yes'
                                ? false
                                : true
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="form-row">
                    <div className="width30">
                      <label htmlFor="FIELD_comorbidities">Comorbidities</label>
                      <select
                        id="FIELD_comorbidities"
                        name="comorbiditiesStatus"
                        value={docboxState.comorbiditiesStatus}
                        onChange={event => {
                          handleInputChange(
                            event.target.name,
                            event.target.value,
                          );
                          if (event.target.value !== 'Yes') {
                            handleInputChange('comorbiditiesDetails', '');
                          }
                        }}>
                        <option value="">Not Specified</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>

                    <div className="width70">
                      <label htmlFor="FIELD_specify-comorbidities">
                        Specify Comorbidities
                      </label>
                      <input
                        id="FIELD_specify-comorbidities"
                        className={
                          docboxState.comorbiditiesStatus !== 'Yes'
                            ? 'disabled'
                            : ''
                        }
                        type="text"
                        name="comorbiditiesDetails"
                        placeholder="List Comorbidities..."
                        value={docboxState.comorbiditiesDetails}
                        onChange={event =>
                          handleInputChange(
                            event.target.name,
                            event.target.value,
                          )
                        }
                        disabled={
                          docboxState.comorbiditiesStatus === 'Yes'
                            ? false
                            : true
                        }
                      />
                    </div>
                  </div>
                </section>

                <h2
                  id="docbox-submodule-contacts"
                  className="docbox-nav-target">
                  Contact
                </h2>
                <section>
                  <div className="form-row">
                    <div className="width50">
                      <label htmlFor="FIELD_patient-email">eMail</label>
                      <input
                        id="FIELD_patient-email"
                        type="email"
                        name="email"
                        placeholder="Patient's eMail Address..."
                        value={docboxState.email}
                        onChange={event =>
                          handleInputChange(
                            event.target.name,
                            event.target.value,
                          )
                        }
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="width50">
                      <label htmlFor="FIELD_patient-phone">Phone</label>
                      <input
                        id="FIELD_patient-phone"
                        type="tel"
                        name="patientPhone"
                        placeholder="(xxx)-xxx-xxxx"
                        value={docboxState.patientPhone}
                        onChange={event =>
                          handleInputChange(
                            event.target.name,
                            event.target.value,
                          )
                        }
                      />
                    </div>

                    <div className="width50">
                      <label htmlFor="FIELD_patient-mobile">Mobile Phone</label>
                      <input
                        id="FIELD_patient-mobile"
                        type="tel"
                        name="patientMobilePhone"
                        placeholder="(xxx)-xxx-xxxx"
                        value={docboxState.patientMobilePhone}
                        onChange={event =>
                          handleInputChange(
                            event.target.name,
                            event.target.value,
                          )
                        }
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="FIELD_patient-address-1" className="noflex">
                      Home Address
                    </label>
                    <div className="form-row" style={{marginTop: '0'}}>
                      <div className="width50">
                        <input
                          id="FIELD_patient-address-1"
                          type="text"
                          name="addressLine1"
                          placeholder="Address (Line 1)..."
                          value={docboxState.addressLine1}
                          onChange={event =>
                            handleInputChange(
                              event.target.name,
                              event.target.value,
                            )
                          }
                        />
                      </div>

                      <div className="width50">
                        <input
                          id="FIELD_patient-address-2"
                          type="text"
                          name="addressLine2"
                          placeholder="Address (Line 2)..."
                          value={docboxState.addressLine2}
                          onChange={event =>
                            handleInputChange(
                              event.target.name,
                              event.target.value,
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="width33">
                      <input
                        id="FIELD_patient-city"
                        type="text"
                        name="city"
                        placeholder="City..."
                        value={docboxState.city}
                        onChange={event =>
                          handleInputChange(
                            event.target.name,
                            event.target.value,
                          )
                        }
                      />
                    </div>

                    <div className="width33">
                      <select
                        id="FIELD_patient-state"
                        name="state"
                        value={docboxState.state}
                        onChange={event =>
                          handleInputChange(
                            event.target.name,
                            event.target.value,
                          )
                        }>
                        <option value="">State...</option>
                        {states.map((state, index) => {
                          return (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="width33">
                      <input
                        id="FIELD_patient-zip"
                        type="text"
                        name="zip"
                        placeholder="Zip..."
                        value={docboxState.zip}
                        onChange={event =>
                          handleInputChange(
                            event.target.name,
                            event.target.value,
                          )
                        }
                      />
                    </div>
                  </div>
                </section>

                <h2>Emergency Contact</h2>
                <section>
                  <div className="form-row">
                    <div className="width50">
                      <label htmlFor="FIELD_contact-1">Contact 1</label>
                      <input
                        id="FIELD_contact-1"
                        type="text"
                        name="emergencyName01"
                        placeholder="Name for Contact 1..."
                        value={docboxState.emergencyName01}
                        onChange={event =>
                          handleInputChange(
                            event.target.name,
                            event.target.value,
                          )
                        }
                      />
                    </div>
                    <div className="width50">
                      <label htmlFor="FIELD_contact-phone-1">Phone 1</label>
                      <input
                        id="FIELD_contact-phone-1"
                        type="tel"
                        name="emergencyPhone01"
                        placeholder="Phone for Contact 1... (xxx)-xxx-xxxx"
                        value={docboxState.emergencyPhone01}
                        onChange={event =>
                          handleInputChange(
                            event.target.name,
                            event.target.value,
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="width50">
                      <label htmlFor="FIELD_contact-2">Contact 2</label>
                      <input
                        id="FIELD_contact-2"
                        type="text"
                        name="emergencyName02"
                        placeholder="Name for Contact 2..."
                        value={docboxState.emergencyName02}
                        onChange={event =>
                          handleInputChange(
                            event.target.name,
                            event.target.value,
                          )
                        }
                      />
                    </div>
                    <div className="width50">
                      <label htmlFor="FIELD_contact-phone-2">Phone 2</label>
                      <input
                        id="FIELD_contact-phone-2"
                        type="tel"
                        name="emergencyPhone02"
                        placeholder="Phone for Contact 2... (xxx)-xxx-xxxx"
                        value={docboxState.emergencyPhone02}
                        onChange={event =>
                          handleInputChange(
                            event.target.name,
                            event.target.value,
                          )
                        }
                      />
                    </div>
                  </div>
                </section>
              </form>
            </div>
          );
        }
      }
    case 'billing':
      if (docboxState.mode === 'View') {
        return (
          <div
            id="docbox-module-billing"
            className="docbox-module docbox-nav-target">
            <h1>Billing</h1>
            <form
              onSubmit={event => {
                event.preventDefault();
                return false;
              }}>
              <h2
                id="docbox-submodule-diagnostic_codes"
                className="docbox-nav-target">
                ICD-10
              </h2>
              <section>
                <div className="form-row view">
                  <div className="width30">
                    <label htmlFor="FIELD_icd">ICD-10 Codes</label>
                  </div>
                  <div className="width70">
                    <p id="FIELD_icd">
                      {docboxState.icd.join(', ') || <span>N/A</span>}
                    </p>
                  </div>
                </div>
              </section>

              <h2>CPT</h2>
              <section>
                <div className="form-row view">
                  <div className="width30">
                    <label htmlFor="FIELD_cpt">CPT Codes</label>
                  </div>
                  <div className="width70">
                    <p id="FIELD_cpt">
                      {docboxState.cpt.join(', ') || <span>N/A</span>}
                    </p>
                  </div>
                </div>
              </section>

              {!isIndustryRep(authState.userType) && (
                <>
                  <h2
                    id="docbox-submodule-insurance"
                    className="docbox-nav-target">
                    Primary Insurance
                  </h2>
                  <section>
                    <div className="form-row view">
                      <div className="width30">
                        <label htmlFor="FIELD_ins-provider-1">Provider</label>
                      </div>
                      <div className="width70">
                        <p id="FIELD_ins-provider-1">
                          {docboxState.insuranceProvider01 || <span>N/A</span>}
                        </p>
                      </div>
                    </div>

                    <div className="form-row view">
                      <div className="width30">
                        <label htmlFor="FIELD_ins-authorization-1">
                          Authorization
                        </label>
                      </div>
                      <div className="width70">
                        <p id="FIELD_ins-authorization-1">
                          {docboxState.insuranceAuthorization01 || (
                            <span>N/A</span>
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="form-row view">
                      <div className="width30">
                        <label htmlFor="FIELD_ins-policy-1">Policy</label>
                      </div>
                      <div className="width70">
                        <p id="FIELD_ins-policy-1">
                          {docboxState.insurancePolicy01 || <span>N/A</span>}
                        </p>
                      </div>
                    </div>

                    <div className="form-row view">
                      <div className="width30">
                        <label htmlFor="FIELD_ins-group-1">Group / Plan</label>
                      </div>
                      <div className="width70">
                        <p id="FIELD_ins-group-1">
                          {docboxState.insuranceGroup01 || <span>N/A</span>}
                        </p>
                      </div>
                    </div>

                    <div className="form-row view">
                      <div className="width30">
                        <label htmlFor="FIELD_ins-copay-1">Co-Pay</label>
                      </div>
                      <div className="width70">
                        <p id="FIELD_ins-copay-1">
                          {docboxState.insuranceCopay01 || <span>N/A</span>}
                        </p>
                      </div>
                    </div>

                    <div className="form-row view">
                      <div className="width30">
                        <label htmlFor="FIELD_ins-deductible-1">
                          Deductible
                        </label>
                      </div>
                      <div className="width70">
                        <p id="FIELD_ins-deductible-1">
                          {docboxState.insuranceDeductible01 || (
                            <span>N/A</span>
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="form-row view">
                      <div className="width30">
                        <label htmlFor="FIELD_ins-type-1">Type</label>
                      </div>
                      <div className="width70">
                        <p id="FIELD_ins-type-1">
                          {docboxState.insuranceType01 || <span>N/A</span>}
                        </p>
                      </div>
                    </div>
                  </section>

                  <h2>Secondary Insurance</h2>
                  <section>
                    <div className="form-row view">
                      <div className="width30">
                        <label htmlFor="FIELD_ins-provider-2">Provider</label>
                      </div>
                      <div className="width70">
                        <p id="FIELD_ins-provider-2">
                          {docboxState.insuranceProvider02 || <span>N/A</span>}
                        </p>
                      </div>
                    </div>

                    <div className="form-row view">
                      <div className="width30">
                        <label htmlFor="FIELD_ins-authorization-2">
                          Authorization
                        </label>
                      </div>
                      <div className="width70">
                        <p id="FIELD_ins-authorization-2">
                          {docboxState.insuranceAuthorization02 || (
                            <span>N/A</span>
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="form-row view">
                      <div className="width30">
                        <label htmlFor="FIELD_ins-policy-2">Policy</label>
                      </div>
                      <div className="width70">
                        <p id="FIELD_ins-policy-2">
                          {docboxState.insurancePolicy02 || <span>N/A</span>}
                        </p>
                      </div>
                    </div>

                    <div className="form-row view">
                      <div className="width30">
                        <label htmlFor="FIELD_ins-group-2">Group / Plan</label>
                      </div>
                      <div className="width70">
                        <p id="FIELD_ins-group-2">
                          {docboxState.insuranceGroup02 || <span>N/A</span>}
                        </p>
                      </div>
                    </div>

                    <div className="form-row view">
                      <div className="width30">
                        <label htmlFor="FIELD_ins-copay-2">Co-Pay</label>
                      </div>
                      <div className="width70">
                        <p id="FIELD_ins-copay-2">
                          {docboxState.insuranceCopay02 || <span>N/A</span>}
                        </p>
                      </div>
                    </div>

                    <div className="form-row view">
                      <div className="width30">
                        <label htmlFor="FIELD_ins-deductible-2">
                          Deductible
                        </label>
                      </div>
                      <div className="width70">
                        <p id="FIELD_ins-deductible-2">
                          {docboxState.insuranceDeductible02 || (
                            <span>N/A</span>
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="form-row view">
                      <div className="width30">
                        <label htmlFor="FIELD_ins-type-2">Type</label>
                      </div>
                      <div className="width70">
                        <p id="FIELD_ins-type-2">
                          {docboxState.insuranceType02 || <span>N/A</span>}
                        </p>
                      </div>
                    </div>
                  </section>

                  {docboxState.eventType === 'Surgery' && (
                    <>
                      <h2>BPCI</h2>
                      <section>
                        <div className="form-row view">
                          <div className="width30">
                            <label htmlFor="FIELD_bpci-patient">
                              BPCI Patient
                            </label>
                          </div>
                          <div className="width70">
                            <p id="FIELD_bpci-patient">
                              {isBPCIPatient(docboxState.bpciPatient)}
                            </p>
                          </div>
                        </div>
                      </section>
                    </>
                  )}
                </>
              )}
            </form>
          </div>
        );
      } else {
        return (
          <div
            id="docbox-module-billing"
            className="docbox-module docbox-nav-target">
            <h1>Billing</h1>
            <form
              onSubmit={event => {
                event.preventDefault();
                return false;
              }}>
              <h2
                id="docbox-submodule-diagnostic_codes"
                className="docbox-nav-target">
                ICD-10
                <button
                  type="button"
                  className="btn-success circle"
                  title="Event Settings"
                  style={{margin: '-3px 0 0 1rem'}}
                  onClick={() => {
                    if (scheduleState.targetUserId) {
                      openEventSettings(
                        getEventSettingsURL,
                        0,
                        scheduleState.targetUserId,
                        'diagnostic-codes',
                      );
                    }
                  }}>
                  <img src={gearIcon} alt="Settings" />
                </button>
              </h2>
              <section>
                <div className="form-row">
                  <div className="width50">
                    <label htmlFor="case-icd-search">ICD-10 Search</label>
                    <BillingTypeahead
                      uri={billingCodesSearchURI}
                      codeType={'icd'}
                      elementId={'case-icd-search'}
                      placeholder={'Search ICD-10 Codes...'}
                      handleSelection={addBillingCode}
                    />
                  </div>

                  <div className="width50">
                    <button
                      type="button"
                      className="btn-success"
                      style={{
                        marginTop: '18px',
                      }}
                      onClick={() => {
                        const alertBody = icdCodesArr => {
                          const codeAlreadyExists = (
                            existingCodesArr,
                            newCode,
                          ) => {
                            return existingCodesArr.some(existingCode => {
                              return existingCode === newCode;
                            });
                          };

                          return (
                            <div>
                              {icdCodesArr.map((codeObj, index) => {
                                const codeIsAdded = codeAlreadyExists(
                                  docboxState.icd,
                                  codeObj.c,
                                );

                                return (
                                  <div
                                    key={index}
                                    className="flex align-center"
                                    style={{margin: '1rem 0'}}>
                                    <div
                                      className={
                                        codeIsAdded
                                          ? 'icon-added'
                                          : 'icon-not-added'
                                      }>
                                      <FontAwesomeIcon
                                        icon={faPlusCircle}
                                        size="lg"
                                        style={{
                                          marginRight: '1rem',
                                        }}
                                      />
                                    </div>
                                    <p
                                      className="clickable"
                                      style={{margin: '0'}}
                                      onClick={event => {
                                        event.preventDefault();
                                        event.stopPropagation();

                                        if (codeIsAdded) {
                                          deleteBillingCode('icd', codeObj.c);
                                          setNotification(
                                            `${codeObj.c} removed from Case.`,
                                          );
                                        } else {
                                          const newCode = {
                                            code: codeObj.c,
                                          };
                                          addBillingCode('icd', newCode);
                                          setNotification(
                                            `${codeObj.c} added to Case.`,
                                          );
                                        }

                                        return closeAlert();
                                      }}>
                                      <b
                                        style={{
                                          fontWeight: '600',
                                          marginRight: '1rem',
                                        }}>
                                        {codeObj.c}
                                      </b>
                                      {codeObj.d}
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        };

                        return openAlert({
                          title: 'Add Bookmarked ICD-10 Codes',
                          body: alertBody(eventSettingsState.icd10),
                          cancelText: 'Close',
                        });
                      }}>
                      Add Bookmarked ICD-10 Codes
                    </button>
                  </div>
                </div>

                <div className="form-row">
                  <div className="width100">
                    <div id="icd-container" className="flex wrap">
                      {docboxState.icd.length ? (
                        docboxState.icd.map((code, index) => {
                          return (
                            <BillingCodeBadge
                              key={index}
                              type={'icd'}
                              code={code}
                              handler={() => deleteBillingCode('icd', code)}
                            />
                          );
                        })
                      ) : (
                        <span>No ICD-10 codes added yet.</span>
                      )}
                    </div>
                  </div>
                </div>
              </section>

              <h2>
                CPT
                <button
                  type="button"
                  className="btn-success circle"
                  title="Event Settings"
                  style={{margin: '-3px 0 0 1rem'}}
                  onClick={() => {
                    if (scheduleState.targetUserId) {
                      openEventSettings(
                        getEventSettingsURL,
                        0,
                        scheduleState.targetUserId,
                        'diagnostic-codes',
                      );
                    }
                  }}>
                  <img src={gearIcon} alt="Settings" />
                </button>
              </h2>
              <section>
                <div className="form-row">
                  <div className="width50">
                    <label htmlFor="case-cpt-search">CPT Search</label>
                    <BillingTypeahead
                      uri={billingCodesSearchURI}
                      codeType={'cpt'}
                      elementId={'case-cpt-search'}
                      placeholder={'Search CPT Codes...'}
                      handleSelection={addBillingCode}
                    />
                  </div>

                  <div className="width50">
                    <button
                      type="button"
                      className="btn-success"
                      style={{
                        marginTop: '18px',
                      }}
                      onClick={() => {
                        const alertBody = cptCodesArr => {
                          const codeAlreadyExists = (
                            existingCodesArr,
                            newCode,
                          ) => {
                            return existingCodesArr.some(existingCode => {
                              return existingCode === newCode;
                            });
                          };

                          return (
                            <div>
                              {cptCodesArr.map((codeObj, index) => {
                                const codeIsAdded = codeAlreadyExists(
                                  docboxState.cpt,
                                  codeObj.c,
                                );

                                return (
                                  <div
                                    key={index}
                                    className="flex align-center"
                                    style={{margin: '1rem 0'}}>
                                    <div
                                      className={
                                        codeIsAdded
                                          ? 'icon-added'
                                          : 'icon-not-added'
                                      }>
                                      <FontAwesomeIcon
                                        icon={faPlusCircle}
                                        size="lg"
                                        style={{
                                          marginRight: '1rem',
                                        }}
                                      />
                                    </div>
                                    <p
                                      className="clickable"
                                      style={{margin: '0'}}
                                      onClick={event => {
                                        event.preventDefault();
                                        event.stopPropagation();

                                        if (codeIsAdded) {
                                          deleteBillingCode('cpt', codeObj.c);
                                          setNotification(
                                            `${codeObj.c} removed from Case.`,
                                          );
                                        } else {
                                          const newCode = {
                                            code: codeObj.c,
                                          };
                                          addBillingCode('cpt', newCode);
                                          setNotification(
                                            `${codeObj.c} added to Case.`,
                                          );
                                        }

                                        return closeAlert();
                                      }}>
                                      <b
                                        style={{
                                          fontWeight: '600',
                                          marginRight: '1rem',
                                        }}>
                                        {codeObj.c}
                                      </b>
                                      {codeObj.d}
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        };

                        return openAlert({
                          title: 'Add Bookmarked CPT Codes',
                          body: alertBody(eventSettingsState.cpt),
                          cancelText: 'Close',
                        });
                      }}>
                      Add Bookmarked CPT Codes
                    </button>
                  </div>
                </div>

                <div className="form-row">
                  <div className="width100">
                    <div id="cpt-container" className="flex wrap">
                      {docboxState.cpt.length ? (
                        docboxState.cpt.map((code, index) => {
                          return (
                            <BillingCodeBadge
                              key={index}
                              type={'cpt'}
                              code={code}
                              handler={() => deleteBillingCode('cpt', code)}
                            />
                          );
                        })
                      ) : (
                        <span>No CPT codes added yet.</span>
                      )}
                    </div>
                  </div>
                </div>
              </section>

              <h2 id="docbox-submodule-insurance" className="docbox-nav-target">
                Primary Insurance
              </h2>
              <section>
                <div className="form-row">
                  <div className="width33">
                    <label htmlFor="FIELD_ins-provider-1">Provider</label>
                    <input
                      id="FIELD_ins-provider-1"
                      type="text"
                      name="insuranceProvider01"
                      placeholder="Insurer..."
                      value={docboxState.insuranceProvider01}
                      onChange={event =>
                        handleInputChange(event.target.name, event.target.value)
                      }
                    />
                  </div>

                  <div className="width33">
                    <label htmlFor="FIELD_ins-authorization-1">
                      Authorization
                    </label>
                    <input
                      id="FIELD_ins-authorization-1"
                      type="text"
                      name="insuranceAuthorization01"
                      placeholder="Auth Code..."
                      value={docboxState.insuranceAuthorization01}
                      onChange={event =>
                        handleInputChange(event.target.name, event.target.value)
                      }
                    />
                  </div>

                  <div className="width33">
                    <label htmlFor="FIELD_ins-policy-1">Policy</label>
                    <input
                      id="FIELD_ins-policy-1"
                      type="text"
                      name="insurancePolicy01"
                      placeholder="ID / Account..."
                      value={docboxState.insurancePolicy01}
                      onChange={event =>
                        handleInputChange(event.target.name, event.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="width33">
                    <label htmlFor="FIELD_ins-group-1">Group / Plan</label>
                    <input
                      id="FIELD_ins-group-1"
                      type="text"
                      name="insuranceGroup01"
                      placeholder="Group / Plan..."
                      value={docboxState.insuranceGroup01}
                      onChange={event =>
                        handleInputChange(event.target.name, event.target.value)
                      }
                    />
                  </div>

                  <div className="width33 flex space-between">
                    <div className="width50">
                      <label htmlFor="FIELD_ins-copay-1">Co-Pay</label>
                      <input
                        id="FIELD_ins-copay-1"
                        type="text"
                        name="insuranceCopay01"
                        placeholder="Co-Pay..."
                        value={docboxState.insuranceCopay01}
                        onChange={event =>
                          handleInputChange(
                            event.target.name,
                            event.target.value,
                          )
                        }
                      />
                    </div>

                    <div className="width50">
                      <label htmlFor="FIELD_ins-deductible-1">Deductible</label>
                      <input
                        id="FIELD_ins-deductible-1"
                        type="text"
                        name="insuranceDeductible01"
                        placeholder="Deductible..."
                        value={docboxState.insuranceDeductible01}
                        onChange={event =>
                          handleInputChange(
                            event.target.name,
                            event.target.value,
                          )
                        }
                      />
                    </div>
                  </div>

                  <div className="width33">
                    <label htmlFor="FIELD_ins-type-1">Type</label>
                    <select
                      id="FIELD_ins-type-1"
                      name="insuranceType01"
                      value={docboxState.insuranceType01}
                      onChange={event =>
                        handleInputChange(event.target.name, event.target.value)
                      }>
                      <option value="">Not Specified</option>
                      <option value="Commercial">Commercial</option>
                      <option value="Motor">Motor</option>
                      <option value="Worker's Comp">Worker's Comp</option>
                      <option value="Medicare">Medicare</option>
                      <option value="Medicaid">Medicaid</option>
                      <option value="Cash">Cash</option>
                    </select>
                  </div>
                </div>
              </section>

              <h2>Secondary Insurance</h2>
              <section>
                <div className="form-row">
                  <div className="width33">
                    <label htmlFor="FIELD_ins-provider-2">Provider</label>
                    <input
                      id="FIELD_ins-provider-2"
                      type="text"
                      name="insuranceProvider02"
                      placeholder="Insurer..."
                      value={docboxState.insuranceProvider02}
                      onChange={event =>
                        handleInputChange(event.target.name, event.target.value)
                      }
                    />
                  </div>

                  <div className="width33">
                    <label htmlFor="FIELD_ins-authorization-2">
                      Authorization
                    </label>
                    <input
                      id="FIELD_ins-authorization-2"
                      type="text"
                      name="insuranceAuthorization02"
                      placeholder="Auth Code..."
                      value={docboxState.insuranceAuthorization02}
                      onChange={event =>
                        handleInputChange(event.target.name, event.target.value)
                      }
                    />
                  </div>

                  <div className="width33">
                    <label htmlFor="FIELD_ins-policy-2">Policy</label>
                    <input
                      id="FIELD_ins-policy-2"
                      type="text"
                      name="insurancePolicy02"
                      placeholder="ID / Account..."
                      value={docboxState.insurancePolicy02}
                      onChange={event =>
                        handleInputChange(event.target.name, event.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="width33">
                    <label htmlFor="FIELD_ins-group-2">Group / Plan</label>
                    <input
                      id="FIELD_ins-group-2"
                      type="text"
                      name="insuranceGroup02"
                      placeholder="Group / Plan..."
                      value={docboxState.insuranceGroup02}
                      onChange={event =>
                        handleInputChange(event.target.name, event.target.value)
                      }
                    />
                  </div>

                  <div className="width33 flex space-between">
                    <div className="width50">
                      <label htmlFor="FIELD_ins-copay-2">Co-Pay</label>
                      <input
                        id="FIELD_ins-copay-2"
                        type="text"
                        name="insuranceCopay02"
                        placeholder="Co-Pay..."
                        value={docboxState.insuranceCopay02}
                        onChange={event =>
                          handleInputChange(
                            event.target.name,
                            event.target.value,
                          )
                        }
                      />
                    </div>

                    <div className="width50">
                      <label htmlFor="FIELD_ins-deductible-2">Deductible</label>
                      <input
                        id="FIELD_ins-deductible-2"
                        type="text"
                        name="insuranceDeductible02"
                        placeholder="Deductible..."
                        value={docboxState.insuranceDeductible02}
                        onChange={event =>
                          handleInputChange(
                            event.target.name,
                            event.target.value,
                          )
                        }
                      />
                    </div>
                  </div>

                  <div className="width33">
                    <label htmlFor="FIELD_ins-type-2">Type</label>
                    <select
                      id="FIELD_ins-type-2"
                      name="insuranceType02"
                      value={docboxState.insuranceType02}
                      onChange={event =>
                        handleInputChange(event.target.name, event.target.value)
                      }>
                      <option value="">Not Specified</option>
                      <option value="Commercial">Commercial</option>
                      <option value="Motor">Motor</option>
                      <option value="Worker's Comp">Worker's Comp</option>
                      <option value="Medicare">Medicare</option>
                      <option value="Medicaid">Medicaid</option>
                      <option value="Cash">Cash</option>
                    </select>
                  </div>
                </div>
              </section>
            </form>
          </div>
        );
      }
    case 'post_op':
      if (docboxState.mode === 'View') {
        return (
          <div
            id="docbox-module-post_op"
            className="docbox-module docbox-nav-target">
            <h1>Post Op</h1>
            <form
              onSubmit={event => {
                event.preventDefault();
                return false;
              }}
              style={{
                paddingTop: '2rem',
              }}>
              <div className="form-row view">
                <div className="width30">
                  <label htmlFor="FIELD_ivf">
                    IVF <b>(Intravenous Fluids)</b>
                  </label>
                </div>
                <div className="width70">
                  <p id="FIELD_ivf">
                    {docboxState.ivf ? (
                      `${docboxState.ivf} cc`
                    ) : (
                      <span>N/A</span>
                    )}
                  </p>
                </div>
              </div>

              <div className="form-row view">
                <div className="width30">
                  <label htmlFor="FIELD_ebl">
                    EBL <b>(Estimated Blood Loss)</b>
                  </label>
                </div>
                <div className="width70">
                  <p id="FIELD_ebl">
                    {docboxState.ebl ? (
                      `${docboxState.ebl} cc`
                    ) : (
                      <span>N/A</span>
                    )}
                  </p>
                </div>
              </div>

              <div className="form-row view">
                <div className="width30">
                  <label htmlFor="FIELD_uop">
                    UOP <b>(Urine Output)</b>
                  </label>
                </div>
                <div className="width70">
                  <p id="FIELD_uop">
                    {docboxState.uop ? (
                      `${docboxState.uop} cc`
                    ) : (
                      <span>N/A</span>
                    )}
                  </p>
                </div>
              </div>

              <div className="form-row view">
                <div className="width30">
                  <label htmlFor="FIELD_dictation-code">Dictation Code</label>
                </div>
                <div className="width70">
                  <p id="FIELD_dictation-code">
                    {docboxState.dictationCode || <span>N/A</span>}
                  </p>
                </div>
              </div>

              <div className="form-row view">
                <div className="width30">
                  <label htmlFor="FIELD_post-op-notes">Post-Op Notes</label>
                </div>
                <div className="width70">
                  <p id="FIELD_post-op-notes">
                    {docboxState.postOpNotes ? (
                      formatNotes(docboxState.postOpNotes)
                    ) : (
                      <span>N/A</span>
                    )}
                  </p>
                </div>
              </div>
            </form>
          </div>
        );
      } else {
        return (
          <div
            id="docbox-module-post_op"
            className="docbox-module docbox-nav-target">
            <h1>Post Op</h1>
            <form
              onSubmit={event => {
                event.preventDefault();
                return false;
              }}
              style={{
                paddingTop: '2rem',
              }}>
              <div className="form-row">
                <div className="width50">
                  <label htmlFor="FIELD_ivf">
                    IVF <b>(Intravenous Fluids)</b>
                  </label>
                  <div style={{position: 'relative'}}>
                    <label
                      className="input-field-icon"
                      title="cubic centimeters">
                      CC
                    </label>
                    <input
                      id="FIELD_ivf"
                      type="number"
                      name="ivf"
                      placeholder="Numbers Only..."
                      value={docboxState.ivf}
                      onChange={event =>
                        handleInputChange(event.target.name, event.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="width50">
                  <label htmlFor="FIELD_ebl">
                    EBL <b>(Estimated Blood Loss)</b>
                  </label>
                  <div style={{position: 'relative'}}>
                    <label
                      className="input-field-icon"
                      title="cubic centimeters">
                      CC
                    </label>
                    <input
                      id="FIELD_ebl"
                      type="number"
                      name="ebl"
                      placeholder="Numbers Only..."
                      value={docboxState.ebl}
                      onChange={event =>
                        handleInputChange(event.target.name, event.target.value)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="width50">
                  <label htmlFor="FIELD_uop">
                    UOP <b>(Urine Output)</b>
                  </label>
                  <div style={{position: 'relative'}}>
                    <label
                      className="input-field-icon"
                      title="cubic centimeters">
                      CC
                    </label>
                    <input
                      id="FIELD_uop"
                      type="number"
                      name="uop"
                      placeholder="Numbers Only..."
                      value={docboxState.uop}
                      onChange={event =>
                        handleInputChange(event.target.name, event.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="width50">
                  <label htmlFor="FIELD_dictation-code">Dictation Code</label>
                  <input
                    id="FIELD_dictation-code"
                    type="text"
                    name="dictationCode"
                    placeholder="Optional Dictation Code..."
                    value={docboxState.dictationCode}
                    onChange={event =>
                      handleInputChange(event.target.name, event.target.value)
                    }
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="width100">
                  <label htmlFor="FIELD_post-op-notes">Post-Op Notes</label>
                  <textarea
                    id="FIELD_post-op-notes"
                    name="postOpNotes"
                    placeholder="Optional Post-Op Notes..."
                    value={docboxState.postOpNotes}
                    onChange={event =>
                      handleInputChange(event.target.name, event.target.value)
                    }></textarea>
                </div>
              </div>
            </form>
          </div>
        );
      }
    case 'audit':
      if (docboxState.mode === 'View') {
        return (
          <div
            id="docbox-module-audit"
            className="docbox-module docbox-nav-target">
            <h1>Audit</h1>
            <form
              onSubmit={event => {
                event.preventDefault();
                return false;
              }}
              style={{
                paddingTop: '2rem',
              }}>
              <div className="form-row view">
                <div className="width30">
                  <label htmlFor="FIELD_editor">Latest Editor</label>
                </div>
                <div className="width70">
                  <p id="FIELD_editor">
                    {docboxState.editor || <span>N/A</span>}
                  </p>
                </div>
              </div>

              <div className="form-row view">
                <div className="width30">
                  <label htmlFor="FIELD_edited">Last Edited</label>
                </div>
                <div className="width70">
                  <p id="FIELD_edited">
                    {docboxState.edited ? (
                      moment(docboxState.edited, 'YYYYMMDDHHmmss').format(
                        'ddd, MMM D, YYYY. h:mm A',
                      )
                    ) : (
                      <span>N/A</span>
                    )}
                  </p>
                </div>
              </div>

              <div className="form-row view">
                <div className="width30">
                  <label htmlFor="FIELD_owner">Owner</label>
                </div>
                <div className="width70">
                  <p id="FIELD_owner">
                    {docboxState.eventOwnerName || <span>N/A</span>}
                  </p>
                </div>
              </div>

              <div className="form-row view">
                <div className="width30">
                  <label htmlFor="FIELD_created">Created</label>
                </div>
                <div className="width70">
                  <p id="FIELD_created">
                    {docboxState.created ? (
                      moment(docboxState.created, 'YYYYMMDDHHmmss').format(
                        'ddd, MMM D, YYYY. h:mm A',
                      )
                    ) : (
                      <span>N/A</span>
                    )}
                  </p>
                </div>
              </div>
            </form>
          </div>
        );
      } else {
        return (
          <div
            id="docbox-module-audit"
            className="docbox-module docbox-nav-target">
            <h1>Audit</h1>
            <form
              onSubmit={event => {
                event.preventDefault();
                return false;
              }}
              style={{
                paddingTop: '2rem',
              }}>
              <div className="form-row">
                <div className="width50">
                  <label htmlFor="case-editor">Latest Editor</label>
                  <p>{docboxState.editor || 'N/A'}</p>
                </div>

                <div className="width50">
                  <label htmlFor="case-edited">Last Edited</label>
                  <p>
                    {docboxState.edited
                      ? moment(docboxState.edited, 'YYYYMMDDHHmmss').format(
                          'MMM D, YYYY. h:mm A',
                        )
                      : 'N/A'}
                  </p>
                </div>
              </div>

              <div className="form-row">
                <div className="width50">
                  <label htmlFor="case-owner">Owner</label>
                  <p>{docboxState.eventOwnerName || 'N/A'}</p>
                </div>

                <div className="width50">
                  <label htmlFor="case-created">Created</label>
                  <p>
                    {docboxState.created
                      ? moment(docboxState.created, 'YYYYMMDDHHmmss').format(
                          'MMM D, YYYY. h:mm A',
                        )
                      : 'N/A'}
                  </p>
                </div>
              </div>
            </form>
          </div>
        );
      }
    default:
      return null;
  }
};

const DeviceRow = ({index, id, name, size, mode}) => {
  const {deleteDevice, updateDevice} = useContext(DocboxContext);

  if (mode === 'View') {
    return (
      <>
        <div className="form-row view">
          <div className="width30">
            <label htmlFor={`FIELD_device-name-${id}`}>
              Device #{index} - Name
            </label>
          </div>
          <div className="width70">
            <p id={`FIELD_device-name-${id}`}>{name || <span>N/A</span>}</p>
          </div>
        </div>
        <div className="form-row view">
          <div className="width30">
            <label htmlFor={`FIELD_device-name-${id}`}>
              Device #{index} - Size
            </label>
          </div>
          <div className="width70">
            <p id={`FIELD_device-name-${id}`}>{size || <span>N/A</span>}</p>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div
        className="device-row flex align-center space-between"
        id={`device-${id}`}
        data-id={id}>
        <div className="width50">
          <label htmlFor={`FIELD_device-name-${id}`}>Device Name</label>
          <input
            id={`FIELD_device-name-${id}`}
            type="text"
            placeholder="Device or Implant..."
            value={name}
            onChange={event => {
              const newName = event.target.value;
              updateDevice(id, newName, size);
            }}
          />
        </div>

        <div className="width33">
          <label htmlFor={`FIELD_device-size-${id}`}>Size</label>
          <input
            id={`FIELD_device-size-${id}`}
            type="text"
            placeholder="Size..."
            value={size}
            onChange={event => {
              const newSize = event.target.value;
              updateDevice(id, name, newSize);
            }}
          />
        </div>

        <DeleteFieldButton handler={() => deleteDevice(id)} />
      </div>
    );
  }
};

const ChecklistGroup = ({index, checklistGroup, viewMode}) => {
  const {
    state: docboxState,
    deleteChecklistGroup,
    updateChecklistGroupField,
    linkChecklistsToPatientReadiness,
  } = useContext(DocboxContext);

  if (viewMode !== undefined && viewMode === true) {
    return (
      <div
        className="checklist-group"
        id={`checklist-group-${checklistGroup.id}`}
        data-id={checklistGroup.id}>
        <div className="flex align-center space-between">
          <div className="width30">
            <label htmlFor={`FIELD_checklist-group-name-${checklistGroup.id}`}>
              Checklist Group #{index}
            </label>
          </div>
          <div className="width70">
            <p>{checklistGroup.name}</p>
          </div>
        </div>
        {checklistGroup.checklist.length &&
          checklistGroup.checklist.map((item, index, array) => {
            let lastItem = false;
            if (index === array.length - 1) {
              lastItem = true;
            }
            return (
              <ChecklistItem
                key={index}
                checklistGroup={checklistGroup}
                item={item}
                viewMode={viewMode}
                lastItem={lastItem}
              />
            );
          })}
      </div>
    );
  } else {
    return (
      <div
        className="checklist-group"
        id={`checklist-group-${checklistGroup.id}`}
        data-id={checklistGroup.id}>
        <div style={{paddingBottom: '0.5rem'}}>
          <label htmlFor={`FIELD_checklist-group-name-${checklistGroup.id}`}>
            Checklist Group Name
          </label>
          <div className="flex space-between align-center">
            <div className="width90">
              <input
                id={`FIELD_checklist-group-name-${checklistGroup.id}`}
                type="text"
                placeholder="Checklist Group Name..."
                value={
                  checklistGroup.name
                    ? parseSanitizedString(checklistGroup.name)
                    : ''
                }
                onChange={event => {
                  const newName = event.target.value;
                  updateChecklistGroupField(checklistGroup, 'name', newName);
                }}
              />
            </div>

            <DeleteFieldButton
              title={`Delete Checklist Group`}
              handler={() => {
                deleteChecklistGroup(checklistGroup.id).then(() => {
                  if (
                    docboxState.checklistClearedLinked === 'Yes' &&
                    docboxState.checklists.length === 1
                  ) {
                    linkChecklistsToPatientReadiness('No');
                  }
                });
              }}
            />
          </div>
        </div>

        {checklistGroup.checklist.length &&
          checklistGroup.checklist.map((item, index, array) => {
            let lastItem = false;
            if (index === array.length - 1) {
              lastItem = true;
            }
            return (
              <ChecklistItem
                key={index}
                checklistGroup={checklistGroup}
                item={item}
                viewMode={viewMode}
                lastItem={lastItem}
              />
            );
          })}
      </div>
    );
  }
};

const ChecklistItem = ({checklistGroup, item, viewMode, lastItem}) => {
  const {state: authState} = useContext(AuthContext);
  const {
    state: docboxState,
    deleteChecklistItem,
    addChecklistItem,
    updateChecklistItem,
    updateChecklistItemStatus,
    linkChecklistsToPatientReadiness,
  } = useContext(DocboxContext);
  const {state: globalState} = useContext(GlobalContext);
  const {
    state: scheduleState,
    getAgenda,
    getDrafts,
  } = useContext(ScheduleContext);

  const convertedTimestamp = () => {
    if (momentTz.tz.guess() === 'America/Los_Angeles') {
      return timeAgoFromNow(moment(item.updated, 'YYYYMMDDhhmmss'));
    } else {
      let updateTime = moment(item.updated, 'YYYYMMDDhhmmss').format(
        'YYYY-MM-DD HH:mm:ss',
      );
      let pacificTime = momentTz.tz(updateTime, 'America/Los_Angeles');
      //convert pst to current user's timezone
      let userTime = pacificTime.tz(momentTz.tz.guess()).format();
      return timeAgoFromNow(userTime);
    }
  };

  if (viewMode !== undefined && viewMode === true) {
    return (
      <div
        className="checklist-item-row flex align-center space-between"
        style={{
          padding: '1rem 0',
        }}>
        <div className="width20">
          <select
            id={`FIELD_checklist-item-status-${item.id}`}
            data-id={item.id}
            value={item.status}
            onChange={event => {
              const url = `${globalState.config.ABS_BASE_URL}${calendarUpdateChecklistURI}`;

              event.target.setAttribute('disabled', 'disabled');

              updateChecklistItemStatus(
                docboxState.checklists,
                item,
                event.target.value,
                url,
                docboxState.eventId,
              ).then(response => {
                if (
                  response &&
                  response.status === 200 &&
                  response.data &&
                  response.data.updated
                ) {
                  const getAgendaURL = `${globalState.config.ABS_BASE_URL}${agendaURI}`;
                  const getDraftsURL = `${globalState.config.ABS_BASE_URL}${draftsURI}`;
                  getDrafts(getDraftsURL, scheduleState.targetUserId);
                  getAgenda(
                    getAgendaURL,
                    scheduleState.targetUserId,
                    scheduleState.selectedCalendarDate,
                  );
                  for (let i = 0; i < response.data.updated.length; i++) {
                    if (response.data.updated[i].id === item.id) {
                      // TODO: use dispatch function to update timestamp for this checklist item using the timestamp from the response (DJL -- timestamp is not accurate as of 20210606)
                      updateChecklistItem(
                        checklistGroup,
                        response.data.updated[i],
                      );
                      break;
                    }
                  }
                }

                event.target.removeAttribute('disabled');
                return;
              });
            }}>
            <option value="TBD">TBD</option>
            <option value="Yes">Yes</option>
            <option value="No">N/A</option>
          </select>
        </div>

        <div className="width70 flex direction-column">
          <p style={{lineHeight: '16px'}}>
            {item.name ? parseSanitizedString(item.name) : ''}
          </p>
          <p style={{lineHeight: '14px'}}>
            <small>
              {item.status !== 'TBD'
                ? `Updated ${
                    item.updated ? convertedTimestamp() : ''
                  } by ${formatFullName(
                    item.updater.name.first,
                    item.updater.name.last,
                  )}`
                : 'PENDING'}
            </small>
          </p>
        </div>
      </div>
    );
  } else {
    return (
      <div style={{marginTop: '0.5rem'}}>
        <label htmlFor={`FIELD_checklist-item-name-${item.id}`}>
          Checklist Item
        </label>
        <div
          className="checklist-item-row flex justify-start align-center"
          style={{paddingBottom: '0.5rem'}}>
          <div className="width20">
            <select
              id={`FIELD_checklist-item-status-${item.id}`}
              data-id={item.id}
              value={item.status}
              onChange={event => {
                updateChecklistItemStatus(
                  docboxState.checklists,
                  item,
                  event.target.value,
                );
              }}>
              <option value="TBD">TBD</option>
              <option value="Yes">Yes</option>
              <option value="No">N/A</option>
            </select>
          </div>

          <div
            className="width70"
            style={{
              marginLeft: '1rem',
            }}>
            <input
              id={`FIELD_checklist-item-name-${item.id}`}
              data-id={item.id}
              type="text"
              placeholder="Checklist Item Name..."
              value={item.name ? parseSanitizedString(item.name) : ''}
              onChange={event => {
                updateChecklistItem(checklistGroup, item, event.target.value);
              }}
            />
          </div>

          <div
            style={{
              marginLeft: '8px',
            }}>
            <DeleteFieldButton
              handler={() => {
                deleteChecklistItem(checklistGroup, item.id);
              }}
              title={'Remove This Checklist Item'}
            />
          </div>
          {lastItem === true && (
            <div
              style={{
                marginLeft: '8px',
              }}>
              <AddFieldButton
                handler={() => {
                  const newChecklistItemId = Date.now();
                  addChecklistItem(checklistGroup, newChecklistItemId);
                }}
                title={'Add New Checklist Item'}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
};

const AttendeeCard = ({attendee, allowDelete, ownerId, type}) => {
  const {deleteProvider, deleteRep} = useContext(DocboxContext);
  const {state: globalState} = useContext(GlobalContext);

  return (
    <div className="attendee-card width50 flex">
      {allowDelete && type && type === 'Provider' && attendee.id != ownerId && (
        <DeleteFieldButton handler={() => deleteProvider(attendee.id)} />
      )}
      {allowDelete && type && type === 'Rep' && attendee.id != ownerId && (
        <DeleteFieldButton handler={() => deleteRep(attendee.id)} />
      )}
      <div
        className="attendee-card-avatar"
        style={{
          backgroundImage: `url(${globalState.config.ABS_BASE_URL}/a/${attendee.avatar}_128.jpg)`,
        }}></div>
      <div
        className="flex direction-column justify-center"
        style={{marginLeft: '1rem', maxWidth: '230px'}}>
        <span>
          <b>{attendee.name}</b>
        </span>
        <span>{attendee.specialty || attendee.company}</span>
        {/* {
          (typeof attendee.status !== 'undefined' && attendee.status) &&
          <span className="flex align-center">
            <div className={`attendee-invite-icon ${formatAttendeeStatus(attendee.status)}`}></div>
            <span>{formatAttendeeStatus(attendee.status)}</span>
          </span>
        } */}
      </div>
    </div>
  );
};

export default DocboxModuleContainer;
