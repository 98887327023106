import createDataContext from './createDataContext';
import instance from '../dataAccess/docspera';
import jwt from 'jsonwebtoken';

import {AlomDecodeType2} from '../libraries/helpers';

let initialState = {
  userId: null,
  isLoggedIn: null,
  userProfile: null,
  jwt: null,
  practiceId: null,
  dsLicenses: [],
  practicePermissions: [],
  errorMessage: '',
  statusMessage: '',
};

const authReducer = (state, action) => {
  switch (action.type) {
    case 'toggle-login-status':
      return {
        ...state,
        isLoggedIn: !state.isLoggedIn,
      };
    case 'login-status':
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case 'login':
      return {
        ...state,
        userId: action.payload.id,
        type: action.payload.type,
        sub: action.payload.sub,
        errorMessage: '',
        statusMessage: '',
      };
    case 'jwt-token':
      return {
        ...state,
        jwt: action.payload,
        userId: AlomDecodeType2(action.payload.decoded.uid.toString()),
        userType: action.payload.decoded.u_t,
        practiceId: action.payload.decoded.pid[0],
        errorMessage: '',
        statusMessage: '',
      };
    case 'set-user-id':
      return {
        ...state,
        userId: action.payload,
        errorMessage: '',
        statusMessage: '',
      };
    case 'get-user-profile':
      return {
        ...state,
        userProfile: action.payload,
        errorMessage: '',
        statusMessage: '',
      };
    case 'get-ds-licenses':
      return {
        ...state,
        dsLicenses: action.payload,
        errorMessage: '',
        statusMessage: '',
      };
    case 'get-practice-permissions':
      return {
        ...state,
        practicePermissions: action.payload,
        errorMessage: '',
        statusMessage: '',
      };
    case 'logout':
      return {
        user: null,
        isLoggedIn: false,
        errorMessage: '',
        statusMessage: '',
      };
    case 'forgot':
      if (action.payload.status === 'Success') {
        return {
          ...state,
          errorMessage: '',
          statusMessage: action.payload['status-message'],
        };
      } else {
        return {
          ...state,
          errorMessage: action.payload['status-message'],
          statusMessage: '',
        };
      }
    case 'error':
      console.log('error :: ', action.payload);
      return {
        ...state,
        errorMessage: action.payload,
        statusMessage: '',
      };
    default:
      return {
        ...state,
      };
  }
};

const toggleLoginStatus = dispatch => async () => {
  return dispatch({type: 'toggle-login-status'});
};

const loginStatus = dispatch => async url => {
  if (!url) {
    return dispatch({
      type: 'Error',
      payload: 'Error from loginStatus: no url',
    });
  }

  try {
    const response = await instance.post(url);

    if (response.status === 200 && response.data.status === 'Logged In') {
      return dispatch({type: 'login-status', payload: true});
    } else {
      return dispatch({type: 'login-status', payload: false});
    }
  } catch (error) {
    return dispatch({type: 'error', payload: 'Could not get login status.'});
  }
};

const setUserId = dispatch => async userId => {
  return dispatch({type: 'set-user-id', payload: userId});
};

const getProfile = dispatch => async (url, userId, miniProfile) => {
  if (isNaN(userId)) {
    return dispatch({
      type: 'error',
      payload: 'Error from getProfile: input userId is NaN',
    });
  }
  if (!url) {
    return dispatch({
      type: 'Error',
      payload: 'Error from getProfile: no url',
    });
  }

  try {
    const request = {
      user: userId.toString(),
      miniProfile: miniProfile ? miniProfile : 0,
    };
    const response = await instance.post(url, JSON.stringify(request));

    if (!response.data) {
      return dispatch({type: 'error', payload: `no response from ${url}`});
    } else {
      if (userId in response.data) {
        dispatch({type: 'get-user-profile', payload: response.data[userId]});
        return response;
      }
    }
  } catch (error) {
    return dispatch({
      type: 'error',
      payload: `Error from getProfile: ${error.message}`,
    });
  }
};

const getToken = dispatch => async url => {
  let token = null;
  const handleToken = token => {
    const decoded = jwt.decode(token);

    dispatch({
      type: 'jwt-token',
      payload: {
        token: token,
        decoded: decoded,
      },
    });

    return decoded;
  };

  // first, check the cookie for the jwt
  if (document.cookie) {
    const jwtSubstr = 'docsperajwt=';
    const cookieArr = document.cookie.split('; ');

    for (let i = 0; i < cookieArr.length; i++) {
      if (cookieArr[i].indexOf(jwtSubstr) !== -1) {
        token = cookieArr[i].split(jwtSubstr).pop();
        break;
      }
    }
  }

  // for development, use environment variable
  if (!token && process.env.REACT_APP_DEV_JWT) {
    console.log('developer env token', `${process.env.REACT_APP_DEV_JWT}`);
    token = `${process.env.REACT_APP_DEV_JWT}`;
  }

  // if we have a token at this point, decode and stash
  if (token) {
    handleToken(token);
  }

  // if no token yet, call the API
  if (!token) {
    try {
      const response = await instance.get(url);

      if (response.data) {
        token = response.data.token;
        handleToken(token);
      } else {
        dispatch({type: 'error', payload: 'no API response from getToken'});
      }
    } catch (err) {
      dispatch({type: 'error', payload: 'error from getToken'});
    }
  }

  return;
};

const getDSLicenses = dispatch => async url => {
  if (!url) {
    return dispatch({
      type: 'Error',
      payload: 'Error from getDSLicenses: no url',
    });
  }

  try {
    const response = await instance.get(url);

    if (!response.data) {
      return dispatch({type: 'error', payload: `no response from ${url}`});
    } else {
      if (Array.isArray(response.data)) {
        dispatch({type: 'get-ds-licenses', payload: response.data});
        return response;
      } else {
        return dispatch({
          type: 'error',
          payload: `could not parse getDSLicenses response`,
        });
      }
    }
  } catch (error) {
    return dispatch({
      type: 'error',
      payload: `Error from getDSLicenses: ${error.message}`,
    });
  }
};

const getPracticePermissions = dispatch => async url => {
  if (!url) {
    return dispatch({
      type: 'Error',
      payload: 'Error from getPracticePermissions: no url',
    });
  }

  try {
    const response = await instance.get(url);

    if (!response.data) {
      return dispatch({type: 'error', payload: `no response from ${url}`});
    } else {
      if (Array.isArray(response.data) && response.data.length > 0) {
        dispatch({type: 'get-practice-permissions', payload: response.data});
        return response;
      } else {
        return dispatch({
          type: 'error',
          payload: `could not parse getPracticePermissions response`,
        });
      }
    }
  } catch (error) {
    return dispatch({
      type: 'error',
      payload: `Error from getPracticePermissions: ${error.message}`,
    });
  }
};

const logout = dispatch => async url => {
  if (!url) {
    return dispatch({type: 'Error', payload: 'Error from logout: no url'});
  }

  try {
    const response = await instance.get(url);
    dispatch({type: 'logout'});
    return response;
  } catch (error) {
    console.log('Logout error', error.message);
    return dispatch({type: 'logout'});
  }
};

export const {Provider, Context} = createDataContext(
  authReducer,
  {
    loginStatus,
    toggleLoginStatus,
    setUserId,
    getProfile,
    getToken,
    getDSLicenses,
    getPracticePermissions,
    logout,
  },
  initialState,
);
