import React, {useContext, useEffect} from 'react';
import moment from 'moment';

import {Context as GlobalContext} from '../../context/GlobalContext';
import {Context as ScheduleContext} from '../../context/ScheduleContext';

import Calendar from './Calendar';

import {
  agendaURI,
  calendarMonthURI,
  getUserPreferencesURI,
  setUserPreferencesURI,
} from '../../dataAccess/apiEndpoints';

import './CalendarContainer.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faListUl} from '@fortawesome/free-solid-svg-icons';

const CalendarContainer = ({isDayClicked, setIsDayClicked}) => {
  return (
    <div id="calendar-container" className="no-select flex direction-column">
      <div style={{flexGrow: '1'}}>
        <Calendar
          isDayClicked={isDayClicked}
          setIsDayClicked={setIsDayClicked}
        />
        <ClinicalShowHide />
        <CalendarLegend />
      </div>
    </div>
  );
};

const CalendarLegend = () => {
  return (
    <section id="calendar-legend">
      <h2>Legend</h2>
      <ul>
        <li>
          <div
            style={{
              border: '2px solid var(--dark-red)',
              height: '14px',
              width: '14px',
            }}></div>
          Today
        </li>
        <li>
          <div
            style={{
              backgroundColor: 'var(--calendar-confirmed)',
              height: '18px',
              width: '18px',
            }}></div>
          Confirmed Events
        </li>
        <li>
          <div
            style={{
              backgroundColor: 'var(--calendar-unconfirmed)',
              height: '18px',
              width: '18px',
            }}></div>
          One or More Unconfirmed Events
        </li>
        <li>
          <div
            style={{
              backgroundColor: 'var(--calendar-conference)',
              height: '18px',
              width: '18px',
            }}></div>
          Conference
        </li>
        <li>
          <div
            style={{
              backgroundColor: 'var(--icon-idle-gray)',
              height: '18px',
              width: '18px',
            }}></div>
          Vacation
        </li>
      </ul>
    </section>
  );
};

const ClinicalShowHide = () => {
  const {
    state: scheduleState,
    getShowClinical,
    setShowClinical,
    selectMonthAndGetCalendar,
    selectDateAndGetAgenda,
  } = useContext(ScheduleContext);
  const {state: globalState} = useContext(GlobalContext);

  const handleClick = () => {
    const url = `${globalState.config.ABS_BASE_URL}${setUserPreferencesURI}`;
    setShowClinical(
      url,
      scheduleState.targetUserId,
      !scheduleState.showClinical,
    ).then(() => {
      const getMonthURL = `${globalState.config.ABS_BASE_URL}${calendarMonthURI}`;
      const getAgendaURL = `${globalState.config.ABS_BASE_URL}${agendaURI}`;
      selectMonthAndGetCalendar(
        getMonthURL,
        scheduleState.selectedCalendarMonth,
        scheduleState.targetUserId,
      );
      selectDateAndGetAgenda(
        getAgendaURL,
        moment(scheduleState.selectedCalendarDate, 'YYYYMMDDTHHmmss'),
        scheduleState.targetUserId,
      );
    });
  };

  useEffect(() => {
    if (scheduleState.targetUserId && globalState.config.ABS_BASE_URL) {
      const url = `${globalState.config.ABS_BASE_URL}${getUserPreferencesURI}`;
      getShowClinical(url, scheduleState.targetUserId);
    }
  }, [scheduleState.targetUserId, globalState.config.ABS_BASE_URL]);

  return (
    <section
      id="clinical-show-hide"
      className={`flex align-center space-between`}>
      <label
        className={`${
          scheduleState.showClinical ? 'clinical-shown' : 'clinical-hidden'
        }`}
        htmlFor="agenda-cal-clinical"
        title={
          scheduleState.showClinical
            ? 'Appointments Shown'
            : 'Appointments Hidden'
        }
        onClick={handleClick}>
        <FontAwesomeIcon icon={faListUl} />
        {scheduleState.showClinical
          ? 'Clinical Cases Shown'
          : 'Clinical Cases Hidden'}
      </label>

      {/* <Toggle
        id="agenda-cal-clinical"
        toggled={scheduleState.showClinical}
        handler={handleClick}
      /> */}
    </section>
  );
};

export default CalendarContainer;
