import React, {useContext, useEffect, useState} from 'react';
import moment from 'moment';
import Docbox from '../../Docbox';
import Modal from '../Modal/Modal';
import Table from '../../Table/Table';
import Pagination from '../Pagination/Pagination';
import GlobalNavMenu from '../../GlobalNavMenu';
import EventSettings from '../../EventSettings';
import CalendarPicker from '../../CalendarPicker';
import useInterval from '../../../hooks/useInterval';
import {
  faBuilding,
  faCaretDown,
  faFemale,
  faMale,
  faPen,
  faPlus,
  faQuestionCircle,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import {triggerOpenDocbox} from '../../../libraries/util';
import {AlomEncodeType2} from '../../../libraries/helpers';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import providerIcon from '../../../assets/img/icons/provider.svg';
import industryIcon from '../../../assets/img/icons/industry.svg';
import ChecklistsModalBody from '../Checklists/ChecklistsModalBody';
import {Context as AuthContext} from '../../../context/AuthContext';
import {Context as DocboxContext} from '../../../context/DocboxContext';
import {Context as GlobalContext} from '../../../context/GlobalContext';
import {Context as ScheduleContext} from '../../../context/ScheduleContext';
import {Context as PDContext} from '../../../context/PracticeDashboardContext';
import {Context as EventSettingsContext} from '../../../context/EventSettingsContext';

import {
  allCasesURI,
  editNotesURI,
  getChecklistsURI,
  settingsURI,
} from '../../../dataAccess/apiEndpoints';

import './AllCases.scss';

const AllCases = () => {
  const {
    getCases,
    editNotes,
    getSettings,
    getWeekCases,
    setTimeFilter,
    getChecklists,
    state: pdState,
    setNotesEditted,
    clearChecklistsModal,
    updateFilterFieldValue,
  } = useContext(PDContext);
  const {state: docboxState, openDocbox} = useContext(DocboxContext);
  const {state: authState} = useContext(AuthContext);
  const {setTargetUserId, setTargetUserName} = useContext(ScheduleContext);
  const {state: eventSettingsState} = useContext(EventSettingsContext);
  const {state: globalState, openAlert, closeAlert} = useContext(GlobalContext);
  const [page, setPage] = useState(0);
  const [currentCase, setCurrentCase] = useState();
  const [currentNotes, setCurrentNotes] = useState();
  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const offset = page * 10;

  useEffect(() => {
    const time = {
      start: moment().startOf('week').format('YYYYMMDD'),
      end: moment().endOf('week').format('YYYYMMDD'),
    };
    setTimeFilter(time.start, time.end);
    if (globalState.config.ABS_BASE_URL) {
      const url = `${globalState.config.ABS_BASE_URL}${settingsURI}`;
      getSettings(url);
    }
  }, [globalState.config.ABS_BASE_URL]);

  useInterval(() => {
    if (pdState.time.start.length !== 0 && globalState.config.ABS_BASE_URL) {
      const url = `${globalState.config.ABS_BASE_URL}${allCasesURI}`;
      getCases(
        url,
        pdState.time,
        pdState.type,
        pdState.location,
        pdState.physician,
        pdState.search,
        pdState.sort_by,
        offset,
      );
    }
  }, 1000 * 60);

  useEffect(() => {
    if (pdState.time.start.length !== 0 && globalState.config.ABS_BASE_URL) {
      const url = `${globalState.config.ABS_BASE_URL}${allCasesURI}`;
      getCases(
        url,
        pdState.time,
        pdState.type,
        pdState.location,
        pdState.physician,
        pdState.search,
        pdState.sort_by,
        offset,
      );
    }
  }, [
    pdState.time,
    pdState.type,
    pdState.location,
    pdState.physician,
    pdState.search,
    pdState.sort_by,
    page,
    globalState.config.ABS_BASE_URL,
    docboxState.checklists,
    pdState.checklistsModal,
  ]);

  useEffect(() => {
    if (pdState.time.start.length !== 0 && globalState.config.ABS_BASE_URL) {
      const settingsURL = `${globalState.config.ABS_BASE_URL}${settingsURI}`;
      getSettings(settingsURL);
      const url = `${globalState.config.ABS_BASE_URL}${allCasesURI}`;
      getWeekCases(url, pdState.time, null, null, null, null, null, 0);
    }
  }, [pdState.time, docboxState.location, globalState.config.ABS_BASE_URL]);

  useEffect(() => {
    if (pdState.notesEditted && globalState.config.ABS_BASE_URL) {
      const url = `${globalState.config.ABS_BASE_URL}${allCasesURI}`;
      getCases(
        url,
        pdState.time,
        pdState.type,
        pdState.location,
        pdState.physician,
        pdState.search,
        pdState.sort_by,
        offset,
      );
      setNotesEditted(false);
    }
  }, [pdState.notesEditted, globalState.config.ABS_BASE_URL]);

  useEffect(() => {
    setPage(0);
  }, [pdState.time, pdState.location, pdState.physician, pdState.search]);

  const handleDateSelect = event => {
    const start = moment(event.target.dataset.date)
      .startOf('week')
      .format('YYYYMMDD');
    const end = moment(event.target.dataset.date)
      .endOf('week')
      .format('YYYYMMDD');
    setTimeFilter(start, end);
  };

  const handleInputChange = (name, value) => {
    if (name === 'physician' || name === 'location') {
      if (value !== 'null') {
        value = parseInt(value);
      }
    }
    if (value === 'null') {
      value = null;
    }
    updateFilterFieldValue(name, value);
  };

  const currentView = globalState.currentView;

  const handleNavLinkClick = inputEndpoint => {
    if (currentView === inputEndpoint) return;
    return window.location.assign(inputEndpoint);
  };

  const handleRowClick = item => {
    const encodedEventId = AlomEncodeType2(item.event_id);
    console.log({event_id: encodedEventId});
    const viewAsUserId = authState.userId ? authState.userId : null;
    if (viewAsUserId && globalState.config.ABS_BASE_URL) {
      triggerOpenDocbox(encodedEventId);
    }
  };

  const handleAddNotes = (item, e) => {
    e.stopPropagation();
    setNotesModalOpen(true);
    setCurrentCase(item);
    setCurrentNotes(item.notes);
  };

  const onPatientReadinessClick = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    const encodedEventId = AlomEncodeType2(item.event_id);
    if (globalState.config.ABS_BASE_URL && encodedEventId) {
      const url = `${globalState.config.ABS_BASE_URL}${getChecklistsURI}`;
      getChecklists(url, encodedEventId).then(() => {
        console.log('clicked on patient readiness');
        openAlert({
          title: 'Checklists',
          body: <ChecklistsModalBody />,
          cancelText: 'Close',
          cancelHandler: () => {
            clearChecklistsModal();
            closeAlert();
          },
        });
      });
    }
  };

  const weeklyCaseLocations = settingsLocation => {
    //filter surgery locations to current week

    let found = false;
    if (
      pdState.weekCases &&
      pdState.weekCases.cases &&
      Array.isArray(pdState.weekCases.cases) &&
      pdState.weekCases.cases.length > 0
    ) {
      pdState.weekCases.cases.forEach(c => {
        if (
          c &&
          c.surgery_location &&
          c.surgery_location.id &&
          settingsLocation.id === c.surgery_location.id
        ) {
          found = true;
        }
      });
    }
    return found;
  };

  return (
    <>
      <GlobalNavMenu />
      <div id="all-cases">
        <div className="content header">
          <form style={{display: 'flex', justifyContent: 'space-between'}}>
            <div style={{display: 'flex'}}>
              {/* Week: */}
              <div className="labelAndDropdown">
                <CalendarPicker
                  id={'pd-week'}
                  name={'week'}
                  selectable={'week'}
                  value={pdState.time.start}
                  endValue={pdState.time.end}
                  handler={event => handleDateSelect(event)}
                  style={{width: '80%'}}
                />
              </div>

              {/* Type */}
              <div className="labelAndDropdown">
                <FontAwesomeIcon
                  className="select-icon"
                  icon={faQuestionCircle}
                  size="lg"
                />
                <label htmlFor="type">TYPE</label>
                <select
                  className={pdState.type ? 'changed-type' : ''}
                  id="type"
                  name="type"
                  value={pdState.type ? pdState.type : ''}
                  onChange={event =>
                    handleInputChange(event.target.name, event.target.value)
                  }>
                  <option value={'null'}> All</option>
                  <option disabled>──────────</option>
                  <option value="Surgery">Surgical</option>
                  <option value="Clinical">Clinical</option>
                  <option value="Vacation">Vacation</option>
                  <option value="Conference">Conference</option>
                </select>
                <FontAwesomeIcon
                  className="select-arrow"
                  icon={faCaretDown}
                  size="lg"
                />
              </div>

              {/* Location */}
              <div className="labelAndDropdown">
                <FontAwesomeIcon
                  className="select-icon"
                  icon={faBuilding}
                  size="lg"
                />
                <label htmlFor="location">LOCATION</label>
                <select
                  className={pdState.location ? 'changed-loc' : ''}
                  id="location"
                  name="location"
                  value={pdState.location ? pdState.location : ''}
                  onChange={event =>
                    handleInputChange(event.target.name, event.target.value)
                  }>
                  <option value={'null'}> All</option>
                  <option disabled>──────────</option>
                  {pdState.settings &&
                    pdState.settings.surgery_locations &&
                    (pdState.location === null ||
                    pdState.settings.surgery_locations
                      .filter(l => weeklyCaseLocations(l))
                      .some(l => l.id === pdState.location)
                      ? pdState.settings.surgery_locations.filter(l =>
                          weeklyCaseLocations(l),
                        )
                      : pdState.settings.surgery_locations.filter(
                          l => l.id === pdState.location,
                        )
                    ).map(location => {
                      return (
                        <option key={location.id} value={location.id}>
                          {' '}
                          {location.name}{' '}
                        </option>
                      );
                    })}
                </select>
                <FontAwesomeIcon
                  className="select-arrow"
                  icon={faCaretDown}
                  size="lg"
                />
              </div>

              {/* Physician */}
              <div className="labelAndDropdown">
                <FontAwesomeIcon
                  className="select-icon"
                  icon={faUser}
                  size="lg"
                />
                <label htmlFor="physician">PHYSICIAN</label>
                <select
                  className={pdState.physician ? 'changed-phys' : ''}
                  id="physician"
                  name="physician"
                  value={pdState.physician ? pdState.physician : ''}
                  onChange={event =>
                    handleInputChange(event.target.name, event.target.value)
                  }>
                  <option value={'null'}> All</option>
                  <option disabled>──────────</option>
                  {pdState.settings &&
                    pdState.settings.physicians &&
                    pdState.settings.physicians.map(physician => {
                      return (
                        <option key={physician.id} value={physician.id}>
                          {' '}
                          {physician.name}{' '}
                        </option>
                      );
                    })}
                </select>
                <FontAwesomeIcon
                  className="select-arrow"
                  icon={faCaretDown}
                  size="lg"
                />
              </div>
            </div>
            {pdState.cases.cases && pdState.cases.cases.length > 0 && (
              <Pagination
                total={pdState.cases.paginate.total}
                page={page}
                setPage={setPage}
                isLoading={pdState.isLoading}
              />
            )}
            {/* Search Bar */}
            {/* REMOVING UNTIL SEARCH FUNCTIONALITY WORKS AGAIN */}
            {/* <div className='input-container'>
              <input
                className='search-input'
                type='text'
                name='search'
                maxLength='256'
                value={pdState.search}
                onChange={(event) => handleInputChange(event.target.name, event.target.value)}
              />
              <img className='search-icon' src={searchIcon} alt='Search' />
            </div> */}
          </form>
        </div>

        <div className="table-container">
          {pdState.isLoading ? (
            <div className="pd-loading" style={{marginTop: 170}}>
              {/* <Loading /> */}
            </div>
          ) : pdState.cases.cases && pdState.cases.cases.length > 0 ? (
            <>
              <Table
                data={pdState.cases.cases}
                cols={tableConstants(handleAddNotes, onPatientReadinessClick)}
                brand="docspera"
                handleRowClick={handleRowClick}
              />
            </>
          ) : (
            <div className="no-cases">
              <h3>No Cases Available for your Search Criteria</h3>
              <p>
                Try a different combination of Week, Case Type, Location or
                Physician.
              </p>
            </div>
          )}
        </div>
        {notesModalOpen && (
          <Modal
            content={
              <EditNotesModalContent
                currentCase={currentCase}
                setCurrentNotes={setCurrentNotes}
                currentNotes={currentNotes}
                editNotes={editNotes}
                setNotesModalOpen={setNotesModalOpen}
                setNotesEditted={setNotesEditted}
                globalState={globalState}
              />
            }
            toggleModal={setNotesModalOpen}
            title="Scheduling Notes"
          />
        )}
      </div>
      {docboxState.isDocboxOpen && <Docbox />}
      {eventSettingsState.isEventSettingsOpen && <EventSettings />}
    </>
  );
};

const EditNotesModalContent = ({
  currentCase,
  setCurrentNotes,
  currentNotes,
  editNotes,
  setNotesModalOpen,
  setNotesEditted,
  globalState,
}) => {
  const handleEditClick = () => {
    if (globalState.config.ABS_BASE_URL) {
      const url = `${globalState.config.ABS_BASE_URL}${editNotesURI}`;
      editNotes(url, AlomEncodeType2(currentCase.event_id), currentNotes);
      setNotesModalOpen(false);
      setNotesEditted(true);
    }
  };
  return (
    <div style={{paddingLeft: 24, paddingRight: 24, paddingBottom: 24}}>
      <form>
        <textarea
          style={{height: '300px', width: '300px', padding: 20}}
          value={currentNotes}
          onChange={e => setCurrentNotes(e.target.value)}></textarea>
        <div
          style={{display: 'flex', justifyContent: 'flex-end', marginTop: 20}}>
          <button
            type="button"
            className="btn-warning"
            style={{marginRight: 20}}
            onClick={() => setNotesModalOpen(false)}>
            Cancel
          </button>
          <button
            type="button"
            className="btn-success"
            title="Book a New Event"
            onClick={() => handleEditClick()}>
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

const findGenderIcon = g => {
  if (g === 'F') {
    return faFemale;
  } else {
    return faMale;
  }
};

const findGenderIconColor = g => {
  if (g === 'F') {
    return 'patient-female';
  } else if (g === 'M') {
    return 'patient-male';
  }
};

const formatPatientName = name => {
  const newName = name.split(' ');
  return `${newName[newName.length - 1].charAt(0)}, ${newName[0].charAt(0)}`;
};

const findText = (percent, checklistLinked) => {
  if (!checklistLinked) {
    return <i>N/A</i>;
  } else {
    if (percent === 0) {
      return `${percent}%`;
    } else if (percent > 0 && percent < 100) {
      return `${percent}%`;
    } else if (percent === 100) {
      return 'Ready';
    }
  }
};

const findStyle = (percent, checklistLinked) => {
  if (!checklistLinked) {
    return 'not-linked-to-checklist';
  } else {
    if (percent === 0) {
      return 'not-started';
    } else if (percent > 0 && percent < 100) {
      return `started`;
    } else if (percent === 100) {
      return 'ready';
    }
  }
};

const tableConstants = (handleAddNotes, onPatientReadinessClick) => {
  return [
    {
      title: 'Surgery \nDate',
      render: rowData => {
        return (
          <div className="date-td">
            <span>
              {moment(rowData.time.start, 'YYYYMMDDHHmmss').format(
                'MMM DD, YYYY',
              )}
            </span>
            <p>
              {moment(rowData.time.start, 'YYYYMMDDHHmmss').format('h:mm A')} -{' '}
              {moment(rowData.time.end, 'YYYYMMDDHHmmss').format('h:mm A')}
            </p>
          </div>
        );
      },
    },
    {
      title: 'Main \nPhysician',
      render: rowData => {
        return (
          <div className={'physician-td'}>
            {/* if physician name is a first + last name object */}
            {rowData.physician.name !== undefined &&
              rowData.physician.name.last !== undefined && (
                <span>
                  {rowData.physician.name.last || ''}
                  {rowData.physician.name.first ? ', ' : ''}
                  {rowData.physician.name.first[0] || ''}
                </span>
              )}
            {/* if physician name is a string */}
            {rowData.physician.name !== undefined &&
              rowData.physician.name.last === undefined && (
                <span>{rowData.physician.name}</span>
              )}
            {/* if physician key or physician name key are undefined/null */}
            {(rowData.physician === undefined ||
              rowData.physician === null ||
              rowData.physician.name === undefined ||
              rowData.physician.name === null) && <span>N/A</span>}
          </div>
        );
      },
    },
    {
      title: 'Surgery / Case Name \n Patient',
      render: rowData => {
        return (
          <div className={'case-td'}>
            <span> {rowData.case}</span>
            <div className="patient-data">
              <div>
                {rowData.type !== 'Conference' &&
                  rowData.type !== 'Vacation' && (
                    <FontAwesomeIcon
                      icon={findGenderIcon(rowData.patient.gender)}
                      className={findGenderIconColor(rowData.patient.gender)}
                      style={{marginTop: '2px'}}
                    />
                  )}
                <span className="patient-name">
                  {rowData.patient.name ? rowData.patient.name : ''}
                </span>
              </div>
              <div>
                {rowData.patient.dob && rowData.patient.dob !== 0 ? (
                  <span>
                    {moment().diff(
                      moment(rowData.patient.dob, 'YYYYMMDDHHmmss'),
                      'years',
                    )}
                    y |
                  </span>
                ) : null}
                <span> {rowData.patient.mrn}</span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Surgery \nLocation',
      render: rowData => {
        return (
          <div className="location-td">
            {rowData.type !== 'Conference' && rowData.type !== 'Vacation' ? (
              <span>{rowData.surgery_location.name}</span>
            ) : (
              <span>N/A</span>
            )}
          </div>
        );
      },
    },
    {
      title: 'Readiness',
      render: rowData => {
        return (
          <div
            className={`readiness-td patient-readiness ${findStyle(
              parseInt(rowData.readiness.percent),
              rowData.readiness.checklists_linked,
            )}`}
            onClick={e => onPatientReadinessClick(e, rowData)}>
            {rowData.type !== 'Conference' && rowData.type !== 'Vacation' ? (
              <span>
                {findText(
                  parseInt(rowData.readiness.percent),
                  rowData.readiness.checklists_linked,
                )}
              </span>
            ) : (
              <span style={{fontStyle: 'italic'}}>N/A</span>
            )}
          </div>
        );
      },
    },
    {
      title: 'Surgery \nParticipants',
      render: rowData => {
        return (
          <div className="participants-td">
            <div className="care-team">
              <img
                style={{width: 12, marginRight: 5}}
                src={providerIcon}
                alt="Provider"
              />
              {rowData.participants.providers &&
              rowData.participants.providers.length > 0 ? (
                rowData.participants.providers.map((p, idx) => {
                  return (
                    <span key={idx}>
                      {p.name.last}
                      {idx !== rowData.participants.providers.length - 1 &&
                        ','}{' '}
                    </span>
                  );
                })
              ) : (
                <span>N/A</span>
              )}
            </div>
            <hr
              style={{
                border: 0,
                height: 0,
                borderTop: '1px solid var(--pd-horizontal)',
              }}></hr>
            <div className="rep">
              <img
                style={{width: 12, marginRight: 5}}
                src={industryIcon}
                alt="Industry"
              />
              {rowData.participants.industry &&
              rowData.participants.industry.length > 0 ? (
                rowData.participants.industry.map((p, idx) => {
                  return (
                    <span key={idx}>
                      {p.name.last}
                      {idx !== rowData.participants.industry.length - 1 &&
                        ','}{' '}
                    </span>
                  );
                })
              ) : (
                <span>N/A</span>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: 'Case Notes \nand Requested Devices',
      render: rowData => {
        return (
          <div className="notes-td">
            <div className="notes-container">
              {rowData.notes &&
                (rowData.notes.length < 75 || rowData.notes === null) && (
                  <span style={{display: 'block'}}>{rowData.notes}</span>
                )}
              {rowData.notes && rowData.notes.length > 75 && (
                // <Notes notes={rowData.notes} />
                <span style={{display: 'block'}}>{rowData.notes}</span>
              )}
              {rowData.notes === null && (
                <span
                  style={{color: 'var(--pd-light-gray)', fontStyle: 'italic'}}>
                  No Notes
                </span>
              )}

              <button
                type="button"
                className={`tiny-button ${
                  rowData.notes === null ? 'add-notes' : ''
                }`}
                onClick={e => handleAddNotes(rowData, e)}>
                <FontAwesomeIcon
                  className="notes-icon"
                  icon={rowData.notes === null ? faPlus : faPen}
                  size="sm"
                />
                {rowData.notes === null ? 'Add Notes' : 'Edit Notes'}
              </button>
            </div>

            <hr
              style={{
                border: 0,
                height: 0,
                borderTop: '1px solid var(--pd-horizontal)',
              }}></hr>
            <div className="devices">
              <span style={{color: 'var(--pd-light-gray)'}}>Devices: </span>
              {rowData.devices.length > 0 ? (
                rowData.devices.map((device, idx) => {
                  return (
                    <span key={idx}>
                      {' '}
                      {device.name}
                      {idx !== rowData.devices.length - 1 && ',  '}{' '}
                    </span>
                  );
                })
              ) : (
                <span style={{color: 'var(--pd-light-gray)'}}>N/A</span>
              )}
            </div>
          </div>
        );
      },
    },
  ];
};

export default AllCases;
