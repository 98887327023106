import React, {useContext} from 'react';
import {Context as GlobalContext} from '../context/GlobalContext';
import './Notification.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

const Notification = () => {
  const {state: globalState, clearNotification} = useContext(GlobalContext);

  return (
    <div id="notification">
      <div className="flex align-center space-between" style={{height: '100%'}}>
        {globalState.notification && (
          <p className="ellipsis">{globalState.notification}</p>
        )}
        <button
          type="button"
          className="btn-close small"
          onClick={() => clearNotification()}>
          <div>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </button>
      </div>
    </div>
  );
};

export default Notification;
