import moment from 'moment';
import {
  dateFromISODate,
  dateFromISODateTime,
  dateToISODate,
  nextISODate,
} from './moment';

export const flattenMonth = fromBackend => {
  const year = parseInt(fromBackend.query.year);
  const month = parseInt(fromBackend.query.month);
  const userId = parseInt(fromBackend.query.user.id);

  const startOfMonth = moment(`${year}${month}`, 'YYYYM')
    .clone()
    .startOf('month');
  const firstDate = startOfMonth.clone().subtract(startOfMonth.day(), 'day');
  const firstISODate = dateToISODate(firstDate);
  const firstYear = firstDate.get('year');
  const firstMonth = firstDate.get('month') + 1;
  const lastDate = moment(firstDate).add(41, 'days');
  const lastISODate = dateToISODate(lastDate);
  const lastYear = lastDate.get('year');
  const lastMonth = lastDate.get('month') + 1;
  const vacationArray = fromBackend.last_month.vacation
    .concat(fromBackend.this_month.vacation)
    .concat(fromBackend.next_month.vacation);
  const conferenceArray = fromBackend.last_month.conference
    .concat(fromBackend.this_month.conference)
    .concat(fromBackend.next_month.conference);

  let result = [];
  let isoDate = firstISODate;

  while (isoDate <= lastISODate) {
    let daySummary = {
      isoDate: isoDate,
      userId: userId,
      confirmed: 0,
      unconfirmed: 0,
      cancelled: 0,
      vacation: '',
      conference: '',
    };
    const thisDate = dateFromISODate(isoDate);
    const thisYear = moment(thisDate).get('year');
    const thisMonth = moment(thisDate).get('month') + 1;
    const thisDay = moment(thisDate).date();

    let monthObj;

    if (thisYear == year && thisMonth == month) {
      monthObj = fromBackend.this_month;
    } else if (thisYear == firstYear && thisMonth == firstMonth) {
      monthObj = fromBackend.last_month;
    } else if (thisYear == lastYear && thisMonth == lastMonth) {
      monthObj = fromBackend.next_month;
    }

    let dayOfMonthStr = thisDay.toString();

    const dayObj = monthObj[dayOfMonthStr];

    if (dayObj) {
      daySummary.confirmed = dayObj.confirmed;
      daySummary.unconfirmed = dayObj.unconfirmed;
      if (dayObj.cancelled) daySummary.cancelled = dayObj.cancelled;
    }

    for (let vacationObj of vacationArray) {
      const vacationStart = dateFromISODateTime(vacationObj.start);
      const vacationEnd = dateFromISODateTime(vacationObj.end);

      if (thisDate.isSame(vacationStart, 'day')) {
        if (thisDate.isSame(vacationEnd, 'day')) {
          daySummary.vacation = 'one_day';
        } else {
          daySummary.vacation = 'start';
        }
      } else if (thisDate.isSame(vacationEnd, 'day')) {
        daySummary.vacation = 'end';
      } else if (thisDate.isBetween(vacationStart, vacationEnd, 'days')) {
        daySummary.vacation = 'mid';
      }
    }

    for (let conferenceObj of conferenceArray) {
      const conferenceStart = dateFromISODateTime(conferenceObj.start);
      const conferenceEnd = dateFromISODateTime(conferenceObj.end);
      if (thisDate.isSame(conferenceStart, 'day')) {
        if (thisDate.isSame(conferenceEnd, 'day')) {
          daySummary.conference = 'one_day';
        } else {
          daySummary.conference = 'start';
        }
      } else if (thisDate.isSame(conferenceEnd, 'day')) {
        daySummary.conference = 'end';
      } else if (thisDate.isBetween(conferenceStart, conferenceEnd, 'days')) {
        daySummary.conference = 'mid';
      }
    }

    result.push(daySummary);
    isoDate = nextISODate(isoDate);
  }

  return result;
};

export const caseCreateJSONPayload = (
  inputObj,
  target,
  inputReasonCancellation,
) => {
  if (inputObj) {
    return {
      calendar: inputObj.calendar || inputObj.eventOwnerId,
      target: target === 1 || target === 0 ? target : null,
      event_type: inputObj.eventType,
      orientation: null,
      case: inputObj.caseName,
      details: inputObj.caseNotes,
      notes: inputObj.schedulingNotes,
      setup: inputObj.setupNotes,
      rebook_dates: inputObj.rebookDates ? inputObj.rebookDates : [],
      attachments: attachmentsEditAPIFormat(inputObj.attachments),
      participants: {
        providers: attendeesArrayAPIFormat(inputObj.providers, inputObj),
        reps: attendeesArrayAPIFormat(inputObj.reps, inputObj),
      },
      time: {
        start: parseInt(`${inputObj.startDate}${inputObj.startTime}00`),
        end: parseInt(`${inputObj.endDate}${inputObj.endTime}00`),
      },
      location: {
        address: inputObj.locationInput,
      },
      patient: {
        name: {
          first: inputObj.patientFirst,
          last: inputObj.patientLast,
        },
        mr: inputObj.mrn,
        dob:
          inputObj.dobYear && inputObj.dobMonth && inputObj.dobDay
            ? parseInt(
                `${inputObj.dobYear}${inputObj.dobMonth}${inputObj.dobDay}`,
              )
            : null,
        patient_status: inputObj.patientStatus || 'Tentative',
        phone: inputObj.patientPhone.toString().replace(/\D+/g, ''),
        mobile_phone: inputObj.patientMobilePhone
          .toString()
          .replace(/\D+/g, ''),
        insurances: [
          {
            auth: inputObj.insuranceAuthorization01,
            co_pay: inputObj.insuranceCopay01,
            deductible: inputObj.insuranceDeductible01,
            group: inputObj.insuranceGroup01,
            policy: inputObj.insurancePolicy01,
            provider: inputObj.insuranceProvider01,
            type: inputObj.insuranceType01,
          },
          {
            auth: inputObj.insuranceAuthorization02,
            co_pay: inputObj.insuranceCopay02,
            deductible: inputObj.insuranceDeductible02,
            group: inputObj.insuranceGroup02,
            policy: inputObj.insurancePolicy02,
            provider: inputObj.insuranceProvider02,
            type: inputObj.insuranceType02,
          },
          {
            auth: '',
            co_pay: '',
            deductible: '',
            group: '',
            policy: '',
            provider: '',
            type: '',
          },
        ],
        demographics: {
          gender: inputObj.gender,
          dob:
            inputObj.dobYear && inputObj.dobMonth && inputObj.dobDay
              ? parseInt(
                  `${inputObj.dobYear}${inputObj.dobMonth}${inputObj.dobDay}`,
                )
              : null,
          height:
            inputObj.height !== 0 && inputObj.height !== null
              ? parseFloat(inputObj.height)
              : null,
          weight:
            inputObj.weight !== 0 && inputObj.weight !== null
              ? parseFloat(inputObj.weight)
              : null,
          ethnicity: inputObj.ethnicity,
        },
        supplemental: {
          email: inputObj.email,
          ssn: null,
          home: {
            line_1: inputObj.addressLine1,
            line_2: inputObj.addressLine2,
            line_3: null,
            line_4: null,
            city: inputObj.city,
            state: inputObj.state,
            zip: inputObj.zip,
            country: inputObj.country,
          },
          emergency: [
            {
              name: inputObj.emergencyName01,
              phone: inputObj.emergencyPhone01.replace(/\D+/g, ''),
            },
            {
              name: inputObj.emergencyName02,
              phone: inputObj.emergencyPhone02.replace(/\D+/g, ''),
            },
          ],
          medical: {
            diabetic: -1,
            hiv: -1,
            conditions: {
              allergies: {
                status: inputObj.allergiesStatus,
                details: inputObj.allergiesDetails,
              },
              anesthesia: {
                status: inputObj.anesthesiaStatus,
                details: inputObj.anesthesiaDetails,
              },
              comorbidities: {
                status: inputObj.comorbiditiesStatus,
                details: inputObj.comorbiditiesDetails,
              },
            },
          },
          notes: inputObj.providerNotes,
        },
        bpci_patient: inputObj.bpciId,
        estimated_discharge: 0,
        discharged: 0,
        discharged_to: null,
        discharged_to_id: null,
      },
      equipment: {
        devices: devicesArrayAPIFormat(inputObj.devices),
      },
      checklists: checklistsArrayAPIFormat(inputObj.checklists),
      checklist_cleared_linked: inputObj.checklistClearedLinked,
      patient_cleared: inputObj.patientCleared,
      codes: {
        icd: inputObj.icd.length > 0 ? inputObj.icd.join(',') : '',
        cpt: inputObj.cpt.length > 0 ? inputObj.cpt.join(',') : '',
      },
      post_op: {
        ivf: inputObj.ivf,
        ebl: inputObj.ebl,
        uop: inputObj.uop,
        dictated: inputObj.dictationCode !== '' ? 1 : 0,
        dictation_code: inputObj.dictationCode,
        notes: inputObj.postOpNotes,
      },
      patient_class: inputObj.patientClass,
      service: inputObj.service,
      laterality: inputObj.laterality,
      diagnosis: inputObj.diagnosis,
      requested: {
        by: inputObj.requestedBy,
        phone: inputObj.requestedPhone.toString().replace(/\D+/g, ''),
      },
      length_stay: inputObj.lengthStay,
      admit_prior_surgery: inputObj.admitPriorSurgery,
      preadmit_reason: inputObj.preadmitReason,
      precert_status: inputObj.precertStatus,
      authorized: {
        from: inputObj.authorizedFrom
          ? parseInt(`${inputObj.authorizedFrom}000000`)
          : null,
        to: inputObj.authorizedTo
          ? parseInt(`${inputObj.authorizedTo}000000`)
          : null,
        comments: inputObj.authorizedComments,
      },
      history_comments: inputObj.historyComments,
      reason_cancellation:
        target === 0 && inputReasonCancellation
          ? inputReasonCancellation
          : inputObj.reasonCancellation,
    };
  }
  return null;
};

export const caseEditJSONPayload = (
  inputObj,
  target,
  inputReasonCancellation,
) => {
  if (inputObj) {
    return {
      calendar: inputObj.calendar || inputObj.eventOwnerId,
      target: target === 1 || target === 0 ? target.toString() : null,
      event_id: inputObj.eventId,
      event_type: inputObj.eventType,
      case: inputObj.caseName,
      details: inputObj.caseNotes,
      notes: inputObj.schedulingNotes,
      setup: inputObj.setupNotes,
      rebook_dates: inputObj.rebookDates ? inputObj.rebookDates : [],
      attachments: attachmentsEditAPIFormat(inputObj.attachments),
      participants: {
        providers: attendeesArrayAPIFormat(inputObj.providers, inputObj),
        reps: attendeesArrayAPIFormat(inputObj.reps, inputObj),
      },
      time: {
        start: `${inputObj.startDate}${inputObj.startTime}00`,
        end: `${inputObj.endDate}${inputObj.endTime}00`,
      },
      location: {
        reassign: inputObj.reassign ? inputObj.reassign : 0,
        address: inputObj.locationInput,
      },
      patient: {
        name: {
          first: inputObj.patientFirst,
          last: inputObj.patientLast,
        },
        mr: inputObj.mrn,
        dob:
          inputObj.dobYear && inputObj.dobMonth && inputObj.dobDay
            ? parseInt(
                `${inputObj.dobYear}${inputObj.dobMonth}${inputObj.dobDay}`,
              )
            : null,
        patient_status: inputObj.patientStatus || 'Tentative',
        phone: inputObj.patientPhone.toString().replace(/\D+/g, ''),
        mobile_phone: inputObj.patientMobilePhone
          .toString()
          .replace(/\D+/g, ''),
        insurances: [
          {
            auth: inputObj.insuranceAuthorization01,
            co_pay: inputObj.insuranceCopay01,
            deductible: inputObj.insuranceDeductible01,
            group: inputObj.insuranceGroup01,
            policy: inputObj.insurancePolicy01,
            provider: inputObj.insuranceProvider01,
            type: inputObj.insuranceType01,
          },
          {
            auth: inputObj.insuranceAuthorization02,
            co_pay: inputObj.insuranceCopay02,
            deductible: inputObj.insuranceDeductible02,
            group: inputObj.insuranceGroup02,
            policy: inputObj.insurancePolicy02,
            provider: inputObj.insuranceProvider02,
            type: inputObj.insuranceType02,
          },
          {
            auth: '',
            co_pay: '',
            deductible: '',
            group: '',
            policy: '',
            provider: '',
            type: '',
          },
        ],
        demographics: {
          gender: inputObj.gender,
          dob:
            inputObj.dobYear && inputObj.dobMonth && inputObj.dobDay
              ? parseInt(
                  `${inputObj.dobYear}${inputObj.dobMonth}${inputObj.dobDay}`,
                )
              : null,
          height:
            inputObj.height !== 0 && inputObj.height !== null
              ? parseFloat(inputObj.height)
              : null,
          weight:
            inputObj.weight !== 0 && inputObj.weight !== null
              ? parseFloat(inputObj.weight)
              : null,
          ethnicity: inputObj.ethnicity,
        },
        supplemental: {
          email: inputObj.email,
          home: {
            line_1: inputObj.addressLine1,
            line_2: inputObj.addressLine2,
            city: inputObj.city,
            state: inputObj.state,
            zip: inputObj.zip,
            country: inputObj.country,
          },
          emergency: [
            {
              name: inputObj.emergencyName01,
              phone: inputObj.emergencyPhone01.replace(/\D+/g, ''),
            },
            {
              name: inputObj.emergencyName02,
              phone: inputObj.emergencyPhone02.replace(/\D+/g, ''),
            },
          ],
          medical: {
            conditions: {
              allergies: {
                status: inputObj.allergiesStatus,
                details: inputObj.allergiesDetails
                  ? inputObj.allergiesDetails
                  : null,
              },
              anesthesia: {
                status: inputObj.anesthesiaStatus,
                details: inputObj.anesthesiaDetails
                  ? inputObj.anesthesiaDetails
                  : null,
              },
              comorbidities: {
                status: inputObj.comorbiditiesStatus,
                details: inputObj.comorbiditiesDetails
                  ? inputObj.comorbiditiesDetails
                  : null,
              },
            },
            allergies: {
              other: inputObj.allergiesDetails,
            },
            anesthesia: {
              type: -1,
              other: inputObj.anesthesiaDetails
                ? inputObj.anesthesiaDetails
                : null,
              redhead: -1,
            },
          },
          notes: inputObj.providerNotes,
        },
        bpci_patient: inputObj.bpciId,
        arrival_request: inputObj.arrivalRequest
          ? parseInt(`${inputObj.startDate}${inputObj.arrivalRequest}00`)
          : -1,
      },
      equipment: {
        devices: devicesArrayEditAPIFormat(inputObj.devices),
      },
      checklists: checklistsArrayEditAPIFormat(inputObj.checklists),
      checklist_cleared_linked: inputObj.checklistClearedLinked,
      patient_cleared: inputObj.patientCleared,
      codes: {
        icd: inputObj.icd.length > 0 ? inputObj.icd.join(',') : '',
        cpt: inputObj.cpt.length > 0 ? inputObj.cpt.join(',') : '',
      },
      post_op: {
        ivf: inputObj.ivf,
        ebl: inputObj.ebl,
        uop: inputObj.uop,
        dictated: inputObj.dictationCode !== '' ? 1 : 0,
        dictation_code: inputObj.dictationCode,
        notes: inputObj.postOpNotes,
      },
      patient_class: inputObj.patientClass,
      service: inputObj.service,
      laterality: inputObj.laterality,
      diagnosis: inputObj.diagnosis,
      requested: {
        by: inputObj.requestedBy,
        phone: inputObj.requestedPhone.toString().replace(/\D+/g, ''),
      },
      length_stay: inputObj.lengthStay,
      admit_prior_surgery: inputObj.admitPriorSurgery,
      preadmit_reason: inputObj.preadmitReason,
      precert_status: inputObj.precertStatus,
      authorized: {
        from: inputObj.authorizedFrom
          ? parseInt(`${inputObj.authorizedFrom}000000`)
          : null,
        to: inputObj.authorizedTo
          ? parseInt(`${inputObj.authorizedTo}000000`)
          : null,
        comments: inputObj.authorizedComments,
      },
      history_comments: inputObj.historyComments,
      reason_cancellation:
        typeof inputReasonCancellation != 'undefined' &&
        inputReasonCancellation !== null
          ? inputReasonCancellation
          : inputObj.reasonCancellation,
    };
  }
  return null;
};

export const devicesArrayAPIFormat = inputDevicesArr => {
  let outputArr = [];

  inputDevicesArr.forEach(device => {
    if (device.name) {
      outputArr.push({
        name: device.name,
        size: device.size,
      });
    }
  });

  return outputArr;
};

export const devicesArrayEditAPIFormat = inputDevicesArr => {
  let outputArr = [];

  inputDevicesArr.forEach(device => {
    if (device.name) {
      outputArr.push({
        name: device.name,
        size: device.size,
        id: '',
      });
    }
  });

  return outputArr;
};

export const attendeesArrayAPIFormat = (inputAttendeesArr, inputStateObj) => {
  let outputArr = [];

  if (
    inputStateObj.mode === 'Create' &&
    inputAttendeesArr == inputStateObj.providers
  ) {
    outputArr.push({
      id: inputStateObj.calendar
        ? parseInt(inputStateObj.calendar)
        : parseInt(inputStateObj.eventOwnerId),
      source: {
        default: 0,
        location: 0,
        manual: 0,
        other: 0,
        owner: 1,
        procedure: 0,
      },
    });
  }

  inputAttendeesArr.forEach(attendee => {
    outputArr.push({
      id: parseInt(attendee.id),
      source: attendee.source,
    });
  });

  return outputArr;
};

export const checklistsArrayAPIFormat = inputChecklistsArr => {
  let outputArr = [];

  inputChecklistsArr.forEach(checklistGroup => {
    let checklistGroupObj = {
      name: checklistGroup.name ? checklistGroup.name : 'Untitled Checklist',
      original_id: checklistGroup.original_id ? checklistGroup.original_id : 0,
      checklist: [],
    };

    checklistGroup.checklist.forEach(checklistItem => {
      const checklistItemObj = {
        name: checklistItem.name
          ? checklistItem.name
          : 'Unnamed Checklist Item',
        status: checklistItem.status,
      };
      checklistGroupObj.checklist.push(checklistItemObj);
    });

    outputArr.push(checklistGroupObj);
  });

  return outputArr;
};

function checklistsArrayEditAPIFormat(inputChecklistsArr) {
  let outputArr = [];

  inputChecklistsArr.forEach(checklistGroup => {
    let checklistGroupObj = {
      id: checklistGroup.id,
      name: checklistGroup.name ? checklistGroup.name : 'Untitled Checklist',
      original_id: checklistGroup.original_id ? checklistGroup.original_id : 0,
      checklist: [],
    };

    if (checklistGroup.hasOwnProperty('new')) {
      delete checklistGroupObj.id;
    }

    checklistGroup.checklist.forEach(checklistItem => {
      let checklistItemObj = {
        id: checklistItem.id,
        name: checklistItem.name
          ? checklistItem.name
          : 'Unnamed Checklist Item',
        status: checklistItem.status,
      };

      if (checklistItem.hasOwnProperty('new')) {
        delete checklistItemObj.id;
      }

      checklistGroupObj.checklist.push(checklistItemObj);
    });

    outputArr.push(checklistGroupObj);
  });

  return outputArr;
}

export const attachmentsEditAPIFormat = inputAttachmentsArr => {
  if (inputAttachmentsArr[0]) {
    let outputArr = [];

    inputAttachmentsArr.forEach(attachment => {
      const $FileObject = {
        file: attachment.id || attachment.file.toString(),
        phi: attachment.phi,
      };
      outputArr.push($FileObject);
    });

    return outputArr;
  } else {
    return [];
  }
};

export const getAttachmentThumbnail = (file, baseURL) => {
  if (file.type) {
    switch (file.type) {
      case 'Image':
        return file.thumb.slice(0, -4) + '_128' + file.thumb.slice(-4);
      case 'PDF':
        return `${baseURL}/assets/pic/Overlay-PDF-128.png`;
      case 'ZIP':
        return `${baseURL}/assets/pic/Overlay-ZIP-128.png`;
      case 'DOC':
        return `${baseURL}/assets/pic/Overlay-DOC-128.png`;
      case 'DOT':
        return `${baseURL}/assets/pic/Overlay-DOC-128.png`;
      case 'DOTX':
        return `${baseURL}/assets/pic/Overlay-DOC-128.png`;
      case 'XLT':
        return `${baseURL}/assets/pic/Overlay-XLS-128.png`;
      case 'XLTX':
        return `${baseURL}/assets/pic/Overlay-XLS-128.png`;
      case 'POT':
        return `${baseURL}/assets/pic/Overlay-PPT-128.png`;
      case 'POTX':
        return `${baseURL}/assets/pic/Overlay-PPT-128.png`;
      case 'DOCX':
        return `${baseURL}/assets/pic/Overlay-DOC-128.png`;
      case 'XLS':
        return `${baseURL}/assets/pic/Overlay-XLS-128.png`;
      case 'XLSX':
        return `${baseURL}/assets/pic/Overlay-XLS-128.png`;
      case 'PPT':
        return `${baseURL}/assets/pic/Overlay-PPT-128.png`;
      case 'PPTX':
        return `${baseURL}/assets/pic/Overlay-PPT-128.png`;
      case 'TXT':
        return `${baseURL}/assets/pic/Overlay-TXT-128.png`;
      case 'RTF':
        return `${baseURL}/assets/pic/Overlay-RTF-128.png`;
      case 'Video':
        return file.thumb
          ? file.thumb
          : `${baseURL}/assets/pic/Overlay-Video-128.png`;
      case 'DICOM':
        return `${baseURL}/assets/pic/Overlay-DICOM-Classic-128.png`;
      case 'DICOMZIP':
        return `${baseURL}/assets/pic/Overlay-DICOM-Classic-128.png`;
      case 'HelloSign':
        return `${baseURL}/assets/pic/Overlay-HelloSign-128.png`;
      case 'hellosign':
        return `${baseURL}/assets/pic/Overlay-HelloSign-128.png`;
      default:
        return `${baseURL}/assets/pic/file_unknown_128.png`;
    }
  }
  return `${baseURL}/assets/files/file_unknown_128.png`;
};
