import React, {useContext, useEffect} from 'react';
import {Context as AuthContext} from '../context/AuthContext';
import {Context as DocboxContext} from '../context/DocboxContext';
import {Context as GlobalContext} from '../context/GlobalContext';
import {Context as EventSettingsContext} from '../context/EventSettingsContext';
import {Context as ContactsContext} from '../context/ContactsContext';
import {Context as ScheduleContext} from '../context/ScheduleContext';
import DocboxHeader from './DocboxHeader';
import './Docbox.scss';
import DocboxModuleContainer from './DocboxModuleContainer';
import {
  AlomEncodeType2,
  findContactByUserID,
  hasDSLicense,
} from '../libraries/helpers';
import {timeAgoFromNow} from '../libraries/moment';
import {
  agendaURI,
  calendarDeleteURI,
  calendarMonthURI,
  draftsURI,
  getSuggestedTimesURI,
  getUserPreferencesURI,
  sendPatientIntakeURI,
} from '../dataAccess/apiEndpoints';
import {
  attendeesHash,
  billingCodesHash,
  locationsHash,
  preOpChecklistsHash,
  proceduresHash,
} from '../dataAccess/preferencesHash';

const Docbox = () => {
  const {state: authState} = useContext(AuthContext);
  const {
    state: docboxState,
    closeDocbox,
    addDevice,
    addProvider,
    addRep,
    addChecklistGroup,
    deleteChecklistGroup,
    deleteCalendarEvent,
    updateFormFieldValue,
    didPatientClearLinkedChecklists,
    getSuggestedTimes,
    showDocboxAside,
  } = useContext(DocboxContext);
  const {state: eventSettingsState, getPreferences} =
    useContext(EventSettingsContext);
  const {state: contactsState} = useContext(ContactsContext);
  const {
    state: scheduleState,
    getAgenda,
    getMonth,
    getDrafts,
  } = useContext(ScheduleContext);
  const {
    state: globalState,
    setNotification,
    openAlert,
    closeAlert,
  } = useContext(GlobalContext);

  // add default device row on case create/edit
  useEffect(() => {
    if (docboxState.mode !== 'View' && !docboxState.devices.length) {
      addDevice();
    }
  }, [docboxState.mode, docboxState.devices]);

  // add default attendees on case create
  useEffect(() => {
    if (docboxState.mode === 'Create') {
      const source = {
        default: 1,
        location: 0,
        manual: 0,
        other: 0,
        owner: 0,
        procedure: 0,
      };

      if (eventSettingsState.providers.length) {
        eventSettingsState.providers.forEach(providerId => {
          const providerFoundInContacts = findContactByUserID(
            providerId,
            contactsState.contacts,
          );
          if (providerFoundInContacts) {
            addProvider(providerFoundInContacts, source);
          }
        });
      }
      if (eventSettingsState.reps.length) {
        eventSettingsState.reps.forEach(repId => {
          const repFoundInContacts = findContactByUserID(
            repId,
            contactsState.contacts,
          );
          if (repFoundInContacts) {
            addRep(repFoundInContacts, source);
          }
        });
      }
    }
  }, []);

  //if there are no checklists and checklistClearedLinked is set to Yes, reset checklistClearedLinked & patientCleared
  useEffect(() => {
    if (
      docboxState.checklists &&
      docboxState.checklists.length === 0 &&
      docboxState.checklistClearedLinked === 'Yes'
    ) {
      updateFormFieldValue('checklistClearedLinked', 'No');
      updateFormFieldValue('patientCleared', 'N/A');
    }
  }, [docboxState.checklists, docboxState.checklistClearedLinked]);

  //add default checklists on case create
  useEffect(() => {
    if (docboxState.mode === 'Create' && eventSettingsState.checklists.length) {
      if (docboxState.eventType === 'Surgery') {
        eventSettingsState.checklists.forEach(checklistGroup => {
          if (checklistGroup.default && checklistGroup.default == 1) {
            addChecklistGroup(checklistGroup);
            updateFormFieldValue('checklistClearedLinked', 'Yes');
            updateFormFieldValue('patientCleared', 'No');
          }
        });
      } else {
        eventSettingsState.checklists.forEach(checklistGroup => {
          if (
            checklistGroup.default &&
            checklistGroup.default == 1 &&
            checklistGroup.id
          ) {
            deleteChecklistGroup(checklistGroup.id);
          }
        });
      }
    }
  }, [docboxState.mode, docboxState.eventType, eventSettingsState.checklists]);

  // if event type is Surgery, get Suggested Times
  useEffect(() => {
    if (
      docboxState.mode !== 'View' &&
      docboxState.eventType === 'Surgery' &&
      docboxState.startDate &&
      docboxState.eventOwnerId
    ) {
      const url = `${globalState.config.ABS_BASE_URL}${getSuggestedTimesURI}`;
      getSuggestedTimes(
        url,
        parseInt(docboxState.startDate),
        AlomEncodeType2(docboxState.eventOwnerId),
      );
    }
  }, [docboxState.eventType, docboxState.startDate, docboxState.eventOwnerId]);

  // set patient cleared anytime checklist cleared or checklists changes
  useEffect(() => {
    if (docboxState.checklistClearedLinked === 'Yes') {
      didPatientClearLinkedChecklists(docboxState.checklists);
    }
  }, [docboxState.checklistClearedLinked, docboxState.checklists]);

  // show aside for patient intake
  useEffect(() => {
    if (
      showPatientIntake(
        docboxState.eventType,
        docboxState.survey,
        authState.dsLicenses,
      )
    ) {
      showDocboxAside(true);
    }
  }, [docboxState.eventType, docboxState.survey, authState.dsLicenses]);

  //get case owner's prefrences list
  useEffect(() => {
    if (docboxState.eventOwnerId && globalState.config.ABS_BASE_URL) {
      const preferencesHashArray = [
        locationsHash,
        billingCodesHash,
        proceduresHash,
        preOpChecklistsHash,
        attendeesHash,
      ];
      const url = `${globalState.config.ABS_BASE_URL}${getUserPreferencesURI}`;
      getPreferences(url, 0, docboxState.eventOwnerId, preferencesHashArray);
    }
  }, [docboxState.eventOwnerId, globalState.config.ABS_BASE_URL]);

  return (
    <>
      <DocboxOverlay />
      <div
        id="docbox"
        className={`flex direction-column${
          docboxState.mode === 'View' && docboxState.showAside
            ? ' show-aside'
            : ''
        }`}>
        <DocboxHeader />
        <DocboxBody />
        {docboxState.mode === 'Edit' && (
          <button
            type="button"
            id="docbox-delete-case"
            className="btn-warning"
            onClick={() => {
              const handleDelete = () => {
                const url = `${globalState.config.ABS_BASE_URL}${calendarDeleteURI}`;
                let userIdForDelete = scheduleState.targetUserId
                  ? scheduleState.targetUserId
                  : authState.userId;
                deleteCalendarEvent(
                  url,
                  docboxState.eventId,
                  userIdForDelete.toString(),
                ).then(response => {
                  const getAgendaURL = `${globalState.config.ABS_BASE_URL}${agendaURI}`;
                  const getMonthURL = `${globalState.config.ABS_BASE_URL}${calendarMonthURI}`;
                  const getDraftsURL = `${globalState.config.ABS_BASE_URL}${draftsURI}`;
                  getDrafts(getDraftsURL, scheduleState.targetUserId);
                  getAgenda(
                    getAgendaURL,
                    scheduleState.targetUserId,
                    scheduleState.selectedCalendarDate,
                  );
                  getMonth(
                    getMonthURL,
                    scheduleState.selectedCalendarMonth,
                    scheduleState.targetUserId,
                  );
                  if (
                    response.status === 200 &&
                    response.data.deleted &&
                    response.data.deleted.length > 0
                  ) {
                    closeAlert();
                    closeDocbox();
                    setNotification('Case deleted successfully.');
                  } else {
                    const alertBody = () => {
                      return (
                        <p>
                          Please contact{' '}
                          <a
                            href="/web/contact"
                            target="_blank"
                            rel="noopener noreferrer">
                            VELYS Insights Customer Support
                          </a>
                          .
                        </p>
                      );
                    };

                    openAlert({
                      title: 'Error Deleting Case',
                      body: alertBody(),
                      cancelText: 'Close',
                    });
                  }
                });
              };

              openAlert({
                body: 'Are you sure you want to delete this Event?',
                okText: 'Delete',
                okHandler: handleDelete,
              });
            }}>
            Delete This Event
          </button>
        )}
      </div>
    </>
  );
};

const DocboxBody = () => {
  const {state: docboxState, setDocboxActiveNavItem} =
    useContext(DocboxContext);

  const handleDocboxScroll = event => {
    let modulesTopsObj = {};
    const currentPosition = event.target.scrollTop;
    const bottomOfScrollContent =
      event.target.scrollHeight - event.target.clientHeight;
    const modulesArray = document.getElementsByClassName('docbox-nav-target');

    Array.prototype.forEach.call(modulesArray, module => {
      const moduleId = module.id;
      const moduleTop = module.offsetTop;
      modulesTopsObj[moduleId] = moduleTop;
    });

    Object.keys(modulesTopsObj).forEach(key => {
      if (currentPosition >= modulesTopsObj[key]) {
        setDocboxActiveNavItem(key);
      } else if (currentPosition >= bottomOfScrollContent) {
        const lastNavItem = document.querySelectorAll(
          '.docbox-nav-target:last-child',
        )[0].id;
        setDocboxActiveNavItem(lastNavItem);
      }
    });
  };

  return (
    <>
      <div
        id="docbox-body"
        className="flex align-start"
        onScroll={handleDocboxScroll}>
        <DocboxNav />
        <DocboxModuleContainer />
        {docboxState.mode === 'View' && docboxState.showAside && (
          <DocboxAside />
        )}
      </div>
    </>
  );
};

const DocboxNav = () => {
  const {state: docboxState, setDocboxActiveNavItem} =
    useContext(DocboxContext);

  useEffect(() => {
    let firstNavItem;
    if (
      docboxState.eventType === 'Conference' ||
      docboxState.eventType === 'Vacation'
    ) {
      firstNavItem = document.querySelectorAll('.docbox-nav-target')[0].id;
    } else {
      firstNavItem = document.querySelectorAll(
        '.docbox-nav-target:first-child',
      )[0].id;
    }
    setDocboxActiveNavItem(firstNavItem);
  }, []);

  return (
    <div id="docbox-nav" className="no-select">
      {Object.keys(docboxState.modules).map((node, index) => {
        return typeof docboxState.modules[node] === 'object' &&
          docboxState.modules[node] !== null ? (
          <DocboxNavItem
            key={index}
            title={node}
            children={docboxState.modules[node]}
          />
        ) : (
          <DocboxNavItem key={index} title={node} />
        );
      })}
    </div>
  );
};

const DocboxNavItem = ({title, children}) => {
  const {state: docboxState, setDocboxActiveNavItem} =
    useContext(DocboxContext);

  const formatTitle = title => {
    return title.split('_').join(' ');
  };

  const handleNavItemClick = event => {
    let target;
    if (event.target.id.includes('-h2')) {
      target = event.target.id.slice(0, -3);
    } else {
      target = event.target.id;
    }
    let modulesTopsObj = {};
    const modulesArray = document.getElementsByClassName('docbox-nav-target');

    Array.prototype.forEach.call(modulesArray, module => {
      const moduleId = module.id;
      const moduleTop = module.offsetTop;
      modulesTopsObj[moduleId] = moduleTop;
    });

    if (target in modulesTopsObj) {
      const docboxBody = document.getElementById('docbox-body');
      const targetOffset = modulesTopsObj[target];
      const currentPosition = docboxBody.scrollTop;
      const scrollDiff = targetOffset - currentPosition;

      if (scrollDiff !== 0) {
        setDocboxActiveNavItem(target);
        docboxBody.scrollTo({left: 0, top: targetOffset, behavior: 'smooth'});
      }
    }
  };

  return children ? (
    <>
      <h1
        id={`docbox-module-${title}`}
        className={
          docboxState.activeNavItem &&
          docboxState.activeNavItem === `docbox-module-${title}`
            ? 'active'
            : ''
        }
        onClick={handleNavItemClick}>
        {formatTitle(title)}
      </h1>
      {Object.keys(children).map((key, index) =>
        key !== 'availability' ? (
          <h2
            key={index}
            id={`docbox-submodule-${key}-h2`}
            className={
              docboxState.activeNavItem &&
              docboxState.activeNavItem === `docbox-submodule-${key}`
                ? 'active'
                : ''
            }
            onClick={handleNavItemClick}>
            {formatTitle(key)}
          </h2>
        ) : (
          ''
        ),
      )}
    </>
  ) : (
    <h1
      id={`docbox-module-${title}`}
      className={
        docboxState.activeNavItem &&
        docboxState.activeNavItem === `docbox-module-${title}`
          ? 'active'
          : ''
      }
      onClick={handleNavItemClick}>
      {formatTitle(title)}
    </h1>
  );
};

const DocboxAside = () => {
  const {state: docboxState, sendPatientIntake} = useContext(DocboxContext);
  const {
    state: globalState,
    setNotification,
    openAlert,
  } = useContext(GlobalContext);
  const {state: authState} = useContext(AuthContext);

  const renderPatientIntake = () => {
    const intakeStatus =
      typeof docboxState.survey.status != 'undefined' &&
      docboxState.survey.status
        ? docboxState.survey.status
        : 0;
    const intakeCount =
      typeof docboxState.survey.count != 'undefined' && docboxState.survey.count
        ? docboxState.survey.count
        : 0;
    const intakeTimestamp =
      typeof docboxState.survey.timestamp != 'undefined' &&
      docboxState.survey.timestamp
        ? docboxState.survey.timestamp
        : null;

    let intakeDisabled = '';
    let sendIntake = '';

    if (intakeStatus == 2) {
      intakeDisabled = ' disabled';
      sendIntake = 'Completed';
    } else if (intakeStatus == 1 || intakeStatus == 0) {
      sendIntake = intakeStatus == 1 && intakeCount > 0 ? 'Resend' : 'Send';
    }

    return (
      <div className="aside-module">
        <p>Patient Intake</p>
        {intakeTimestamp && intakeStatus == 1 && (
          <p>Last sent: {timeAgoFromNow(intakeTimestamp)}</p>
        )}
        <button
          type="button"
          className={`btn-primary${intakeDisabled}`}
          onClick={event => {
            if (
              docboxState.patientFirst &&
              docboxState.patientLast &&
              (docboxState.patientMobilePhone ||
                docboxState.patientPhone ||
                docboxState.email)
            ) {
              const uri = sendPatientIntakeURI.replace(
                '{eventid}',
                docboxState.eventId.toString(),
              );
              const url = `${globalState.config.ABS_BASE_URL}${uri}`;

              event.target.classList.add('disabled');
              event.target.innerHTML = 'Wait...';

              sendPatientIntake(url, docboxState.eventId).then(response => {
                console.log('sendPatientIntake response', response);
                if (response && response.status && response.status === 200) {
                  event.target.innerHTML = 'Sent';
                  setNotification('Patient Intake sent.');
                } else {
                  event.target.innerHTML = 'Error';
                  setNotification('Error sending Patient Intake.');
                }
              });
            } else {
              let missingItems = [];
              if (!docboxState.patientFirst) {
                missingItems.push('first name');
              }
              if (!docboxState.patientLast) {
                missingItems.push('last name');
              }
              if (
                !(
                  docboxState.patientMobilePhone ||
                  docboxState.patientPhone ||
                  docboxState.email
                )
              ) {
                missingItems.push('phone number or email');
              }
              let alertBody = '';
              if (missingItems.length > 2) {
                let last = missingItems.pop();
                alertBody = missingItems.join(', ');
                alertBody += ', and ' + last;
              } else {
                alertBody = missingItems.join(' and ');
              }

              return openAlert({
                title: 'Add Patient Info to Send Intake',
                body: `Update the case with patient ${alertBody}.`,
                cancelText: 'Close',
              });
            }
          }}>
          {sendIntake}
        </button>
      </div>
    );
  };

  return (
    <div id="docbox-aside">
      {showPatientIntake(
        docboxState.eventType,
        docboxState.survey,
        authState.dsLicenses,
      ) && renderPatientIntake()}
    </div>
  );
};

const DocboxOverlay = () => {
  return <div id="docbox-overlay"></div>;
};

const showPatientIntake = (eventType, surveyObj, licensesArray) => {
  //check license 27 for 'IS - Can Send Intakes'
  return eventType &&
    eventType !== 'Conference' &&
    eventType != 'Vacation' &&
    typeof surveyObj.status != 'undefined' &&
    Array.isArray(licensesArray) &&
    hasDSLicense(licensesArray, 27)
    ? true
    : false;
};

export default Docbox;
