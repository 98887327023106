export const formatFullName = (first, last) => {
  return `${first}${first && last && ' '}${last}`;
};

export const AlomEncodeType2 = Number => {
  if (typeof Number === 'number' && Number % 1 == 0 && Number > 0) {
    var NumberArray = Number.toString().split('');
    var SumOfNumber = eval(NumberArray.join('+'));
    var SumOfLength = Number.toString().length;
    while (SumOfNumber > 9) {
      var Digit = SumOfNumber.toString().split('');
      SumOfNumber = eval(Digit.join('+'));
    }
    while (SumOfLength > 9) {
      var Digit = SumOfLength.toString().split('');
      SumOfLength = eval(Digit.join('+'));
    }
    return Number + '' + SumOfLength + '' + SumOfNumber;
  } else {
    return false;
  }
};

export const AlomDecodeType2 = encodedUserID => {
  // Can we convert the input into an integer?
  if (!Number.isInteger(parseInt(encodedUserID))) {
    return 0;
  }

  // Removed last 2 chars from the right, and convert back to an integer.
  const strippedUserID = parseInt(encodedUserID.slice(0, -2));

  //Is the New Value a Positive Integer?
  if (strippedUserID > 0) {
    //Does the Original Value equal to the encoded form of the New Value.
    if (AlomEncodeType2(strippedUserID) === encodedUserID) {
      return strippedUserID;
    }
  }

  return 0;
};

export const docboxCaseHasPatient = eventObj => {
  return typeof eventObj.patient == 'object' && !Array.isArray(eventObj.patient)
    ? true
    : false;
};

export const convertHeight = (inputHeight, convertTo) => {
  if (convertTo === 'Metric') {
    // expects US Customary (inches), converts to Metric (cm)
    return parseFloat((inputHeight * 2.54).toFixed(2));
  }

  // default expects Metric (cm), converts to US Customary (inches)
  return Math.round(parseFloat(inputHeight) / 2.54);
};

export const convertWeight = (inputWeight, convertTo) => {
  if (convertTo === 'Metric') {
    // expects US Customary (lbs), converts to Metric (kg)
    return parseFloat((inputWeight / 2.20462).toFixed(2));
  }

  // default expects Metric (kg), converts to US Customary (lbs)
  const weightLbs = parseFloat(inputWeight) * 2.20462;
  const weightFinal = 0.5 * Math.floor(weightLbs * 2.0 + 0.5);
  return parseFloat(weightFinal.toFixed(2));
};

export const convertInchesToFtIn = inputInches => {
  let outputArray = ['', ''],
    newFt = parseInt(parseFloat(inputInches) / 12),
    newIn = Math.round(parseFloat(inputInches) % 12);

  if (newIn == 12) {
    newFt = newFt + 1;
    newIn = 0;
  }
  outputArray[0] = newFt;
  outputArray[1] = newIn;
  return outputArray;
};

// input height and weight must be in metric
export const calculateBMI = (height, weight) => {
  return parseFloat((weight / Math.pow(height, 2)) * 10000).toFixed(1);
};

export const formatEthnicity = inputEthnicity => {
  switch (inputEthnicity) {
    case '':
      return 'Not Specified';
    case 'AustralisIndigenae/Australoid':
      return 'Aboriginal Australian';
    case 'EoropeusAlbescens/Caucasoid':
      return 'Caucasian / White / Middle Eastern';
    case 'AfricanusNegreus/Negroid':
      return 'African / African American / Black';
    case 'AsiaticusFucus/Mongoloid':
      return 'Asian / Indian / Eskimo';
    case 'AsiaticusFucus/Pacificoid':
      return 'Native Hawaiian / Pacific Islander';
    case 'AmericanusRubescens':
      return 'Native American / Alaska Native';
    case 'AmericanusRubescens/Latinoid':
      return 'Hispanic / Latino';
    case 'Multiracial':
      return 'Multiracial';
    case 'Unknown':
      return 'Unknown';
    default:
      return inputEthnicity;
  }
};

export const formatAttendeeStatus = inputStatus => {
  if (inputStatus == 'Unconfirmed') {
    return 'Invited';
  } else if (inputStatus == 'Confirmed') {
    return 'Confirmed';
  } else if (inputStatus == 'Declined' || inputStatus == 'Cancelled') {
    return 'Declined';
  } else {
    return '';
  }
};

export const formatPhoneNumber = inputString => {
  const phoneNumber = inputString ? inputString.replace(/\D/g, '') : '';
  if (phoneNumber && parseInt(phoneNumber) > 0) {
    if (phoneNumber.length > 10) {
      return (
        phoneNumber.slice(0, 1) +
        '-' +
        phoneNumber.slice(1, 4) +
        '-' +
        phoneNumber.slice(4, 7) +
        '-' +
        phoneNumber.slice(7)
      );
    } else if (phoneNumber.length > 9) {
      return (
        '(' +
        phoneNumber.slice(0, 3) +
        ') ' +
        phoneNumber.slice(3, 6) +
        '-' +
        phoneNumber.slice(6)
      );
    } else if (phoneNumber.length > 6) {
      return phoneNumber.slice(0, 3) + '-' + phoneNumber.slice(3);
    } else {
      return phoneNumber;
    }
  } else {
    return inputString;
  }
};

export const isBPCIPatient = bpciStatus => {
  return bpciStatus == 1 ? 'Yes' : 'No';
};

export const sanitizeString = input => {
  if (input && typeof input === 'string') {
    return input
      .replace(/\</g, '&lt;')
      .replace(/\>/g, '&gt;')
      .replace(/\(/g, '&#40;')
      .replace(/\)/g, '&#41;');
  }
  return false;
};

export const parseSanitizedString = input => {
  if (input && typeof input === 'string') {
    return input
      .replace(/\&lt;/g, '<')
      .replace(/\&gt;/g, '>')
      .replace(/\&#40;/g, '(')
      .replace(/\&#41;/g, ')');
  }
  return '';
};

export const findContactByUserID = (inputUserId, contactArray) => {
  if (contactArray && Array.isArray(contactArray)) {
    for (let index in contactArray) {
      if (
        'id' in contactArray[index] &&
        contactArray[index].id == inputUserId
      ) {
        return contactArray[index];
        break;
      }
    }
  }
  return null;
};

export const determineAvailabilityHour = index => {
  switch (index) {
    case 0:
      return '0000';
    case 4:
      return '0100';
    case 8:
      return '0200';
    case 12:
      return '0300';
    case 16:
      return '0400';
    case 20:
      return '0500';
    case 24:
      return '0600';
    case 28:
      return '0700';
    case 32:
      return '0800';
    case 36:
      return '0900';
    case 40:
      return '1000';
    case 44:
      return '1100';
    case 48:
      return '1200';
    case 52:
      return '1300';
    case 56:
      return '1400';
    case 60:
      return '1500';
    case 64:
      return '1600';
    case 68:
      return '1700';
    case 72:
      return '1800';
    case 76:
      return '1900';
    case 80:
      return '2000';
    case 84:
      return '2100';
    case 88:
      return '2200';
    case 92:
      return '2300';
  }
};

export const isIndustryRep = userType => {
  return userType && userType == 2 ? true : false;
};

export const isPHIConsented = phiConsent => {
  return phiConsent && phiConsent == 1 ? true : false;
};

// Input: array of users who have given the logged in user shared schedule access, and the user ID of the case owner
export const hasSharedCalendarPermission = (
  sharedScheduleArray,
  caseOwnerId,
) => {
  if (Array.isArray(sharedScheduleArray) && parseInt(caseOwnerId)) {
    for (let i = 0; i < sharedScheduleArray.length; i++) {
      if (
        'id' in sharedScheduleArray[i] &&
        sharedScheduleArray[i].id == caseOwnerId
      ) {
        return true;
      }
    }
  }
  return false;
};

// is the user you are currently viewing as authorized to edit the case? (i.e. a provider attendee)
export const isAuthorizedEditor = (providersArray, userId) => {
  if (Array.isArray(providersArray) && parseInt(userId)) {
    for (let i = 0; i < providersArray.length; i++) {
      if ('id' in providersArray[i] && providersArray[i].id == userId) {
        return true;
      }
    }
  }
  return false;
};

export const hasDSLicense = (licenseArray, licenseKeyID) => {
  if (licenseKeyID && Array.isArray(licenseArray) && licenseArray.length > 0) {
    for (let i = 0; i < licenseArray.length; i++) {
      if (licenseArray[i].key && licenseArray[i].key === licenseKeyID) {
        return licenseArray[i].enabled;
      }
    }
  }
  return;
};

export const hasPracticeAdminAccess = (
  practicePermissionsArray,
  practiceId,
) => {
  const practiceIdTemp = parseInt(practiceId);
  if (
    practiceIdTemp > 0 &&
    Array.isArray(practicePermissionsArray) &&
    practicePermissionsArray.length > 0
  ) {
    for (let i = 0; i < practicePermissionsArray.length; i++) {
      if (
        practicePermissionsArray[i].id &&
        practicePermissionsArray[i].id === practiceIdTemp
      ) {
        return practicePermissionsArray[i].permission === 3 ? true : false;
      }
    }
  }
  return;
};

export const handleLinkClick = inputEndpoint => {
  return window.location.assign(inputEndpoint);
};

// build the query params string
export const buildQueryParams = params =>
  Object.keys(params)
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    .join('&');

export const getCookie = cname => {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      let value = c.substring(name.length, c.length);
      return value;
    }
  }
  return '';
};

export const setPrintOrientation = () => {
  const style = document.createElement('style');
  style.innerHTML = `@page {size: landscape !important}`;
  style.id = 'page-orientation';
  document.head.appendChild(style);
};

export const removePrintOrientation = () => {
  const child = document.getElementById('page-orientation');
  child.parentNode.removeChild(child);
};
