import React, {useContext, useEffect} from 'react';
import {Context as PDContext} from '../../../context/PracticeDashboardContext';
import {Context as GlobalContext} from '../../../context/GlobalContext';
import {getAvailabilityTimesURI} from '../../../dataAccess/apiEndpoints';
import WeeklyCalendar from '../WeeklyCalendar/WeeklyCalendar';

const AvailabilityTimes = ({partialDay}) => {
  const {state: pdState, getAvailabilityTimes} = useContext(PDContext);
  const {state: globalState} = useContext(GlobalContext);

  useEffect(() => {
    if (pdState.physician && globalState.config.ABS_BASE_URL) {
      const url = `${globalState.config.ABS_BASE_URL}${getAvailabilityTimesURI}`;
      getAvailabilityTimes(url, pdState.physician);
    }
  }, [pdState.physician, globalState.config.ABS_BASE_URL]);

  return (
    <div>
      <div style={{paddingBottom: 20}}>
        <span style={{marginLeft: 30}}>
          This calendar dictates the selected physician's
        </span>
        <span style={{fontWeight: 600}}> week day availability hours</span>
        <span> going forward.</span>
      </div>
      {pdState.availabilityTimes && (
        <WeeklyCalendar
          incrementNum={60}
          hoverToDisplayTime
          partialDay={partialDay}
          dayHeaderFormat={'dddd'}
        />
      )}
    </div>
  );
};

export default AvailabilityTimes;
