import React from 'react';
import DocboxHeader from './DocboxHeader';
import DocboxModuleContainer from './DocboxModuleContainer';

import './DocboxPrint.scss';

const DocboxPrint = () => {
  return (
    <div id="docbox-print">
      <DocboxHeader />
      <DocboxModuleContainer />
    </div>
  );
};

export default DocboxPrint;
