import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faBuilding,
  faCaretDown,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import {
  CheckCircleOutline,
  CloseSharp,
  FilterAltSharp,
} from '@mui/icons-material';
import './PDDropdown.scss';

const PDDropdown = ({
  defaultValue,
  selectedValue,
  handleInputChange,
  filterType,
  settings,
  tab,
  weeklyCaseLocations,
}) => {
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);
  const [selectedName, setSelectedName] = useState('');
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = event => {
    setSelectedName(event.target.innerText);
    if (event.target.value === 0) {
      if(tab === 'pending-cases') {
        handleInputChange('owner_id', 0);
      } else {
        handleInputChange(filterType, 'null');
      }
    } else {
      if(tab === 'pending-cases') {
        handleInputChange('owner_id', event.target.value);
      } else {
        handleInputChange(filterType, event.target.value);
      }
    }
    setAnchorEl(null);
  };

  const formatPhysicianName = name => {
    const arrName = name.trim().split(' ');
    return arrName[arrName.length - 1] + ', ' + arrName[0].charAt(0);
  };

  const formatLongName = name => {
    return name.substr(0, 15) + '...';
  };

  return (
    <div
      className={`filter-container ${
        selectedValue && 'not-null'
      }`}
      style={{marginRight: '15px'}}>
      <Box
        className={`filter-box ${selectedValue && 'not-null'}`}
        onClick={handleClick}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          textAlign: 'center',
          width:
            selectedValue ? 'unset' : '250px',
          minWidth: '250px',
        }}>
        <div>
          <FontAwesomeIcon
            className="select-icon"
            icon={filterType === 'location' ? faBuilding : faUser}
            size="lg"
          />
          <span className="filter-name">{filterType}</span>
          <span
            className={`selected-name  ${
              selectedValue && 'not-null'
            }`}>
            {(selectedValue && selectedName) ?
              selectedName :
              (selectedValue) ?
                formatPhysicianName(defaultValue) :
                'All'
            }
          </span>
        </div>
        <FontAwesomeIcon
          className="select-arrow"
          icon={faCaretDown}
          size="lg"
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="filter-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            width: 250,
            border: '1px solid var(--light-gray)',
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}>
        <div
          className="filter-title flex"
          style={{
            padding: '0px 8px 5px 8px',
            fontSize: '12px',
            alignItems: 'center',
            borderBottom: '1px solid var(--light-gray)',
            marginBottom: '8px',
            justifyContent: 'space-between',
            cursor: 'pointer',
          }}>
          <div className="flex" style={{alignItems: 'center'}}>
            <FilterAltSharp
              sx={{
                fontSize: '16px',
                color: 'var(--light-gray)',
                marginRight: '5px',
                top: '2px',
              }}
            />
            {filterType.toUpperCase()} FILTER
          </div>
          <CloseSharp
            sx={{fontSize: '16px', color: 'var(--light-gray)'}}
            onClick={handleClose}
          />
        </div>
        <div className='menu-items-list'>
          {tab !== 'physician-schedule' &&
            <>
              <MenuItem
                className={`${(selectedValue === null || selectedValue === 0) && 'selected'}`}
                value={'null'}
                name={'null'}
                onClick={handleChange}>
                <CheckCircleOutline
                  className={`check-icon ${(selectedValue === null || selectedValue === 0) && 'selected'}`}
                  sx={{ marginRight: '10px' }}
                />
                All
              </MenuItem>
              <Divider />
            </>
          }
          {filterType === 'location' &&
            settings &&
            settings.surgery_locations &&
            settings.surgery_locations
              .filter(l => weeklyCaseLocations(l))
              .map(location => {
                return (
                  <MenuItem
                    key={location.id}
                    className={`${selectedValue === location.id && 'selected'}`}
                    name={location.name}
                    value={location.id}
                    onClick={handleChange}>
                    <CheckCircleOutline
                      className={`check-icon ${selectedValue === location.id && 'selected'
                        }`}
                      sx={{ marginRight: '10px' }}
                    />
                    {location.name.length > 15 ?
                      formatLongName(location.name)
                      :
                      location.name
                    }
                  </MenuItem>
                );
              })}
          {filterType === 'physician' &&
            settings &&
            settings.physicians &&
            settings.physicians.map(physician => {
              return (
                <MenuItem
                  key={physician.id}
                  className={`${selectedValue === physician.id && 'selected'}`}
                  name={physician.name}
                  value={physician.id}
                  onClick={handleChange}>
                  <CheckCircleOutline
                    className={`check-icon ${selectedValue === physician.id && 'selected'
                      }`}
                    sx={{ marginRight: '10px' }}
                  />
                  {formatPhysicianName(physician.name)}
                </MenuItem>
              );
            })}
        </div>
      </Menu>
    </div>
  );
};

export default PDDropdown;
