import React, {useContext, useState} from 'react';
import {useDropzone} from 'react-dropzone';

import {Context as AuthContext} from '../context/AuthContext';
import {Context as DocboxContext} from '../context/DocboxContext';
import {Context as GlobalContext} from '../context/GlobalContext';
import {Context as PDContext} from '../context/PracticeDashboardContext';

import {Loading} from './ui/atoms';
import UploadIcon from '../assets/img/icons/UploadIcon.svg';

import {
  calendarEditURI,
  uploadFileURI,
  viewCaseURI,
} from '../dataAccess/apiEndpoints';

import {AlomEncodeType2} from '../libraries/helpers';
import {getAttachmentThumbnail} from '../libraries/workers';

import './UploadButton.scss';

const UploadButton = props => {
  const {state: authState} = useContext(AuthContext);
  const {
    uploadFile,
    editCalendarEvent,
    openDocbox: getViewData,
  } = useContext(DocboxContext);
  const {updateCaseAttachments} = useContext(PDContext);
  const {state: globalState, setNotification} = useContext(GlobalContext);

  const [isLoading, setIsLoading] = useState(false);

  const {open, getRootProps, getInputProps} = useDropzone({
    noDrag: true,
    noClick: true,
    noKeyboard: true,
    maxSize: 2048000000,
    onDrop: async acceptedFiles => {
      setIsLoading(true);
      const url = `${globalState.config.ABS_BASE_URL}${uploadFileURI}`;
      const viewCaseURL = `${globalState.config.ABS_BASE_URL}${viewCaseURI}`;

      const encodedEventId = AlomEncodeType2(props.case.event_id);
      try {
        let uploadedFiles = [],
          updatedAttachments = props.case.attachments;
        for (const file of acceptedFiles) {
          const response = await uploadFile(url, file);

          if (!response) {
            setIsLoading(false);
            return setNotification(
              'File upload error: no response. (Error 101)',
            );
          }

          if (!response.data) {
            setIsLoading(false);
            return setNotification(
              'File upload error: no response data. (Error 102)',
            );
          }

          const newAttachmentId = response.data?.file?.id;
          const newAttachmentPHI = response.data?.file?.phi;

          if (
            response.data.status &&
            response.data.status === 'success' &&
            response.data.file &&
            newAttachmentId
          ) {
            uploadedFiles.push({
              file: String(newAttachmentId),
              phi: newAttachmentPHI || 0,
            });
            // Add newly uploaded file to existing attachments in state
            const {
              id,
              phi,
              name,
              type,
              title,
              thumb,
              video,
              comment,
              download,
              ...restData
            } = response.data?.file;

            updatedAttachments.push({
              ...restData,
              type,
              has_phi: phi || 0,
              attachment_id: id,
              file_name: name,
              expires: 3600,
              url:
                type === 'Image'
                  ? getAttachmentThumbnail(
                      response.data?.file,
                      globalState.config.ABS_BASE_URL,
                    )
                  : download,
            });
          } else {
            setIsLoading(false);
            setNotification(
              'File upload error: response data error. (Error 103)',
            );
          }
        }

        const viewData = await getViewData(
          'View',
          encodedEventId,
          viewCaseURL,
          props.case.owner_id,
          null,
          authState.userType,
          null,
          null,
          null,
          'returnData',
        );

        if (!viewData?.response?.event) {
          setIsLoading(false);
          return setNotification('File upload error: could not get case data');
        }

        const data = {...viewData.response.event};
        const uploadUrl = `${globalState.config.ABS_BASE_URL}${calendarEditURI}`;

        const oldAttachments = data.attachments
          .filter(
            attachment =>
              !uploadedFiles.some(
                fileData => String(fileData.file) === String(attachment.id),
              ),
          )
          .map(attachment => ({
            file: String(attachment.id),
            phi: attachment?.phi,
          }));

        data.attachments = [...oldAttachments, ...uploadedFiles];

        const editResponse = await editCalendarEvent(uploadUrl, data);

        if (!editResponse?.data) {
          setIsLoading(false);
          return setNotification(
            'File upload error: Failed to update case attachments',
          );
        }
        updateCaseAttachments(props.case.event_id, updatedAttachments);
        // Stop loading if edit was successful
        if (editResponse?.data) {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        return setNotification('An error occurred during File upload');
      }
    },
  });

  return (
    <div {...getRootProps({className: 'dropzone flex justify-end'})}>
      <input {...getInputProps()} />
      <div
        className="button-container"
        style={{
          opacity: isLoading ? '0.5' : 1,
          cursor: isLoading ? 'not-allowed' : 'pointer',
        }}
        onClick={() => (isLoading ? {} : open())}>
        {isLoading ? (
          <div className="upload-spinner">
            <Loading />
          </div>
        ) : (
          <img src={UploadIcon} alt="upload icon" />
        )}
        <button
          type="button"
          disabled={isLoading}
          style={{
            cursor: isLoading ? 'not-allowed' : 'pointer',
          }}
          className="btn-upload">
          {props.btnTitle || 'UPLOAD'}
        </button>
      </div>
    </div>
  );
};

export default UploadButton;
