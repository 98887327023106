import React, {useContext, useEffect, useState} from 'react';
import {Context as PDContext} from '../../../context/PracticeDashboardContext';
import {Context as GlobalContext} from '../../../context/GlobalContext';
import {
  setAvailabilityCalendarURI,
  setAvailabilityTimesURI,
} from '../../../dataAccess/apiEndpoints';
import buildCalendar from './buildCalendar';
import {AlomEncodeType2} from '../../../libraries/helpers';
import moment from 'moment';
import Selecto from 'react-selecto';
import './WeeklyCalendar.scss';

const WeeklyCalendar = ({
  selectedDate,
  hoverToDisplayTime,
  dayHeaderFormat,
  partialDay,
  incrementNum,
  events,
}) => {
  const {
    state: pdState,
    setAvailabilityTimes,
    setAvailabilityCalendar,
  } = useContext(PDContext);
  const {state: globalState} = useContext(GlobalContext);
  // week is a collection of days, each day: a collection of 96 quarter hours
  // an array of arrays ( a matrix! )
  const [calendar, setCalendar] = useState([]);
  const [currentMoment, setCurrentMoment] = useState(moment(selectedDate));
  const [startHour, setStartHour] = useState(7);
  const [endHour, setEndHour] = useState(19);
  const [availabilityTimes, setAvailableTimes] = useState();
  const [availabilityCalendar, setAvailabilityCal] = useState();

  useEffect(() => {
    pdState.availabilityTimes && setAvailableTimes(pdState.availabilityTimes);
  }, [pdState.availabilityTimes]);

  useEffect(() => {
    pdState.availabilityCalendar &&
      setAvailabilityCal(pdState.availabilityCalendar);
  }, [pdState.availabilityCalendar]);

  useEffect(() => {
    partialDay ? setStartHour(7) : setStartHour(0);
    partialDay ? setEndHour(19) : setEndHour(24);
  }, [partialDay]);

  useEffect(() => {
    setCurrentMoment(moment(selectedDate));
  }, [selectedDate]);

  useEffect(() => {
    setCalendar(buildCalendar(currentMoment));
  }, [currentMoment]);

  useEffect(() => {
    // console.log('pdState.availabilityCalendar', pdState.availabilityCalendar)
  }, [pdState.availabilityCalendar]);

  const isToday = day => {
    return day.isSame(new Date(), 'day');
  };

  const isTomorrow = day => {
    const tomorrow = moment().add(1, 'days').format('DD');
    return day.format('DD') === tomorrow;
  };

  const isLastWeekday = day => {
    return day.format('ddd') === 'Sat';
  };

  const isSunday = day => {
    return day.format('ddd') === 'Sun';
  };

  const isQuarterAvailable = quarter => {
    let date = quarter.format('YYYYMMDD');
    let idx = quarter.format('HH') * 4 + quarter.format('mm') / 15;

    if (availabilityCalendar && availabilityCalendar[date]) {
      if (availabilityCalendar[date][idx] === '1') {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleQuarterHourSelect = selectedQuarters => {
    let newAvailabilityCalendar = pdState.availabilityCalendar;

    selectedQuarters.forEach(quarter => {
      let currQuarter = moment(quarter.dataset.target, 'YYYYMMDD HH:mm:ss');
      let date = currQuarter.format('YYYYMMDD');
      let idx = currQuarter.format('HH') * 4 + currQuarter.format('mm') / 15;
      let newNum;
      if (availabilityCalendar[date][idx] === '1') {
        newNum = '0';
      } else if (availabilityCalendar[date][idx] === '0') {
        newNum = '1';
      }
      let times = availabilityCalendar[date];
      times = times.substr(0, idx) + newNum + times.substr(idx + 1);
      newAvailabilityCalendar[date] = times;
    });
    if (globalState.config.ABS_BASE_URL) {
      const url = `${globalState.config.ABS_BASE_URL}${setAvailabilityCalendarURI}`;
      setAvailabilityCalendar(
        url,
        AlomEncodeType2(pdState.physician),
        newAvailabilityCalendar,
      );
    }
  };

  const isHourAvailable = binaryNum => {
    if (binaryNum === '1') {
      return true;
    } else {
      return false;
    }
  };

  const handleHourClick = hour => {
    let idx = parseInt(hour.format('HH'));
    let day = hour.format('ddd').toLowerCase();
    let newNum;
    if (availabilityTimes[day][idx] === '1') {
      newNum = '0';
    } else if (availabilityTimes[day][idx] === '0') {
      newNum = '1';
    }
    let times = availabilityTimes[day];
    times = times.substr(0, idx) + newNum + times.substr(idx + 1);
    let newAvailability = pdState.availabilityTimes;

    newAvailability[day] = times;
    if (globalState.config.ABS_BASE_URL) {
      const url = `${globalState.config.ABS_BASE_URL}${setAvailabilityTimesURI}`;
      setAvailabilityTimes(
        url,
        AlomEncodeType2(pdState.physician),
        newAvailability,
      );
    }
  };

  return (
    <div id="weekly-calendar">
      {calendar.map((day, index) => (
        <div
          key={`${index}-day`}
          className={`selecto-area day day${index} ${
            day &&
            Array.isArray(day) &&
            day.length > 0 &&
            isToday(day[0]) &&
            'today'
          }`}>
          {/* QUARTER HOUR  - for Physician's Schedule & Availability Calendar */}
          {incrementNum === 15 &&
            availabilityCalendar &&
            day &&
            Array.isArray(day) &&
            day.length > 0 && (
              <Selecto
                dragContainer={`.day${index}`}
                selectableTargets={['.selecto-area .quarter']}
                hitRate={1}
                selectByClick={true}
                selectFromInside={true}
                ratio={0}
                onSelect={e => {
                  e.selected.forEach(el => {
                    if (el.classList.contains('available')) {
                      el.classList.add('avail-selected');
                    } else {
                      el.classList.add('selected');
                    }
                  });
                  e.removed.forEach(el => {
                    if (el.classList.contains('available')) {
                      el.classList.remove('avail-selected');
                    } else {
                      el.classList.remove('selected');
                    }
                  });
                }}
                onSelectEnd={e => {
                  if (e.selected && e.selected.length > 0) {
                    handleQuarterHourSelect(e.selected);
                  }
                }}></Selecto>
            )}
          {incrementNum === 15 &&
            availabilityCalendar &&
            day &&
            Array.isArray(day) &&
            day.length > 0 &&
            day.map((quarterHour, idx) => (
              <div key={`quarter-hour-${idx}`}>
                {/* Day Header */}
                {idx === 0 && (
                  <>
                    <div key={idx} className={`day-header`}>
                      <span>
                        {dayHeaderFormat
                          ? quarterHour.format(dayHeaderFormat)
                          : quarterHour.format('ddd MMM D')}
                      </span>
                    </div>
                  </>
                )}

                {/* Quarter Hour */}

                {quarterHour.format('H') >= startHour &&
                  quarterHour.format('H') < endHour && (
                    <div
                      key={`${idx}-quarter`}
                      className={`
                      quarter 
                      ${quarterHour.format('H') % 2 === 0 ? 'even' : 'odd'} 
                      ${isToday(day[0]) && 'quarter-of-today'}
                      ${
                        isTomorrow(day[0]) &&
                        !isSunday(day[0]) &&
                        'quarter-of-tomorrow'
                      }
                      ${
                        isLastWeekday(day[0]) &&
                        !isToday(day[0]) &&
                        'quarter-of-saturday'
                      }
                      ${isQuarterAvailable(quarterHour) && 'available'}
                    `}
                      data-target={quarterHour.format('YYYYMMDD HH:mm:ss')}>
                      {/* Times on the Left '6 am' */}
                      {quarterHour.format('ddd') === 'Sun' &&
                        quarterHour.format('mm') == '00' && (
                          <span key={`${idx}-hour`} className={`time-on-left`}>
                            {quarterHour.format('h a')}
                          </span>
                        )}

                      {/* Times on the Left '6:30' */}
                      {quarterHour.format('ddd') === 'Sun' &&
                        quarterHour.format('mm') == '30' && (
                          <span
                            key={`${idx}-half-hour`}
                            className={`time-on-left`}
                            style={{
                              color: 'var(--medium-gray)',
                              fontWeight: 400,
                            }}>
                            {quarterHour.format('h:mm')}
                          </span>
                        )}

                      {/* HoverToDisplayTime */}
                      {hoverToDisplayTime && (
                        <span
                          key={`${idx}-hover`}
                          className={`hover-time`}>{`${quarterHour.format(
                          'h:mm',
                        )} - ${quarterHour
                          .clone()
                          .add(15, 'minutes')
                          .format('h:mm a')}`}</span>
                      )}

                      {/* Event Here?? */}
                      {/* Check here if day & time match start of an event */}
                      {events &&
                        events.length > 0 &&
                        events.map((event, idx) => {
                          if (
                            moment(event.time.start).format('ddd') ===
                            quarterHour.format('ddd')
                          ) {
                            if (
                              moment(event.time.start).format('h') ===
                              quarterHour.format('h')
                            ) {
                              if (
                                moment(event.time.start).format('mm') ===
                                quarterHour.format('mm')
                              ) {
                                return (
                                  <div
                                    className="event-box"
                                    key={`event-${idx}`}>
                                    <p>
                                      {moment(event.time.start).format(
                                        'ddd h:mm a',
                                      )}
                                    </p>
                                  </div>
                                );
                              }
                            }
                          }
                        })}
                    </div>
                  )}
              </div>
            ))}

          {/* HOUR - for Availability Times */}
          {incrementNum === 60 &&
            availabilityTimes &&
            Array.isArray(day) &&
            day.length > 0 && (
              <>
                {day.map((hour, idx) => (
                  <div key={`hour-${idx}`}>
                    {/* Day Header */}
                    {idx === 0 && (
                      <div key={idx} className={`day-header`}>
                        <span>
                          {dayHeaderFormat
                            ? hour.format(dayHeaderFormat)
                            : hour.format('ddd MMM D')}
                        </span>
                      </div>
                    )}

                    {/* Hour */}
                    {hour.format('H') >= startHour &&
                      hour.format('H') < endHour &&
                      hour.format('mm') == '00' && (
                        <div
                          key={`${idx}-hour`}
                          className={`
                        hour
                        ${hour.format('H') % 2 === 0 ? 'even' : 'odd'} 
                        ${isToday(day[0]) && 'quarter-of-today'}
                        ${isTomorrow(day[0]) && 'quarter-of-tomorrow'}
                        ${
                          isLastWeekday(day[0]) &&
                          !isToday(day[0]) &&
                          'quarter-of-saturday'
                        }
                        ${
                          availabilityTimes &&
                          hour &&
                          availabilityTimes[hour.format('ddd').toLowerCase()] &&
                          isHourAvailable(
                            availabilityTimes[hour.format('ddd').toLowerCase()][
                              parseInt(hour.format('HH'))
                            ],
                          ) &&
                          'available'
                        }
                      `}
                          onClick={() => handleHourClick(hour)}>
                          {/* Times on the Left '6 am' */}
                          {hour.format('ddd') === 'Sun' &&
                            hour.format('mm') == '00' && (
                              <span
                                key={`${idx}-hour`}
                                className={`time-on-left`}>
                                {hour.format('h a')}
                              </span>
                            )}

                          {/* HoverToDisplayTime */}
                          {hoverToDisplayTime && hour.format('mm') == '00' && (
                            <span
                              key={`${idx}-hover`}
                              className={`hover-time`}>{`${hour.format(
                              'h',
                            )} - ${hour
                              .clone()
                              .add(60, 'minutes')
                              .format('h a')}`}</span>
                          )}
                        </div>
                      )}
                  </div>
                ))}
              </>
            )}
        </div>
      ))}
    </div>
  );
};

export default WeeklyCalendar;
