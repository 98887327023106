import './EventSettings.scss';

import {faLock, faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useContext, useEffect} from 'react';

import gearIcon from '../assets/img/icons/gear.svg';
import {Context as AuthContext} from '../context/AuthContext';
import {Context as ContactsContext} from '../context/ContactsContext';
import {Context as EventSettingsContext} from '../context/EventSettingsContext';
import {Context as GlobalContext} from '../context/GlobalContext';
import {Context as ScheduleContext} from '../context/ScheduleContext';
import {
  attendeeSearchURI,
  billingCodesSearchURI,
  setUserPreferencesURI,
} from '../dataAccess/apiEndpoints';
import {parseSanitizedString} from '../libraries/helpers';
import ContactCard from './ContactCard';
import {
  AddFieldButton,
  BillingCodeBadge,
  BillingCodeCard,
  BillingTypeahead,
  DeleteFieldButton,
  Tabs,
  Typeahead,
} from './ui/atoms';

const EventSettings = () => {
  const {state: authState} = useContext(AuthContext);
  const {state: scheduleState} = useContext(ScheduleContext);
  const {state: contactsState} = useContext(ContactsContext);
  const {
    state: eventSettingsState,
    closeEventSettings,
    addProvider,
    deleteProvider,
    addRep,
    deleteRep,
    addBillingCode,
    addProcedure,
    addLocation,
    addChecklistGroup,
    saveEventSettings,
  } = useContext(EventSettingsContext);
  const {state: globalState, setNotification} = useContext(GlobalContext);
  let userProfile = {
    id: authState.userId,
    avatar:
      authState.userProfile.avatar !== undefined
        ? authState.userProfile.avatar
        : '',
    name: `${authState.userProfile.name.first} ${authState.userProfile.name.last}`,
    specialty:
      authState.userProfile.profession !== undefined &&
      authState.userProfile.profession.specialty !== undefined &&
      authState.userProfile.profession.specialty.length > 0 &&
      authState.userProfile.profession.specialty[0].field !== undefined
        ? authState.userProfile.profession.specialty[0].field
        : '',
  };

  let tabsArray = [
    {
      name: 'Procedures',
      id: 'procedures',
      default:
        eventSettingsState.defaultVisibleTab === 'procedures' ||
        eventSettingsState.defaultVisibleTab === null
          ? true
          : false,
    },
    {
      name: 'Locations',
      id: 'locations',
      default:
        eventSettingsState.defaultVisibleTab === 'locations' ? true : false,
    },
    {
      name: 'Attendees',
      id: 'attendees',
      default:
        eventSettingsState.defaultVisibleTab === 'attendees' ? true : false,
    },
    {
      name: 'Checklists',
      id: 'checklists',
      default:
        eventSettingsState.defaultVisibleTab === 'checklists' ? true : false,
    },
  ];

  if (scheduleState.targetUserId == authState.userId) {
    tabsArray.push({
      name: 'Diagnostic Codes',
      id: 'diagnostic-codes',
      default:
        eventSettingsState.defaultVisibleTab === 'diagnostic-codes'
          ? true
          : false,
    });
  }

  return (
    <>
      <EventSettingsOverlay />
      <div id="event-settings" className={`flex direction-column`}>
        <header className="flex align-center nowrap">
          <section>
            <img src={gearIcon} />
          </section>
          <section>
            <h1>Event Settings</h1>
            <h2>Select your default event settings</h2>
          </section>
          <section className="btn-container">
            <button
              type="button"
              className="btn-success"
              onClick={() => {
                if (eventSettingsState && scheduleState.targetUserId) {
                  const url = `${globalState.config.ABS_BASE_URL}${setUserPreferencesURI}`;
                  saveEventSettings(
                    url,
                    eventSettingsState,
                    scheduleState.targetUserId,
                  ).then(() => {
                    closeEventSettings();
                    setNotification('All Event Settings saved.');
                  });
                }
              }}>
              Save
            </button>
            <button
              type="button"
              className="btn-close circle"
              onClick={() => closeEventSettings()}>
              <div>
                <FontAwesomeIcon icon={faTimes} size="lg" />
              </div>
            </button>
          </section>
        </header>

        <Tabs
          tabsArray={tabsArray}
          tabsContainerId={'tabs-container_event-settings'}
          style={{
            margin: '1rem',
          }}
        />

        <section className="flex align-start">
          {/* <div>
            Navbar
          </div> */}

          <div id="tabs-container_event-settings">
            <div
              id="procedures"
              style={
                eventSettingsState.defaultVisibleTab === 'procedures' ||
                eventSettingsState.defaultVisibleTab === null
                  ? null
                  : {display: 'none'}
              }>
              <div className="tab-details">
                <div className="flex align-center space-between">
                  <div className="width70">
                    <h3>Set Up Default Surgical Procedures</h3>
                  </div>

                  <div className="width30 flex justify-end">
                    <button
                      type="button"
                      className="btn-success"
                      onClick={() => {
                        const newProcedureId = Date.now();
                        addProcedure(newProcedureId).then(() => {
                          return document
                            .getElementById(`procedure-name-${newProcedureId}`)
                            .focus();
                        });
                      }}>
                      Add New Procedure
                    </button>
                  </div>
                </div>
                <p>
                  Include devices, location, event duration and attendees. Use
                  these default procedures when creating new Cases to quickly
                  include devices, location, event duration and attendees with
                  one click.
                </p>
              </div>

              <form
                onSubmit={event => {
                  event.preventDefault();
                  return false;
                }}>
                {eventSettingsState.procedures.map((procedure, index) => {
                  return (
                    <EventSettingsProcedureCard
                      key={index}
                      procedure={procedure}
                      index={index + 1}
                    />
                  );
                })}
              </form>

              <div
                className="flex justify-end"
                style={{
                  borderTop: '1px solid var(--light-gray)',
                  paddingTop: '2rem',
                }}>
                <button
                  type="button"
                  className="btn-success"
                  onClick={() => {
                    const newProcedureId = Date.now();
                    addProcedure(newProcedureId).then(() => {
                      return document
                        .getElementById(`procedure-name-${newProcedureId}`)
                        .focus();
                    });
                  }}>
                  Add New Procedure
                </button>
              </div>
            </div>

            <div
              id="locations"
              style={
                eventSettingsState.defaultVisibleTab === 'locations'
                  ? null
                  : {display: 'none'}
              }>
              <div className="tab-details">
                <div className="flex align-center space-between">
                  <div className="width70">
                    <h3>Set Up Surgery Locations</h3>
                  </div>

                  <div className="width30 flex justify-end">
                    <button
                      type="button"
                      className="btn-success"
                      onClick={() => {
                        const newLocationId = Date.now();
                        addLocation(newLocationId).then(() => {
                          return document
                            .getElementById(`location-name-${newLocationId}`)
                            .focus();
                        });
                      }}>
                      Add New Location
                    </button>
                  </div>
                </div>
                <p>
                  Create default surgery locations and add care team members for
                  each location. Use these locations when creating new Cases to
                  quickly set the location and add attendees with one click.
                </p>
              </div>

              <form
                onSubmit={event => {
                  event.preventDefault();
                  return false;
                }}>
                {eventSettingsState.locations.map((location, index) => {
                  return (
                    <EventSettingsLocationCard
                      key={index}
                      location={location}
                      index={index + 1}
                    />
                  );
                })}
              </form>

              <div
                className="flex justify-end"
                style={{
                  borderTop: '1px solid var(--light-gray)',
                  paddingTop: '2rem',
                }}>
                <button
                  type="button"
                  className="btn-success"
                  onClick={() => {
                    const newLocationId = Date.now();
                    addLocation(newLocationId).then(() => {
                      return document
                        .getElementById(`location-name-${newLocationId}`)
                        .focus();
                    });
                  }}>
                  Add New Location
                </button>
              </div>
            </div>

            <div
              id="attendees"
              style={
                eventSettingsState.defaultVisibleTab === 'attendees'
                  ? null
                  : {display: 'none'}
              }>
              <div className="tab-details">
                <h3>Set Up Default Attendees</h3>
                <p>
                  All attendees listed here will be automatically invited to
                  this Physician's Cases (including events sent by EMR).
                </p>
              </div>

              <form
                onSubmit={event => {
                  event.preventDefault();
                  return false;
                }}>
                <div
                  style={{
                    borderTop: '1px solid var(--light-gray)',
                    padding: '2rem 0',
                  }}>
                  <div className="flex align-center space-between">
                    <div className="width50">
                      <label htmlFor="provider-typeahead">
                        Providers <b>({eventSettingsState.providers.length})</b>
                      </label>
                      <Typeahead
                        uri={attendeeSearchURI}
                        userId={authState.userId}
                        elementId={'provider-typeahead'}
                        placeholder={'My Physicians / Care Team Members'}
                        filter={array =>
                          array.filter(
                            element =>
                              element.type === 'Physician' ||
                              element.type === 'Careteam',
                          )
                        }
                        handleSelection={addProvider}
                      />
                    </div>
                  </div>

                  <div className="flex align-center space-between">
                    <div className="width100">
                      <div
                        id="providers-container"
                        className="flex space-between wrap">
                        {eventSettingsState.providers.length &&
                        contactsState.contacts.length ? (
                          renderAttendeesCards(
                            'Provider',
                            eventSettingsState.providers,
                            contactsState.contacts,
                            deleteProvider,
                            userProfile,
                          )
                        ) : (
                          <span>No providers added yet.</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    borderTop: '1px solid var(--light-gray)',
                    padding: '2rem 0',
                  }}>
                  <div className="flex align-center space-between">
                    <div className="width50">
                      <label htmlFor="rep-typeahead">
                        Industry Reps <b>({eventSettingsState.reps.length})</b>
                      </label>
                      <Typeahead
                        uri={attendeeSearchURI}
                        userId={authState.userId}
                        elementId={'rep-typeahead'}
                        placeholder={'My Industry Contacts'}
                        filter={array =>
                          array.filter(element => element.type === 'Industry')
                        }
                        handleSelection={addRep}
                      />
                    </div>
                  </div>

                  <div className="flex align-center space-between">
                    <div className="width100">
                      <div
                        id="industry-container"
                        className="flex space-between wrap">
                        {eventSettingsState.reps.length &&
                        contactsState.contacts.length ? (
                          renderAttendeesCards(
                            'Industry',
                            eventSettingsState.reps,
                            contactsState.contacts,
                            deleteRep,
                          )
                        ) : (
                          <span>No industry reps added yet.</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div
              id="checklists"
              style={
                eventSettingsState.defaultVisibleTab === 'checklists'
                  ? null
                  : {display: 'none'}
              }>
              <div className="tab-details">
                <div className="flex align-center space-between">
                  <div className="width70">
                    <h3>Set Up Pre-Defined Checklist Groups</h3>
                  </div>

                  <div className="width30 flex justify-end">
                    <button
                      type="button"
                      className="btn-success"
                      onClick={() => {
                        const newChecklistGroupId = Date.now();
                        addChecklistGroup(newChecklistGroupId).then(() => {
                          return document
                            .getElementById(
                              `checklist-group-name-${newChecklistGroupId}`,
                            )
                            .focus();
                        });
                      }}>
                      Add New Checklist
                    </button>
                  </div>
                </div>
                <p>
                  Create a customized group of checklist items. Use these
                  pre-defined checklist groups when creating new Cases.
                </p>
              </div>

              <form
                onSubmit={event => {
                  event.preventDefault();
                  return false;
                }}>
                {eventSettingsState.checklists.map((checklistGroup, index) => {
                  return (
                    <EventSettingsChecklistCard
                      key={index}
                      checklistGroup={checklistGroup}
                      index={index + 1}
                    />
                  );
                })}
              </form>

              <div
                className="flex justify-end"
                style={{
                  borderTop: '1px solid var(--light-gray)',
                  paddingTop: '2rem',
                }}>
                <button
                  type="button"
                  className="btn-success"
                  onClick={() => {
                    const newChecklistGroupId = Date.now();
                    addChecklistGroup(newChecklistGroupId).then(() => {
                      return document
                        .getElementById(
                          `checklist-group-name-${newChecklistGroupId}`,
                        )
                        .focus();
                    });
                  }}>
                  Add New Checklist
                </button>
              </div>
            </div>

            {scheduleState.targetUserId == authState.userId && (
              <div
                id="diagnostic-codes"
                style={
                  eventSettingsState.defaultVisibleTab === 'diagnostic-codes'
                    ? null
                    : {display: 'none'}
                }>
                <div className="tab-details">
                  <h3>Set Up Frequently Used Diagnostic Codes</h3>
                  <p>
                    Bookmark ICD-10 & CPT codes for quick access to the ones you
                    use most.
                  </p>
                </div>

                <form
                  onSubmit={event => {
                    event.preventDefault();
                    return false;
                  }}>
                  <div
                    style={{
                      borderTop: '1px solid var(--light-gray)',
                      padding: '2rem 0',
                    }}>
                    <div className="flex align-center space-between">
                      <div className="width100">
                        <label htmlFor="icd-typeahead">
                          ICD-10 Codes{' '}
                          <b>({eventSettingsState.icd10.length} Bookmarked)</b>
                        </label>
                        <BillingTypeahead
                          uri={billingCodesSearchURI}
                          codeType={'icd'}
                          elementId={'icd-typeahead'}
                          placeholder={'Search ICD-10 Codes...'}
                          handleSelection={addBillingCode}
                        />
                      </div>
                    </div>

                    <div className="flex align-center space-between">
                      <div className="width100">
                        <div
                          id="icd-container"
                          className="flex space-between wrap">
                          {eventSettingsState.icd10.length ? (
                            eventSettingsState.icd10.map((code, index) => {
                              return (
                                <BillingCodeCard
                                  key={index}
                                  type={'icd'}
                                  code={code}
                                />
                              );
                            })
                          ) : (
                            <span>No ICD-10 codes added yet.</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      borderTop: '1px solid var(--light-gray)',
                      padding: '2rem 0',
                    }}>
                    <div className="flex align-center space-between">
                      <div className="width100">
                        <label htmlFor="cpt-typeahead">
                          CPT Codes{' '}
                          <b>({eventSettingsState.cpt.length} Bookmarked)</b>
                        </label>
                        <BillingTypeahead
                          uri={billingCodesSearchURI}
                          codeType={'cpt'}
                          elementId={'cpt-typeahead'}
                          placeholder={'Search CPT Codes...'}
                          handleSelection={addBillingCode}
                        />
                      </div>
                    </div>

                    <div className="flex align-center space-between">
                      <div className="width100">
                        <div
                          id="cpt-container"
                          className="flex space-between wrap">
                          {eventSettingsState.cpt.length ? (
                            eventSettingsState.cpt.map((code, index) => {
                              return (
                                <BillingCodeCard
                                  key={index}
                                  type={'cpt'}
                                  code={code}
                                />
                              );
                            })
                          ) : (
                            <span>No CPT codes added yet.</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

const renderAttendeesCards = (
  attendeeType,
  attendeesArray,
  contactsArray,
  handleDelete,
  userProfile,
) => {
  let outputArray = [];
  attendeesArray.forEach(attendee => {
    if (userProfile && attendee.toString() === userProfile.id.toString()) {
      outputArray.push(userProfile);
    }

    for (let index in contactsArray) {
      if ('id' in contactsArray[index] && contactsArray[index].id == attendee) {
        outputArray.push(contactsArray[index]);
        break;
      }
    }
  });

  return outputArray.map((attendee, index) => {
    return (
      <ContactCard
        key={index}
        type={attendeeType}
        contact={attendee}
        allowDelete={true}
        handleDelete={() => handleDelete(attendee.id)}
      />
    );
  });
};

const EventSettingsProcedureCard = ({procedure, index}) => {
  const {state: authState} = useContext(AuthContext);
  const {state: contactsState} = useContext(ContactsContext);
  const {
    state: eventSettingsState,
    deleteProcedure,
    updateProcedureField,
    addProviderToProcedure,
    deleteProviderFromProcedure,
    addRepToProcedure,
    deleteRepFromProcedure,
    addDeviceToProcedure,
    updateProcedureDevice,
    deleteDeviceFromProcedure,
    addBillingCodeToProcedure,
    deleteBillingCodeFromProcedure,
  } = useContext(EventSettingsContext);
  let careteamArray = procedure.providers.length
    ? procedure.providers.split(',')
    : [];
  let repsArray = procedure.reps.length ? procedure.reps.split(',') : [];
  let icdArray = procedure.icd10.length ? procedure.icd10.split(', ') : [];
  let cptArray = procedure.cpt.length ? procedure.cpt.split(', ') : [];
  let userProfile = {
    id: authState.userId,
    avatar:
      authState.userProfile.avatar !== undefined
        ? authState.userProfile.avatar
        : '',
    name: `${authState.userProfile.name.first} ${authState.userProfile.name.last}`,
    specialty:
      authState.userProfile.profession !== undefined &&
      authState.userProfile.profession.specialty !== undefined &&
      authState.userProfile.profession.specialty.length > 0 &&
      authState.userProfile.profession.specialty[0].field !== undefined
        ? authState.userProfile.profession.specialty[0].field
        : '',
  };

  useEffect(() => {
    if (procedure.devices && !procedure.devices.length) {
      addDeviceToProcedure(procedure);
    }
  }, [procedure]);

  return (
    <div className="procedure-card">
      <div
        className="flex align-center space-between"
        style={{marginBottom: '1rem'}}>
        <div className="width90">
          <h4>Procedure #{index}</h4>
        </div>

        <div className="width10 flex justify-end">
          <DeleteFieldButton
            handler={() => {
              deleteProcedure(procedure.id);
            }}
            title={`Delete Procedure #${index}`}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="width100">
          <label htmlFor={`procedure-name-${procedure.id}`}>
            Procedure Name
          </label>
          <div>
            <input
              id={`procedure-name-${procedure.id}`}
              type="text"
              placeholder="Custom Procedure Name..."
              value={procedure.name ? parseSanitizedString(procedure.name) : ''}
              onChange={event =>
                updateProcedureField(procedure, 'name', event.target.value)
              }
            />
          </div>
        </div>
      </div>

      <div className="form-row" style={{paddingTop: '0.5rem'}}>
        <div className="width50">
          <label htmlFor={`procedure-providers-typeahead-${procedure.id}`}>
            Providers <b>({careteamArray.length})</b>
          </label>
          <Typeahead
            uri={attendeeSearchURI}
            userId={authState.userId}
            elementId={`procedure-providers-typeahead-${procedure.id}`}
            placeholder={'Add Providers...'}
            filter={array =>
              array.filter(
                element =>
                  element.type === 'Physician' || element.type === 'Careteam',
              )
            }
            handleSelection={addProviderToProcedure}
            procedure={procedure}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="width100">
          <div
            id={`procedure-providers-container-${procedure.id}`}
            className="flex space-between wrap">
            {careteamArray.length && contactsState.contacts.length ? (
              renderProcedureAttendeeCards(
                'Provider',
                careteamArray,
                contactsState.contacts,
                procedure,
                deleteProviderFromProcedure,
                userProfile,
              )
            ) : (
              <span>No providers added yet.</span>
            )}
          </div>
        </div>
      </div>

      <div className="form-row" style={{paddingTop: '1rem'}}>
        <div className="width50">
          <label htmlFor={`procedure-reps-typeahead-${procedure.id}`}>
            Industry Reps <b>({repsArray.length})</b>
          </label>
          <Typeahead
            uri={attendeeSearchURI}
            userId={authState.userId}
            elementId={`procedure-reps-typeahead-${procedure.id}`}
            placeholder={'Add Industry Reps...'}
            filter={array =>
              array.filter(element => element.type === 'Industry')
            }
            handleSelection={addRepToProcedure}
            procedure={procedure}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="width100">
          <div
            id={`procedure-reps-container-${procedure.id}`}
            className="flex space-between wrap">
            {repsArray.length && contactsState.contacts.length ? (
              renderProcedureAttendeeCards(
                'Industry',
                repsArray,
                contactsState.contacts,
                procedure,
                deleteRepFromProcedure,
              )
            ) : (
              <span>No industry reps added yet.</span>
            )}
          </div>
        </div>
      </div>

      <div className="form-row" style={{paddingTop: '0.5rem'}}>
        <div className="width50">
          <label htmlFor={`FIELD_procedure-duration-${procedure.id}`}>
            Duration
          </label>
          <select
            id={`FIELD_procedure-duration-${procedure.id}`}
            value={procedure.duration ? procedure.duration.toString() : '0'}
            onChange={event =>
              updateProcedureField(
                procedure,
                'duration',
                parseInt(event.target.value),
              )
            }>
            <option value="0">Select A Duration...</option>
            <option value="15">15 Mins</option>
            <option value="30">30 Mins</option>
            <option value="45">45 Mins</option>
            <option value="60">1 Hour</option>
            <option value="75">1 Hour 15 Mins</option>
            <option value="90">1 Hour 30 Mins</option>
            <option value="105">1 Hour 45 Mins</option>
            <option value="120">2 Hours</option>
            <option value="135">2 Hours 15 Mins</option>
            <option value="150">2 Hours 30 Mins</option>
            <option value="165">2 Hours 45 Mins</option>
            <option value="180">3 Hours</option>
            <option value="240">4 Hours</option>
            <option value="300">5 Hours</option>
            <option value="360">6 Hours</option>
          </select>
        </div>
      </div>

      <div className="form-row" style={{paddingTop: '0.5rem'}}>
        <div className="width100">
          <label htmlFor={`procedure-icd-typeahead-${procedure.id}`}>
            ICD-10 <b>({icdArray.length})</b>
          </label>
          <BillingTypeahead
            uri={billingCodesSearchURI}
            codeType={'icd'}
            elementId={`procedure-icd-typeahead-${procedure.id}`}
            procedure={procedure}
            placeholder={'Search ICD-10 Codes...'}
            handleSelection={addBillingCodeToProcedure}
          />
        </div>
      </div>

      <div className="form-row" style={{paddingTop: '0.5rem'}}>
        <div className="width100">
          <div
            id={`procedure-icd-container-${procedure.id}`}
            className="flex wrap">
            {icdArray.length ? (
              icdArray.map((code, index) => {
                return (
                  <BillingCodeBadge
                    key={index}
                    type={'icd'}
                    code={code}
                    handler={() =>
                      deleteBillingCodeFromProcedure('icd', code, procedure)
                    }
                  />
                );
              })
            ) : (
              <span>No ICD-10 codes added yet.</span>
            )}
          </div>
        </div>
      </div>

      <div className="form-row" style={{paddingTop: '0.5rem'}}>
        <div className="width100">
          <label htmlFor={`procedure-cpt-typeahead-${procedure.id}`}>
            CPT <b>({cptArray.length})</b>
          </label>
          <BillingTypeahead
            uri={billingCodesSearchURI}
            codeType={'cpt'}
            elementId={`procedure-cpt-typeahead-${procedure.id}`}
            procedure={procedure}
            placeholder={'Search CPT Codes...'}
            handleSelection={addBillingCodeToProcedure}
          />
        </div>
      </div>

      <div className="form-row" style={{paddingTop: '0.5rem'}}>
        <div className="width100">
          <div id="cpt-container" className="flex wrap">
            {cptArray.length ? (
              cptArray.map((code, index) => {
                return (
                  <BillingCodeBadge
                    key={index}
                    type={'cpt'}
                    code={code}
                    handler={() =>
                      deleteBillingCodeFromProcedure('cpt', code, procedure)
                    }
                  />
                );
              })
            ) : (
              <span>No CPT codes added yet.</span>
            )}
          </div>
        </div>
      </div>

      <div className="form-row" style={{paddingTop: '0.5rem'}}>
        <div className="width100">
          <label htmlFor={`procedure-setup-${procedure.id}`}>Setup</label>
          <div>
            <input
              id={`procedure-setup-${procedure.id}`}
              type="text"
              placeholder="Optional Setup Details..."
              value={
                procedure.setup ? parseSanitizedString(procedure.setup) : ''
              }
              onChange={event =>
                updateProcedureField(procedure, 'setup', event.target.value)
              }
            />
          </div>
        </div>
      </div>

      <div className="form-row" style={{paddingTop: '0.5rem'}}>
        <div className="width70">
          <label htmlFor={`procedure-devices-${procedure.id}`}>Equipment</label>
          <div>
            {procedure.devices.length > 0 &&
              procedure.devices.map((device, index) => {
                return (
                  <div key={index} className="flex align-center">
                    <div className="width70">
                      <input
                        id={`procedure-device-${device.id}`}
                        type="text"
                        placeholder={`Device #${index + 1} Name...`}
                        value={
                          device.name ? parseSanitizedString(device.name) : ''
                        }
                        onChange={event =>
                          updateProcedureDevice(
                            procedure,
                            device,
                            event.target.value,
                          )
                        }
                      />
                    </div>

                    <div
                      style={{
                        marginLeft: '14px',
                      }}>
                      <DeleteFieldButton
                        handler={() => {
                          deleteDeviceFromProcedure(procedure, device.id);
                        }}
                        title={'Remove This Device'}
                      />
                    </div>

                    {index === procedure.devices.length - 1 && (
                      <div
                        style={{
                          marginLeft: '8px',
                        }}>
                        <AddFieldButton
                          handler={() => {
                            addDeviceToProcedure(procedure);
                          }}
                          title={'Add New Device'}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

const renderProcedureAttendeeCards = (
  attendeeType,
  attendeesArray,
  contactsArray,
  procedure,
  handleDelete,
  userProfile,
) => {
  let outputArray = [];

  attendeesArray.forEach(attendee => {
    if (userProfile && attendee.toString() === userProfile.id.toString()) {
      outputArray.push(userProfile);
    }

    for (let index in contactsArray) {
      if ('id' in contactsArray[index] && contactsArray[index].id == attendee) {
        outputArray.push(contactsArray[index]);
        break;
      }
    }
  });

  return outputArray.map((attendee, index) => {
    return (
      <ContactCard
        key={index}
        type={attendeeType}
        contact={attendee}
        allowDelete={true}
        handleDelete={() => handleDelete(procedure, attendee.id)}
      />
    );
  });
};

const EventSettingsLocationCard = ({location, index}) => {
  const {state: authState} = useContext(AuthContext);
  const {state: contactsState} = useContext(ContactsContext);
  const {
    updateLocationName,
    addProviderToLocation,
    deleteProviderFromLocation,
    deleteLocation,
  } = useContext(EventSettingsContext);
  const fromEMR = location.source && location.source === 'EMR' ? true : false;
  let careteamArray = location.careteam.length
    ? location.careteam.split(',')
    : [];
  let userProfile = {
    id: authState.userId,
    avatar:
      authState.userProfile.avatar !== undefined
        ? authState.userProfile.avatar
        : '',
    name: `${authState.userProfile.name.first} ${authState.userProfile.name.last}`,
    specialty:
      authState.userProfile.profession !== undefined &&
      authState.userProfile.profession.specialty !== undefined &&
      authState.userProfile.profession.specialty.length > 0 &&
      authState.userProfile.profession.specialty[0].field !== undefined
        ? authState.userProfile.profession.specialty[0].field
        : '',
  };

  return (
    <div className="location-card">
      <div
        className="flex align-center space-between"
        style={{marginBottom: '1rem'}}>
        <div className="width90">
          <h4>Location #{index}</h4>
        </div>

        {!fromEMR && (
          <div className="width10 flex justify-end">
            <DeleteFieldButton
              handler={() => {
                deleteLocation(location.id);
              }}
              title={`Delete Location #${index}`}
            />
          </div>
        )}
      </div>

      <div className="form-row">
        <div className="width100">
          <label htmlFor={`location-name-${location.id}`}>
            Location Name
            {fromEMR && <b style={{marginLeft: '0.5rem'}}>(From EMR)</b>}
          </label>
          <div style={{position: 'relative'}}>
            {fromEMR && (
              <label
                className="input-field-icon"
                title="Names of EMR-based locations cannot be edited">
                <FontAwesomeIcon icon={faLock} />
              </label>
            )}
            <input
              id={`location-name-${location.id}`}
              className={fromEMR ? 'emr-location' : ''}
              type="text"
              placeholder="Custom Location Name..."
              value={
                location.location ? parseSanitizedString(location.location) : ''
              }
              readOnly={fromEMR ? true : false}
              onChange={event =>
                updateLocationName(location, event.target.value)
              }
            />
          </div>
        </div>
      </div>

      <div className="form-row" style={{paddingTop: '0.5rem'}}>
        <div className="width50">
          <label htmlFor={`location-typeahead-${location.id}`}>
            Providers <b>({careteamArray.length})</b>
          </label>
          <Typeahead
            uri={attendeeSearchURI}
            userId={authState.userId}
            elementId={`location-typeahead-${location.id}`}
            placeholder={'Add Providers for this Location...'}
            filter={array =>
              array.filter(
                element =>
                  element.type === 'Physician' || element.type === 'Careteam',
              )
            }
            handleSelection={addProviderToLocation}
            location={location}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="width100">
          <div
            id={`location-providers-container-${location.id}`}
            className="flex space-between wrap">
            {careteamArray.length && contactsState.contacts.length ? (
              renderLocationProviderCards(
                'Provider',
                careteamArray,
                contactsState.contacts,
                location,
                deleteProviderFromLocation,
                userProfile,
              )
            ) : (
              <span>No providers added to this location yet.</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const renderLocationProviderCards = (
  attendeeType,
  attendeesArray,
  contactsArray,
  location,
  handleDelete,
  userProfile,
) => {
  let outputArray = [];

  attendeesArray.forEach(attendee => {
    if (userProfile && attendee.toString() === userProfile.id.toString()) {
      outputArray.push(userProfile);
    }
    for (let index in contactsArray) {
      if ('id' in contactsArray[index] && contactsArray[index].id == attendee) {
        outputArray.push(contactsArray[index]);
        break;
      }
    }
  });

  return outputArray.map((attendee, index) => {
    return (
      <ContactCard
        key={index}
        type={attendeeType}
        contact={attendee}
        allowDelete={true}
        handleDelete={() => handleDelete(location, attendee.id)}
      />
    );
  });
};

const EventSettingsChecklistCard = ({checklistGroup, index}) => {
  const {
    deleteChecklistGroup,
    updateChecklistGroupField,
    addChecklistItem,
    updateChecklistItem,
    deleteChecklistItem,
    setDefaultChecklistGroup,
  } = useContext(EventSettingsContext);
  const {openAlert} = useContext(GlobalContext);

  return (
    <div className="checklist-group-card">
      <div
        className="flex align-center space-between"
        style={{marginBottom: '1rem'}}>
        <div className="width90">
          <h4>Checklist Group #{index}</h4>
        </div>

        <div className="width10 flex justify-end">
          <DeleteFieldButton
            handler={() => {
              deleteChecklistGroup(checklistGroup.id);
            }}
            title={`Delete Checklist Group #${index}`}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="width100">
          <label htmlFor={`checklist-group-name-${checklistGroup.id}`}>
            Checklist Group Name
          </label>
          <div>
            <input
              id={`checklist-group-name-${checklistGroup.id}`}
              type="text"
              placeholder="Custom Group Name..."
              value={
                checklistGroup.name
                  ? parseSanitizedString(checklistGroup.name)
                  : ''
              }
              onChange={event =>
                updateChecklistGroupField(
                  checklistGroup,
                  'name',
                  event.target.value,
                )
              }
            />
          </div>
        </div>
      </div>

      <div className="form-row" style={{paddingTop: '0.5rem'}}>
        <div className="width100 flex align-center">
          <input
            id={`set-default-checklist-${checklistGroup.id}`}
            type="checkbox"
            checked={
              checklistGroup.default && checklistGroup.default === 1
                ? true
                : false
            }
            onChange={event => {
              setDefaultChecklistGroup(checklistGroup.id, event.target.checked);
            }}
          />
          <label
            htmlFor={`set-default-checklist-${checklistGroup.id}`}
            style={{marginLeft: '0.5rem'}}>
            Set as Default
          </label>
          <span
            className="set-default-learn-more"
            onClick={() =>
              openAlert({
                body: 'You may select one pre-defined checklist group which will be added to your Cases automatically.',
                cancelText: 'Close',
              })
            }
            style={{marginLeft: '2rem'}}>
            Learn More
          </span>
        </div>
      </div>

      <div className="form-row" style={{paddingTop: '0.5rem'}}>
        <div className="width70">
          <label htmlFor={`checklist-items-${checklistGroup.id}`}>
            Checklist Items
          </label>
          <div>
            {checklistGroup.checklist.length > 0 &&
              checklistGroup.checklist.map((checklistItem, index) => {
                return (
                  <div key={index} className="flex align-center">
                    <div className="width70">
                      <input
                        id={`checklist-item-${checklistItem.id}`}
                        type="text"
                        placeholder={`Checklist Item #${index + 1}...`}
                        value={
                          checklistItem.name
                            ? parseSanitizedString(checklistItem.name)
                            : ''
                        }
                        onChange={event =>
                          updateChecklistItem(
                            checklistGroup,
                            checklistItem,
                            event.target.value,
                          )
                        }
                      />
                    </div>

                    {index !== 0 && (
                      <div
                        style={{
                          marginLeft: '14px',
                        }}>
                        <DeleteFieldButton
                          handler={() => {
                            deleteChecklistItem(
                              checklistGroup,
                              checklistItem.id,
                            );
                          }}
                          title={'Remove This Checklist Item'}
                        />
                      </div>
                    )}

                    {index === checklistGroup.checklist.length - 1 && (
                      <div
                        style={{
                          marginLeft: '14px',
                        }}>
                        <AddFieldButton
                          handler={() => {
                            const newChecklistItemId = Date.now();
                            addChecklistItem(
                              checklistGroup,
                              newChecklistItemId,
                            );
                          }}
                          title={'Add New Checklist Item'}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

const EventSettingsOverlay = () => {
  return <div id="event-settings-overlay"></div>;
};

export default EventSettings;
