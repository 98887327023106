import React, {useContext} from 'react';
import {Context as DocboxContext} from '../context/DocboxContext';
import {Context as GlobalContext} from '../context/GlobalContext';
import {DeleteFieldButton} from './ui/atoms';
import './AttachmentCard.scss';
import {updateFilePHIURI} from '../dataAccess/apiEndpoints';
import {getAttachmentThumbnail} from '../libraries/workers';

const AttachmentCard = ({file, allowEdit, handleDelete}) => {
  const {updateFilePHI} = useContext(DocboxContext);
  const {state: globalState} = useContext(GlobalContext);

  return (
    <div className="attachment-card width50 flex" data-id={file.id}>
      {allowEdit && (
        <DeleteFieldButton
          handler={
            handleDelete
              ? handleDelete
              : () => {
                  console.log(
                    'no delete handler for this attachment card component',
                  );
                }
          }
        />
      )}
      <div
        className="attachment-card-icon"
        style={{
          backgroundImage: `url(${getAttachmentThumbnail(
            file,
            globalState.config.ABS_BASE_URL,
          )})`,
        }}></div>
      <div
        className="flex direction-column justify-center"
        style={{marginLeft: '1rem', maxWidth: '230px'}}>
        <a
          href={file.url || file.preview}
          className="ellipsis"
          target="_blank"
          rel="noopener noreferrer"
          title={file.name}>
          <span>{file.name}</span>
        </a>
        {allowEdit && (
          <p className="flex align-center">
            <input
              type="checkbox"
              checked={file.phi == 1 ? true : false}
              onChange={() => {
                const newPHI = file.phi == 1 ? 0 : 1;
                const fileObj = {
                  ...file,
                  phi: newPHI,
                };
                const url = `${globalState.config.ABS_BASE_URL}${updateFilePHIURI}`;
                const request = {
                  phi_no: newPHI == 0 ? file.id.toString() : '',
                  phi_yes: newPHI == 1 ? file.id.toString() : '',
                };
                updateFilePHI(url, request, fileObj);
              }}
            />
            <span>
              <b>Has PHI?</b>
            </span>
          </p>
        )}
        {!allowEdit && (
          <p>
            <b>PHI:</b> {file.phi == 1 ? 'Yes' : 'No'}
          </p>
        )}
      </div>
    </div>
  );
};

export default AttachmentCard;
