import React, {useContext, useEffect, useState} from 'react';

import {Context as AuthContext} from '../context/AuthContext';
import {Context as GlobalContext} from '../context/GlobalContext';
import {Context as DocboxContext} from '../context/DocboxContext';
import {Context as ContactsContext} from '../context/ContactsContext';
import {Context as EventSettingsContext} from '../context/EventSettingsContext';
import Notification from './Notification';
import Alert from './Alert';
import DocboxPrint from './DocboxPrint';
import {formatFullName} from '../libraries/helpers';

import {
  contactsURI,
  jwtTokenURI,
  loginStatusURI,
  profileIndustryURI,
  profileURI,
} from '../dataAccess/apiEndpoints';

import './GlobalHeader.scss';

const GlobalHeader = () => {
  const {
    state: authState,
    loginStatus,
    getToken,
    getProfile,
  } = useContext(AuthContext);
  const {
    state: globalState,
    toggleNavMenu,
    setCurrentView,
    toggleUserMenu,
    getConfig,
  } = useContext(GlobalContext);
  const {state: contactsState, getContacts} = useContext(ContactsContext);
  const {state: docboxState} = useContext(DocboxContext);
  const {state: eventSettingsState} = useContext(EventSettingsContext);

  const [isLoading, setisLoading] = useState(true);

  const firstName =
    authState.userId && authState.userProfile
      ? authState['userProfile']['name']['first']
      : '';
  const lastName =
    authState.userId && authState.userProfile
      ? authState['userProfile']['name']['last']
      : '';
  const userName = formatFullName(firstName, lastName);

  // fetch config.json
  useEffect(() => {
    getConfig();
  }, []);

  // check login status on app initialization
  useEffect(() => {
    if (globalState.config.ABS_BASE_URL) {
      const url = `${globalState.config.ABS_BASE_URL}${loginStatusURI}`;
      // check login Status
      loginStatus(url);
    }
  }, [globalState.config.ABS_BASE_URL]);

  useEffect(() => {
    if (globalState.config.REACT_ABS_BASE_URL) {
      let currentView = window.location.href.split(
        globalState.config.REACT_ABS_BASE_URL,
      );
      currentView = currentView[currentView.length - 1] || '/';
      setCurrentView(currentView);
    }
  }, [globalState.config.REACT_ABS_BASE_URL]);

  // Prevents scroll on the main page when modal or overlay is open
  useEffect(() => {
    if (
      docboxState.isDocboxOpen ||
      eventSettingsState.isEventSettingsOpen ||
      globalState.isIdle
    ) {
      return document.body.classList.add('docbox-open');
    } else {
      return document.body.classList.remove('docbox-open');
    }
  }, [
    docboxState.isDocboxOpen,
    eventSettingsState.isEventSettingsOpen,
    globalState.isIdle,
  ]);

  // if isLoggedIn changes, ping the jwt API
  useEffect(() => {
    if (
      authState.isLoggedIn === true &&
      authState.jwt === null &&
      globalState.config.ABS_BASE_URL
    ) {
      const url = `${globalState.config.ABS_BASE_URL}${jwtTokenURI}`;
      getToken(url);
    }
  }, [authState.isLoggedIn, globalState.config.ABS_BASE_URL]);

  // if we have a token, get user profile
  useEffect(() => {
    if (
      authState.jwt !== null &&
      authState.userId &&
      authState.userType &&
      globalState.config.ABS_BASE_URL
    ) {
      const endpoint =
        authState.userType === 2 ? profileIndustryURI : profileURI;
      const url = `${globalState.config.ABS_BASE_URL}${endpoint}`;
      getProfile(url, authState.userId);
    }
  }, [
    authState.jwt,
    authState.userId,
    authState.userType,
    globalState.config.ABS_BASE_URL,
  ]);

  // finish loading if we are logged in and have a profile
  useEffect(() => {
    if (authState.isLoggedIn === true && authState.userProfile !== null) {
      setisLoading(false);
    }
  }, [authState.isLoggedIn, authState.userProfile]);

  // get Contacts list
  useEffect(() => {
    if (
      authState.userId &&
      !contactsState.contacts &&
      globalState.config.ABS_BASE_URL
    ) {
      const url = `${globalState.config.ABS_BASE_URL}${contactsURI}`;
      getContacts(url, authState.userId, null, null, null, null);
    }
  }, [authState.userId, globalState.config.ABS_BASE_URL]);

  // if (isLoading) {
  //   return <Loading />
  // }

  return (
    <>
      {/* <header id="global-header" className="no-select"> */}
      {/* <section> */}
      {/* hamburger menu icon */}
      {/* <FontAwesomeIcon
            id="nav-menu-icon"
            icon={faBars}
            size="lg"
            onClick={() => {
              toggleNavMenu();
            }}
          /> */}
      {/* <div
          className='logo'
            onClick={() => {
              handleLinkClick("/web/practice-dashboard/all-cases");
            }}
          ></div>
        </section> */}

      {/* {
          userName &&
          <section
            id='userMenu'
            tabIndex='0'
            onClick={() => {
                toggleUserMenu(!globalState.isUserMenuOpen);
            }}
            onBlur={() => toggleUserMenu(false)}
          >
          <span>{userName || 'User'}</span>
            <FontAwesomeIcon
              id="nav-user-caret"
              icon={faAngleDown}
            />

            {
              (globalState.isUserMenuOpen) &&
              <div
                id="user-menu"
              >
                <div
                  className="flex align-center space-between"
                  onClick={() => {
                    handleLinkClick(`${globalState.config.ABS_BASE_URL}/profile`)
                  }}
                >
                  <span>My Profile</span>
                  {
                    authState.userId &&
                    <span id="user-id">{authState.userId}</span>
                  }
                  </div>
                <div
                  onClick={() => {
                    // VELYS query param - DOC-4414
                    let query = buildQueryParams(window.name ? {'cl': false} : {})
                    handleLinkClick(`${globalState.config.ABS_BASE_URL}/logout?${query}`)
                  }}
                >
                  Logout
                  </div>
              </div>
            } */}
      {/* </section> */}
      {/* } */}

      {/* </header> */}

      {docboxState.isDocboxOpen && docboxState.mode === 'View' && (
        <DocboxPrint />
      )}

      {globalState.showNotification && <Notification />}

      {globalState.showAlert && <Alert />}

      {/* {
        (globalState.isIdle) &&
        <InactivityOverlay />
      } */}
    </>
  );
};

export default GlobalHeader;
