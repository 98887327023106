import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import {Provider as AuthProvider} from './context/AuthContext';
import {Provider as GlobalProvider} from './context/GlobalContext';
import {Provider as ContactsProvider} from './context/ContactsContext';
import {Provider as ScheduleProvider} from './context/ScheduleContext';
import {Provider as DocboxProvider} from './context/DocboxContext';
import {Provider as EventSettingsProvider} from './context/EventSettingsContext';
import {Provider as PracticeDashboardProvider} from './context/PracticeDashboardContext';

import GlobalHeader from './components/GlobalHeader';
import AutoLogoutTimer from './components/AutoLogoutTimer';
// Practice Dashboard
import AllCases from './components/PracticeDashboard/AllCases/AllCases';
import AllCasesRepDashboard from './components/PracticeDashboard/AllCasesRepDashboard/AllCasesRepDB';
import PhysiciansSchedule from './components/PracticeDashboard/PhysiciansSchedule/PhysiciansSchedule';
import PendingCases from './components/PracticeDashboard/PendingCases/PendingCases';
import PDSettings from './components/PracticeDashboard/PDSettings/PDSettings';
import AllCasesKSRC from './components/PracticeDashboard/AllCasesKSRC/AllCasesKSRC';
//UCSF Search Tool
import ScheduleSearch from './components/UCSFSearch/ScheduleSearch';

//DocSpera Schedule
import MyCalendar from './components/Schedule/MyCalendar';

import './App.scss';

function App() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <React.Fragment>
      <Router>
        <AuthProvider>
          <ContactsProvider>
            <GlobalProvider>
              <EventSettingsProvider>
                <DocboxProvider>
                  <ScheduleProvider>
                    <PracticeDashboardProvider>
                      <div className="page-container">
                        <GlobalHeader />

                        <main id="ds-main">
                          {/* Routes */}
                          <Switch>
                            {/* <Route exact path='/'>
                  <AutoLogoutTimer ComponentName={MySchedule} />
                </Route> */}

                            {/* FOR DEVELOPMENT IN A NORMAL CHROME BROWSER WINDOW */}
                            {/* Schedule */}
                            <Route exact path="/web">
                              <AutoLogoutTimer ComponentName={AllCases} />
                            </Route>

                            {/* Practice Dashboard */}
                            <Route path="/web/practice-dashboard/all-cases">
                              <AutoLogoutTimer ComponentName={AllCases} />
                            </Route>

                            {/* KSRC Dashboard */}
                            <Route path="/web/ksrc-dashboard">
                              <AutoLogoutTimer ComponentName={AllCasesKSRC} />
                            </Route>

                            {/* Rep Dashboard */}
                            <Route path="/web/rep-dashboard">
                              <AutoLogoutTimer
                                ComponentName={AllCasesRepDashboard}
                              />
                            </Route>

                            <Route path="/web/practice-dashboard/physicians-schedule">
                              <AutoLogoutTimer
                                ComponentName={PhysiciansSchedule}
                              />
                            </Route>

                            <Route path="/web/practice-dashboard/pending">
                              <AutoLogoutTimer ComponentName={PendingCases} />
                            </Route>

                            <Route path="/web/practice-dashboard/settings">
                              <AutoLogoutTimer ComponentName={PDSettings} />
                            </Route>

                            {/* UCSF Search Tool */}
                            <Route path="/web/ucsf-search-tool">
                              <AutoLogoutTimer ComponentName={ScheduleSearch} />
                            </Route>

                            {/* DocSpera Schedule */}
                            <Route exact path="/web/docspera-schedule">
                              <AutoLogoutTimer ComponentName={MyCalendar} />
                            </Route>
                          </Switch>
                          {/*/ Routes */}
                        </main>

                        {/* <GlobalFooter /> */}
                      </div>
                    </PracticeDashboardProvider>
                  </ScheduleProvider>
                </DocboxProvider>
              </EventSettingsProvider>
            </GlobalProvider>
          </ContactsProvider>
        </AuthProvider>
      </Router>
    </React.Fragment>
  );
}

export default App;
