import React from 'react';

const Airplane = ({color}) => {
  if (!color) {
    color = '#17b4e1';
  }

  return (
    <div style={{width: 35}}>
      <svg id="Layer_1" viewBox="0 0 44.43 39.15">
        <g>
          <path
            style={{fill: color}}
            d="M23.37,30.88c-2,2.52-3.95,5-5.94,7.39a5.24,5.24,0,0,1-1.31.87,4.5,4.5,0,0,1-.62-1.5c-.05-3.51,0-7,0-10.54A2.52,2.52,0,0,1,16.38,25q9.39-8.72,18.76-17.47a1.36,1.36,0,0,0,.48-.8l-7.15,4.57-18,11.57a1.7,1.7,0,0,1-2,.1C5.93,21.62,3.37,20.34.85,19,.47,18.76,0,18.32,0,18s.54-.72.93-.89q21-8.49,42-16.94A5.52,5.52,0,0,1,44.33,0a4.93,4.93,0,0,1,.07,1.58Q41,14.46,37.46,27.3q-1.1,4-2.16,8c-.36,1.31-.72,1.46-2,.78C30,34.39,26.78,32.67,23.37,30.88Z"
            transform="translate(0 0.01)"
          />
        </g>
      </svg>
    </div>
  );
};

export default Airplane;
