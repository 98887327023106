import React, {useContext, useRef, useState} from 'react';
import {Context as GlobalContext} from '../../../../../context/GlobalContext';

import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import './BillingTypeahead.scss';

const Typeahead = ({
  uri,
  codeType,
  elementId,
  filter,
  placeholder,
  handleSelection,
  procedure,
}) => {
  const ref = useRef();
  const {state: globalState} = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleSearch = query => {
    setIsLoading(true);

    const request = {
      search: query,
      type: codeType,
    };

    fetch(`${globalState.config.ABS_BASE_URL}${uri}`, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(request),
    })
      .then(resp => resp.json())
      .then(({result}) => {
        let options = result.map(code => ({
          code: code.code.toString(),
          short: code.short,
          description: code.description,
          highlight: code.highlight,
        }));

        if (filter) {
          options = filter(options);
        }

        if (codeType === 'cpt') {
          if (
            options.every(
              cpt => typeof cpt.code === 'string' && !isNaN(cpt.code),
            )
          ) {
            options = options.sort((a, b) => {
              return parseInt(a.code) - parseInt(b.code);
            });
          }
          if (options.findIndex(cpt => cpt.code === query.toString()) !== -1) {
            options = options.slice(
              options.findIndex(cpt => cpt.code === query.toString()),
            );
          }
        }

        setOptions(options);
        setIsLoading(false);
      });
  };

  const handleChange = event => {
    const code = event[0];
    handleSelection(codeType, code, procedure);
  };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  return (
    <AsyncTypeahead
      filterBy={filterBy}
      id={elementId ? elementId : ''}
      className="typeahead"
      isLoading={isLoading}
      labelKey="code"
      minLength={2}
      onSearch={handleSearch}
      onChange={event => {
        if (event.length > 0) {
          handleChange(event);
        }
      }}
      options={options}
      placeholder={placeholder ? placeholder : ''}
      renderMenuItemChildren={(option, props) => (
        <>
          <span>
            <b>{option.code}</b>
          </span>
          <span style={{marginLeft: '2rem'}}>{option.description}</span>
        </>
      )}
    />
  );
};

export default Typeahead;
