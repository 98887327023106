import React from 'react';
import './BillingCodeBadge.scss';
import {DeleteFieldButton} from '../..';

const BillingCodeBadge = ({type, code, handler}) => {
  return (
    <div className={`billing-code-badge flex align-center ${type}`}>
      <span>{code}</span>
      <DeleteFieldButton handler={handler} />
    </div>
  );
};

export default BillingCodeBadge;
