import React, {useContext, useRef, useState} from 'react';
import {Context as GlobalContext} from '../../../../context/GlobalContext';

import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import './Typeahead.scss';
import {AlomEncodeType2, formatFullName} from '../../../../libraries/helpers';

const Typeahead = ({
  uri,
  userId,
  elementId,
  filter,
  placeholder,
  handleSelection,
  inputSource,
  location,
  procedure,
}) => {
  const ref = useRef();
  const {state: globalState} = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleSearch = query => {
    setIsLoading(true);

    const request = {
      user: parseInt(AlomEncodeType2(userId)),
      search: query,
    };

    fetch(`${globalState.config.ABS_BASE_URL}${uri}`, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(request),
    })
      .then(resp => resp.json())
      .then(({results}) => {
        let options = results.contacts.map(contact => ({
          avatar: contact.avatar,
          id: contact.id,
          name: formatFullName(contact.name.first, contact.name.last),
          specialty: contact.specialty ? contact.specialty : '',
          type: contact.type,
        }));

        if (filter) {
          options = filter(options);
        }

        setOptions(options);
        setIsLoading(false);
      });
  };

  const handleChange = event => {
    const attendee = event[0];
    const source = inputSource
      ? inputSource
      : {
          default: 0,
          location: 0,
          manual: 1,
          other: 0,
          owner: 0,
          procedure: 0,
        };
    const locationParam = location ? location : null;
    const procedureParam = procedure ? procedure : null;

    handleSelection(attendee, source, locationParam, procedureParam);
  };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  return (
    <AsyncTypeahead
      filterBy={filterBy}
      id={elementId ? elementId : ''}
      className="typeahead"
      isLoading={isLoading}
      labelKey="name"
      minLength={2}
      onSearch={handleSearch}
      ref={ref}
      onChange={event => {
        ref.current.clear();
        handleChange(event);
      }}
      options={options}
      placeholder={placeholder ? placeholder : ''}
      renderMenuItemChildren={(option, props) => (
        <>
          <img
            // alt={option.name}
            src={`${globalState.config.ABS_BASE_URL}/a/${option.avatar}_64.jpg`}
            style={{
              borderRadius: '100%',
              height: '24px',
              marginRight: '10px',
              width: '24px',
            }}
          />
          <span>
            <b style={{fontWeight: '500'}}>{option.name}</b> &nbsp;{' '}
            {option.specialty ? `(${option.specialty})` : ''}
          </span>
        </>
      )}
    />
  );
};

export default Typeahead;
