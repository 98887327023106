import React, {useState} from 'react';
import {editNotesURI} from '../../../dataAccess/apiEndpoints';
import {AlomEncodeType2} from '../../../libraries/helpers';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuItem from '@mui/material/MenuItem';

const ModalContent = ({
  currentCase,
  setCurrentNotes,
  currentNotes,
  editNotes,
  setEditModalOpen,
  setNotesEditted,
  globalState,
  modalType,
  currentValue,
  splitNotes,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [typeValue, setTypeValue] = useState(currentValue);
  const statuses = ['Scheduled', 'Pre Op', 'Ready for OR', 'In OR', 'Closing', 'Post Op', 'Cancelled' ];
  const anes = [
    '––',
    'Ankle',
    'Bier Block',
    'Digital',
    'General',
    'Local',
    'MAC',
    'Nerve Block',
    'Spinal',
  ];
  const blockOrders = [
    '––',
    'Ankle',
    'Axillary',
    'Digital',
    'Fascia Iliac',
    'Femoral Nerve',
    'Infraclavicular',
    'Inframammary',
    'Interscalene',
    'Local',
    'Low Femoral',
    'Pectoralis Major',
    'PENG',
    'Popliteal',
    'Sciatic',
    'Supraclavicular',
  ];

  const getDropDownList = () => {
    if (modalType === 'status') {
      return statuses;
    } else if (modalType === 'anesthesia') {
      return anes;
    } else if (modalType === 'block') {
      return blockOrders;
    } else {
      return [];
    }
  };

  const handleEditClick = () => {
    if (globalState.config.ABS_BASE_URL) {
      const url = `${globalState.config.ABS_BASE_URL}${editNotesURI}`;
      if (modalType === 'sch-notes') {
        let extractedNotes =
          currentCase.notes && extractNotes(currentCase.notes);
        editNotes(
          url,
          AlomEncodeType2(currentCase.event_id),
          currentNotes + '|||' + extractedNotes,
        );
      } else {
        editNotes(url, AlomEncodeType2(currentCase.event_id), currentNotes);
      }
      // setNotesModalOpen(false);
      setEditModalOpen(false);
      setNotesEditted(true);
    }
  };

  const extractNotes = caseNotes => {
    let arr = caseNotes.split('|||');
    return arr
      .filter(
        str =>
          str.includes('status') ||
          str.includes('anesthesia') ||
          str.includes('anesthesia') ||
          str.includes('block'),
      )
      .join('|||');
  };

  const removePrevType = caseNotes => {
    let arr = caseNotes.split('|||');
    return arr.filter(str => !str.includes(modalType)).join('|||');
  };

  const handleChange = value => {
    if (currentCase.notes) {
      setCurrentNotes(
        removePrevType(currentCase.notes) + '|||' + modalType + ':' + value,
      );
    } else {
      setCurrentNotes(
        (currentNotes && splitNotes(currentNotes)) +
          '|||' +
          modalType +
          ':' +
          value,
      );
    }
    setTypeValue(value);
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div
        style={{
          marginBottom: '10px',
          color: 'var(--blueDocspera',
          fontWeight: '400',
        }}>
        {modalType === 'sch-notes'
          ? 'Scheduling Notes'
          : modalType.charAt(0).toUpperCase() + modalType.slice(1)}
      </div>
      <form>
        {modalType === 'sch-notes' ? (
          <textarea
            style={{
              height: '100px',
              width: '325px',
              padding: 10,
              border: '1px solid var(--blueDocspera)',
              borderRadius: '4px',
              margin: 0,
            }}
            value={currentNotes}
            onChange={e => setCurrentNotes(e.target.value)}></textarea>
        ) : (
          <Accordion
            sx={{width: '325px', borderColor: 'var(--blueDocspera)'}}
            onChange={() => setIsOpen(!isOpen)}
            expanded={isOpen}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon style={{color: 'black'}} />}
              // id="patient-anesthesia"
            >
              <Typography>{isOpen ? '' : typeValue}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {getDropDownList().map(a => (
                <MenuItem key={a} value={a} onClick={e => handleChange(a)}>
                  {a}
                </MenuItem>
              ))}
            </AccordionDetails>
          </Accordion>
        )}
        <div
          style={{display: 'flex', justifyContent: 'flex-end', marginTop: 20}}>
          <button
            type="button"
            className="btn-warning"
            style={{marginRight: 20}}
            // onClick={() => setNotesModalOpen(false)}
            onClick={() => setEditModalOpen(false)}>
            Cancel
          </button>
          <button
            type="button"
            className="btn-success"
            title="Book a New Event"
            onClick={() => handleEditClick()}>
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default ModalContent;
