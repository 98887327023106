import React from 'react';

function ClinicalIcon() {
  return (
    <svg viewBox="0 0 54.42 54.42">
      <circle fill="#FF933C" cx="27.21" cy="27.21" r="27.21" />
      <g id="GEqfZZ.tif">
        <path
          fill="#fff"
          d="M314.3,186.16c.74-.27,1-.06,1.26.58a6.4,6.4,0,0,0,7.23,4.36,6.48,6.48,0,0,0,5.24-6.53c0-2,0-4,0-6a6,6,0,0,1,5.62-6.06,6.1,6.1,0,0,1,6.27,5.27,1.84,1.84,0,0,0,.82,1,2.87,2.87,0,0,1,1.19,3.7,2.8,2.8,0,0,1-3.59,1.43,2.71,2.71,0,0,1-1.6-3.5,3.38,3.38,0,0,1,1.41-1.62c.74-.41.68-.88.54-1.48a4.87,4.87,0,0,0-9.58,1.19c-.05,2.17,0,4.35,0,6.52a7.4,7.4,0,0,1-6.6,7.2,7.49,7.49,0,0,1-8.12-5.63A2.76,2.76,0,0,1,314.3,186.16ZM340,182.63c-.21-.25-.29-.37-.39-.47-.33-.35-.67-.7-1-1-.1-.09-.32-.25-.34-.23-.19.19-.51.43-.48.62a1.5,1.5,0,0,0,1.43,1.43C339.41,183,339.65,182.76,340,182.63Z"
          transform="translate(-292.84 -144.82)"
        />
        <path
          fill="#fff"
          d="M307.43,174.45l1.82-1.22c.44.56.81,1.11,1.26,1.58a33.82,33.82,0,0,0,2.68,2.54,2.13,2.13,0,0,0,2.9,0c1.06-.88,2-1.88,3-2.86a11.57,11.57,0,0,0,1-1.3l1.84,1.26a15,15,0,0,1-5.07,5.1A1.49,1.49,0,0,0,316,181c0,.82-.17,1.64-.25,2.46-.14,1.48-.14,1.48-1.88,1.31-.16-1.17-.37-2.37-.49-3.59a1.87,1.87,0,0,0-1-1.67A14.11,14.11,0,0,1,307.43,174.45Z"
          transform="translate(-292.84 -144.82)"
        />
        <path
          fill="#fff"
          d="M322.15,173l-.62-.44c1.11-2.9,2.22-5.63,3.19-8.41a10.93,10.93,0,0,0,.4-2.87c.21-2.62-1.31-4-3.77-4.9-.23-.09-.61.09-.88.23a1.7,1.7,0,0,1-2.29-.43,1.64,1.64,0,0,1,.49-2.19,1.59,1.59,0,0,1,2.24.55,2.61,2.61,0,0,0,.88.78c3.84,1.69,4.84,3.29,4.33,7.43A26.34,26.34,0,0,1,322.15,173Z"
          transform="translate(-292.84 -144.82)"
        />
        <path
          fill="#fff"
          d="M308.09,172.5l-.95.56c-2.25-4.09-4.19-8.19-4-13a5.11,5.11,0,0,1,2.94-4.34c1-.54,1.88-1.13,2.83-1.7a1.57,1.57,0,0,1,2.12,2.27,1.35,1.35,0,0,1-2.08.29c-.75-.76-1.33-.39-2-.07-2.19,1.07-3,2.71-2.78,5.24a26.08,26.08,0,0,0,3.26,9.62Z"
          transform="translate(-292.84 -144.82)"
        />
      </g>
    </svg>
  );
}

export default ClinicalIcon;
