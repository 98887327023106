import React from 'react';
import './DeleteFieldButton.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

const DeleteFieldButton = ({handler, title, style}) => {
  return (
    <button
      type="button"
      className="btn-close small"
      onClick={handler}
      title={title ? title : null}
      style={style ? style : null}>
      <div>
        <FontAwesomeIcon icon={faTimes} />
      </div>
    </button>
  );
};

export default DeleteFieldButton;
