import React, {useContext} from 'react';
import {Context as PDContext} from '../../../context/PracticeDashboardContext';
import {Context as GlobalContext} from '../../../context/GlobalContext';
import {
  AlomEncodeType2,
  formatFullName,
  parseSanitizedString,
} from '../../../libraries/helpers';
import {determineAgeFromDob} from '../../../libraries/moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFemale, faMale} from '@fortawesome/free-solid-svg-icons';
import {
  calendarUpdateChecklistURI,
  getChecklistsURI,
} from '../../../dataAccess/apiEndpoints';
import moment from 'moment';

import './ChecklistsModalBody.scss';

const ChecklistsModalBody = () => {
  const {
    state: pdState,
    updateChecklistItemStatus,
    getChecklists,
  } = useContext(PDContext);
  const {
    state: globalState,
    setNotification,
    openAlert,
  } = useContext(GlobalContext);

  if (pdState.checklistsModal.event == undefined) {
    return null;
  }

  const encodedEventId = pdState.checklistsModal.event.event_id
    ? AlomEncodeType2(pdState.checklistsModal.event.event_id)
    : '';
  const patientFirst = pdState.checklistsModal.patient.name.first || '';
  const patientLast = pdState.checklistsModal.patient.name.last || '';
  const patientName =
    patientFirst || patientLast
      ? formatFullName(patientFirst, patientLast)
      : 'Patient Name N/A';
  const dob = pdState.checklistsModal.patient.dob
    ? moment(pdState.checklistsModal.patient.dob, 'YYYYMMDDHHmmss').format(
        'YYYYMMDD',
      )
    : '';
  const age = dob ? `${determineAgeFromDob(dob)} y` : '';
  const gender = pdState.checklistsModal.patient.gender
    ? pdState.checklistsModal.patient.gender
    : '';
  const readinessStatus = pdState.checklistsModal.readiness.status;
  let statusArray = [];
  let readinessText = '';
  let readinessClass = '';

  if (readinessStatus === 'Pending') {
    for (let x = 0; x < pdState.checklistsModal.checklists.length; x++) {
      for (
        let i = 0;
        i < pdState.checklistsModal.checklists[x].checklist.length;
        i++
      ) {
        statusArray.push(
          pdState.checklistsModal.checklists[x].checklist[i].status,
        );
      }
    }

    if (
      statusArray.includes('TBD') &&
      !statusArray.includes('Yes') &&
      !statusArray.includes('No')
    ) {
      readinessText = 'Not Started';
      readinessClass = 'not-started';
    } else if (
      statusArray.includes('TBD') &&
      (statusArray.includes('Yes') || statusArray.includes('No'))
    ) {
      readinessText = 'Not Yet';
      readinessClass = 'not-yet';
    }
  } else if (readinessStatus === 'Ready') {
    readinessText = 'Ready';
    readinessClass = 'ready';
  }

  const findGenderIcon = g => {
    if (g === 'Female') {
      return faFemale;
    } else {
      return faMale;
    }
  };

  const findGenderIconColor = g => {
    if (g === 'Female') {
      return 'patient-female';
    } else if (g === 'Male') {
      return 'patient-male';
    }
  };

  return (
    <div className="checklists-modal-body">
      <div className="flex align-center space-between">
        <div className="flex align-center">
          <FontAwesomeIcon
            icon={findGenderIcon(gender)}
            className={findGenderIconColor(gender)}
            style={{marginTop: '2px'}}
          />
          <p>{patientName}</p>
          {age && <p>{age}</p>}
        </div>

        {readinessClass && readinessText && (
          <div className="flex align-center">
            <div className={readinessClass}>{readinessText}</div>
          </div>
        )}
      </div>
      {pdState.checklistsModal.checklists &&
        pdState.checklistsModal.checklists.length > 0 && (
          <form
            onSubmit={event => {
              event.preventDefault();
              return false;
            }}>
            {pdState.checklistsModal.checklists.map((checklistGroup, index) => {
              return (
                <div className="checklist-group" key={index}>
                  <label>{checklistGroup.name}</label>
                  <div>
                    {checklistGroup.checklist &&
                      checklistGroup.checklist.length > 0 &&
                      checklistGroup.checklist.map((checklistItem, index) => {
                        return (
                          <div
                            key={index}
                            className="checklist-item flex align-center space-between">
                            <div className="width20">
                              <select
                                value={checklistItem.status}
                                onChange={event => {
                                  const url = `${globalState.config.ABS_BASE_URL}${calendarUpdateChecklistURI}`;

                                  event.target.setAttribute(
                                    'disabled',
                                    'disabled',
                                  );

                                  updateChecklistItemStatus(
                                    pdState.checklistsModal.checklists,
                                    checklistItem,
                                    event.target.value,
                                    url,
                                    encodedEventId,
                                  ).then(response => {
                                    if (
                                      response &&
                                      response.status === 200 &&
                                      response.data &&
                                      response.data.updated
                                    ) {
                                      const url = `${globalState.config.ABS_BASE_URL}${getChecklistsURI}`;

                                      getChecklists(url, encodedEventId).then(
                                        () => {
                                          event.target.removeAttribute(
                                            'disabled',
                                          );
                                          setNotification(
                                            'Checklist item updated successfully.',
                                          );
                                        },
                                      );
                                    } else {
                                      const alertBody = () => {
                                        return (
                                          <p>
                                            Please contact{' '}
                                            <a
                                              href="/web/contact"
                                              target="_blank"
                                              rel="noopener noreferrer">
                                              VELYS Insights Customer Support
                                            </a>
                                            .
                                          </p>
                                        );
                                      };

                                      openAlert({
                                        title: 'Error Updating Checklist Item',
                                        body: alertBody(),
                                        cancelText: 'Close',
                                      });

                                      event.target.removeAttribute('disabled');
                                    }

                                    return;
                                  });
                                }}>
                                <option value="TBD">TBD</option>
                                <option value="Yes">Yes</option>
                                <option value="No">N/A</option>
                              </select>
                            </div>
                            <div className="width80">
                              <p>{parseSanitizedString(checklistItem.name)}</p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            })}
          </form>
        )}
      {(!pdState.checklistsModal.checklists ||
        pdState.checklistsModal.checklists.length === 0) && (
        <p>No checklists added yet.</p>
      )}
    </div>
  );
};

export default ChecklistsModalBody;
