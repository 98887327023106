import React, {useContext, useEffect, useState} from 'react';
import moment from 'moment';
import {Context as PDContext} from '../../../context/PracticeDashboardContext';
import {Context as GlobalContext} from '../../../context/GlobalContext';
import {Context as ScheduleContext} from '../../../context/ScheduleContext';
import {Context as DocboxContext} from '../../../context/DocboxContext';
import {Context as AuthContext} from '../../../context/AuthContext';
import {
  editEventsURI,
  getAvailabilityCalendarURI,
  physiciansScheduleURI,
  settingsURI,
} from '../../../dataAccess/apiEndpoints';
import {Loading} from '../../ui/atoms';
import useInterval from '../../../hooks/useInterval';
import {AlomEncodeType2} from '../../../libraries/helpers';
import {triggerCreateDocbox} from '../../../libraries/util';
import {faCaretDown, faUser} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import GlobalNavMenu from '../../GlobalNavMenu';
import CalendarPlugin from './CalendarPlugin';
import CalendarPicker from '../../CalendarPicker';
import Airplane from '../../../assets/svg-components/Airplane';
import PDDropdown from '../PDDropdown/PDDropdown';

import './PhysiciansSchedule.scss';

const PhysiciansSchdule = () => {
  const {
    state: pdState,
    setTimeFilter,
    getPhysiciansSchedule,
    editEvents,
    getSettings,
    getAvailabilityCalendar,
    updateFilterFieldValue,
  } = useContext(PDContext);
  const {state: globalState} = useContext(GlobalContext);
  const {state: docboxState, openDocbox} = useContext(DocboxContext);
  const {state: authState} = useContext(AuthContext);
  const {state: scheduleState, setTargetUserId, setTargetUserName} = useContext(ScheduleContext);
  const [rescheduledEvents, setRescheduledEvents] = useState([]);
  const [workingHours, setWorkingHours] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false);

  useEffect(() => {
    const time = {
      start: moment().startOf('week').format('YYYYMMDD'),
      end: moment().endOf('week').format('YYYYMMDD'),
    };
    setTimeFilter(time.start, time.end);
    if (globalState.config.ABS_BASE_URL) {
      const url = `${globalState.config.ABS_BASE_URL}${settingsURI}`;
      getSettings(url);
    }
  }, [globalState.config.ABS_BASE_URL]);

  useEffect(() => {
    // set first physician as default
    if (
      pdState.physician === null &&
      pdState.settings &&
      pdState.settings.physicians
    ) {
      const physician = pdState.settings.physicians.some(
        p => p.id == authState.userId,
      )
        ? authState.userId
        : pdState.settings.physicians[0].id;
      handleInputChange('physician', parseInt(physician));
    }
  }, [pdState.settings]);

  // Set targetUserId & targetUserName for event settings / docbox purposes
  useEffect(() => {
    if (pdState.settings) {
      let targetUser = pdState.settings.physicians.find(
        x => x.id === pdState.physician,
      );
      setTargetUserId(pdState.physician);
      setTargetUserName(targetUser.name);
    }
  }, [pdState.physician]);

  useEffect(() => {
    if (
      pdState.time &&
      pdState.physician &&
      globalState.config.ABS_BASE_URL
    ) {
      const url = `${globalState.config.ABS_BASE_URL}${physiciansScheduleURI}`;
      getPhysiciansSchedule(url, pdState.time, pdState.physician);
    }
  }, [
    pdState.time,
    pdState.physician,
    globalState.config.ABS_BASE_URL,
  ]);

  useEffect(() => {
    if (pdState.time && pdState.physician && globalState.config.ABS_BASE_URL) {
      const url = `${globalState.config.ABS_BASE_URL}${getAvailabilityCalendarURI}`;
      getAvailabilityCalendar(
        url,
        pdState.time.start,
        pdState.time.end,
        AlomEncodeType2(parseInt(pdState.physician)),
      );
    }
  }, [pdState.time, pdState.physician, globalState.config.ABS_BASE_URL]);

  //refresh phys sch & cal when docbox case edited in parent window
  useEffect(() => {
    if (refreshPage) {
      const physSchUrl = `${globalState.config.ABS_BASE_URL}${physiciansScheduleURI}`;
      getPhysiciansSchedule(physSchUrl, pdState.time, pdState.physician);
      const availCalUrl = `${globalState.config.ABS_BASE_URL}${getAvailabilityCalendarURI}`;
      getAvailabilityCalendar(
        availCalUrl,
        pdState.time.start,
        pdState.time.end,
        AlomEncodeType2(parseInt(pdState.physician)),
      );
      setRefreshPage(false);
    }
  }, [refreshPage]);

  useEffect(() => {
    //listen to postMessage from iframe parent (DocSpera)
    const navigate = evt => {
      let evtOrigin;
      // LOCAL
      if (window.location.href.includes('http://docspera.localhost')) {
        evtOrigin = 'http://docspera.localhost';
        // STAGING
      } else if (window.location.href.includes('docvisor')) {
        evtOrigin = 'https://docvisor.com';
        // PRODUCTION
      } else if (window.location.href.includes('docspera')) {
        evtOrigin = 'https://docspera.com';
      } else {
        console.log('no origin to receive from');
        return;
      }

      if (evt.origin === evtOrigin) {
        if (evt.data.type === 'eventCreate') {
          let event = evt.data.payload;
          if (event) {
            setRefreshPage(true);
          }
        }
        if (evt.data.type === 'eventEdit') {
          let event = evt.data.payload;
          if (event) {
            setRefreshPage(true);
          }
        }
        if (evt.data.type === 'printTriggered') {
          //check if parent window detected cmd/ctrl + p
          let event = evt.data.payload;
          if (event) {
            window.print();
          }
        }
      }
    };

    // listen for ctrl(cmd) + p
    const checkPrintKey = e => {
      if (
        (e.ctrlKey || e.metaKey) &&
        (e.key == 'p' ||
          e.charCode == 16 ||
          e.charCode == 112 ||
          e.keyCode == 80)
      ) {
        e.preventDefault();
        window.print();
      }
    };

    window.addEventListener('keydown', checkPrintKey, false);
    window.addEventListener('message', navigate, false);

    //clean up
    return () => window.removeEventListener('message', navigate);
  }, []);

  useEffect(() => {
    // parse and format for businessHours prop
    //  [
    //   {
    //      daysOfWeek: [1, 2 ,3],
    //      startTime: '10:00'
    //      endTime: '18:00'
    //   }, ...
    // ]
    if (pdState.availabilityCalendar) {
      let hoursArray = [];

      let timesArray = Object.values(pdState.availabilityCalendar);

      for (let x = 0; x < timesArray.length; x++) {
        for (let y = 0; y < timesArray[x].length; y++) {
          if (timesArray[x][y] == '1') {
            let slotInterval = 15 * y;
            let hoursObj = {
              daysOfWeek: [x],
              startTime: moment('00:00', 'HH:mm')
                .add(slotInterval.toString(), 'minutes')
                .format('HH:mm'),
              endTime: moment('00:15', 'HH:mm')
                .add(slotInterval.toString(), 'minutes')
                .format('HH:mm'),
            };
            hoursArray.push(hoursObj);
          }
        }
      }
      setWorkingHours(hoursArray);
    }
  }, [pdState.availabilityCalendar]);

  useInterval(() => {
    if (
      pdState.time &&
      pdState.physician &&
      globalState.config.ABS_BASE_URL &&
      pdState.settings
    ) {
      const url = `${globalState.config.ABS_BASE_URL}${physiciansScheduleURI}`;
      let physician = 0;

      if (
        pdState.settings &&
        pdState.settings.physicians &&
        pdState.settings.physicians.some(p => p.id == pdState.physician)
      ) {
        physician = pdState.physician;
      } else {
        //if current user is not a physician
        physician =
          pdState.settings &&
          pdState.settings.physicians &&
          pdState.settings.physicians[0].id;
      }
      getPhysiciansSchedule(url, pdState.time, physician);
    }
  }, 1000 * 60);

  const handleDateSelect = event => {
    const start = moment(event.target.dataset.date)
      .startOf('week')
      .format('YYYYMMDD');
    const end = moment(event.target.dataset.date)
      .endOf('week')
      .format('YYYYMMDD');
    setTimeFilter(start, end);
  };

  const handleInputChange = (name, value) => {
    // Physician
    updateFilterFieldValue(name, parseInt(value));
  };

  const openDocboxCreate = e => {
    console.log('create');
    const currentTime = moment().format('HHmmss');
    const targetUserId = scheduleState.targetUserId;
    const areKeysPressed = e.shiftKey && e.metaKey;
    const currentWeekStart = moment().startOf('week').format('YYYYMMDD');
    const selectedWeekStart = pdState.time.start;

    if (selectedWeekStart === currentWeekStart) {
      //currentWeek: create case date = today's date
      const today = moment().format('YYYYMMDD');
      const todayStart = `${today}${currentTime}`;
      const todayEnd = moment(todayStart, 'YYYYMMDDHHmmss')
        .add(1, 'hours')
        .format('YYYYMMDDHHmmss');
      triggerCreateDocbox(todayStart, todayEnd, targetUserId, areKeysPressed);
    } else {
      //all other weeks: create case date = Monday of the week
      const selectedStartMon = moment(selectedWeekStart)
        .add(1, 'day')
        .format('YYYYMMDD');
      const selectedStart = `${selectedStartMon}${currentTime}`;
      const selectedEnd = moment(selectedStart, 'YYYYMMDDHHmmss')
        .add(1, 'hours')
        .format('YYYYMMDDHHmmss');
      triggerCreateDocbox(
        selectedStart,
        selectedEnd,
        targetUserId,
        areKeysPressed,
      );
    }
  };

  const callEditEvents = () => {
    if (globalState.config.ABS_BASE_URL) {
      const url = `${globalState.config.ABS_BASE_URL}${editEventsURI}`;
      const physician = pdState.physician;
      const events = rescheduledEvents;
      const scheduleUrl = `${globalState.config.ABS_BASE_URL}${physiciansScheduleURI}`;
      editEvents(url, physician, events).then(() =>
        getPhysiciansSchedule(scheduleUrl, pdState.time, pdState.physician),
      );
      setRescheduledEvents([]);
    }
  };

  return (
    <div className="physicians-schedule">
      <GlobalNavMenu />
      <div style={{width: '100%', marginTop: '20px'}}>
        <div
          className="content header"
          style={{justifyContent: 'space-between'}}>
          <div className="physician-schedule-subheader">
            <form>
              <div style={{display: 'flex', flexWrap: 'wrap'}}>
                {/* Week */}
                <div className="labelAndDropdown">
                  <CalendarPicker
                    id={'pd-week'}
                    name={'week'}
                    selectable={'week'}
                    value={pdState.time.start}
                    endValue={pdState.time.end}
                    handler={event => handleDateSelect(event)}
                    rescheduledEvents={rescheduledEvents}
                    setRescheduledEvents={setRescheduledEvents}
                    style={{width: '98%'}}
                  />
                </div>
                {/* Physician */}
                <PDDropdown
                  defaultValue={scheduleState.targetUserName}
                  selectedValue={pdState.physician}
                  handleInputChange={handleInputChange}
                  filterType="physician"
                  settings={pdState.settings}
                  tab="physician-schedule"
                />
              </div>
            </form>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: 20,
              }}
            >
              <div 
                className="icon-airplane" 
                style={{position: 'relative'}}
                onClick={() => callEditEvents()}
              >
                <Airplane
                  color={
                    rescheduledEvents.length > 0
                      ? 'var(--blueDocspera)'
                      : 'var(--light-gray)'
                  }
                />
                {rescheduledEvents.length > 0 && (
                  <div className="airplane-number">
                    <span>{rescheduledEvents.length}</span>
                  </div>
                )}
              </div>

              <button
                type="button"
                className="btn-success"
                title="Book a New Event"
                style={{
                  padding: '0 1.25rem',
                  marginLeft: 16,
                  minWidth: '150px',
                }}
                onClick={(e) => openDocboxCreate(e)}>
                Book Case
              </button>
            </div>
          </div>
        </div>
        {pdState.isLoading ? (
          <div className="schedule-loading">
            <Loading />
          </div>
        ) : (
          <CalendarPlugin
            events={pdState.physiciansCases}
            rescheduledEvents={rescheduledEvents}
            setRescheduledEvents={setRescheduledEvents}
            workingHours={workingHours}
          />
        )}
      </div>
    </div>
  );
};

export default PhysiciansSchdule;
