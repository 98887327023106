import axios from 'axios';

const fileUploadInstance = axios.create({
  withCredentials: true,
  headers: {
    'content-type': 'multipart/form-data',
  },
});

export default fileUploadInstance;
