import React from 'react';
import './AddFieldButton.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';

const AddFieldButton = ({handler, title}) => {
  return (
    <button
      type="button"
      className="btn-add small"
      onClick={handler}
      title={title ? title : null}>
      <div>
        <FontAwesomeIcon icon={faPlus} />
      </div>
    </button>
  );
};

export default AddFieldButton;
