import React, {useContext, useEffect, useState} from 'react';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faFemale,
  faMale,
  faUser,
} from '@fortawesome/free-solid-svg-icons';

import Docbox from '../../Docbox';
import Notes from '../Notes/Notes';
import Table from '../../Table/Table';
import {Loading, Toggle} from '../../ui/atoms';
import Pagination from '../Pagination/Pagination';
import GlobalNavMenu from '../../GlobalNavMenu';
import EventSettings from '../../EventSettings';
import PDDropdown from '../PDDropdown/PDDropdown';
import useInterval from '../../../hooks/useInterval';
import {triggerOpenDocbox} from '../../../libraries/util';
import ChecklistsModalBody from '../Checklists/ChecklistsModalBody';
import {Context as AuthContext} from '../../../context/AuthContext';
import {Context as DocboxContext} from '../../../context/DocboxContext';
import {Context as GlobalContext} from '../../../context/GlobalContext';
import {Context as ScheduleContext} from '../../../context/ScheduleContext';
import {Context as PDContext} from '../../../context/PracticeDashboardContext';
import {Context as EventSettingsContext} from '../../../context/EventSettingsContext';
import {
  getChecklistsURI,
  pendingCasesURI,
  settingsURI,
} from '../../../dataAccess/apiEndpoints';

import './PendingCases.scss';

const PendingCases = () => {
  const {
    state: pdState,
    getPendingCases,
    getSettings,
    updateFilterFieldValue,
    setMonthToggle,
    getChecklists,
    clearChecklistsModal,
  } = useContext(PDContext);
  const {state: docboxState, openDocbox} = useContext(DocboxContext);
  const {setTargetUserId, setTargetUserName} = useContext(ScheduleContext);
  const {state: eventSettingsState} = useContext(EventSettingsContext);
  const {state: authState} = useContext(AuthContext);
  const {state: globalState, openAlert, closeAlert} = useContext(GlobalContext);
  const [monthOptions, setMonthOptions] = useState();
  const [page, setPage] = useState(0);
  const [refreshPage, setRefreshPage] = useState(false);
  const offset = page * 10;

  useEffect(() => {
    if (globalState.config.ABS_BASE_URL) {
      const url = `${globalState.config.ABS_BASE_URL}${settingsURI}`;
      getSettings(url);
    }
    generateMonthOptions();
  }, [globalState.config.ABS_BASE_URL]);

  // reset page to 1 if physician or year/month changes
  useEffect(() => {
    setPage(0);
  }, [pdState.owner_id, pdState.year_month]);

  useEffect(() => {
    if (globalState.config.ABS_BASE_URL) {
      const url = `${globalState.config.ABS_BASE_URL}${pendingCasesURI}`;
      getPendingCases(
        url,
        pdState.owner_id,
        pdState.year_month,
        pdState.patient_readiness,
        offset,
      );
    }
  }, [
    pdState.owner_id,
    pdState.year_month,
    pdState.patient_readiness,
    page,
    globalState.config.ABS_BASE_URL,
    pdState.checklistsModal
  ]);

  useInterval(() => {
    if (globalState.config.ABS_BASE_URL) {
      const url = `${globalState.config.ABS_BASE_URL}${pendingCasesURI}`;
      getPendingCases(
        url,
        pdState.owner_id,
        pdState.year_month,
        pdState.patient_readiness,
        offset,
      );
    }
  }, 1000 * 60);


  //refresh pending cases when docbox case edited in parent window
  useEffect(() => {
    if (refreshPage) {
      const url = `${globalState.config.ABS_BASE_URL}${pendingCasesURI}`;
      getPendingCases(
        url,
        pdState.owner_id,
        pdState.year_month,
        pdState.patient_readiness,
        offset,
      );
      setRefreshPage(false);
    }
  }, [refreshPage]);

  useEffect(() => {
    //listen to postMessage from iframe parent (DocSpera)
    const navigate = evt => {
      let evtOrigin;
      // LOCAL
      if (window.location.href.includes('http://docspera.localhost')) {
        evtOrigin = 'http://docspera.localhost';
        // STAGING
      } else if (window.location.href.includes('docvisor')) {
        evtOrigin = 'https://docvisor.com';
        // PRODUCTION
      } else if (window.location.href.includes('docspera')) {
        evtOrigin = 'https://docspera.com';
      } else {
        console.log('no origin to receive from');
        return;
      }

      if (evt.origin === evtOrigin) {
        if (evt.data.type === 'eventEdit') {
          let event = evt.data.payload;
          if (event) {
            setRefreshPage(true);
          }
        }
      }
    };

    window.addEventListener('message', navigate, false);

    //clean up
    return () => window.removeEventListener('message', navigate);
  }, []);


  useEffect(() => {
    if (pdState.monthToggle) {
      handleInputChange('year_month', parseInt(moment().format('YYYYMM')));
    } else {
      handleInputChange('year_month', null);
    }
  }, [pdState.monthToggle]);

  const handleInputChange = (name, value) => {
    if (name === 'physician') {
      if (value !== 'null') {
        value = parseInt(value);
      }
    }
    if (value === 'null') {
      value = null;
    }
    updateFilterFieldValue(name, value);
  };

  let options = [];
  const generateMonthOptions = () => {
    let now = moment();
    let startMonth = now.clone().subtract(24, 'months').startOf('year');
    let endMonth = now.clone().add(24, 'months').endOf('year').add(1, 'month');
    let month = startMonth.clone();
    while (month.isBefore(endMonth, 'month')) {
      options.push(month.format('YYYYMM'));
      month.add(1, 'month');
    }
    setMonthOptions(options);
  };

  const handleRowClick = item => {
    console.log({id: item.event_id});
    triggerOpenDocbox(item.event_id);
    // const mode = 'View'
    // const viewAsUserId = authState.userId ? authState.userId : null
    // // Set targetUserId & targetUserName for event settings / docbox purposes
    // setTargetUserId(item.owner.id)
    // setTargetUserName(`${item.owner.name.first} ${item.owner.name.last}`)
    // if (viewAsUserId && globalState.config.ABS_BASE_URL) {
    //   const url = `${globalState.config.ABS_BASE_URL}${viewCaseURI}`
    //   openDocbox(mode, item.event_id.toString(), url, viewAsUserId, null, authState.userType)
    // }
  };

  const onPatientReadinessClick = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    const encodedEventId = item.event_id;
    if (globalState.config.ABS_BASE_URL && encodedEventId) {
      const url = `${globalState.config.ABS_BASE_URL}${getChecklistsURI}`;
      getChecklists(url, encodedEventId).then(() => {
        openAlert({
          title: 'Checklists',
          body: <ChecklistsModalBody />,
          cancelText: 'Close',
          cancelHandler: () => {
            clearChecklistsModal();
            closeAlert();
          },
        });
      });
    }
  };

  return (
    <>
      <GlobalNavMenu />
      <div id="pending-cases" style={{width: '100%'}}>
        <div className="content header">
          <form style={{display: 'flex', justifyContent: 'space-between', paddingBottom: '10px'}}>
            {/* <div>
            <h1 className='pending-cases-header'>Pending Cases</h1>
            {pdState.pendingCases.events && (
              <p style={{ marginTop: 10 }}>{pdState.pendingCases.paginate.total} patients</p>
            )}
          </div> */}
            <div style={{display: 'inline-flex'}}>
              {/* Month Toggle */}
              <div className="labelAndDropdown">
                <Toggle
                  id="agenda-cal-clinical"
                  toggled={pdState.monthToggle}
                  handler={() => setMonthToggle(!pdState.monthToggle)}
                  onText="Month"
                  offText="All"
                  large
                />
              </div>

              {/* Month Dropdown */}
              {pdState.monthToggle && (
                <div className="labelAndDropdown" style={{paddingBottom: 0}}>
                  <label htmlFor="year_month">Month:</label>
                  <select
                    id="year_month"
                    name="year_month"
                    value={pdState.year_month}
                    onChange={event =>
                      handleInputChange(event.target.name, event.target.value)
                    }>
                    {monthOptions.map(option => {
                      return (
                        <option
                          selected={
                            option === moment().format('YYYYMM') ? true : false
                          }
                          value={option}>
                          {' '}
                          {moment(option, 'YYYYMM').format('MMM YYYY')}{' '}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}

              {/* Physicians */}
              <PDDropdown
                defaultValue={''}
                selectedValue={pdState.owner_id}
                handleInputChange={handleInputChange}
                filterType="physician"
                settings={pdState.settings}
                tab="pending-cases"
              />
            </div>
            {pdState.pendingCases.events &&
              pdState.pendingCases.events.length > 0 && (
                <Pagination
                  total={pdState.pendingCases.paginate.total}
                  page={page}
                  setPage={setPage}
                  isLoading={pdState.isLoading}
                />
              )}
          </form>
        </div>
        <div style={{width: '100%'}}>
          {pdState.isLoading ? (
            <Loading />
          ) : pdState.pendingCases.events &&
            pdState.pendingCases.events.length > 0 ? (
            <>
              <Table
                data={pdState.pendingCases.events}
                cols={tableConstants(onPatientReadinessClick)}
                brand="docspera"
                handleRowClick={handleRowClick}
              />
            </>
          ) : (
            <div className="no-cases">
              <h3>No Pending Cases Available for your Search Criteria</h3>
              <p>Try a different combination of Month and Physician above.</p>
            </div>
          )}
        </div>
      </div>
      {eventSettingsState.isEventSettingsOpen && <EventSettings />}
    </>
  );
};

const getDuration = (start, end) => {
  let duration = moment.duration(
    moment(end, 'YYYYMMDDHHmmss').diff(moment(start, 'YYYYMMDDHHmmss')),
  );
  if (duration.asMinutes() < 60) {
    return `${duration.asMinutes()} m`;
  }
  if (duration.asHours() % 1 !== 0) {
    return `${Math.floor(duration.asHours())} h ${
      (duration.asHours() % 1) * 60
    } m`;
  }
  return `${duration.asHours()} h`;
};

const findText = (percent, checklistLinked) => {
  if (!checklistLinked) {
    return 'N/A';
  } else {
    if (percent === 0) {
      return `${percent}%`;
    } else if (percent > 0 && percent < 100) {
      return `${percent}%`;
    } else if (percent === 100) {
      return 'Ready';
    }
  }
};

const findStyle = (percent, checklistLinked) => {
  if (!checklistLinked) {
    return 'not-linked-to-checklist';
  } else {
    if (percent === 0) {
      return 'not-started';
    } else if (percent > 0 && percent < 100) {
      return `started`;
    } else if (percent === 100) {
      return 'ready';
    }
  }
};

const tableConstants = onPatientReadinessClick => {
  return [
    {
      title: 'Surgery \nDate',
      render: rowData => {
        return (
          <div className="date-td">
            <span>
              {moment(rowData.times.start, 'YYYYMMDDHHmmss').format(
                'MMM D, YYYY',
              )}
            </span>
            <p>
              {moment(rowData.times.start, 'YYYYMMDDHHmmss').format('h:mma')} -{' '}
              {moment(rowData.times.end, 'YYYYMMDDHHmmss').format('h:mma')}
            </p>
            <div>
              <span>
                ({getDuration(rowData.times.start, rowData.times.end)})
              </span>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Main \nPhysician',
      render: rowData => {
        return (
          <div className={'physician-td'}>
            <span>
              {rowData.owner.name.last}, {rowData.owner.name.first}
            </span>
          </div>
        );
      },
    },
    {
      title: 'Surgery / Case Name \nPatient',
      render: rowData => {
        const findGenderIcon = g => {
          if (g === 'F') {
            return faFemale;
          } else {
            return faMale;
          }
        };

        const findGenderIconColor = g => {
          if (g === 'F') {
            return 'patient-female';
          } else if (g === 'M') {
            return 'patient-male';
          }
        };
        return (
          <div className={'case-td'}>
            <span> {rowData.case}</span>
            <p className="patient-name">
              {rowData.patient.name.first} {rowData.patient.name.last}
            </p>
            <div>
              {rowData.type !== 'Conference' && rowData.type !== 'Vacation' && (
                <FontAwesomeIcon
                  icon={findGenderIcon(rowData.patient.gender)}
                  className={findGenderIconColor(rowData.patient.gender)}
                  style={{marginTop: '2px'}}
                />
              )}
              {rowData.patient.dob && rowData.patient.dob !== 0 ? (
                <span>{moment().diff(rowData.patient.dob, 'years')} y</span>
              ) : (
                <span></span>
              )}
              <span>{rowData.patient.mrn}</span>
            </div>
            <div>
              {/* {rowData.type !== 'Conference' && rowData.type !== 'Vacation' && (
                <FontAwesomeIcon
                  icon={findGenderIcon(rowData.patient.gender)}
                  className={findGenderIconColor(rowData.patient.gender)}
                  style={{ marginTop: '2px' }}
                />
              )}

              */}
            </div>
          </div>
        );
      },
    },
    {
      title: 'Readiness',
      render: rowData => {
        return (
          <div
            className={`readiness-td patient-readiness ${findStyle(
              parseInt(rowData.readiness_percent),
              rowData.checklists_linked,
            )}`}
            onClick={e => onPatientReadinessClick(e, rowData)}>
            <span>
              {findText(
                parseInt(rowData.readiness_percent),
                rowData.checklists_linked,
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: 'Case Notes',
      render: rowData => {
        return (
          <div className="notes">
            {rowData.notes &&
              (rowData.notes.length < 75 || rowData.notes === null) && (
                <span style={{display: 'block'}}>{rowData.notes}</span>
              )}
            {rowData.notes && rowData.notes.length > 75 && (
              <Notes notes={rowData.notes} />
            )}
          </div>
        );
      },
    },
    {
      title: 'Reason for \nCancellation',
      render: rowData => {
        return (
          <div>
            <span>{rowData.reason_cancellation}</span>
          </div>
        );
      },
    },
  ];
};

export default PendingCases;
