export default function buildCalendar(value) {
  const startDay = value.clone().startOf('week').subtract(15, 'minutes');
  const endDay = value.clone().endOf('week');

  const day = startDay.clone();
  const calendar = [];
  // calendar algorithm
  while (day.isBefore(endDay, 'day')) {
    calendar.push(
      Array(96)
        .fill(0)
        .map(() => day.add(15, 'minutes').clone()),
    );
  }
  return calendar;
}
