import React, {useContext} from 'react';
import {Context as AuthContext} from '../context/AuthContext';
import {Context as DocboxContext} from '../context/DocboxContext';
import {Context as GlobalContext} from '../context/GlobalContext';
import {Context as ScheduleContext} from '../context/ScheduleContext';
import './DocboxHeader.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPen, faTimes} from '@fortawesome/free-solid-svg-icons';
import {faCommentAlt} from '@fortawesome/free-regular-svg-icons';
import {
  determineAgeFromDob,
  differenceBtwnTwoIsos,
  renderFriendlyDateTime,
  validateStartAndEndTime,
} from '../libraries/moment';
import {
  formatFullName,
  hasSharedCalendarPermission,
  isAuthorizedEditor,
} from '../libraries/helpers';
import {caseCreateJSONPayload, caseEditJSONPayload} from '../libraries/workers';
import printIcon from '../assets/img/icons/print.svg';
import {
  agendaURI,
  calendarCreateURI,
  calendarEditURI,
  calendarMonthURI,
  draftsURI,
  viewCaseURI,
} from '../dataAccess/apiEndpoints';

const DocboxHeader = () => {
  const {state: authState} = useContext(AuthContext);
  const {state: globalState} = useContext(GlobalContext);
  const {
    state: scheduleState,
    getAgenda,
    getMonth,
    getMyDraftsCount,
    getDrafts,
  } = useContext(ScheduleContext);
  const {
    state: docboxState,
    openDocbox,
    closeDocbox,
    createCalendarEvent,
    editCalendarEvent,
    updateFormFieldValue,
  } = useContext(DocboxContext);
  const {setNotification, openAlert, closeAlert} = useContext(GlobalContext);

  const handleCalendarSave = (
    destination,
    inputState,
    event,
    reasonCancellation,
  ) => {
    const elementId = event.target.id;
    const originalText = event.target.innerHTML;
    const startTime = `${inputState.startDate}${inputState.startTime}00`;
    const endTime = `${inputState.endDate}${inputState.endTime}00`;

    let missingRequired = false;

    if (inputState.caseName == '') {
      setNotification('Please enter a case name.');
      document.getElementById('FIELD_case-name').focus();
      missingRequired = true;
    }

    if (validateStartAndEndTime(startTime, endTime) === false) {
      setNotification('End time is earlier than start time.');
      missingRequired = true;
    }

    if (missingRequired) {
      reenableSaveButtons(elementId, originalText);
    } else {
      const alertBody = () => {
        return (
          <p>
            Please contact{' '}
            <a href="/web/contact" target="_blank" rel="noopener noreferrer">
              VELYS Insights Customer Support
            </a>
            .
          </p>
        );
      };

      let requestPayload = null;
      let target = 1;

      if (destination === 'Pending') {
        target = 0;
      }

      document.getElementById(elementId).classList.add('disabled');
      document.getElementById(elementId).innerHTML = 'Please Wait...';

      if (elementId === 'docbox-save-event-btn') {
        document
          .getElementById('docbox-pending-event-btn')
          .classList.add('hidden');
      }
      if (elementId === 'docbox-pending-event-btn') {
        document
          .getElementById('docbox-save-event-btn')
          .classList.add('hidden');
      }

      if (inputState.mode === 'Create') {
        requestPayload = caseCreateJSONPayload(
          inputState,
          target,
          reasonCancellation,
        );

        if (requestPayload) {
          const url = `${globalState.config.ABS_BASE_URL}${calendarCreateURI}`;
          createCalendarEvent(url, requestPayload).then(response => {
            const getAgendaURL = `${globalState.config.ABS_BASE_URL}${agendaURI}`;
            const getMonthURL = `${globalState.config.ABS_BASE_URL}${calendarMonthURI}`;
            const getDraftsURL = `${globalState.config.ABS_BASE_URL}${draftsURI}`;
            getAgenda(
              getAgendaURL,
              scheduleState.targetUserId,
              scheduleState.selectedCalendarDate,
            );
            getMonth(
              getMonthURL,
              scheduleState.selectedCalendarMonth,
              scheduleState.targetUserId,
            );
            getMyDraftsCount(getDraftsURL, authState.userId);
            getDrafts(getDraftsURL, scheduleState.targetUserId);
            if (
              response &&
              response.status === 200 &&
              response.data.status === 'Created'
            ) {
              closeDocbox();
              setNotification('Case created successfully.');
            } else {
              reenableSaveButtons(elementId, originalText);
              openAlert({
                title: 'Error Creating Case',
                body: alertBody(),
                cancelText: 'Close',
              });
            }
          });
        }
      }
      if (inputState.mode === 'Edit') {
        requestPayload =
          reasonCancellation !== null
            ? caseEditJSONPayload(inputState, target, reasonCancellation)
            : caseEditJSONPayload(inputState, target);

        if (requestPayload) {
          const url = `${globalState.config.ABS_BASE_URL}${calendarEditURI}`;
          editCalendarEvent(url, requestPayload).then(response => {
            const getAgendaURL = `${globalState.config.ABS_BASE_URL}${agendaURI}`;
            const getMonthURL = `${globalState.config.ABS_BASE_URL}${calendarMonthURI}`;
            const getDraftsURL = `${globalState.config.ABS_BASE_URL}${draftsURI}`;
            getAgenda(
              getAgendaURL,
              scheduleState.targetUserId,
              scheduleState.selectedCalendarDate,
            );
            getMonth(
              getMonthURL,
              scheduleState.selectedCalendarMonth,
              scheduleState.targetUserId,
            );
            getMyDraftsCount(getDraftsURL, authState.userId);
            getDrafts(getDraftsURL, scheduleState.targetUserId);
            if (
              response &&
              response.status === 200 &&
              response.data.status === 'Updated'
            ) {
              const viewCaseURL = `${globalState.config.ABS_BASE_URL}${viewCaseURI}`;
              const targetUserId = scheduleState.targetUserId
                ? scheduleState.targetUserId
                : docboxState.eventOwnerId;
              openDocbox(
                'View',
                inputState.eventId,
                viewCaseURL,
                targetUserId,
                null,
                authState.userType,
                null,
                null,
                null,
              );
              setNotification('Case saved successfully.');
            } else {
              openAlert({
                title: 'Error Saving Case',
                body: alertBody(),
                cancelText: 'Close',
              });
            }
          });
        }
      }
    }
    return;
  };

  const reenableSaveButtons = (elementId, originalText) => {
    document.getElementById(elementId).classList.remove('disabled');
    document.getElementById(elementId).innerHTML = originalText;

    if (elementId === 'docbox-save-event-btn') {
      document
        .getElementById('docbox-pending-event-btn')
        .classList.remove('hidden');
    }
    if (elementId === 'docbox-pending-event-btn') {
      document
        .getElementById('docbox-save-event-btn')
        .classList.remove('hidden');
    }
  };

  return (
    <header id="docbox-header" className="flex align-center space-between">
      <section className="flex align-center">
        <div>
          {docboxState.mode === 'Create' && (
            <b id="case-title">Create a New Case</b>
          )}
          {docboxState.mode === 'View' && (
            <p id="case-title">{docboxState.caseName}</p>
          )}
          {docboxState.mode === 'Edit' && (
            <p id="case-title">Edit: {docboxState.caseName}</p>
          )}
          {(docboxState.mode === 'View' || docboxState.mode === 'Edit') && (
            <p id="patient-title">
              {(docboxState.patientFirst || docboxState.patientLast) && (
                <span>
                  {formatFullName(
                    docboxState.patientFirst,
                    docboxState.patientLast,
                  )}
                </span>
              )}
              {docboxState.dobYear &&
                docboxState.dobMonth &&
                docboxState.dobDay && (
                  <span>
                    {determineAgeFromDob(
                      `${docboxState.dobYear}${docboxState.dobMonth}${docboxState.dobDay}`,
                    )}{' '}
                    y
                  </span>
                )}
              {docboxState.gender && <span>{docboxState.gender}</span>}
            </p>
          )}
        </div>
        <div
          style={{
            borderRight: '1px solid var(--light-gray)',
            margin: '0',
            height: '34px',
          }}></div>
        <div className="flex space-between">
          {(docboxState.mode === 'Create' || docboxState.mode === 'Edit') && (
            <div>
              <p>Case Owner</p>
              <p>
                {docboxState.eventOwnerName
                  ? docboxState.eventOwnerName
                  : scheduleState.targetUserName}
              </p>
            </div>
          )}
          {docboxState.mode === 'View' && (
            <>
              <div>
                <p>Time</p>
                <p>
                  {renderFriendlyDateTime(
                    `${docboxState.startDate}${docboxState.startTime}00`,
                    `${docboxState.endDate}${docboxState.endTime}00`,
                  )}
                </p>
              </div>
              <div>
                <p>Duration</p>
                <p>
                  {differenceBtwnTwoIsos(
                    `${docboxState.startDate}${docboxState.startTime}00`,
                    `${docboxState.endDate}${docboxState.endTime}00`,
                    true,
                  ) || 'N/A'}
                </p>
              </div>
            </>
          )}
        </div>
      </section>
      <section className="flex align-center nowrap">
        {docboxState.mode !== 'View' && (
          <>
            <button
              type="button"
              id="docbox-pending-event-btn"
              className="btn-primary"
              onClick={event => {
                if (!docboxState.reasonCancellation) {
                  const handlePendingCancel = () => {
                    updateFormFieldValue('reasonCancellation', '');
                    closeAlert();
                  };
                  const handlePendingConfirm = () => {
                    const selectedReason = document.getElementById(
                      'FIELD_reason-cancellation',
                    ).value;

                    if (selectedReason == '') {
                      setNotification(
                        'Please select a reason for cancellation',
                      );
                      document
                        .getElementById('FIELD_reason-cancellation')
                        .focus();
                      return false;
                    }

                    closeAlert();
                    handleCalendarSave(
                      'Pending',
                      docboxState,
                      event,
                      selectedReason,
                    );
                  };

                  if (docboxState.caseName == '') {
                    setNotification('Please enter a case name.');
                    document.getElementById('FIELD_case-name').focus();
                    return false;
                  }

                  openAlert({
                    title: 'Save to Pending Cases?',
                    body: <PendingAlertBody />,
                    cancelHandler: handlePendingCancel,
                    okHandler: handlePendingConfirm,
                    okText: 'Confirm',
                  });
                } else {
                  handleCalendarSave('Pending', docboxState, event);
                }
              }}>
              Pending
            </button>
            <button
              type="button"
              id="docbox-save-event-btn"
              className="btn-success"
              onClick={event => {
                if (docboxState.target === 0) {
                  const handleSaveConfirm = () => {
                    closeAlert();
                    handleCalendarSave('Save', docboxState, event, '');
                  };

                  openAlert({
                    body: '*** MOVE THIS CASE OUT OF PENDING? ***',
                    okText: 'Confirm',
                    okHandler: handleSaveConfirm,
                  });
                } else if (docboxState.patientStatus === 'Cancelled') {
                  handleCalendarSave('Pending', docboxState, event);
                } else {
                  handleCalendarSave('Save', docboxState, event);
                }
              }}>
              Save
            </button>
          </>
        )}
        {docboxState.mode === 'View' &&
        globalState.currentView !== '/web/practice-dashboard/all-cases' &&
        globalState.currentView !==
          '/web/practice-dashboard/physicians-schedule' &&
        globalState.currentView !== '/web/practice-dashboard/pending' &&
        globalState.currentView !== '/web/rebook' &&
        (authState.userId == docboxState.eventOwnerId ||
          hasSharedCalendarPermission(
            scheduleState.sharedSchedules,
            docboxState.eventOwnerId,
          ) ||
          isAuthorizedEditor(
            docboxState.providers,
            scheduleState.targetUserId,
          )) ? (
          <button
            type="button"
            id="docbox-edit"
            className="btn-success circle"
            onClick={() =>
              openDocbox(
                'Edit',
                null,
                null,
                null,
                null,
                authState.userType,
                null,
                null,
                docboxState.eventType,
              )
            }>
            <FontAwesomeIcon icon={faPen} />
          </button>
        ) : (
          docboxState.mode === 'View' &&
          authState.practicePermissions &&
          authState.practicePermissions[0] &&
          authState.practicePermissions[0].permission > 1 && (
            <button
              type="button"
              id="docbox-edit"
              className="btn-success circle"
              onClick={() =>
                openDocbox(
                  'Edit',
                  null,
                  null,
                  null,
                  null,
                  authState.userType,
                  null,
                  null,
                  docboxState.eventType,
                )
              }>
              <FontAwesomeIcon icon={faPen} />
            </button>
          )
        )}
        {docboxState.mode === 'View' &&
        globalState.currentView !== '/web/practice-dashboard/all-cases' &&
        globalState.currentView !==
          '/web/practice-dashboard/physicians-schedule' &&
        globalState.currentView !== '/web/practice-dashboard/pending' &&
        globalState.currentView !== '/web/rebook' &&
        docboxState.threadId ? (
          <button
            type="button"
            className="btn-success circle"
            onClick={() =>
              window.location.assign(
                `${globalState.config.ABS_BASE_URL}/live-messages/read/${docboxState.threadId}`,
              )
            }>
            <FontAwesomeIcon icon={faCommentAlt} />
          </button>
        ) : (
          docboxState.mode === 'View' &&
          ((authState.practicePermissions &&
            authState.practicePermissions[0] &&
            authState.practicePermissions[0].permission > 1) ||
            (Array.isArray(docboxState.providers) &&
              docboxState.providers.length > 0 &&
              docboxState.providers.some(
                provider => provider.id == authState.userId,
              ))) && (
            <button
              type="button"
              className="btn-success circle"
              onClick={() =>
                window.location.assign(
                  `${globalState.config.ABS_BASE_URL}/live-messages/read/${docboxState.threadId}`,
                )
              }>
              <FontAwesomeIcon icon={faCommentAlt} />
            </button>
          )
        )}
        {docboxState.mode === 'View' && (
          <button
            type="button"
            className="btn-success circle"
            onClick={() => window.print()}>
            <img src={printIcon} alt="Print" />
          </button>
        )}
        <button
          type="button"
          id="docbox-close-btn"
          className="btn-close"
          onClick={event => {
            if (docboxState.mode === 'Edit' || docboxState.mode === 'Create') {
              console.log('clicked');
              const handleCancelExit = () => {
                closeAlert();
              };
              const handleEditExit = () => {
                closeAlert();
                const url = `${globalState.config.ABS_BASE_URL}${viewCaseURI}`;
                const targetUserId = scheduleState.targetUserId
                  ? scheduleState.targetUserId
                  : docboxState.eventOwnerId;
                openDocbox(
                  'View',
                  docboxState.eventId,
                  url,
                  targetUserId,
                  null,
                  authState.userType,
                  null,
                  null,
                  null,
                );
              };
              const handleCreateExit = () => {
                closeAlert();
                closeDocbox();
              };
              openAlert({
                title:
                  docboxState.mode === 'Create'
                    ? 'Cancel creating a case?'
                    : 'Cancel editing this case?',
                body: <ExitingAlertBody />,
                cancelHandler: handleCancelExit,
                okHandler:
                  docboxState.mode === 'Create'
                    ? handleCreateExit
                    : handleEditExit,
                cancelText: 'Stay',
                okText: 'Exit',
              });
            } else {
              closeDocbox();
            }
          }}>
          <div>
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </div>
        </button>
      </section>
    </header>
  );
};

const PendingAlertBody = () => {
  return (
    <div>
      <p style={{marginTop: '0'}}>
        Please select a reason for event cancellation:
      </p>

      <form
        onSubmit={event => {
          event.preventDefault();
          return false;
        }}>
        <div className="flex align-center space-between">
          <div className="width100">
            <label htmlFor="FIELD_reason-cancellation">
              Reason for Cancellation{' '}
              <b>
                (<strong style={{color: '#f93535'}}>•</strong> Required)
              </b>
            </label>
            <select
              id="FIELD_reason-cancellation"
              name="reasonCancellation"
              defaultValue="">
              <option value="" disabled>
                SELECT REASON...
              </option>
              <option value="Infection">Infection</option>
              <option value="On the Fence">On the Fence</option>
              <option value="COVID Positive">COVID Positive</option>
              <option value="Insurance Auth">Insurance Auth</option>
              <option value="Medical Clearance">Medical Clearance</option>
              <option value="Changed Mind">Changed Mind</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
      </form>
    </div>
  );
};

const ExitingAlertBody = () => {
  return (
    <div>
      <p style={{marginTop: '0'}}>Any unsaved progress will not be saved</p>
    </div>
  );
};

export default DocboxHeader;
