import React, {useContext, useEffect, useState} from 'react';
import {Context as AuthContext} from '../../context/AuthContext';
import {Context as ScheduleContext} from '../../context/ScheduleContext';
import {Context as DocboxContext} from '../../context/DocboxContext';
import {Context as EventSettingsContext} from '../../context/EventSettingsContext';
// import { Context as PatientContext } from '../../context/PatientContext'
import {Context as GlobalContext} from '../../context/GlobalContext';

import AgendaCard from './AgendaCard';
import Table from '../Table/Table';
import calendarIcon from '../../assets/Calendar-Blank-0096FA.svg';
import docsperaLogo from '../../assets/Logo-Full-0096FA.svg';
import {faCalendarAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {getEventStartToEndTime} from '../../libraries/moment';
import {triggerCreateDocbox} from '../../libraries/util';

import './AgendaView.scss';
import moment from 'moment';

const AgendaView = ({isDayClicked}) => {
  const {state: authState} = useContext(AuthContext);
  const {state: globalState} = useContext(GlobalContext);
  const {state: scheduleState, getCalendarSubscription} =
    useContext(ScheduleContext);
  const {state: docboxState, openDocbox} = useContext(DocboxContext);
  const {state: eventSettingsState, openEventSettings} =
    useContext(EventSettingsContext);
  // const { state: patientState, initializeModalEvent, openEventModal } = useContext(PatientContext)
  const {openAlert, setNotification} = useContext(GlobalContext);
  const [showQAView, setShowQAView] = useState(false);

  const totalEvents = scheduleState.agenda.length;
  const selectedDate = moment(scheduleState.selectedCalendarDate).format(
    'YYYYMMDD',
  );

  useEffect(() => {
    if (globalState.currentView === '/patientpath/qa-tools/test-calendar') {
      setShowQAView(true);
    } else {
      setShowQAView(false);
    }
  }, [globalState.currentView]);

  const openDocboxCreate = e => {
    console.log('create');
    const selectedDate = moment(
      scheduleState.selectedCalendarDate,
      'YYYYMMDDTHHmmss',
    ).format('YYYYMMDD');
    const currentTime = moment().format('HHmmss');
    const eventStart = `${selectedDate}${currentTime}`;
    const eventEnd = moment(eventStart, 'YYYYMMDDHHmmss')
      .add(1, 'hours')
      .format('YYYYMMDDHHmmss');
    const targetUserId = scheduleState.targetUserId;
    const areKeysPressed = e.shiftKey && e.metaKey;
    triggerCreateDocbox(eventStart, eventEnd, targetUserId, areKeysPressed);
  };

  const renderAgendaList = () => {
    if (showQAView === true) {
      return (
        <Table
          data={scheduleState.agenda}
          cols={tableConstants(
            globalState,
            scheduleState,
            authState,
            openDocbox,
          )}
          brand="velys"
          // handleRowClick={handleRowClick}
        />
      );
    } else {
      return scheduleState.agenda.map(element => {
        return <AgendaCard key={element.event_id} {...element}></AgendaCard>;
      });
    }
  };

  return (
    <>
      <div id="agenda-view">
        {/* agenda print layout header */}
        <div id="PrintHeaderContainer">
          <div
            style={{
              overflow: 'visible',
              width: '100%',
              height: '45px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <div>
              <FontAwesomeIcon
                icon={faCalendarAlt}
                style={{
                  color: 'var(--blueDocspera)',
                  width: '38px',
                  height: '38px',
                  marginRight: '15px',
                }}
              />
              <div style={{ display: 'inline-block' }}>
                <h1
                  style={{
                    color: '#0096FA',
                    fontSize: '18px',
                    margin: '10px 0 4px 0',
                    width: 'calc(100vw - 224px)',
                    textTransform: 'uppercase'
                  }}>
                  Schedule for{' '}
                  {moment(scheduleState.selectedCalendarDate).format(
                    'MMM D, YYYY',
                  )}
                </h1>
                <div style={{ display: 'flex', width: 'calc(100vw - 300px)', }}>
                  <p
                    style={{
                      fontWeight: '600',
                      fontSize: '12pxem',
                      lineHeight: '1.1em',
                      marginTop: 0,
                      marginRight: 10,
                      textTransform: 'uppercase',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap'
                    }}>
                    {scheduleState.targetUserName}
                  </p>
                  <p
                    style={{
                      fontWeight: '600',
                      fontSize: '12pxem',
                      lineHeight: '1.1em',
                      marginTop: 0,
                      whiteSpace: 'nowrap'
                    }}>
                    ({totalEvents || 'No'} {totalEvents === 1 ? 'Case' : 'Cases'})
                  </p>
                </div>
              </div>
            </div>
            <img src={docsperaLogo} height="40" width="135" alt="" />
          </div>
        </div>

        <header>
          {
            <h1>
              {totalEvents || 'No'} {totalEvents === 1 ? 'Case' : 'Cases'}
            </h1>
          }

          <span>
            {moment(scheduleState.selectedCalendarDate).format(
              'dddd, MMM, D, YYYY',
            )}
            {moment(
              scheduleState.selectedCalendarDate,
              'YYYYMMDDTHHmmss',
            ).isSame(moment(), 'day') && <>&nbsp;&nbsp;(Today)</>}
          </span>
        </header>
        {isDayClicked && scheduleState.isLoading ? (
          <div className="Loading"></div>
        ) : (
          <section id="agenda-list">
            {scheduleState.agenda.length ? (
              renderAgendaList()
            ) : (
              <div className="flex no-events">
                <div className="calendar-img">
                  {moment(scheduleState.selectedCalendarDate).format('D')}
                </div>
                <div>
                  <h2>There are no events for this day</h2>
                  <h5>
                    {moment(scheduleState.selectedCalendarDate).format(
                      'dddd, MMM, D, YYYY',
                    )}
                  </h5>
                  <button
                    type="button"
                    className="btn-success create-event"
                    title="Book a New Event"
                    style={{padding: '3px 6px', marginTop: 20}}
                    onClick={e => {
                      openDocboxCreate(e);
                    }}>
                    Book a Case
                  </button>
                </div>
              </div>
            )}
          </section>
        )}
      </div>
    </>
  );
};

const tableConstants = (globalState, scheduleState, authState, openDocbox) => {
  const renderURL = eventId => {
    return `${globalState.config.ABS_BASE_URL}/patientpath/patient/${eventId}/${scheduleState.targetUserId}`;
  };

  return [
    {
      title: 'Start / End',
      render: rowData => {
        return (
          <div className="date-td">
            <p>{getEventStartToEndTime(rowData.start, rowData.end, false)}</p>
          </div>
        );
      },
    },
    {
      title: 'Event ID',
      render: rowData => {
        return (
          <div className="event-id-td">
            <p>{rowData.event_id}</p>
          </div>
        );
      },
    },
    {
      title: 'Case',
      render: rowData => {
        return (
          <div className={'case-td'}>
            <p className="case-name">{rowData.case}</p>
          </div>
        );
      },
    },
    {
      title: 'Event Type',
      render: rowData => {
        return (
          <div className={'event-type-td'}>
            <p className="case-name">{rowData.event_type}</p>
          </div>
        );
      },
    },
    {
      title: 'View Case',
      render: rowData => {
        return (
          <div className={'files-td'}>
            <p>
              <a
                href={`${globalState.config.ABS_BASE_URL}/web/schedule/event/${rowData.event_id}`}
                target="_blank"
                rel="noreferrer">
                View Case
              </a>
            </p>
          </div>
        );
      },
    },
    {
      title: 'View Equipment',
      render: rowData => {
        return (
          <div className={'equipment-td'}>
            <p>
              {rowData.event_type === 'Conference' ||
              rowData.event_type === 'Vacation' ? null : (
                <a
                  href={`${renderURL(rowData.event_id)}/equipment`}
                  target="_blank"
                  rel="noreferrer">
                  Open Equipment
                </a>
              )}
            </p>
          </div>
        );
      },
    },
    {
      title: 'View Insurance',
      render: rowData => {
        return (
          <div className={'insurance-td'}>
            <p>
              {rowData.event_type === 'Conference' ||
              rowData.event_type === 'Vacation' ? null : (
                <a
                  href={`${renderURL(rowData.event_id)}/insurance`}
                  target="_blank"
                  rel="noreferrer">
                  Open Insurance
                </a>
              )}
            </p>
          </div>
        );
      },
    },
    {
      title: 'View Files',
      render: rowData => {
        return (
          <div className={'files-td'}>
            <p>
              {rowData.event_type === 'Conference' ||
              rowData.event_type === 'Vacation' ? null : (
                <a
                  href={`${renderURL(rowData.event_id)}/files`}
                  target="_blank"
                  rel="noreferrer">
                  Open Files
                </a>
              )}
            </p>
          </div>
        );
      },
    },
    {
      title: 'View HCP Checklist',
      render: rowData => {
        return (
          <div className={'hcp-checklist-td'}>
            <p>
              {rowData.event_type === 'Conference' ||
              rowData.event_type === 'Vacation' ? null : (
                <a
                  href={`${renderURL(rowData.event_id)}/hcp-checklist`}
                  target="_blank"
                  rel="noreferrer">
                  Open HCP Checklist
                </a>
              )}
            </p>
          </div>
        );
      },
    },
    {
      title: 'View Notes',
      render: rowData => {
        return (
          <div className={'notes-td'}>
            <p>
              {rowData.event_type === 'Conference' ||
              rowData.event_type === 'Vacation' ? null : (
                <a
                  href={`${renderURL(rowData.event_id)}/notes`}
                  target="_blank"
                  rel="noreferrer">
                  Open Notes
                </a>
              )}
            </p>
          </div>
        );
      },
    },
    {
      title: 'View HCP Team',
      render: rowData => {
        return (
          <div className={'hcp-team-td'}>
            <p>
              {rowData.event_type === 'Conference' ||
              rowData.event_type === 'Vacation' ? null : (
                <a
                  href={`${renderURL(rowData.event_id)}/hcp-team`}
                  target="_blank"
                  rel="noreferrer">
                  Open HCP Team
                </a>
              )}
            </p>
          </div>
        );
      },
    },
    {
      title: 'View Thread',
      render: rowData => {
        return (
          <div className={'thread-td'}>
            <p>
              {rowData.event_type === 'Conference' ||
              rowData.event_type === 'Vacation' ? null : (
                <a
                  href={`${renderURL(rowData.event_id)}/thread`}
                  target="_blank"
                  rel="noreferrer">
                  Open Thread
                </a>
              )}
            </p>
          </div>
        );
      },
    },
  ];
};

export default AgendaView;
