import React, {useContext} from 'react';
import './ContactCard.scss';

import {Context as GlobalContext} from '../context/GlobalContext';

import {DeleteFieldButton} from './ui/atoms';

const ContactCard = ({type, contact, allowDelete, handleDelete}) => {
  const {state: globalState} = useContext(GlobalContext);

  return (
    <div className="contact-card flex">
      {allowDelete && (
        <DeleteFieldButton
          handler={
            handleDelete
              ? handleDelete
              : () => {
                  console.log(
                    'no delete handler for this contact card component',
                  );
                }
          }
        />
      )}
      <div
        className="contact-card-avatar"
        style={{
          backgroundImage: `url(${globalState.config.ABS_BASE_URL}/a/${contact.avatar}_128.jpg)`,
        }}></div>
      <div
        className="flex direction-column justify-center"
        style={{margin: '1rem', width: '143px', flexGrow: '1'}}>
        <span className="ellipsis">
          <b>{contact.name}</b>
        </span>
        {type && type === 'Provider' && (
          <span className="ellipsis">{contact.specialty}</span>
        )}
        {type && type === 'Industry' && (
          <span className="ellipsis">{contact.company}</span>
        )}
        {/* {
          (typeof contact.status !== 'undefined' && contact.status) &&
          <span className="flex align-center">
            <div className={`contact-invite-icon ${formatcontactStatus(contact.status)}`}></div>
            <span>{formatcontactStatus(contact.status)}</span>
          </span>
        } */}
      </div>
    </div>
  );
};

export default ContactCard;
