import React, {useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faAngleDown,
  faCalendarAlt,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import PrintSharpIcon from '@mui/icons-material/PrintSharp';
import SearchIcon from '@mui/icons-material/Search';

import {Context as AuthContext} from '../context/AuthContext';
import {Context as GlobalContext} from '../context/GlobalContext';
import {Context as ScheduleContext} from '../context/ScheduleContext';
import {Context as PDContext} from '../context/PracticeDashboardContext';
import {
  draftsURI,
  getSharedSchedulesURI,
  getDSLicensesURI,
  getPracticePermissionsURI,
  getCountsURI,
} from '../dataAccess/apiEndpoints';
import {
  AlomEncodeType2,
  hasDSLicense,
  hasPracticeAdminAccess,
  isIndustryRep,
  setPrintOrientation,
  removePrintOrientation
} from '../libraries/helpers';

import './GlobalNavMenu.scss';

const GlobalNavMenu = () => {
  const {
    state: authState,
    getDSLicenses,
    getPracticePermissions,
  } = useContext(AuthContext);
  const {state: globalState, getCounts} = useContext(GlobalContext);
  const {
    state: scheduleState,
    getMyDraftsCount,
    getSharedSchedules,
  } = useContext(ScheduleContext);

  const [currentSection, setCurrentSection] = useState(null);
  const currentView = globalState.currentView;

  const getCurrentSection = () => {
    const selectedNavLink = document.querySelectorAll('.nav-link.selected');
    if (selectedNavLink.length) {
      const currentSection = selectedNavLink[0].parentElement.dataset.section;
      return setCurrentSection(currentSection);
    }
  };

  const {pathname} = useLocation();

  const {state: pdState, updateFilterFieldValue} = useContext(PDContext);

  const handleNavLinkClick = inputEndpoint => {
    if (currentView === inputEndpoint) return;
    return window.location.assign(inputEndpoint);
  };

  // Get Pending Cases total count
  useEffect(() => {
    if (authState.userId && globalState.config.ABS_BASE_URL) {
      const url = `${globalState.config.ABS_BASE_URL}${draftsURI}`;
      getMyDraftsCount(url, authState.userId);
    }
  }, [authState.userId, globalState.config.ABS_BASE_URL]);

  // Get Shared Schedules list
  useEffect(() => {
    if (authState.userId && globalState.config.ABS_BASE_URL) {
      const url = `${globalState.config.ABS_BASE_URL}${getSharedSchedulesURI}`;
      getSharedSchedules(url, authState.userId);
    }
  }, [authState.userId, globalState.config.ABS_BASE_URL]);

  // Get licenses
  useEffect(() => {
    if (authState.userId && globalState.config.ABS_BASE_URL) {
      const encodedUserId = AlomEncodeType2(authState.userId);
      const uri = getDSLicensesURI.replace('{userid}', encodedUserId);
      const url = `${globalState.config.ABS_BASE_URL}${uri}`;
      getDSLicenses(url);
    }
  }, [authState.userId, globalState.config.ABS_BASE_URL]);

  // Get practice permissions
  useEffect(() => {
    if (authState.userId && globalState.config.ABS_BASE_URL) {
      const encodedUserId = AlomEncodeType2(authState.userId);
      const uri = getPracticePermissionsURI.replace('{userid}', encodedUserId);
      const url = `${globalState.config.ABS_BASE_URL}${uri}`;
      getPracticePermissions(url);
    }
  }, [authState.userId, globalState.config.ABS_BASE_URL]);

  // Get unread messages count
  useEffect(() => {
    if (authState.userId && globalState.config.ABS_BASE_URL) {
      const url = `${globalState.config.ABS_BASE_URL}${getCountsURI}`;
      getCounts(url, authState.userId);
    }
  }, [authState.userId, globalState.config.ABS_BASE_URL]);

  // Set the active highlighted list item in the navbar
  useEffect(() => {
    if (globalState.currentView) {
      getCurrentSection();
    }
  }, [
    globalState.currentView,
    scheduleState.sharedSchedules,
    authState.dsLicenses,
    authState.practicePermissions,
  ]);

  useEffect(() => {
    if(pathname === '/web/ksrc-dashboard' && !document.getElementById('page-orientation')) {
      setPrintOrientation();
    } else if((pathname === '/web/docspera-schedule') && (scheduleState.viewMode === 'Weekly') && !document.getElementById('page-orientation')){
      setPrintOrientation();
    } else {
      document.getElementById('page-orientation') && removePrintOrientation();
    }
  }, [pathname, scheduleState.viewMode])

  if (!globalState.isNavMenuOpen) {
    return null;
  }

  return (
    authState.dsLicenses.length > 0 &&
    hasDSLicense(authState.dsLicenses, 2) &&
    (globalState.currentView.includes('practice-dashboard') ||
      globalState.currentView.includes('ksrc-dashboard')) && (
      <header className="global-nav-header">
        <nav>
          <ul>
            <div>
              {hasDSLicense(authState.dsLicenses, 38) ? (
                <li
                  className={`${
                    pathname === '/web/ksrc-dashboard' ? 'selected' : ''
                  }`}
                  onClick={() => handleNavLinkClick('/web/ksrc-dashboard')}>
                  <h3>ALL CASES</h3>
                  <p>
                    {`${
                      pathname === '/web/ksrc-dashboard'
                        ? `${
                            pdState?.cases?.paginate
                              ? pdState?.cases?.paginate?.total
                              : 'loading...'
                          } Cases`
                        : 'Upcoming Surgeries'
                    }`}
                  </p>
                </li>
              ) : (
                <li
                  className={`${
                    pathname === '/web/practice-dashboard/all-cases'
                      ? 'selected'
                      : ''
                  }`}
                  onClick={() =>
                    handleNavLinkClick('/web/practice-dashboard/all-cases')
                  }>
                  <h3>ALL CASES</h3>
                  <p>
                    {`${
                      pathname === '/web/practice-dashboard/all-cases'
                        ? `${
                            pdState?.cases?.paginate
                              ? pdState?.cases?.paginate?.total
                              : 'loading...'
                          } Cases`
                        : 'Upcoming Surgeries'
                    }`}
                  </p>
                </li>
              )}
              <li
                className={`${
                  pathname === '/web/practice-dashboard/physicians-schedule'
                    ? 'selected'
                    : ''
                }`}
                onClick={() =>
                  handleNavLinkClick(
                    '/web/practice-dashboard/physicians-schedule',
                  )
                }>
                <h3>PHYSICIAN'S SCHEDULE</h3>
                <p>Weekly Outlook</p>
              </li>
              <li
                className={`${
                  pathname === '/web/practice-dashboard/pending'
                    ? 'selected'
                    : ''
                }`}
                onClick={() =>
                  handleNavLinkClick('/web/practice-dashboard/pending')
                }>
                <h3>PENDING CASES</h3>
                <p>{`${
                  pathname === '/web/practice-dashboard/pending'
                    ? `${
                        pdState?.pendingCases?.paginate
                          ? pdState?.pendingCases?.paginate?.total
                          : 'loading...'
                      } Cases`
                    : 'Patient Readiness'
                }`}</p>
              </li>
            </div>
            <div className="header-functions">
              {(pathname === '/web/ksrc-dashboard' ||
                pathname === '/web/practice-dashboard/all-cases') && (
                <form
                  id="search-bar"
                  onSubmit={event => {
                    event.preventDefault();
                    return false;
                  }}>
                  <SearchIcon fontSize="small" className="search-icon" />
                  <input
                    id="search-bar-field"
                    type="text"
                    name="search"
                    value={pdState.search ? pdState.search : ''}
                    onChange={event =>
                      updateFilterFieldValue(
                        event.target.name,
                        event.target.value,
                      )
                    }
                    placeholder="Search"
                  />
                </form>
              )}
              {hasDSLicense(authState.dsLicenses, 38) &&
                pathname === '/web/ksrc-dashboard' && (
                  <div className="print-btn">
                    <PrintSharpIcon
                      fontSize="large"
                      onClick={() => window.print()}
                    />
                  </div>
                )}
            </div>
          </ul>
        </nav>
      </header>
    )
    // <aside id="global-nav-menu" className="no-select">
    /* PRACTICE DASHBOARD */
    /* {
        //check license 2 for 'Practice Dashboard'
        (authState.dsLicenses.length > 0 && hasDSLicense(authState.dsLicenses, 2)) && */
    /* <>
          <h3
            className={`flex align-center space-between ${currentSection === 'practice-dashboard' ? "selected": ""}`}
            data-section="practice-dashboard"
            onClick={(event) => {
              setCurrentSection(event.currentTarget.dataset.section)
            }}
          >
            <span>Practice Dashboard</span>
            <span className='arrow-icon'>
              <FontAwesomeIcon
                icon={(currentSection === 'practice-dashboard') ? faAngleDown : faAngleRight}
              />
            </span>
          </h3>
          <ul
            className={`nav-list${(currentSection === 'practice-dashboard') ? ' active' : ''}`}
            data-section="practice-dashboard"
          >
            <li
              className={"nav-link" + (currentView === "/web/practice-dashboard/all-cases" ? " selected" : "")}
              onClick={()=> handleNavLinkClick('/web/practice-dashboard/all-cases')}
            >All Cases</li>

            <li
              className={"nav-link" + (currentView === "/web/practice-dashboard/physicians-schedule" ? " selected" : "")}
              onClick={()=> handleNavLinkClick('/web/practice-dashboard/physicians-schedule')}
            >Physician's Schedule</li>

            <li
              className={"nav-link" + (currentView === "/web/practice-dashboard/pending" ? " selected" : "")}
              onClick={()=> handleNavLinkClick('/web/practice-dashboard/pending')}
            >Pending Cases</li>

            {
              (authState.practicePermissions.length > 0 && authState.practiceId && hasPracticeAdminAccess(authState.practicePermissions, authState.practiceId)) &&
              <li
                className={"nav-link" + (currentView === "/web/practice-dashboard/settings" ? " selected" : "")}
                onClick={()=> handleNavLinkClick('/web/practice-dashboard/settings')}
              >PD Settings</li>
            }

          </ul>
        </> */
    /* } */
    /* PRACTICE DASHBOARD */

    /* SMART SCHEDULING */
    /* {
        //check license 22 for 'Intelligent Scheduler'
        (authState.dsLicenses.length > 0 && hasDSLicense(authState.dsLicenses, 22)) && */
    /* <>
          <h3
            className={`flex align-center space-between ${currentSection === 'smart-scheduling' ? "selected": ""}`}
            data-section="smart-scheduling"
            onClick={(event) => {
              setCurrentSection(event.currentTarget.dataset.section)
            }}
          >
            <span>Smart Scheduling</span>
            <span className='arrow-icon'>
              <FontAwesomeIcon
                icon={(currentSection === 'smart-scheduling') ? faAngleDown : faAngleRight}
              />
            </span>
          </h3>
          <ul
            className={`nav-list${(currentSection === 'smart-scheduling') ? ' active' : ''}`}
            data-section="smart-scheduling"
          >
            <li
              className={"nav-link" + (currentView === "/web/smart-scheduling" ? " selected" : "")}
              onClick={()=> handleNavLinkClick('/web/smart-scheduling')}
            >Smart Scheduling</li>
            <li
              className={"nav-link" + (currentView === "/web/rebook" ? " selected" : "")}
              onClick={()=> handleNavLinkClick('/web/rebook')}
            >Rebook Tool</li>
          </ul>
        </> */
    /* } */
    /* SMART SCHEDULING */

    /* SCHEDULE */
    /* <h3
        className={`flex align-center space-between ${currentSection === 'schedule' ? "selected": ""}`}
        data-section="schedule"
        onClick={(event) => {
          setCurrentSection(event.currentTarget.dataset.section)
        }}
      >
        <span>Schedule</span>
        <span className='arrow-icon'>
          <FontAwesomeIcon
            icon={(currentSection === 'schedule') ? faAngleDown : faAngleRight}
          />
        </span>
      </h3>
      <ul
        className={`nav-list${(currentSection === 'schedule') ? ' active' : ''}`}
        data-section="schedule"
      >
        <li
          className={"nav-link" + (currentView === "/web" || currentView === "/web/schedule" ? " selected" : "")}
          onClick={() => currentView === '/' ? handleNavLinkClick('/') : handleNavLinkClick('/web/schedule')}
        >
          My Schedule
        </li>

        <li
          className={"nav-link flex space-between" + (currentView === "/web/schedule/pending" ? " selected" : "")}
          onClick={() => handleNavLinkClick('/web/schedule/pending')}
        >
          My Pending Cases ({`${scheduleState.myDraftsCount}`})
        </li>
        <li
          className={"nav-link" + (currentView === "/web/schedule/search" ? " selected" : "")}
          onClick={() => handleNavLinkClick('/web/schedule/search')}
        >
          Search
        </li>
        {
          (!isIndustryRep(authState.userType)) &&
          <li
            className={`nav-link${(currentView === '/web/settings/share') ? ' selected' : ''}`}
            onClick={() => handleNavLinkClick('/web/settings/share')}
          >
            Share
          </li>
        }

        {
          (scheduleState.sharedSchedules && scheduleState.sharedSchedules.length > 0) &&
          <hr />
        }

        {
          (scheduleState.sharedSchedules && scheduleState.sharedSchedules.length > 0) &&
          scheduleState.sharedSchedules.map((user) => {
            const handleSharedScheduleClick = (inputUserId) => {
              return window.location.assign('/web/shared-schedule/' + inputUserId)
            }

            return (
              <li
                className={"nav-link shared" + (currentView === "/web/shared-schedule/" + user.id ? " selected" : "")}
                key={user.id}
                onClick={() => handleSharedScheduleClick(user.id)}
              >
                <span className='arrow-icon'>
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                  />
                </span>
                &nbsp;&nbsp;
                {user.name}
              </li>
            )
          })
        }

        {
          (scheduleState.sharedSchedules && scheduleState.sharedSchedules.length > 0) &&
          <hr />
        }
      </ul> */
    /* SCHEDULE */

    /* CONTACTS */
    /* <h3
        className={`flex align-center space-between ${currentSection === 'contacts' ? "selected": ""}`}
        data-section="contacts"
        onClick={(event) => {
          setCurrentSection(event.currentTarget.dataset.section)
        }}
      >
        <span>Contacts</span>
        <span className='arrow-icon'>
          <FontAwesomeIcon
            icon={(currentSection === 'contacts') ? faAngleDown : faAngleRight}
          />
        </span>
      </h3>
      <ul
        className={`nav-list${(currentSection === 'contacts') ? ' active' : ''}`}
        data-section="contacts"
      >
        <li
          className="nav-link"
          onClick={() => handleNavLinkClick(`${globalState.config.ABS_BASE_URL}/contacts`)}
        >
          My Contacts
        </li>
        {
          (!isIndustryRep(authState.userType)) &&
          <li
            className="nav-link"
            onClick={() => {
              const uri = (authState.practiceId) ? `/contacts/search/practice/${authState.practiceId.toString()}` : '/contacts/mypractice'
              handleNavLinkClick(`${globalState.config.ABS_BASE_URL}${uri}`)
            }}
          >
            Groups
          </li>
        }
      </ul> */
    /* CONTACTS */

    /* MESSAGES */
    /* <h3
        className={`flex align-center space-between ${currentSection === 'messages' ? "selected": ""}`}
        data-section="messages"
        onClick={(event) => {
          setCurrentSection(event.currentTarget.dataset.section)
        }}
      >
        <span>Messages</span>
        <span className='arrow-icon'>
          <FontAwesomeIcon
            icon={(currentSection === 'messages') ? faAngleDown : faAngleRight}
          />
        </span>
      </h3>
      <ul
        className={`nav-list${(currentSection === 'messages') ? ' active' : ''}`}
        data-section="messages"
      >
        <li
          className="nav-link"
          onClick={() => handleNavLinkClick(`${globalState.config.ABS_BASE_URL}/live-messages/inbox`)}
        >
          Inbox
          {
            (globalState.unreadMessagesCount !== null && globalState.unreadMessagesCount > 0) &&
            <>
              <span> ({globalState.unreadMessagesCount})</span>
            </>
          }
        </li>
        <li
          className="nav-link"
          onClick={() => handleNavLinkClick(`${globalState.config.ABS_BASE_URL}/live-messages/compose`)}
        >
          Compose New Message
        </li>
      </ul> */
    /* MESSAGES */

    /* PROFILE */
    /* <h3
        className="nav-link"
        onClick={() => handleNavLinkClick(`${globalState.config.ABS_BASE_URL}/profile`)}
      >
        Profile
      </h3> */
    /* PROFILE */

    /* SETTINGS */
    /* <h3
        className="nav-link"
        onClick={() => handleNavLinkClick(`${globalState.config.ABS_BASE_URL}/profile/settings`)}
      >
        Settings
      </h3> */
    /* SETTINGS */

    /* LOGOUT */
    /* <h3 className="nav-link">Logout</h3> */
    /* LOGOUT */
    // </aside>
  );
};

export default GlobalNavMenu;
