import React, {useContext} from 'react';
import {useDropzone} from 'react-dropzone';
import {Context as DocboxContext} from '../context/DocboxContext';
import {Context as GlobalContext} from '../context/GlobalContext';

import {uploadFileURI} from '../dataAccess/apiEndpoints';
import AttachmentCard from './AttachmentCard';

import './Dropzone.scss';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: '16px',
};

const Dropzone = props => {
  const {
    state: docboxState,
    uploadFile,
    addAttachment,
    deleteAttachment,
  } = useContext(DocboxContext);
  const {state: globalState, setNotification} = useContext(GlobalContext);
  const {open, getRootProps, getInputProps} = useDropzone({
    noClick: true,
    noKeyboard: true,
    noDrag: true,
    maxSize: 2048000000,
    onDrop: acceptedFiles => {
      const url = `${globalState.config.ABS_BASE_URL}${uploadFileURI}`;

      acceptedFiles.forEach(file => {
        setNotification('Uploading file. Please wait...');
        uploadFile(url, file).then(response => {
          if (!response) {
            setNotification('File upload error: no response. (Error 101)');
          } else {
            if (!response.data) {
              setNotification(
                'File upload error: no response data. (Error 102)',
              );
            } else {
              if (
                response.data.status &&
                response.data.status === 'success' &&
                response.data.file &&
                response.data.file.id
              ) {
                addAttachment(response.data.file);
              } else {
                setNotification(
                  'File upload error: response data error. (Error 103)',
                );
              }
            }
          }
        });
      });
    },
  });

  const previews = docboxState.attachments.map((file, index) => {
    return (
      <AttachmentCard
        key={index}
        file={file}
        allowEdit={true}
        handleDelete={() => deleteAttachment(file.id)}
      />
    );
  });

  return (
    <section className="dropzone-container">
      <aside style={thumbsContainer}>
        {docboxState.attachments.length === 0 && (
          <span>No files added yet.</span>
        )}
        {docboxState.attachments.length > 0 && (
          <div id="attachment-card-list" className="flex space-between wrap">
            {previews}
          </div>
        )}
      </aside>

      <div {...getRootProps({className: 'dropzone flex justify-end'})}>
        <input {...getInputProps()} />
        <button type="button" className="btn-success" onClick={open}>
          {props.btnTitle || 'Upload File'}
        </button>
      </div>
    </section>
  );
};

export default Dropzone;
