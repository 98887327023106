import React from 'react';

function VacationIcon() {
  return (
    <svg viewBox="0 0 54.42 54.42">
      <circle fill="#9595A7" cx="27.21" cy="27.21" r="27.21" />
      <g id="qxQ0BD">
        <path
          fill="white"
          d="M303.7,187.33a5.31,5.31,0,0,0,.4-.51c1.83-2.79,3.68-5.57,5.48-8.39a16.51,16.51,0,0,1,2.92-3.63,1.49,1.49,0,0,1,.31.16c2.33,2,4.66,3.95,7,5.95a1,1,0,0,1,.15.8,40,40,0,0,1-1.42,4.59,1.84,1.84,0,0,0,.76,2.57l.15.08,3.53-5.64,3.48,1.88.17-.2-1.89-3.46,5.66-3.5c-.78-1.28-1.14-1.43-2.43-1l-4.77,1.53a.7.7,0,0,1-.88-.2c-1.94-2.3-3.91-4.57-5.85-6.88a.67.67,0,0,1,0-.64,12.21,12.21,0,0,1,1.74-1.56c2.43-1.65,4.9-3.25,7.35-4.87l3.32-2.22c-.64-1-.76-1.06-1.75-.78a40.4,40.4,0,0,0-4.24,1.22,2.29,2.29,0,0,1-3.13-.83.71.71,0,0,0-.3-.27c-.15-.08-.4-.2-.48-.14a8.75,8.75,0,0,0-1.55,1.42c-.15.21.12.73.22,1.17,0,0,0,0-.06,0-2.11.46-4.22.93-6.34,1.37a.84.84,0,0,1-.63-.22c-.94-.86-1.85-1.75-2.79-2.61a11.69,11.69,0,0,0-5.33-3.17c-1.25-.27-1.85.28-1.67,1.55a6.67,6.67,0,0,0,1.46,3.41c1.46,1.74,3,3.39,4.55,5.07a.91.91,0,0,1,.31,1c-.53,1.77-1,3.56-1.51,5.34a3.08,3.08,0,0,1-.19.47c-.92-.61-1-.61-1.79.15-.17.17-.34.35-.5.54a.77.77,0,0,0,0,1.18c.33.33.63.69,1,1a.85.85,0,0,1,.27,1c-.54,1.86-1.05,3.73-1.56,5.6C302.66,186.62,302.74,186.76,303.7,187.33Z"
          transform="translate(-287.38 -145.91)"
        />
        <path
          fill="white"
          d="M303.7,187.33c-1-.57-1-.71-.79-1.66.51-1.87,1-3.74,1.56-5.6a.85.85,0,0,0-.27-1c-.36-.31-.66-.67-1-1a.77.77,0,0,1,0-1.18c.16-.19.33-.37.5-.54.79-.76.87-.76,1.79-.15a3.08,3.08,0,0,0,.19-.47c.5-1.78,1-3.57,1.51-5.34a.91.91,0,0,0-.31-1c-1.53-1.68-3.09-3.33-4.55-5.07a6.67,6.67,0,0,1-1.46-3.41c-.18-1.27.42-1.82,1.67-1.55a11.69,11.69,0,0,1,5.33,3.17c.94.86,1.85,1.75,2.79,2.61a.84.84,0,0,0,.63.22c2.12-.44,4.23-.91,6.34-1.37.06,0,.11-.07.06,0-.1-.44-.37-1-.22-1.17a8.75,8.75,0,0,1,1.55-1.42c.08-.06.33.06.48.14a.71.71,0,0,1,.3.27,2.29,2.29,0,0,0,3.13.83,40.4,40.4,0,0,1,4.24-1.22c1-.28,1.11-.22,1.75.78l-3.32,2.22c-2.45,1.62-4.92,3.22-7.35,4.87a12.21,12.21,0,0,0-1.74,1.56.67.67,0,0,0,0,.64c1.94,2.31,3.91,4.58,5.85,6.88a.7.7,0,0,0,.88.2l4.77-1.53c1.29-.41,1.65-.26,2.43,1l-5.66,3.5L326.6,185l-.17.2L323,183.31,319.42,189l-.15-.08a1.84,1.84,0,0,1-.76-2.57,40,40,0,0,0,1.42-4.59,1,1,0,0,0-.15-.8c-2.31-2-4.64-4-7-5.95a1.49,1.49,0,0,0-.31-.16,16.51,16.51,0,0,0-2.92,3.63c-1.8,2.82-3.65,5.6-5.48,8.39A5.31,5.31,0,0,1,303.7,187.33Z"
          transform="translate(-287.38 -145.91)"
        />
      </g>
    </svg>
  );
}

export default VacationIcon;
