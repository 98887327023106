import React, {useContext, useEffect} from 'react';
import {Context as PDContext} from '../../../context/PracticeDashboardContext';
import {Context as GlobalContext} from '../../../context/GlobalContext';
import {getAvailabilityCalendarURI} from '../../../dataAccess/apiEndpoints';
import WeeklyCalendar from '../WeeklyCalendar/WeeklyCalendar';
import {AlomEncodeType2} from '../../../libraries/helpers';

const AvailabilityCalendar = ({partialDay, selected}) => {
  const {state: pdState, getAvailabilityCalendar} = useContext(PDContext);
  const {state: globalState} = useContext(GlobalContext);

  useEffect(() => {
    if (
      selected === 'calendar' &&
      pdState.time &&
      pdState.physician &&
      globalState.config.ABS_BASE_URL
    ) {
      const url = `${globalState.config.ABS_BASE_URL}${getAvailabilityCalendarURI}`;
      getAvailabilityCalendar(
        url,
        pdState.time.start,
        pdState.time.end,
        AlomEncodeType2(parseInt(pdState.physician)),
      );
    }
  }, [
    selected,
    pdState.time,
    pdState.physician,
    globalState.config.ABS_BASE_URL,
  ]);

  return (
    <div>
      <div style={{paddingBottom: 20}}>
        <span style={{marginLeft: 30}}>
          This calendar dictates the selected physician's
        </span>
        <span style={{fontWeight: 600}}> specific availability</span>
        <span> by date & time.</span>
      </div>
      <WeeklyCalendar
        incrementNum={15}
        hoverToDisplayTime
        partialDay={partialDay}
        selectedDate={pdState.time.start}
      />
    </div>
  );
};

export default AvailabilityCalendar;
