import * as React from 'react';

const AttachmentIcon = props => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="47.16"
    height="47.16"
    viewBox="0 0 47.16 47.16">
    <path
      id="Icon_feather-paperclip"
      data-name="Icon feather-paperclip"
      d="M32.16,16.575,18.375,30.36A9.005,9.005,0,0,1,5.64,17.625L19.425,3.84a6,6,0,0,1,8.49,8.49l-13.8,13.785A3,3,0,1,1,9.87,21.87L22.6,9.15"
      transform="translate(-1.474 23.829) rotate(-45)"
      fill="none"
      stroke="#0096fa"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="3"
    />
  </svg>
);

export default AttachmentIcon;
