import React, {useContext} from 'react';
import {Context as PDContext} from '../../../../context/PracticeDashboardContext';
import {Context as GlobalContext} from '../../../../context/GlobalContext';
import {setRightsURI, settingsURI} from '../../../../dataAccess/apiEndpoints';
import './AccessRightsToggle.scss';

const AccessRightsToggle = ({rights, id}) => {
  const {setRights, getSettings} = useContext(PDContext);
  const {state: globalState} = useContext(GlobalContext);

  const handler = string => {
    if (rights !== 'Admin') {
      if (rights !== string) {
        let physicians = [{id: parseInt(id), rights: string}];
        // physicians: [{id: 13173, rights:'None' || 'Read' || 'Full'}]
        if (globalState.config.ABS_BASE_URL) {
          const url = `${globalState.config.ABS_BASE_URL}${settingsURI}`;
          const rightsUrl = `${globalState.config.ABS_BASE_URL}${setRightsURI}`;
          setRights(rightsUrl, physicians).then(() => getSettings(url));
        }
      }
    }
  };

  return (
    <div id="access-rights-toggle">
      <div
        className={`${rights === 'None' ? 'none-selected' : 'none'}`}
        onClick={() => handler('None')}>
        No Access
      </div>
      <div
        className={`${rights === 'Read' ? 'read-selected' : 'read'}`}
        onClick={() => handler('Read')}>
        Read Only
      </div>
      <div
        className={`${rights === 'Full' ? 'full-selected' : 'full'}`}
        onClick={() => handler('Full')}>
        Full Access
      </div>
    </div>
  );
};

export default AccessRightsToggle;
