import React, {useContext, useEffect, useState} from 'react';
import {Context as PDContext} from '../../../context/PracticeDashboardContext';
import {Context as GlobalContext} from '../../../context/GlobalContext';
import {settingsURI} from '../../../dataAccess/apiEndpoints';
import AvailabilityTimes from './AvailabilityTimes';
import AvailabilityCalendar from './AvailabilityCalendar';
import Preferences from './Preferences';
import GlobalNavMenu from '../../GlobalNavMenu';
import {Tabs, Toggle} from '../../ui/atoms';
import CalendarPicker from '../../CalendarPicker';
import moment from 'moment';
import './PDSettings.scss';

const PDSettings = () => {
  const {
    state: pdState,
    getSettings,
    updateFilterFieldValue,
    setTimeFilter,
  } = useContext(PDContext);
  const {state: globalState} = useContext(GlobalContext);
  const [selected, setSelected] = useState();
  const [partialDay, setPartialDay] = useState(true);

  useEffect(() => {
    if (globalState.config.ABS_BASE_URL) {
      const url = `${globalState.config.ABS_BASE_URL}${settingsURI}`;
      getSettings(url);
    }
    const time = {
      start: moment().startOf('week').format('YYYYMMDD'),
      end: moment().endOf('week').format('YYYYMMDD'),
    };
    setTimeFilter(time.start, time.end);
  }, [globalState.config.ABS_BASE_URL]);

  useEffect(() => {
    // set first physician as default
    if (
      pdState.physician === null &&
      pdState.settings &&
      pdState.settings.physicians
    ) {
      handleInputChange(
        'physician',
        parseInt(pdState.settings.physicians[0].id),
      );
    }
  }, [pdState.settings]);

  const tabsArray = [
    {
      name: 'Preferences & Access',
      id: 'preferences',
      default: true,
    },
    {
      name: 'Availability Times',
      id: 'times',
    },
    {
      name: 'Availability Calendar',
      id: 'calendar',
    },
  ];

  const handleInputChange = (name, value) => {
    if (name === 'physician') {
      if (value !== 'null') {
        value = parseInt(value);
      }
    }
    if (value === 'null') {
      value = null;
    }
    updateFilterFieldValue(name, value);
  };

  const handleDateSelect = event => {
    const start = moment(event.target.dataset.date)
      .startOf('week')
      .format('YYYYMMDD');
    const end = moment(event.target.dataset.date)
      .endOf('week')
      .format('YYYYMMDD');
    setTimeFilter(start, end);
  };

  return (
    <>
      <GlobalNavMenu />
      <div id="pd-settings" style={{width: '100%'}}>
        <div className="content header">
          <h1>Practice Dashboard Settings</h1>

          <form>
            {/* Week: */}
            {selected === 'calendar' && (
              <div className="labelAndDropdown">
                <label htmlFor="week">Week:</label>
                <CalendarPicker
                  id={'week'}
                  name={'week'}
                  selectable={'week'}
                  value={pdState.time.start}
                  endValue={pdState.time.end}
                  handler={event => handleDateSelect(event)}
                  style={{width: '98%'}}
                />
              </div>
            )}

            {/* Month Toggle */}
            {(selected === 'calendar' || selected === 'times') && (
              <div className="labelAndDropdown">
                <label htmlFor="monthToggle">Show:</label>
                <Toggle
                  id="agenda-cal-clinical"
                  toggled={partialDay}
                  handler={() => setPartialDay(!partialDay)}
                  onText="Partial Day"
                  offText="Full Day"
                  large
                />
              </div>
            )}

            {/* Physician */}
            {(selected === 'calendar' || selected === 'times') && (
              <div className="labelAndDropdown">
                <label htmlFor="physician">Physician:</label>
                <select
                  id="physician"
                  name="physician"
                  value={pdState.physician}
                  onChange={event =>
                    handleInputChange(event.target.name, event.target.value)
                  }>
                  {pdState.settings &&
                    pdState.settings.physicians &&
                    pdState.settings.physicians.map(physician => {
                      return (
                        <option key={physician.id} value={physician.id}>
                          {' '}
                          {physician.name}{' '}
                        </option>
                      );
                    })}
                </select>
              </div>
            )}
          </form>
        </div>
        <Tabs
          tabsArray={tabsArray}
          tabsContainerId={'tabs-container_preferences'}
          style={{margin: '0 0 1rem 2rem'}}
          setSelected={setSelected}
        />
        <div id="tabs-container_preferences">
          <div id="preferences">
            <Preferences />
          </div>
          <div id="times">
            <AvailabilityTimes partialDay={partialDay} />
          </div>
          <div id="calendar">
            <AvailabilityCalendar partialDay={partialDay} selected={selected} />
          </div>
        </div>
      </div>
    </>
  );
};

export default PDSettings;
