import React, {useContext, useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';

import {Context as AuthContext} from '../../context/AuthContext';
import {Context as GlobalContext} from '../../context/GlobalContext';
import {Context as ScheduleContext} from '../../context/ScheduleContext';
import {Context as DocboxContext} from '../../context/DocboxContext';
import {Context as EventSettingsContext} from '../../context/EventSettingsContext';
// import { Context as PatientContext } from '../../context/PatientContext'
import WeeklyCalendar from './WeeklyCalendar';
import AgendaView from './AgendaView';
import CalendarContainer from './CalendarContainer';
import CalendarPicker from '../CalendarPicker';

import './MyCalendar.scss';
import {formatFullName} from '../../libraries/helpers';
import {
  sendSharedScheduleId,
  triggerCreateDocbox,
  triggerEventSettings,
  triggerOpenICal,
} from '../../libraries/util';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCog,
  faPrint,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import {faCalendar} from '@fortawesome/free-regular-svg-icons';
import Airplane from '../../assets/svg-components/Airplane';

import {
  attendeesHash,
  billingCodesHash,
  locationsHash,
  preOpChecklistsHash,
  proceduresHash,
} from '../../dataAccess/preferencesHash';
import {
  agendaURI,
  calendarMonthURI,
  getSharedSchedulesURI,
  getUserPreferencesURI,
} from '../../dataAccess/apiEndpoints';

import moment from 'moment';
import GlobalNavMenu from '../GlobalNavMenu';
import { rescheduleApi } from '../../api/api';

const MyCalendar = () => {
  const {
    state: authState,
    getDSLicenses,
    getPracticePermissions,
  } = useContext(AuthContext);
  const {
    state: globalState,
    setNotification,
    openAlert,
  } = useContext(GlobalContext);
  const {
    state: scheduleState,
    setSharedScheduleId,
    setSharedScheduleName,
    setTargetUserId,
    setTargetUserName,
    setViewMode,
    getSharedSchedules,
    selectWeekAndGetAgenda,
    getCalendarSubscription,
    selectDateAndGetAgenda,
    selectMonthAndGetCalendar,
  } = useContext(ScheduleContext);
  const {state: docboxState, openDocbox} = useContext(DocboxContext);
  const {state: eventSettingsState, getPreferences} =
    useContext(EventSettingsContext);
  // const { state: patientState, initializeModalEvent, openEventModal } = useContext(PatientContext)
  let {paramCalendarId} = useParams();
  const [rescheduledEvents, setRescheduledEvents] = useState([]);

  const firstName =
    authState.userId && authState.userProfile
      ? authState['userProfile']['name']['first']
      : '';
  const lastName =
    authState.userId && authState.userProfile
      ? authState['userProfile']['name']['last']
      : '';
  const userName = formatFullName(firstName, lastName);

  const [refreshPage, setRefreshPage] = useState(false);
  const [isScheduleLoading, setIsScheduleLoading] = useState(true);
  const [isWeeklyScheduleLoading, setIsWeeklyScheduleLoading] = useState(true);
  const [isDayClicked, setIsDayClicked] = useState(true);
  let autoRefresh = useRef();

  const openDocboxCreate = e => {
    console.log('create');
    const currentTime = moment().format('HHmmss');
    const targetUserId = scheduleState.targetUserId;
    const areKeysPressed = e.shiftKey && e.metaKey;

    if (scheduleState.viewMode === 'Weekly') {
      const currentWeekStart = moment().startOf('week').format('YYYYMMDD');
      const selectedWeekStart = scheduleState.selectedCalendarWeek.start;

      if (selectedWeekStart === currentWeekStart) {
        //currentWeek: create case date = today's date
        const today = moment().format('YYYYMMDD');
        const todayStart = `${today}${currentTime}`;
        const todayEnd = moment(todayStart, 'YYYYMMDDHHmmss')
          .add(1, 'hours')
          .format('YYYYMMDDHHmmss');
        triggerCreateDocbox(todayStart, todayEnd, targetUserId, areKeysPressed);
      } else {
        //all other weeks: create case date = Monday of the week
        const selectedStartMon = moment(selectedWeekStart)
          .add(1, 'day')
          .format('YYYYMMDD');
        const selectedStart = `${selectedStartMon}${currentTime}`;
        const selectedEnd = moment(selectedStart, 'YYYYMMDDHHmmss')
          .add(1, 'hours')
          .format('YYYYMMDDHHmmss');
        triggerCreateDocbox(
          selectedStart,
          selectedEnd,
          targetUserId,
          areKeysPressed,
        );
      }
    } else {
      //Daily View: create case date = selected date
      const selectedDate = moment(
        scheduleState.selectedCalendarDate,
        'YYYYMMDDTHHmmss',
      ).format('YYYYMMDD');
      const eventStart = `${selectedDate}${currentTime}`;
      const eventEnd = moment(eventStart, 'YYYYMMDDHHmmss')
        .add(1, 'hours')
        .format('YYYYMMDDHHmmss');
      triggerCreateDocbox(eventStart, eventEnd, targetUserId, areKeysPressed);
    }
  };

  // get calendar month and agenda for target user
  useEffect(() => {
    if (
      scheduleState.viewMode === 'Daily' &&
      scheduleState.targetUserId &&
      globalState.config.ABS_BASE_URL
    ) {
      const newDate = moment();
      let getAgendaURL = `${globalState.config.ABS_BASE_URL}${agendaURI}`;
      let getMonthURL = `${globalState.config.ABS_BASE_URL}${calendarMonthURI}`;
      selectMonthAndGetCalendar(
        getMonthURL,
        moment(scheduleState.selectedCalendarDate).format('YYYYMM'),
        scheduleState.targetUserId,
      );
      selectDateAndGetAgenda(
        getAgendaURL,
        scheduleState.selectedCalendarDate,
        scheduleState.targetUserId,
      ).then(() => {
        setIsScheduleLoading(false);
      });
    }
  }, [
    scheduleState.viewMode,
    scheduleState.targetUserId,
    globalState.config.ABS_BASE_URL,
  ]);

  // If shared schedule id in the URL, set it as the target. Else, set logged in user as target
  useEffect(() => {
    if (authState.userId && authState.userProfile) {
      setTargetUserId(authState.userId);
      setTargetUserName(
        formatFullName(
          authState['userProfile']['name']['first'],
          authState['userProfile']['name']['last'],
        ),
      );
      sendSharedScheduleId(authState.userId);
    }
  }, [authState.userId, authState.userProfile]);

  // get preferences of the target user
  useEffect(() => {
    if (scheduleState.targetUserId && globalState.config.ABS_BASE_URL) {
      const preferencesHashArray = [
        locationsHash,
        billingCodesHash,
        proceduresHash,
        preOpChecklistsHash,
        attendeesHash,
      ];
      const url = `${globalState.config.ABS_BASE_URL}${getUserPreferencesURI}`;
      getPreferences(url, 0, scheduleState.targetUserId, preferencesHashArray);
    }
  }, [scheduleState.targetUserId, globalState.config.ABS_BASE_URL]);

  // Get Shared Schedules list
  useEffect(() => {
    if (authState.userId && globalState.config.ABS_BASE_URL) {
      const url = `${globalState.config.ABS_BASE_URL}${getSharedSchedulesURI}`;
      getSharedSchedules(url, authState.userId);
    }
  }, [authState.userId, globalState.config.ABS_BASE_URL]);

  // get calendar week and agenda for target user
  useEffect(() => {
    //set page title to 'Physician's name' for print preview
    document.title = `DocSpera | ${scheduleState.targetUserName}`;
    if (
      scheduleState.viewMode === 'Weekly' &&
      scheduleState.selectedCalendarWeek &&
      scheduleState.targetUserId &&
      globalState.config.ABS_BASE_URL
    ) {
      const url = `${globalState.config.ABS_BASE_URL}${agendaURI}`;
      selectWeekAndGetAgenda(
        url,
        scheduleState.selectedCalendarWeek,
        scheduleState.targetUserId,
      ).then(() => {
        setIsWeeklyScheduleLoading(false);
      });
    }
  }, [
    scheduleState.viewMode,
    scheduleState.targetUserId,
    globalState.config.ABS_BASE_URL,
  ]);

  //refresh weekly schedule when case created/edited in parent window
  useEffect(() => {
    if (refreshPage) {
      const url = `${globalState.config.ABS_BASE_URL}${agendaURI}`;
      selectWeekAndGetAgenda(
        url,
        scheduleState.selectedCalendarWeek,
        scheduleState.targetUserId,
      );
      setRefreshPage(false);
    }
  }, [refreshPage]);

  useEffect(() => {
    //listen to postMessage from iframe parent (DocSpera)
    const navigate = evt => {
      let evtOrigin;
      // LOCAL
      if (window.location.href.includes('http://docspera.localhost')) {
        evtOrigin = 'http://docspera.localhost';
        // STAGING
      } else if (window.location.href.includes('docvisor')) {
        evtOrigin = 'https://docvisor.com';
        // PRODUCTION
      } else if (window.location.href.includes('docspera')) {
        evtOrigin = 'https://docspera.com';
      } else {
        console.log('no origin to receive from');
        return;
      }

      if (evt.origin === evtOrigin) {
        if (evt.data.type === 'eventCreate') {
          let event = evt.data.payload;
          if (event) {
            setRefreshPage(true);
          }
        }
        if (evt.data.type === 'eventEdit') {
          let event = evt.data.payload;
          if (event) {
            setRefreshPage(true);
          }
        }
        if (evt.data.type === 'printTriggered') {
          //check if parent window detected cmd/ctrl + p
          let event = evt.data.payload;
          if (event) {
            window.print();
          }
        }
      }
    };

    // listen for ctrl(cmd) + p
    const checkPrintKey = e => {
      if (
        (e.ctrlKey || e.metaKey) &&
        (e.key == 'p' ||
          e.charCode == 16 ||
          e.charCode == 112 ||
          e.keyCode == 80)
      ) {
        e.preventDefault();
        window.print();
      }
    };

    window.addEventListener('keydown', checkPrintKey, false);
    window.addEventListener('message', navigate, false);

    //clean up
    return () => window.removeEventListener('message', navigate);
  }, []);

  //autorefresh weekly schedule every 60 sec
  useEffect(() => {
    if (
      scheduleState.selectedCalendarWeek &&
      globalState.config.ABS_BASE_URL &&
      scheduleState.viewMode === 'Weekly' &&
      scheduleState.targetUserId
    ) {
      clearInterval(autoRefresh.current);
      if (rescheduledEvents.length === 0) {
        //only auto refresh when no there are no rescheduled events, to not cause events to "reset" when rescheduled events are not saved
        autoRefresh.current = AutoRefreshWeeklyAgenda(
          scheduleState.selectedCalendarWeek,
          scheduleState.targetUserId,
        );
      }
    }
  }, [
    scheduleState.selectedCalendarWeek,
    globalState.config.ABS_BASE_URL,
    scheduleState.viewMode,
    scheduleState.targetUserId,
    rescheduledEvents,
  ]);

  //autorefresh daily schedule every 60 sec
  useEffect(() => {
    if (
      scheduleState.selectedCalendarDate &&
      globalState.config.ABS_BASE_URL &&
      scheduleState.viewMode === 'Daily' &&
      scheduleState.targetUserId
    ) {
      clearInterval(autoRefresh.current);
      autoRefresh.current = AutoRefreshDailyAgenda(
        moment(scheduleState.selectedCalendarDate),
        scheduleState.targetUserId,
      );
    }
  }, [
    scheduleState.selectedCalendarDate,
    scheduleState.viewMode,
    globalState.config.ABS_BASE_URL,
    scheduleState.targetUserId,
  ]);

  function AutoRefreshDailyAgenda(selectedDate, selectedTargetUser) {
    return setInterval(
      function () {
        const getAgendaURL = `${globalState.config.ABS_BASE_URL}${agendaURI}`;
        selectDateAndGetAgenda(getAgendaURL, selectedDate, selectedTargetUser);
      },
      60000, //60 second auto refresh
    );
  }

  function AutoRefreshWeeklyAgenda(selectedWeek, selectedTargetUser) {
    return setInterval(
      function () {
        const url = `${globalState.config.ABS_BASE_URL}${agendaURI}`;
        selectWeekAndGetAgenda(url, selectedWeek, selectedTargetUser);
      },
      60000, //60 second auto refresh
    );
  }

  const previousWeek = () => {
    const url = `${globalState.config.ABS_BASE_URL}${agendaURI}`;
    const newWeek = {
      start: moment(scheduleState.selectedCalendarWeek.start, 'YYYYMMDD')
        .subtract('1', 'week')
        .startOf('week')
        .format('YYYYMMDD'),
      end: moment(scheduleState.selectedCalendarWeek.start, 'YYYYMMDD')
        .subtract('1', 'week')
        .endOf('week')
        .format('YYYYMMDD'),
    };
    selectWeekAndGetAgenda(url, newWeek, scheduleState.targetUserId);
  };

  const nextWeek = () => {
    const url = `${globalState.config.ABS_BASE_URL}${agendaURI}`;
    const newWeek = {
      start: moment(scheduleState.selectedCalendarWeek.start, 'YYYYMMDD')
        .add('1', 'week')
        .startOf('week')
        .format('YYYYMMDD'),
      end: moment(scheduleState.selectedCalendarWeek.start, 'YYYYMMDD')
        .add('1', 'week')
        .endOf('week')
        .format('YYYYMMDD'),
    };
    selectWeekAndGetAgenda(url, newWeek, scheduleState.targetUserId);
  };

  const handleDateSelect = event => {
    const url = `${globalState.config.ABS_BASE_URL}${agendaURI}`;
    const newWeek = {
      start: moment(event.target.dataset.date)
        .startOf('week')
        .format('YYYYMMDD'),
      end: moment(event.target.dataset.date).endOf('week').format('YYYYMMDD'),
    };
    selectWeekAndGetAgenda(url, newWeek, scheduleState.targetUserId);
  };

  const openICalModal = () => {
    let targetUserId = scheduleState.targetUserId;
    triggerOpenICal(targetUserId);
  };

  const callEditEvents = () => {
    if (globalState.config.ABS_BASE_URL) {
      const events = rescheduledEvents;
      const agendaUrl = `${globalState.config.ABS_BASE_URL}${agendaURI}`;
      rescheduleApi(events).then(() => 
        selectWeekAndGetAgenda(
          agendaUrl,
          scheduleState.selectedCalendarWeek,
          scheduleState.targetUserId,
        ),
      );
      setRescheduledEvents([]);
    }
  };

  // if (isLoading) {
  //   return <Loading />
  // }

  return (
    <>
      {isScheduleLoading ? (
        <div className="Loading"></div>
      ) : (
        <>
          <GlobalNavMenu />
          <section className="schedule-container">
            <div
              id="calendar-subheader"
              className="flex space-between wrap align-space-between">
              <div className="flex align-center">
                <div id="calendar-view-toggler" className="flex align-center">
                  <div
                    className={
                      scheduleState.viewMode === 'Daily' ? 'selected' : ''
                    }
                    onClick={() => {
                      setViewMode('Daily');
                      clearInterval(autoRefresh.current);
                    }}>
                    Daily
                  </div>

                  <div
                    className={
                      scheduleState.viewMode === 'Weekly' ? 'selected' : ''
                    }
                    onClick={() => {
                      setViewMode('Weekly');
                      clearInterval(autoRefresh.current);
                    }}>
                    Weekly
                  </div>
                </div>

                {scheduleState.viewMode === 'Weekly' && (
                  <div id="week-selector" className="flex align-center">
                    <div
                      className="flex justify-center align-center"
                      onClick={() => {
                        if (rescheduledEvents && rescheduledEvents.length > 0) {
                          //if usere agrees to move to prev week, reset dragged events
                          if (
                            window.confirm(
                              'Do you want to leave the page? Your unsaved data will be lost.',
                            ) == true
                          ) {
                            setRescheduledEvents([]);
                            return previousWeek();
                          }
                        } else {
                          return previousWeek();
                        }
                      }}>
                      <FontAwesomeIcon icon={faCaretLeft} size="lg" />
                    </div>

                    <div className="flex justify-center no-select">
                      <CalendarPicker
                        id={'week'}
                        name={'week'}
                        selectable={'week'}
                        value={scheduleState.selectedCalendarWeek.start}
                        endValue={scheduleState.selectedCalendarWeek.end}
                        handler={event => handleDateSelect(event)}
                        rescheduledEvents={rescheduledEvents}
                        setRescheduledEvents={setRescheduledEvents}
                        style={{width: '98%'}}
                      />
                      {/* {getEventStartToEndTime(scheduleState.selectedCalendarWeek.start, scheduleState.selectedCalendarWeek.end)} */}
                    </div>

                    <div
                      className="flex justify-center align-center"
                      onClick={() => {
                        if (rescheduledEvents && rescheduledEvents.length > 0) {
                          //if usere agrees to move to next week, reset dragged events
                          if (
                            window.confirm(
                              'Do you want to leave the page? Your unsaved data will be lost.',
                            ) == true
                          ) {
                            setRescheduledEvents([]);
                            return nextWeek();
                          }
                        } else {
                          return nextWeek();
                        }
                      }}>
                      <FontAwesomeIcon icon={faCaretRight} size="lg" />
                    </div>
                  </div>
                )}
                <div className="dropdown-box">
                  <FontAwesomeIcon
                    className="select-user"
                    icon={faUser}
                    size="sm"
                  />
                  <select
                    id="calendar-selector"
                    name="calendar-selector"
                    defaultValue={scheduleState.targetUserId}
                    onChange={e => {
                      const targetUserID = parseInt(e.target.value);
                      const targetUserName =
                        targetUserID === authState.userId
                          ? userName
                          : scheduleState.sharedSchedules.filter(
                              user => user.id == targetUserID,
                            )[0].name;
                      setTargetUserId(targetUserID);
                      setTargetUserName(targetUserName);
                      sendSharedScheduleId(targetUserID);
                      return;
                    }}>
                    <option value={authState.userId}>My Calendar</option>

                    {scheduleState.sharedSchedules &&
                      scheduleState.sharedSchedules.length > 0 &&
                      scheduleState.sharedSchedules.map(user => {
                        // console.log('add user to physician dropdown', user);
                        return (
                          <option key={user.id} value={user.id}>
                            {user.name}
                          </option>
                        );
                      })}
                  </select>
                  <FontAwesomeIcon
                    className="select-arrow"
                    icon={faCaretDown}
                    size="lg"
                  />
                </div>
              </div>

              <div className="flex align-center">
                {scheduleState.viewMode === 'Weekly' && (
                  <div
                    className="icon-airplane"
                    style={{position: 'relative'}}
                    onClick={() => callEditEvents()}>
                    <Airplane
                      color={
                        rescheduledEvents.length > 0
                          ? 'var(--blueDocspera)'
                          : 'var(--light-gray)'
                      }
                    />
                    {rescheduledEvents.length > 0 && (
                      <div className="airplane-number">
                        <span>{rescheduledEvents.length}</span>
                      </div>
                    )}
                  </div>
                )}
                <div
                  className="flex align-center create-event"
                  onClick={e => {
                    openDocboxCreate(e);
                  }}>
                  {/* <FontAwesomeIcon
              icon={faPlus}
              size="lg"
            /> Event */}
                  Book Case
                </div>
                <div
                  className="flex align-center event-settings"
                  onClick={() => {
                    triggerEventSettings(scheduleState.targetUserId);
                  }}>
                  <FontAwesomeIcon icon={faCog} size="lg" />
                </div>

                <div
                  className="flex align-center print-calendar"
                  title="Print Calendar"
                  onClick={() => {
                    window.print();
                  }}>
                  <FontAwesomeIcon icon={faPrint} size="lg" />
                </div>

                {
                  <div
                    className="flex align-center add-calendar"
                    title="Add to Calendar"
                    onClick={() => {
                      openICalModal();
                    }}>
                    <FontAwesomeIcon icon={faCalendar} size="lg" />
                  </div>
                }
              </div>
            </div>

            <section id="schedule" className="content-container-2">
              <div
                className={`content${
                  scheduleState.viewMode === 'Daily'
                    ? ' flex justify-start'
                    : ''
                }`}>
                {scheduleState.viewMode === 'Weekly' && (
                  <>
                    <WeeklyCalendar
                      rescheduledEvents={rescheduledEvents}
                      setRescheduledEvents={setRescheduledEvents}
                      isWeeklyScheduleLoading={isWeeklyScheduleLoading}
                      autoRefresh={autoRefresh}
                      AutoRefreshWeeklyAgenda={AutoRefreshWeeklyAgenda}
                    />
                  </>
                )}

                {scheduleState.viewMode === 'Daily' && (
                  <>
                    <CalendarContainer
                      isDayClicked={isDayClicked}
                      setIsDayClicked={setIsDayClicked}
                    />
                    <AgendaView isDayClicked={isDayClicked} />
                  </>
                )}
              </div>
            </section>
          </section>
        </>
      )}
    </>
  );
};

export default MyCalendar;
