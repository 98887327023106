import React, {useEffect, useRef} from 'react';
import {
  faAngleRight,
  faAngleLeft,
  faSync,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './Pagination.scss';

const Pagination = ({
  total,
  page,
  setPage,
  isLoading,
  isPDLoading,
  autoScroll,
}) => {
  let numOfPages = Math.ceil(total / 10);
  //auto scroll every 20s
  useEffect(() => {
    if (autoScroll && page + 1 && numOfPages) {
      const autoPaginateTimer = setInterval(() => {
        if (page + 1 < numOfPages) {
          setPage(page + 1);
        } else if (page + 1 === numOfPages) {
          setPage(0);
        }
      }, 1000 * 20);
      return () => {
        clearInterval(autoPaginateTimer);
      };
    }
  }, [autoScroll, page, numOfPages]);

  return (
    <div className="pagination">
      <div
        className={`prev-page ${page <= 0 ? 'end' : ''} ${autoScroll && 'end'}`}
        onClick={() => {
          page > 0 && setPage(page - 1);
        }}>
        <FontAwesomeIcon icon={faAngleLeft} size="1x" />
      </div>

      <div className={`pages`}>
        {isLoading || isPDLoading ? (
          <FontAwesomeIcon
            className="fa-spin"
            icon={faSync}
            size="1x"
            color="var(--icon-idle-gray)"
            spin
          />
        ) : (
          <span>
            {' '}
            {page + 1} of {numOfPages}{' '}
          </span>
        )}
      </div>

      <div
        className={`next-page ${page + 1 >= numOfPages ? 'end' : ''} ${
          autoScroll && 'end'
        }`}
        onClick={() => {
          page + 1 < numOfPages && setPage(page + 1);
        }}>
        <FontAwesomeIcon icon={faAngleRight} size="1x" />
      </div>
    </div>
  );
};

export default Pagination;
