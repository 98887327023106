import React, {useState} from 'react';
import './Notes.scss';

const Notes = ({notes}) => {
  const [shorten, setShorten] = useState(true);

  return (
    <div>
      {notes && shorten ? (
        <>
          <span>{`${notes.substr(0, 75)} ...`}</span>
          {/* <span
            className="see-more"
            onClick={(e) => {
              e.stopPropagation();
              setShorten(false);
            }}
          >
            See more
          </span> */}
        </>
      ) : (
        <>
          <span>{notes}</span>
          <span
            className="see-more"
            onClick={e => {
              e.stopPropagation();
              setShorten(true);
            }}>
            See less
          </span>
        </>
      )}
    </div>
  );
};

export default Notes;
