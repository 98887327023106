import createDataContext from './createDataContext';
import instance from '../dataAccess/docspera';

let initialState = {
  editors: null,
  contacts: null,
  vendorGroups: null,
  errorMessage: '',
  statusMessage: '',
};

const contactsReducer = (state, action) => {
  switch (action.type) {
    case 'SetContacts':
      return {
        ...state,
        contacts: action.payload.contacts,
      };
    case 'SetEditors':
      return {
        ...state,
        editors: action.payload.editors,
      };
    case 'AddEditor':
      if (action.payload) {
        return {
          ...state,
          editors: [...state.editors, action.payload],
        };
      }
      return state;
    case 'RemoveEditor':
      if (action.payload) {
        return {
          ...state,
          editors: state.editors.filter(
            editor => editor.id != action.payload.id,
          ),
        };
      }
      return state;
    case 'SetVendorGroups':
      return {
        ...state,
        vendorGroups: action.payload.vendorGroups,
      };
    case 'ResetState':
      return initialState;
    case 'Status':
      return {
        ...state,
        statusMessage: action.payload,
        errorMessage: '',
      };
    case 'Error':
      console.log('error :: ', action.payload);
      return {
        ...state,
        errorMessage: action.payload,
        statusMessage: '',
      };
    default:
      return state;
  }
};

const getContacts =
  dispatch => async (url, userId, contactType, filter, limit, offset) => {
    if (isNaN(userId)) {
      return dispatch({
        type: 'Error',
        payload: 'Error from getContacts: input userId is NaN',
      });
    }
    if (!url) {
      return dispatch({
        type: 'Error',
        payload: 'Error from getContacts: no url',
      });
    }

    const request = {
      user: userId,
      type: contactType ? contactType : 'All',
      filter: filter ? filter : '',
      paginate: {
        limit: limit ? limit : 1000,
        offset: offset ? offset : 0,
      },
    };

    return fetch(url, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    })
      .then(res => res.json())
      .then(response => {
        if (!response) {
          return dispatch({
            type: 'Error',
            payload: `null response from ${url}`,
          });
        } else {
          if (response && response.contacts) {
            const payload = {
              contacts: response.contacts.length ? response.contacts : [],
            };

            return dispatch({type: 'SetContacts', payload: payload});
          } else {
            return dispatch({
              type: 'Error',
              payload: 'Could not parse contacts from API.',
            });
          }
        }
      })
      .catch(error => {
        console.error('Error:', url, error);
      });

    // try {
    //   const request = {
    //     user: userId,
    //     type: contactType ? contactType : 'All',
    //     filter: filter ? filter : '',
    //     paginate: {
    //       limit: limit ? limit : 1000,
    //       offset: offset ? offset : 0
    //     }
    //   }
    //   const response = await instance.post(url, request)

    //   if (!response.data) {
    //     return dispatch({ type: 'Error', payload: `null response from ${url}` })
    //   } else {
    //     if (response.data && response.data.contacts) {
    //       const payload = {
    //         contacts: response.data.contacts.length ? response.data.contacts : []
    //       }

    //       return dispatch({ type: 'SetContacts', payload: payload })
    //     } else {
    //       return dispatch({ type: 'Error', payload: 'Could not parse contacts from API.' })
    //     }
    //   }
    // } catch (error) {
    //   return dispatch({ type: 'Error', payload: `Error from getContacts: ${error.message}` })
    // }
  };

const getEditors = dispatch => async (url, userId) => {
  if (isNaN(userId)) {
    return dispatch({
      type: 'Error',
      payload: 'Error from getEditors: input userId is NaN',
    });
  }
  if (!url) {
    return dispatch({
      type: 'Error',
      payload: 'Error from getEditors: no url',
    });
  }

  try {
    const request = {
      user: userId,
    };
    const response = await instance.post(url, request);

    if (!response.data) {
      return dispatch({type: 'Error', payload: `null response from ${url}`});
    } else {
      if (response.data && response.data.editors) {
        const payload = {
          editors: response.data.editors.length ? response.data.editors : [],
        };

        return dispatch({type: 'SetEditors', payload: payload});
      } else {
        return dispatch({
          type: 'Error',
          payload: 'Could not parse editors from API.',
        });
      }
    }
  } catch (error) {
    return dispatch({
      type: 'Error',
      payload: `Error from getEditors: ${error.message}`,
    });
  }
};

const addEditor = dispatch => async (url, userId, editor) => {
  if (!editor) {
    return dispatch({
      type: 'Error',
      payload: 'Error from addEditor: no editor',
    });
  }
  if (isNaN(userId)) {
    return dispatch({
      type: 'Error',
      payload: 'Error from addEditor: input userId is NaN',
    });
  }
  if (editor && editor.id && isNaN(editor.id)) {
    return dispatch({
      type: 'Error',
      payload: 'Error from addEditor: input editorId is NaN',
    });
  }
  if (!url) {
    return dispatch({type: 'Error', payload: 'Error from addEditor: no url'});
  }

  try {
    const request = {
      user: userId,
      add: editor.id.toString(),
    };
    const response = await instance.post(url, request);

    if (!response.data) {
      return dispatch({type: 'Error', payload: `null response from ${url}`});
    } else {
      if (
        response.data &&
        response.data.status &&
        response.data.status === 'Added'
      ) {
        const payload = {
          name: editor.name,
          id: editor.id.toString(),
          type: editor.type,
          sub: editor.sub,
          specialty: editor.specialty,
          avatar: editor.avatar.toString(),
        };

        dispatch({type: 'AddEditor', payload: payload});
        return response;
      } else {
        return dispatch({
          type: 'Error',
          payload: 'API error from adding editor.',
        });
      }
    }
  } catch (error) {
    return dispatch({
      type: 'Error',
      payload: `Error from addEditor: ${error.message}`,
    });
  }
};

const removeEditor = dispatch => async (url, editorId) => {
  if (isNaN(editorId)) {
    return dispatch({
      type: 'Error',
      payload: 'Error from removeEditor: input editorId is NaN',
    });
  }
  if (!url) {
    return dispatch({
      type: 'Error',
      payload: 'Error from removeEditor: no url',
    });
  }

  try {
    const request = {
      remove: editorId.toString(),
    };
    const response = await instance.post(url, request);

    if (!response.data) {
      return dispatch({type: 'Error', payload: `null response from ${url}`});
    } else {
      if (
        response.data &&
        response.data.status &&
        response.data.status === 'Removed'
      ) {
        const payload = {
          id: editorId.toString(),
        };

        dispatch({type: 'RemoveEditor', payload: payload});
        return response;
      } else {
        return dispatch({
          type: 'Error',
          payload: 'API error from removing editor.',
        });
      }
    }
  } catch (error) {
    dispatch({
      type: 'Error',
      payload: `Error from removeEditor: ${error.message}`,
    });
  }
};

const getVendorGroups =
  dispatch => async (url, currentVendorGroupsArray, filter) => {
    if (currentVendorGroupsArray === null) {
      if (!url) {
        return dispatch({
          type: 'Error',
          payload: 'Error from getVendorGroups: no url',
        });
      }

      try {
        const request = {
          filter: filter ? filter : 'None',
        };
        const response = await instance.post(url, request);

        if (!response.data) {
          return dispatch({
            type: 'Error',
            payload: `null response from ${url}`,
          });
        } else {
          if (
            response.data &&
            response.data.status === 'Success' &&
            Array.isArray(response.data.groups)
          ) {
            const payload = {
              vendorGroups: response.data.groups.length
                ? response.data.groups
                : [],
            };

            dispatch({type: 'SetVendorGroups', payload: payload});

            return payload.vendorGroups;
          } else {
            return dispatch({
              type: 'Error',
              payload: `API error from ${url}`,
            });
          }
        }
      } catch (error) {
        dispatch({
          type: 'Error',
          payload: `Error from getVendorGroups: ${error.message}`,
        });
      }
    } else {
      return currentVendorGroupsArray;
    }
  };

const resetState = dispatch => () => {
  return dispatch({type: 'ResetState'});
};

export const {Provider, Context} = createDataContext(
  contactsReducer,
  {
    getContacts,
    getEditors,
    addEditor,
    removeEditor,
    getVendorGroups,
    resetState,
  },
  initialState,
);
