import React, {useContext, useEffect, useState} from 'react';
import moment from 'moment';

import {Context as ScheduleContext} from '../../context/ScheduleContext';
import {Context as GlobalContext} from '../../context/GlobalContext';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretLeft, faCaretRight} from '@fortawesome/free-solid-svg-icons';

import {dateFromISODate, getMonthAndYear} from '../../libraries/moment';
import {agendaURI, calendarMonthURI} from '../../dataAccess/apiEndpoints';

import './Calendar.scss';

const Calendar = ({isDayClicked, setIsDayClicked}) => {
  const {
    state: scheduleState,
    selectDateAndGetAgenda,
    selectCalendarMonth,
    selectMonthAndGetCalendar,
  } = useContext(ScheduleContext);
  const {state: globalState} = useContext(GlobalContext);
  const startOfMonth = moment(scheduleState.selectedCalendarMonth, 'YYYYMM')
    .clone()
    .startOf('month');
  let getAgendaURL = `${globalState.config.ABS_BASE_URL}${agendaURI}`;
  let getMonthURL = `${globalState.config.ABS_BASE_URL}${calendarMonthURI}`;

  const [refreshPage, setRefreshPage] = useState(false);

  //refresh daily view on case create/edit in parent view
  useEffect(() => {
    if (refreshPage) {
      getAgendaURL = `${globalState.config.ABS_BASE_URL}${agendaURI}`;
      getMonthURL = `${globalState.config.ABS_BASE_URL}${calendarMonthURI}`;
      selectMonthAndGetCalendar(
        getMonthURL,
        scheduleState.selectedCalendarMonth,
        scheduleState.targetUserId,
      );
      selectDateAndGetAgenda(
        getAgendaURL,
        scheduleState.selectedCalendarDate,
        scheduleState.targetUserId,
      );
      setRefreshPage(false);
    }
  }, [refreshPage]);

  //listen to postMessage from iframe parent (DocSpera)
  useEffect(() => {
    const navigate = evt => {
      let evtOrigin;
      // LOCAL
      if (window.location.href.includes('http://docspera.localhost')) {
        evtOrigin = 'http://docspera.localhost';
        // STAGING
      } else if (window.location.href.includes('docvisor')) {
        evtOrigin = 'https://docvisor.com';
        // PRODUCTION
      } else if (window.location.href.includes('docspera')) {
        evtOrigin = 'https://docspera.com';
      } else {
        console.log('no origin to receive from');
        return;
      }

      if (evt.origin === evtOrigin) {
        if (evt.data.type === 'eventCreate') {
          let event = evt.data.payload;
          if (event) {
            setRefreshPage(true);
          }
        }
        if (evt.data.type === 'eventEdit') {
          let event = evt.data.payload;
          if (event) {
            setRefreshPage(true);
          }
        }
      }
    };

    window.addEventListener('message', navigate, false);

    //clean up
    return () => window.removeEventListener('message', navigate);
  }, []);

  // build weeks array for rendering
  const weeks = [
    [], // first week
  ];

  let date = startOfMonth.clone().subtract(startOfMonth.day(), 'day');

  // go through each day and build up the calendar
  for (let i = 0; i < 42; i++) {
    let weekNum = Math.floor(i / 7);

    if (weeks[weekNum] === undefined) {
      weeks[weekNum] = [];
    }

    let type = 'none';
    let confirmed = 0;
    let unconfirmed = 0;
    let objDate = date.toDate();

    // Day types: 'empty', 'none', 'confirmed', 'unconfirmed', 'cancelled', 'one_day_vacation', one_day_conference',
    // 'start_vacation', 'start_conference', 'mid_vacation', or 'mid_conference', 'end_vacation', or 'end_conference'
    for (let dayOfMonth of scheduleState.month) {
      const daysDate = dateFromISODate(dayOfMonth.isoDate);
      if (daysDate.isSame(date, 'd')) {
        confirmed = dayOfMonth.confirmed;
        unconfirmed = dayOfMonth.unconfirmed;

        if (dayOfMonth.unconfirmed > 0) {
          type = 'unconfirmed';
        } else if (dayOfMonth.confirmed > 0) {
          type = 'confirmed';
        } else if (dayOfMonth.vacation == 'one_day') {
          type = 'one_day_vacation';
        } else if (dayOfMonth.vacation == 'start') {
          type = 'start_vacation';
        } else if (dayOfMonth.vacation == 'mid') {
          type = 'mid_vacation';
        } else if (dayOfMonth.vacation == 'end') {
          type = 'end_vacation';
        } else if (dayOfMonth.conference == 'one_day') {
          type = 'one_day_conference';
        } else if (dayOfMonth.conference == 'start') {
          type = 'start_conference';
        } else if (dayOfMonth.conference == 'mid') {
          type = 'mid_conference';
        } else if (dayOfMonth.conference == 'end') {
          type = 'end_conference';
        }

        break;
      }
    }

    const dayObj = {
      date: date.date(),
      weekNum: weekNum,
      type: type,
      confirmed: confirmed,
      unconfirmed: unconfirmed,
      moment: objDate,
      currentMonth: date.isSame(
        moment(scheduleState.selectedCalendarMonth, 'YYYYMM'),
        'month',
      ),
    };

    weeks[weekNum].push(dayObj);

    date = date.add(1, 'd');
  }

  const previousMonth = () => {
    setIsDayClicked(false);
    const newMonth = moment(scheduleState.selectedCalendarMonth, 'YYYYMM')
      .subtract(1, 'month')
      .format('YYYYMM');
    selectMonthAndGetCalendar(
      getMonthURL,
      newMonth,
      scheduleState.targetUserId,
    ).then(() => {
      setIsDayClicked(true);
    });
  };

  const nextMonth = () => {
    setIsDayClicked(false);
    const newMonth = moment(scheduleState.selectedCalendarMonth, 'YYYYMM')
      .add(1, 'month')
      .format('YYYYMM');
    selectMonthAndGetCalendar(
      getMonthURL,
      newMonth,
      scheduleState.targetUserId,
    ).then(() => {
      setIsDayClicked(true);
    });
  };

  const setToday = () => {
    setIsDayClicked(false);
    const newDate = moment();
    selectMonthAndGetCalendar(
      getMonthURL,
      newDate.format('YYYYMM'),
      scheduleState.targetUserId,
    );
    selectDateAndGetAgenda(
      getAgendaURL,
      newDate,
      scheduleState.targetUserId,
    ).then(() => {
      setIsDayClicked(true);
    });
  };

  const handleDayClicked = day => {
    setIsDayClicked(true);
    selectDateAndGetAgenda(
      getAgendaURL,
      day.moment,
      scheduleState.targetUserId,
    );
    if (!day.currentMonth) {
      const newMonth = moment(day.moment);
      selectMonthAndGetCalendar(
        getMonthURL,
        newMonth.format('YYYYMM'),
        scheduleState.targetUserId,
      );
    }
  };

  const findBackgroundColor = (type, currentMonth) => {
    switch (type) {
      case 'empty':
        return;
      case 'none':
        return;
      case 'one_day_vacation':
        return currentMonth
          ? 'var(--calendar-vacation)'
          : 'var(--calendar-vacation)';
      case 'start_vacation':
        return currentMonth
          ? 'var(--calendar-vacation)'
          : 'var(--calendar-vacation)';
      case 'mid_vacation':
        return currentMonth
          ? 'var(--calendar-vacation)'
          : 'var(--calendar-vacation)';
      case 'end_vacation':
        return currentMonth
          ? 'var(--calendar-vacation)'
          : 'var(--calendar-vacation)';
      case 'one_day_conference':
        return currentMonth
          ? 'var(--calendar-conference)'
          : 'var(--calendar-conference)';
      case 'start_conference':
        return currentMonth
          ? 'var(--calendar-conference)'
          : 'var(--calendar-conference)';
      case 'mid_conference':
        return currentMonth
          ? 'var(--calendar-conference)'
          : 'var(--calendar-conference)';
      case 'end_conference':
        return currentMonth
          ? 'var(--calendar-conference)'
          : 'var(--calendar-conference)';
      case 'unconfirmed':
        return currentMonth
          ? 'var(--calendar-unconfirmed'
          : 'var(--calendar-unconfirmed)';
      case 'confirmed':
        return currentMonth
          ? 'var(--calendar-confirmed)'
          : 'var(--calendar-confirmed)';
      default:
        return;
    }
  };

  const findOpacity = currentMonth => {
    return !currentMonth ? '0.5' : '1';
  };

  const findFontColor = (type, currentMonth) => {
    if (currentMonth) {
      switch (type) {
        case 'empty':
          return;
        case 'none':
          return;
        case 'one_day_vacation':
          return 'var(--white)';
        case 'start_vacation':
          return 'var(--white)';
        case 'mid_vacation':
          return 'var(--white)';
        case 'end_vacation':
          return 'var(--white)';
        case 'one_day_conference':
          return 'var(--white)';
        case 'start_conference':
          return 'var(--white)';
        case 'mid_conference':
          return 'var(--white)';
        case 'end_conference':
          return 'var(--white)';
        case 'unconfirmed':
          return 'var(--white)';
        case 'confirmed':
          return 'var(--white)';
        default:
          return 'var(--white)';
      }
    }
    return 'var(--black)';
  };

  const findBorderColor = (type, isToday) => {
    if (isToday) {
      return 'var(--dark-blue)';
    }
  };

  const findLeftRadius = type => {
    switch (type) {
      case 'one_day_vacation':
        return '100%';
      case 'start_vacation':
        return '100%';
      case 'mid_vacation':
        return '0';
      case 'end_vacation':
        return '0';
      case 'one_day_conference':
        return '100%';
      case 'start_conference':
        return '100%';
      case 'mid_conference':
        return '0';
      case 'end_conference':
        return '0';
      default:
        return '100%';
    }
  };

  const findRightRadius = type => {
    switch (type) {
      case 'one_day_vacation':
        return '100%';
      case 'start_vacation':
        return '0';
      case 'mid_vacation':
        return '0';
      case 'end_vacation':
        return '100%';
      case 'one_day_conference':
        return '100%';
      case 'start_conference':
        return '0';
      case 'mid_conference':
        return '0';
      case 'end_conference':
        return '100%';
      default:
        return '100%';
    }
  };

  // const findMarginRight = (type) => {
  //   switch(type){
  //     case 'one_day_vacation':
  //       return '2px'
  //     case 'start_vacation':
  //       return '2px'
  //     case 'mid_vacation':
  //       return 0
  //     case 'end_vacation':
  //       return '2px'
  //     case 'one_day_conference':
  //       return '2px'
  //     case 'start_conference':
  //       return 0
  //     case 'mid_conference':
  //       return 0
  //     case 'end_conference':
  //       return '2px'
  //     case 'unconfirmed':
  //       return '2px'
  //     case 'confirmed':
  //       return '2px'
  //     default:
  //       return 0
  //   }
  // }

  // const findMarginLeft = (type) => {
  //   switch(type){
  //     case 'one_day_vacation':
  //       return '2px'
  //     case 'start_vacation':
  //       return '2px'
  //     case 'mid_vacation':
  //       return 0
  //     case 'end_vacation':
  //       return 0
  //     case 'one_day_conference':
  //       return '2px'
  //     case 'start_conference':
  //       return '2px'
  //     case 'mid_conference':
  //       return 0
  //     case 'end_conference':
  //       return 0
  //     case 'unconfirmed':
  //       return '2px'
  //     case 'confirmed':
  //       return '2px'
  //     default:
  //       return 0
  //   }
  // }

  return (
    <section id="calendar">
      <header className="flex justify-center align-center direction-row">
        <div
          className="flex justify-center align-center"
          onClick={() => {
            previousMonth();
          }}>
          <FontAwesomeIcon icon={faCaretLeft} size="lg" />
        </div>
        <div>
          <h1>{getMonthAndYear(scheduleState.selectedCalendarMonth)}</h1>
        </div>
        <div
          className="flex justify-center align-center"
          onClick={() => {
            nextMonth();
          }}>
          <FontAwesomeIcon icon={faCaretRight} size="lg" />
        </div>
      </header>

      <div id="view-today" className="flex align-center">
        {
          <div
            onClick={() => {
              setToday();
            }}>
            Return to Today
          </div>
        }
      </div>

      <div id="weekdays" className="flex direction-row nowrap justify-center">
        <div>Sun</div>
        <div>Mon</div>
        <div>Tue</div>
        <div>Wed</div>
        <div>Thu</div>
        <div>Fri</div>
        <div>Sat</div>
      </div>

      {/* Calendar Main */}
      {!isDayClicked && scheduleState.isLoading ? (
        <div className="Loading"></div>
      ) : (
        <div id="month">
          {weeks.map((week, index) => {
            return (
              <div
                className="week flex direction-row nowrap justify-center align-center"
                key={index}>
                {week.map((day, index) => {
                  let isToday = moment(day.moment).isSame(moment(), 'd');
                  let isSelected = moment(
                    scheduleState.selectedCalendarDate,
                  ).isSame(day.moment, 'd');

                  return (
                    <div
                      className={`day flex justify-center align-center ${
                        isToday ? 'today' : ''
                      } ${!day.currentMonth ? 'not-month' : ''} ${
                        isSelected ? 'selected' : ''
                      }`}
                      key={index}
                      onClick={() => handleDayClicked(day)}>
                      <div
                        className={`flex justify-center align-center ${
                          isToday ? 'today' : day.type
                        } ${isSelected ? 'selected' : ''} ${day.type}`}
                        style={{
                          backgroundColor: findBackgroundColor(
                            day.type,
                            day.currentMonth,
                          ),
                          borderBottomRightRadius: `${findRightRadius(
                            day.type,
                          )}`,
                          borderBottomLeftRadius: `${findLeftRadius(day.type)}`,
                          borderTopLeftRadius: `${findLeftRadius(day.type)}`,
                          borderTopRightRadius: `${findRightRadius(day.type)}`,
                          borderColor: findBorderColor(day.type, isToday),
                          color: findFontColor(day.type, day.currentMonth),
                          height: '35px',
                          opacity: `${findOpacity(day.currentMonth)}`,
                        }}>
                        {day.date}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
      {/*/ Calendar Main */}
    </section>
  );
};

export default Calendar;
