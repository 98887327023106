import createDataContext from './createDataContext';
import instance from '../dataAccess/docspera';
import {dateFromISODateTime, dateToISODate} from '../libraries/moment';
import {flattenMonth} from '../libraries/workers';
import moment from 'moment';

import {showHideClinicalHash} from '../dataAccess/preferencesHash';

const initialState = {
  isLoading: false,
  viewMode: 'Daily',
  selectedCalendarDate: moment().format('YYYYMMDD[T]HHmmss'),
  selectedCalendarMonth: moment().format('YYYYMM'),
  selectedCalendarWeek: {
    start: moment().startOf('week').format('YYYYMMDD'),
    end: moment().endOf('week').format('YYYYMMDD'),
  },
  month: [],
  calendarMonth: [],
  calendarWeek: [],
  agenda: [],
  drafts: [],
  myDraftsCount: '0',
  showClinical: false,
  sharedSchedules: [],
  sharedScheduleId: null,
  sharedScheduleName: '',
  sharedScheduleLicenses: [],
  targetUserId: null,
  targetUserName: '',
  availabilityCalendar: null,
  scheduleSearchResults: [],
  scheduleSearchQuery: '',
  errorMessage: '',
};

const scheduleReducer = (state, action) => {
  switch (action.type) {
    case 'SelectCalendarDate':
      return {
        ...state,
        selectedCalendarDate: action.payload,
        errorMessage: '',
      };
    case 'SelectCalendarMonth':
      return {
        ...state,
        selectedCalendarMonth: action.payload,
        errorMessage: '',
      };
    case 'SelectCalendarWeek':
      return {
        ...state,
        selectedCalendarWeek: action.payload,
        errorMessage: '',
      };
    case 'AgendaStart':
      return {
        ...state,
        isLoading: true,
      };
    case 'AgendaFinish':
      return {
        ...state,
        agenda: action.payload,
        isLoading: false,
      };
    case 'CalendarMonthStart':
      return {
        ...state,
        isLoading: true,
      };
    case 'CalendarMonthFinish':
      return {
        ...state,
        calendarMonth: action.payload,
        isLoading: false,
      };
    case 'CalendarWeekStart':
      return {
        ...state,
        isLoading: true,
      };
    case 'CalendarWeekFinish':
      return {
        ...state,
        calendarWeek: action.payload,
        isLoading: false,
      };
    case 'MonthFinish':
      return {
        ...state,
        month: action.payload,
        isLoading: false,
      };
    case 'DraftsStart':
      return {
        ...state,
        isLoading: true,
      };
    case 'DraftsFinish':
      return {
        ...state,
        drafts: action.payload,
        isLoading: false,
      };
    case 'GetMyDraftsCount':
      return {
        ...state,
        myDraftsCount: action.payload,
      };
    case 'SetShowClinical':
      return {
        ...state,
        showClinical: action.payload,
        errorMessage: '',
      };
    case 'SetSharedScheduleId':
      return {
        ...state,
        sharedScheduleId: action.payload,
        errorMessage: '',
      };
    case 'SetSharedScheduleName':
      return {
        ...state,
        sharedScheduleName: action.payload,
      };
    case 'GetSharedSchedules':
      return {
        ...state,
        sharedSchedules: action.payload,
      };
    case 'GetSharedScheduleDSLicenses':
      return {
        ...state,
        sharedScheduleLicenses: action.payload,
        errorMessage: '',
        statusMessage: '',
      };
    case 'SetTargetUserId':
      return {
        ...state,
        targetUserId: action.payload,
        errorMessage: '',
      };
    case 'SetTargetUserName':
      return {
        ...state,
        targetUserName: action.payload,
      };
    case 'GetScheduleSearch':
      return {
        ...state,
        scheduleSearchResults: action.payload,
      };
    case 'SetScheduleSearchQuery':
      return {
        ...state,
        scheduleSearchQuery: action.payload,
      };
    case 'ClearScheduleSearch':
      return {
        ...state,
        scheduleSearchResults: [],
        scheduleSearchQuery: '',
      };
    case 'SetViewMode':
      return {
        ...state,
        viewMode: action.payload,
      };
    case 'SetAvailabilityCalendar': {
      return {
        ...state,
        availabilityCalendar: action.payload,
        isLoading: false,
      };
    }
    case 'Error':
      return {
        ...state,
        errorMessage: action.payload,
      };
    case 'ClearSchedule':
      return initialState;
    default:
      return state;
  }
};

/*** MY AGENDA **********/
const getAgenda = dispatch => async (url, userId, selectedDate) => {
  if (isNaN(userId)) {
    return dispatch({
      type: 'Error',
      payload: 'Error from getAgenda: input userId is NaN',
    });
  }
  if (!url) {
    return dispatch({type: 'Error', payload: 'Error from getAgenda: no url'});
  }

  dispatch({type: 'AgendaStart'});

  try {
    const cleanDate = selectedDate ? moment(selectedDate) : moment();
    const isoStart = moment(dateToISODate(cleanDate), 'YYYYMMDD').format(
      'YYYYMMDDHHmmss',
    );
    const startDate = dateFromISODateTime(isoStart);
    const request = {
      year: moment(startDate).get('year'),
      month: moment(startDate).get('month') + 1,
      day: moment(startDate).date(),
      days: 1,
      user: userId,
    };

    const response = await instance.post(url, request);

    if (!response.data) {
      return dispatch({type: 'Error', payload: `null response from ${url}`});
    } else {
      if ('agenda' in response.data) {
        if (!Array.isArray(response.data.agenda)) {
          return dispatch({
            type: 'Error',
            payload: 'getAgenda: incorrect data type for agenda array.',
          });
        }
        return dispatch({
          type: 'AgendaFinish',
          payload: response.data.agenda,
        });
      }
    }
  } catch (error) {
    return dispatch({
      type: 'Error',
      payload: `Error from getAgenda: ${error.message}`,
    });
  }
};

const getMonth = dispatch => async (url, selectedMonth, userId) => {
  if (isNaN(userId)) {
    return dispatch({
      type: 'Error',
      payload: 'Error from getMonth: input userId is NaN',
    });
  }
  if (!url) {
    return dispatch({type: 'Error', payload: 'Error from getMonth: no url'});
  }

  dispatch({type: 'CalendarMonthStart'});

  try {
    const year = moment(selectedMonth, 'YYYYMM').format('YYYY');
    const month = moment(selectedMonth, 'YYYYMM').format('MM');
    const request = {
      year: year,
      month: month,
      user: userId,
    };

    const response = await instance.post(url, request);

    if (!response.data) {
      return dispatch({type: 'Error', payload: `null response from ${url}`});
    } else {
      const monthArray = flattenMonth(response.data);
      dispatch({type: 'MonthFinish', payload: monthArray});
      return dispatch({type: 'CalendarMonthFinish', payload: response.data});
    }
  } catch (error) {
    return dispatch({
      type: 'Error',
      payload: `Error from getMonth: ${error.message}`,
    });
  }
};
/*/* MY AGENDA **********/

/*** MY PENDING CASES **********/
const getDrafts = dispatch => async (url, userId) => {
  if (!userId || (userId && isNaN(userId))) {
    return dispatch({
      type: 'Error',
      payload: 'Error from getDrafts: invalid userId',
    });
  }
  if (!url) {
    return dispatch({type: 'Error', payload: 'Error from getDrafts: no url'});
  }

  dispatch({type: 'DraftsStart'});

  try {
    const request = {
      type: 'All',
      paginate: {
        limit: 100,
        offset: 0,
      },
      user: userId.toString(),
    };

    const response = await instance.post(url, request);

    if (!response.data) {
      return dispatch({type: 'Error', payload: `null response from ${url}`});
    } else {
      return dispatch({type: 'DraftsFinish', payload: response.data.drafts});
    }
  } catch (error) {
    return dispatch({
      type: 'Error',
      payload: `Error from getDrafts: ${error.message}`,
    });
  }
};

const getMyDraftsCount = dispatch => async (url, userId) => {
  if (!userId || (userId && isNaN(userId))) {
    return dispatch({
      type: 'Error',
      payload: 'Error from getMyDraftsCount: invalid userId',
    });
  }
  if (!url) {
    return dispatch({
      type: 'Error',
      payload: 'Error from getMyDraftsCount: no url',
    });
  }

  try {
    const request = {
      type: 'All',
      paginate: {
        limit: 100,
        offset: 0,
      },
      user: userId,
    };

    const response = await instance.post(url, request);

    if (!response.data) {
      return dispatch({type: 'Error', payload: `null response from ${url}`});
    } else {
      if (
        response.data &&
        response.data.drafts &&
        response.data.drafts.length
      ) {
        const payload =
          response.data.drafts.length > 100
            ? '100+'
            : response.data.drafts.length.toString();
        return dispatch({type: 'GetMyDraftsCount', payload: payload});
      }
      return;
    }
  } catch (error) {
    return dispatch({
      type: 'Error',
      payload: `Error from getMyDraftsCount: ${error.message}`,
    });
  }
};
/*/* MY PENDING CASES **********/

/*** CALENDAR PICKER **********/
const selectDateAndGetAgenda =
  dispatch => async (url, selectedDate, userId) => {
    if (isNaN(userId)) {
      return dispatch({
        type: 'Error',
        payload: 'Error from selectDateAndGetAgenda: input userId is NaN',
      });
    }
    if (!url) {
      return dispatch({
        type: 'Error',
        payload: 'Error from selectDateAndGetAgenda: no url',
      });
    }

    dispatch({
      type: 'SelectCalendarDate',
      payload: moment(selectedDate).format('YYYYMMDD[T]HHmmss'),
    });
    dispatch({type: 'AgendaStart'});

    try {
      const cleanDate = selectedDate ? moment(selectedDate) : moment();
      const isoStart = moment(dateToISODate(cleanDate), 'YYYYMMDD').format(
        'YYYYMMDDHHmmss',
      );
      const startDate = dateFromISODateTime(isoStart);
      const request = {
        year: moment(startDate).get('year'),
        month: moment(startDate).get('month') + 1,
        day: moment(startDate).date(),
        days: 1,
        user: userId,
      };

      const response = await instance.post(url, request);

      if (!response.data) {
        return dispatch({
          type: 'Error',
          payload: `null response from ${url}`,
        });
      } else {
        if ('agenda' in response.data) {
          if (!Array.isArray(response.data.agenda)) {
            return dispatch({
              type: 'Error',
              payload:
                'selectDateAndGetAgenda: incorrect data type for agenda array.',
            });
          }
          return dispatch({
            type: 'AgendaFinish',
            payload: response.data.agenda,
          });
        }
        return;
      }
    } catch (error) {
      return dispatch({
        type: 'Error',
        payload: `Error from selectDateAndGetAgenda: ${error.message}`,
      });
    }
  };

const selectCalendarMonth = dispatch => selectedMonth => {
  return dispatch({type: 'SelectCalendarMonth', payload: selectedMonth});
};

const selectMonthAndGetCalendar =
  dispatch => async (url, selectedMonth, userId) => {
    if (isNaN(userId)) {
      return dispatch({
        type: 'Error',
        payload: 'Error from selectMonthAndGetCalendar: input userId is NaN',
      });
    }
    if (!url) {
      return dispatch({
        type: 'Error',
        payload: 'Error from selectMonthAndGetCalendar: no url',
      });
    }

    dispatch({type: 'SelectCalendarMonth', payload: selectedMonth});
    dispatch({type: 'CalendarMonthStart'});

    try {
      const year = moment(selectedMonth, 'YYYYMM').format('YYYY');
      const month = moment(selectedMonth, 'YYYYMM').format('MM');
      const request = {
        year: year,
        month: month,
        user: userId,
      };

      const response = await instance.post(url, request);

      if (!response.data) {
        return dispatch({
          type: 'Error',
          payload: `null response from ${url}`,
        });
      } else {
        const monthArray = flattenMonth(response.data);
        dispatch({type: 'MonthFinish', payload: monthArray});
        return dispatch({
          type: 'CalendarMonthFinish',
          payload: response.data,
        });
      }
    } catch (error) {
      return dispatch({
        type: 'Error',
        payload: `Error from selectMonthAndGetCalendar: ${error.message}`,
      });
    }
  };

const selectWeekAndGetAgenda =
  dispatch => async (url, selectedWeek, userId) => {
    if (isNaN(userId)) {
      return dispatch({
        type: 'Error',
        payload: 'Error from selectWeekAndGetAgenda: input userId is NaN',
      });
    }
    if (!url) {
      return dispatch({
        type: 'Error',
        payload: 'Error from selectWeekAndGetAgenda: no url',
      });
    }

    dispatch({type: 'SelectCalendarWeek', payload: selectedWeek});
    dispatch({type: 'CalendarWeekStart'});

    if (selectedWeek && selectedWeek.start !== undefined) {
      try {
        const isoStart = moment(
          dateToISODate(selectedWeek.start),
          'YYYYMMDD',
        ).format('YYYYMMDDHHmmss');
        const startDate = dateFromISODateTime(isoStart);
        const request = {
          year: moment(startDate).get('year'),
          month: moment(startDate).get('month') + 1,
          day: moment(startDate).date(),
          days: 7,
          user: userId,
        };

        const response = await instance.post(url, request);

        if (!response.data) {
          return dispatch({
            type: 'Error',
            payload: `null response from ${url}`,
          });
        } else {
          if ('agenda' in response.data) {
            if (!Array.isArray(response.data.agenda)) {
              return dispatch({
                type: 'Error',
                payload:
                  'selectWeekAndGetAgenda: incorrect data type for agenda array.',
              });
            }
            return dispatch({
              type: 'CalendarWeekFinish',
              payload: response.data.agenda,
            });
          }
          return;
        }
      } catch (error) {
        return dispatch({
          type: 'Error',
          payload: `Error from selectWeekAndGetAgenda: ${error.message}`,
        });
      }
    }

    return;
  };

const getShowClinical = dispatch => async (url, userId) => {
  if (isNaN(userId)) {
    return dispatch({
      type: 'Error',
      payload: 'Error from getShowClinical: invalid user ID',
    });
  }
  if (!url) {
    return dispatch({
      type: 'Error',
      payload: 'Error from getShowClinical: no url',
    });
  }

  const request = {
    all: 0,
    id: userId,
    preferences: [showHideClinicalHash],
  };

  if (request.preferences.length) {
    try {
      const response = await instance.post(url, request);

      if (!response.data) {
        return dispatch({type: 'Error', payload: `no response from ${url}`});
      } else {
        if (
          response.data &&
          response.data.preferences &&
          response.data.preferences.length
        ) {
          let newShowClinicalValue = null;

          response.data.preferences.forEach(preference => {
            const key = Object.keys(preference)[0];
            const value = preference[key];

            if (key === showHideClinicalHash) {
              newShowClinicalValue = parseInt(value) === 1 ? true : false;
            }
          });

          return dispatch({
            type: 'SetShowClinical',
            payload: newShowClinicalValue,
          });
        } else {
          return dispatch({
            type: 'Error',
            payload: 'Could not parse showHideClinical preference from API.',
          });
        }
      }
    } catch (error) {
      return dispatch({type: 'Error', payload: error.message});
    }
  }
  return;
};

const setShowClinical =
  dispatch => async (url, userId, newShowClinicalValue) => {
    if (isNaN(userId)) {
      return dispatch({
        type: 'Error',
        payload: 'Error from setShowClinical: invalid user ID',
      });
    }
    if (!url) {
      return dispatch({
        type: 'Error',
        payload: 'Error from setShowClinical: no url',
      });
    }

    let request = {
      preferences: [],
      id: userId,
    };
    let hideClinical = newShowClinicalValue === true ? 1 : 0;

    request.preferences.push({
      b9e816d56f8d58fe134538b94d5c5916ebcabeff: hideClinical,
    });

    try {
      const response = await instance.post(url, request);

      if (!response.data) {
        return dispatch({type: 'Error', payload: `no response from ${url}`});
      } else {
        if (
          response.data &&
          response.data.preferences &&
          response.data.preferences.length
        ) {
          dispatch({type: 'SetShowClinical', payload: newShowClinicalValue});
        } else {
          return dispatch({
            type: 'Error',
            payload:
              'Could not confirm showHideClinical preference save from API.',
          });
        }
      }
    } catch (error) {
      return dispatch({type: 'Error', payload: error.message});
    }
  };
/*/* CALENDAR PICKER **********/

/*** SHARED SCHEDULE **********/
const setSharedScheduleId = dispatch => userId => {
  return dispatch({
    type: 'SetSharedScheduleId',
    payload: userId ? userId : null,
  });
};

const setSharedScheduleName = dispatch => userName => {
  return dispatch({
    type: 'SetSharedScheduleName',
    payload: userName ? userName : '',
  });
};

const getSharedSchedules = dispatch => async (url, userId) => {
  if (isNaN(userId)) {
    return dispatch({
      type: 'Error',
      payload: 'Error from getSharedSchedules: invalid user ID',
    });
  }
  if (!url) {
    return dispatch({
      type: 'Error',
      payload: 'Error from getSharedSchedules: no url',
    });
  }

  let request = {
    user: userId,
  };

  try {
    const response = await instance.post(url, request);

    if (!response.data) {
      return dispatch({type: 'Error', payload: `no response from ${url}`});
    } else {
      if (
        response.data &&
        response.data.calendars &&
        response.data.calendars.length
      ) {
        dispatch({
          type: 'GetSharedSchedules',
          payload: response.data.calendars,
        });
      } else {
        return dispatch({
          type: 'Error',
          payload: 'Could not parse sharedSchedules from API.',
        });
      }
    }
  } catch (error) {
    return dispatch({type: 'Error', payload: error.message});
  }
};

const getSharedScheduleDSLicenses = dispatch => async url => {
  if (!url) {
    return dispatch({
      type: 'Error',
      payload: 'Error from getDSLicenses: no url',
    });
  }

  try {
    const response = await instance.get(url);
    if (!response.data) {
      return dispatch({type: 'error', payload: `no response from ${url}`});
    } else {
      if (Array.isArray(response.data)) {
        dispatch({
          type: 'GetSharedScheduleDSLicenses',
          payload: response.data,
        });
        return response;
      } else {
        return dispatch({
          type: 'error',
          payload: `could not parse getDSLicenses response`,
        });
      }
    }
  } catch (error) {
    return dispatch({
      type: 'error',
      payload: `Error from getDSLicenses: ${error.message}`,
    });
  }
};

const setTargetUserId = dispatch => userId => {
  return dispatch({type: 'SetTargetUserId', payload: userId ? userId : null});
};

const setTargetUserName = dispatch => userName => {
  return dispatch({
    type: 'SetTargetUserName',
    payload: userName ? userName : '',
  });
};
/*/* SHARED SCHEDULE **********/

const getScheduleSearch = dispatch => async (url, queryString) => {
  if (!url) {
    return dispatch({
      type: 'Error',
      payload: 'Error from getScheduleSearch: no url',
    });
  }

  if (queryString) {
    let request = {
      search: {
        query: queryString,
      },
    };

    try {
      const response = await instance.post(url, request);

      if (!response.data) {
        return dispatch({type: 'Error', payload: `no response from ${url}`});
      } else {
        dispatch({type: 'SetScheduleSearchQuery', payload: queryString});

        // const payload = [
        //   {
        //     "event":{
        //       "id":			111,
        //       "case":			"Total <em>Sa</em>crum Replacement",
        //       "date": {
        //         "start":	20171109090000,
        //         "end":		20171109100000
        //       },
        //       "physician":{
        //         "name":		"Sudi <em>Sa</em>bet",
        //         "id":		47,
        //         "avatar":	-1
        //       },
        //       "location":{
        //         "name":		"<em>Sa</em>n Francisco"
        //       }
        //     },
        //     "patient": {
        //       "name":			"<em>Sa</em>rah Ashley Miller",
        //       "gender":		"F",
        //       "dob":			19711204,
        //       "status":		"Confirmed",
        //       "mrn":			"<em>Sa</em>M-12345"
        //     }
        //   }
        // ]
        // dispatch({ type: "GetScheduleSearch", payload: payload });

        if (
          response.data &&
          response.data.status === 'Success' &&
          response.data.results &&
          response.data.results.length
        ) {
          return dispatch({
            type: 'GetScheduleSearch',
            payload: response.data.results,
          });
        } else {
          return dispatch({
            type: 'Error',
            payload: 'Could not parse schedule search results from API.',
          });
        }
      }
    } catch (error) {
      return dispatch({type: 'Error', payload: error.message});
    }
  }
  return;
};

const getCalendarSubscription = dispatch => async url => {
  if (!url) {
    return dispatch({
      type: 'Error',
      payload: 'Error from getCalendarSubscription: no url',
    });
  }

  try {
    return await instance.get(url);
  } catch (error) {
    return dispatch({type: 'Error', payload: error.message});
  }
};

const clearScheduleSearch = dispatch => () => {
  return dispatch({type: 'ClearScheduleSearch'});
};

const sendTelehealthInvite = dispatch => async url => {
  if (!url) {
    return dispatch({
      type: 'Error',
      payload: 'Error from sendTelehealthInvite: no url',
    });
  }

  try {
    return await instance.post(url);
  } catch (error) {
    return dispatch({type: 'Error', payload: error.message});
  }
};

const clearSchedule = dispatch => async () => {
  return dispatch({type: 'ClearSchedule'});
};

const setSearchResults = dispatch => (data, queryString) => {
  if (!data) {
    return dispatch({type: 'Error', payload: `no response`});
  } else {
    dispatch({type: 'SetScheduleSearchQuery', payload: queryString});

    if (data) {
      return dispatch({type: 'GetScheduleSearch', payload: data});
    } else {
      return dispatch({
        type: 'Error',
        payload: 'Could not parse schedule search results from API.',
      });
    }
  }
};

const setViewMode = dispatch => newMode => {
  let payload = 'Weekly';

  if (newMode && newMode === 'Daily') {
    payload = 'Daily';
  }

  return dispatch({type: 'SetViewMode', payload: payload});
};

const getAvailabilityCalendar =
  dispatch => async (url, start, end, physician) => {
    dispatch({type: 'SetLoading', payload: true});
    if (!url) {
      return dispatch({
        type: 'Error',
        payload: 'Error from getAvailabilityCalendar: no url',
      });
    }
    const request = {
      start: start,
      end: end,
      user_id: parseInt(physician),
    };
    try {
      const response = await instance.post(url, request);
      if (!response) {
        return dispatch({type: 'Error', payload: `no response from ${url}`});
      } else {
        if (response.data) {
          dispatch({
            type: 'SetAvailabilityCalendar',
            payload: response.data.dates,
          });
        } else {
          return dispatch({
            type: 'Error',
            payload: 'Could not parse getAvailabilityCalendar from API.',
          });
        }
      }
    } catch (error) {
      return dispatch({type: 'Error', payload: error.message});
    }
  };

const editEvents = dispatch => async (url, physician, events) => {
  dispatch({type: 'SetLoading', payload: true});
  if (!url) {
    return dispatch({
      type: 'Error',
      payload: 'Error from editEvents: no url',
    });
  }
  const request = {
    physician: physician,
    events: events,
  };
  try {
    const response = await instance.post(url, request);
    if (!response) {
      return dispatch({type: 'Error', payload: `no response from ${url}`});
    } else {
      if (response.data) {
        // console.log('response.data from editEvents', response.data)
        // drag & dropped event updated
      } else {
        return dispatch({
          type: 'Error',
          payload: 'Could not parse editEvents from API.',
        });
      }
    }
  } catch (error) {
    return dispatch({type: 'Error', payload: error.message});
  }
};

export const {Provider, Context} = createDataContext(
  scheduleReducer,
  {
    getAgenda,
    getMonth,
    getDrafts,
    getMyDraftsCount,
    selectDateAndGetAgenda,
    selectCalendarMonth,
    selectMonthAndGetCalendar,
    selectWeekAndGetAgenda,
    getShowClinical,
    setShowClinical,
    setSharedScheduleId,
    setSharedScheduleName,
    getSharedSchedules,
    getSharedScheduleDSLicenses,
    setTargetUserId,
    setTargetUserName,
    getScheduleSearch,
    getCalendarSubscription,
    clearScheduleSearch,
    sendTelehealthInvite,
    clearSchedule,
    setSearchResults,
    setViewMode,
    getAvailabilityCalendar,
    editEvents,
  },
  initialState,
);
