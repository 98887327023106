import React, {useContext, useEffect, useState} from 'react';
import moment from 'moment';
import Docbox from '../../Docbox';
import Notes from '../Notes/Notes';
import Modal from '../Modal/Modal';
import Table from '../../Table/Table';
import {Loading} from '../../ui/atoms';
import Pagination from '../Pagination/Pagination';
import GlobalNavMenu from '../../GlobalNavMenu';
import EventSettings from '../../EventSettings';
import CalendarPicker from '../../CalendarPicker';
import useInterval from '../../../hooks/useInterval';
import {
  faTimes,
  faCaretDown,
  faQuestion,
  faQuestionCircle,
  faBuilding,
  faUser,
  faPlus,
  faEdit,
  faPenSquare,
  faPen,
  faCaretRight,
  faCalendarWeek,
} from '@fortawesome/free-solid-svg-icons';
import {triggerOpenDocbox} from '../../../libraries/util';
import {AlomEncodeType2} from '../../../libraries/helpers';
import searchIcon from '../../../assets/img/icons/search.svg';
import ArrowRightSharpIcon from '@mui/icons-material/ArrowRightSharp';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import docsperaLogo from '../../../assets/Logo-Full-0096FA.svg';
import {faFemale, faMale} from '@fortawesome/free-solid-svg-icons';
import {Context as AuthContext} from '../../../context/AuthContext';
import {Context as DocboxContext} from '../../../context/DocboxContext';
import {Context as GlobalContext} from '../../../context/GlobalContext';
import {Context as ScheduleContext} from '../../../context/ScheduleContext';
import {Context as PDContext} from '../../../context/PracticeDashboardContext';
import {Context as EventSettingsContext} from '../../../context/EventSettingsContext';
import ModalContent from '../Modal/ModalContent';
import PDDropdown from '../PDDropdown/PDDropdown';

import {
  viewCaseURI,
  allCasesURI,
  settingsURI,
  editNotesURI,
  getChecklistsURI,
} from '../../../dataAccess/apiEndpoints';

import './AllCasesKSRC.scss';
import {Diversity1} from '@mui/icons-material';

const AllCasesKSRC = () => {
  const {
    getCases,
    editNotes,
    getSettings,
    getWeekCases,
    getPrintCases,
    setTimeFilter,
    getChecklists,
    state: pdState,
    setNotesEditted,
    clearChecklistsModal,
    updateFilterFieldValue,
  } = useContext(PDContext);
  const {state: docboxState, openDocbox} = useContext(DocboxContext);
  const {state: authState} = useContext(AuthContext);
  const {setTargetUserId, setTargetUserName} = useContext(ScheduleContext);
  const {state: eventSettingsState} = useContext(EventSettingsContext);
  const {state: globalState, openAlert, closeAlert} = useContext(GlobalContext);
  const [page, setPage] = useState(0);
  const [currentCase, setCurrentCase] = useState();
  const [currentNotes, setCurrentNotes] = useState();
  const [modalType, setModalType] = useState('');
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [autoScroll, setAutoScroll] = useState(false);
  const [isSortIndex, setIsSortIndex] = useState(1);
  const [isSorting, setIsSorting] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [isPDLoading, setIsPDLoading] = useState(true);
  const offset = page * 10;

  useEffect(() => {
    const time = {
      start: moment().format('YYYYMMDD'),
      end: moment().format('YYYYMMDD'),
    };
    setTimeFilter(time.start, time.end);
    if (globalState.config.ABS_BASE_URL) {
      const url = `${globalState.config.ABS_BASE_URL}${settingsURI}`;
      getSettings(url);
    }
  }, [globalState.config.ABS_BASE_URL]);

  useInterval(() => {
    if (
      pdState.time.start.length !== 0 &&
      globalState.config.ABS_BASE_URL &&
      !autoScroll
    ) {
      const url = `${globalState.config.ABS_BASE_URL}${allCasesURI}`;
      getCases(
        url,
        pdState.time,
        'Surgery',
        pdState.location,
        pdState.physician,
        pdState.search,
        pdState.sort_by,
        offset,
      );
    }
  }, 1000 * 60);

  useEffect(() => {
    if (pdState.time.start.length !== 0 && globalState.config.ABS_BASE_URL) {
      const url = `${globalState.config.ABS_BASE_URL}${allCasesURI}`;
      setIsPDLoading(true);
      getCases(
        url,
        pdState.time,
        'Surgery',
        pdState.location,
        pdState.physician,
        pdState.search,
        pdState.sort_by,
        offset,
      ).then(() => {
        if (isSorting) {
          setIsSorting(false);
        }
        setIsPDLoading(false);
      });
    }
  }, [
    pdState.time,
    pdState.location,
    pdState.physician,
    pdState.search,
    pdState.sort_by,
    page,
    globalState.config.ABS_BASE_URL,
    docboxState.checklists,
    pdState.checklistsModal,
  ]);

  useEffect(() => {
    if (pdState.time.start.length !== 0 && globalState.config.ABS_BASE_URL) {
      const settingsURL = `${globalState.config.ABS_BASE_URL}${settingsURI}`;
      getSettings(settingsURL);
      const url = `${globalState.config.ABS_BASE_URL}${allCasesURI}`;
      getWeekCases(
        url,
        pdState.time,
        'Surgery',
        null,
        null,
        null,
        'surgery_location',
        offset,
      );
    }
  }, [pdState.time, globalState.config.ABS_BASE_UR]);

  useEffect(() => {
    if (
      pdState.time.start.length !== 0 &&
      globalState.config.ABS_BASE_URL &&
      pdState.cases.paginate
    ) {
      const url = `${globalState.config.ABS_BASE_URL}${allCasesURI}`;
      getPrintCases(
        url,
        pdState.time,
        'Surgery',
        pdState.location,
        pdState.physician,
        pdState.search,
        pdState.sort_by,
        0,
        pdState.cases.paginate.total,
      );
    }
  }, [
    pdState.time,
    pdState.location,
    pdState.physician,
    pdState.search,
    pdState.sort_by,
    page,
    globalState.config.ABS_BASE_URL,
    pdState.cases.paginate,
  ]);

  useEffect(() => {
    if (pdState.notesEditted && globalState.config.ABS_BASE_URL) {
      const url = `${globalState.config.ABS_BASE_URL}${allCasesURI}`;
      getCases(
        url,
        pdState.time,
        'Surgery',
        pdState.location,
        pdState.physician,
        pdState.search,
        pdState.sort_by,
        offset,
      );
      setNotesEditted(false);
    }
  }, [pdState.notesEditted, globalState.config.ABS_BASE_URL]);

  useEffect(() => {
    setPage(0);
  }, [pdState.time, pdState.location, pdState.physician, pdState.search]);

  //refresh pd cases & settings when docbox case edited in parent window
  useEffect(() => {
    if (refreshPage) {
      const url = `${globalState.config.ABS_BASE_URL}${allCasesURI}`;
      const settingsURL = `${globalState.config.ABS_BASE_URL}${settingsURI}`;
      getSettings(settingsURL);
      getCases(
        url,
        pdState.time,
        'Surgery',
        pdState.location,
        pdState.physician,
        pdState.search,
        pdState.sort_by,
        offset,
      ).then(() => setIsPDLoading(false));
      getWeekCases(
        url,
        pdState.time,
        'Surgery',
        null,
        null,
        null,
        'surgery_location',
        offset,
      );
      setRefreshPage(false);
    }
  }, [refreshPage]);

  useEffect(() => {
    //listen to postMessage from iframe parent (DocSpera)
    const navigate = evt => {
      let evtOrigin;
      // LOCAL
      if (window.location.href.includes('http://docspera.localhost')) {
        evtOrigin = 'http://docspera.localhost';
        // STAGING
      } else if (window.location.href.includes('docvisor')) {
        evtOrigin = 'https://docvisor.com';
        // PRODUCTION
      } else if (window.location.href.includes('docspera')) {
        evtOrigin = 'https://docspera.com';
      } else {
        console.log('no origin to receive from');
        return;
      }

      if (evt.origin === evtOrigin) {
        if (evt.data.type === 'eventEdit') {
          let event = evt.data.payload;
          if (event) {
            setRefreshPage(true);
          }
        }
        if (evt.data.type === 'printTriggered') {
          //check if parent window detected cmd/ctrl + p
          let event = evt.data.payload;
          if (event) {
            window.print();
          }
        }
      }
    };

    // listen for ctrl(cmd) + p
    const checkPrintKey = e => {
      if (
        (e.ctrlKey || e.metaKey) &&
        (e.key == 'p' ||
          e.charCode == 16 ||
          e.charCode == 112 ||
          e.keyCode == 80)
      ) {
        e.preventDefault();
        window.print();
      }
    };

    window.addEventListener('keydown', checkPrintKey, false);
    window.addEventListener('message', navigate, false);

    //clean up
    return () => window.removeEventListener('message', navigate);
  }, []);

  const handleDateSelect = event => {
    const start = moment(event.target.dataset.date)
      .startOf('day')
      .format('YYYYMMDD');
    const end = moment(event.target.dataset.date)
      .endOf('day')
      .format('YYYYMMDD');
    setPage(0);
    setTimeFilter(start, end);
  };

  const handleInputChange = (name, value) => {
    if (name === 'physician' || name === 'location') {
      if (value !== 'null') {
        value = parseInt(value);
      }
    }
    if (value === 'null') {
      value = null;
    }
    updateFilterFieldValue(name, value);
  };

  const currentView = globalState.currentView;

  const handleNavLinkClick = inputEndpoint => {
    if (currentView === inputEndpoint) return;
    return window.location.assign(inputEndpoint);
  };

  const handleRowClick = item => {
    const encodedEventId = AlomEncodeType2(item.event_id);
    console.log({event_id: encodedEventId});
    const viewAsUserId = authState.userId ? authState.userId : null;
    if (viewAsUserId && globalState.config.ABS_BASE_URL) {
      triggerOpenDocbox(encodedEventId, viewAsUserId);
    }
  };

  const handleEditModal = (item, e, type) => {
    e.stopPropagation();
    setModalType(type);
    setEditModalOpen(true);
    setCurrentCase(item);
    setCurrentNotes(item.notes && splitNotes(item.notes));
  };

  const weeklyCaseLocations = settingsLocation => {
    //filter surgery locations to current week

    let found = false;
    if (
      pdState.weekCases &&
      pdState.weekCases.cases &&
      Array.isArray(pdState.weekCases.cases) &&
      pdState.weekCases.cases.length > 0
    ) {
      pdState.weekCases.cases.forEach(c => {
        if (
          c &&
          c.surgery_location &&
          c.surgery_location.id &&
          settingsLocation.id === c.surgery_location.id
        ) {
          found = true;
        }
      });
    }
    return found;
  };

  const splitNotes = (noteString, type) => {
    if (noteString.includes('|||')) {
      let notesArr = noteString.split('|||');
      if (type === 'status') {
        let statusString = notesArr.find(str => str.includes('status'));
        let strParts = statusString.split(':');
        return strParts.length === 2 ? strParts[1] : 'Scheduled';
      } else if (type === 'anesthesia') {
        let statusString = notesArr.find(str => str.includes('anesthesia'));
        let strParts = statusString.split(':');
        return strParts.length === 2 ? strParts[1] : '––';
      } else if (type === 'block') {
        let statusString = notesArr.find(str => str.includes('block'));
        let strParts = statusString.split(':');
        return strParts.length === 2 ? strParts[1] : '––';
      } else {
        let schNotes = notesArr.find(
          str =>
            !str.includes('status') ||
            !str.includes('anesthesia') ||
            !str.includes('block'),
        );
        return schNotes !== 'null' && schNotes !== null && schNotes !== ''
          ? schNotes
          : '';
      }
    } else {
      return noteString;
    }
  };

  const getModalTitle = type => {
    if (type === 'status') {
      return 'Status';
    } else if (type === 'anesthesia') {
      return 'Anesthesia Type';
    } else if (type === 'block') {
      return 'Block Order';
    } else if (type === 'sch-notes') {
      return 'Edit Notes';
    } else {
      return '';
    }
  };

  const getCurrentValue = (type, notes) => {
    if (type === 'status') {
      return notes.includes('status')
        ? splitNotes(notes, 'status')
        : 'Scheduled';
    } else if (type === 'anesthesia') {
      return notes.includes('anesthesia')
        ? splitNotes(notes, 'anesthesia')
        : '';
    } else if (type === 'block') {
      return notes.includes('block') ? splitNotes(notes, 'block') : '';
    } else {
      return '';
    }
  };

  const getPhysicianName = physicianId => {
    let foundObj = pdState.settings.physicians.find(
      physician => physician.id === physicianId,
    );
    if (foundObj) {
      return foundObj.name;
    } else {
      return 'N/A';
    }
  };

  const getLocationName = locationId => {
    let foundObj = pdState.settings.surgery_locations.find(
      location => location.id === locationId,
    );
    if (foundObj) {
      return foundObj.name;
    } else {
      return 'N/A';
    }
  };

  return (
    <>
      <GlobalNavMenu />
      <div id="all-cases-ksrc">
        {/* Print Cases Header */}
        <div id="print-header">
          <div
            style={{
              overflow: 'visible',
              width: '100%',
              height: '45px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <div>
              <FontAwesomeIcon
                icon={faCalendarWeek}
                style={{
                  color: 'var(--blueDocspera)',
                  width: '38px',
                  height: '38px',
                  marginRight: '15px',
                }}
              />
              <div style={{display: 'inline-block'}}>
                <h1
                  style={{
                    color: '#0096FA',
                    fontSize: '20px',
                    margin: '10px 0 4px 0',
                    width: 'calc(100vw - 224px)',
                  }}>
                  Schedule for{' '}
                  {moment(pdState.time.start).format('MMMM D, YYYY')}
                </h1>
                <div style={{display: 'flex'}}>
                  <p
                    style={{
                      fontWeight: '600',
                      fontSize: '12pxem',
                      lineHeight: '1.1em',
                      marginTop: 0,
                      marginRight: 10,
                    }}>
                    Location:{' '}
                    {pdState.location
                      ? getLocationName(pdState.location)
                      : 'ALL'}
                  </p>
                  <p
                    style={{
                      fontWeight: '600',
                      fontSize: '12pxem',
                      lineHeight: '1.1em',
                      marginTop: 0,
                    }}>
                    Physician:{' '}
                    {pdState.physician
                      ? getPhysicianName(pdState.physician)
                      : 'ALL'}
                  </p>
                </div>
              </div>
            </div>
            <img src={docsperaLogo} height="45" width="135" alt="" />
          </div>
        </div>

        {/* Header */}
        <div className="content header">
          <form style={{display: 'flex', justifyContent: 'space-between'}}>
            <div style={{display: 'flex'}}>
              {/* Auto Scroll */}
              <input
                type="checkbox"
                id="auto-scroll"
                className={`toggle`}
                value=""
                checked={autoScroll ? 'Checked' : ''}
                data-label={'Auto Scroll'}
                onChange={e => setAutoScroll(e.target.checked)}
              />
              {!autoScroll && (
                <ArrowRightSharpIcon
                  className={`auto-scroll-icon ${
                    window.navigator.userAgent.indexOf('Win') > -1 && 'windows'
                  }`}
                  style={{
                    position: 'absolute',
                    top: 28,
                    left: 116,
                    fontSize: '16px',
                    color: 'var(--white)',
                    filter: 'drop-shadow(0 1px 2px rgba(0, 0, 0, .3))',
                  }}
                />
              )}
              {/* Day: */}
              <div className="labelAndDropdown">
                <CalendarPicker
                  id={'pd-day'}
                  name={'day'}
                  selectable={'day'}
                  value={pdState.time.start}
                  endValue={pdState.time.end}
                  handler={event => handleDateSelect(event)}
                  style={{width: '80%'}}
                />
              </div>

              {/* Physician */}
              {/* <div className='labelAndDropdown'>
                <FontAwesomeIcon
                  className='select-icon'
                  icon={faUser}
                  size="lg"
                />
                <label htmlFor='physician'>PHYSICIAN</label>
                <select
                  className={pdState.physician ? 'changed-phys' : ''}
                  id='physician'
                  name='physician'
                  value={pdState.physician ? pdState.physician : ''}
                  onChange={(event) =>
                    handleInputChange(event.target.name, event.target.value)
                  }
                >
                  <option value={'null'}> All </option>
                  <option disabled>──────────</option>
                  {pdState.settings &&
                    pdState.settings.physicians &&
                    pdState.settings.physicians.map((physician) => {
                      return (
                        <option key={physician.id} value={physician.id}>
                          {' '}
                          {physician.name}{' '}
                        </option>
                      );
                    })}
                </select>
                <FontAwesomeIcon
                  className='select-arrow'
                  icon={faCaretDown}
                  size="lg"
                />
              </div> */}

              {/* Location */}
              {/* <div className='labelAndDropdown'>
                <FontAwesomeIcon
                  className='select-icon'
                  icon={faBuilding}
                  size="lg"
                />
                <label htmlFor='location'>LOCATION</label>
                <select
                  className={pdState.location ? 'changed-loc' : ''}
                  id='location'
                  name='location'
                  value={pdState.location ? pdState.location : ''}
                  onChange={(event) =>
                    handleInputChange(event.target.name, event.target.value)
                  }
                >
                  <option value={'null'}> All </option>
                  <option disabled>──────────</option>
                  {pdState.settings &&
                    pdState.settings.surgery_locations &&
                    (pdState.location === null ||
                      pdState.settings.surgery_locations
                        .filter((l) => weeklyCaseLocations(l))
                        .some((l) => l.id === pdState.location)
                      ? pdState.settings.surgery_locations.filter((l) =>
                        weeklyCaseLocations(l)
                      )
                      : pdState.settings.surgery_locations.filter(
                        (l) => l.id === pdState.location
                      )
                    ).map((location) => {
                      return (
                        <option key={location.id} value={location.id}>
                          {' '}
                          {location.name}{' '}
                        </option>
                      );
                    })}
                </select>
                <FontAwesomeIcon
                  className='select-arrow'
                  icon={faCaretDown}
                  size="lg"
                />
              </div> */}

              <PDDropdown
                defaultValue={''}
                selectedValue={pdState.physician}
                handleInputChange={handleInputChange}
                filterType="physician"
                settings={pdState.settings}
                tab="all-cases"
                weeklyCaseLocations={weeklyCaseLocations}
              />
              <PDDropdown
                defaultValue={''}
                selectedValue={pdState.location}
                handleInputChange={handleInputChange}
                filterType="location"
                settings={pdState.settings}
                tab="all-cases"
                weeklyCaseLocations={weeklyCaseLocations}
              />
            </div>

            {/* Pagination */}
            {pdState.cases.cases && pdState.cases.cases.length > 0 && (
              <Pagination
                total={pdState.cases.paginate.total}
                page={page}
                setPage={setPage}
                isLoading={pdState.isLoading}
                isPDLoading={isPDLoading}
                autoScroll={autoScroll}
              />
            )}
            {/* Search Bar */}
            {/* REMOVING UNTIL SEARCH FUNCTIONALITY WORKS AGAIN */}
            {/* <div className='input-container'>
              <input
                className='search-input'
                type='text'
                name='search'
                maxLength='256'
                value={pdState.search}
                onChange={(event) => handleInputChange(event.target.name, event.target.value)}
              />
              <img className='search-icon' src={searchIcon} alt='Search' />
            </div> */}
          </form>
        </div>

        <div className="table-container">
          {(isPDLoading || pdState.isLoading) && !isSorting ? (
            <div className="pd-loading" style={{marginTop: 200}}></div>
          ) : pdState.cases.cases && pdState.cases.cases.length > 0 ? (
            <>
              <Table
                data={pdState.cases.cases}
                cols={tableConstants(handleEditModal, splitNotes)}
                brand="ksrc"
                handleRowClick={handleRowClick}
                isSortIndex={isSortIndex}
                setIsSortIndex={setIsSortIndex}
                setIsSorting={setIsSorting}
                handleInputChange={handleInputChange}
              />
            </>
          ) : (
            <div className="no-cases">
              <h3>No Cases Available for your Search Criteria</h3>
              <p>
                Try a different combination of Week, Case Type, Location or
                Physician.
              </p>
            </div>
          )}
        </div>
        <div id="print-table">
          {pdState.printCases.cases && pdState.printCases.cases.length > 0 ? (
            <Table
              data={pdState.printCases.cases}
              cols={tableConstants(handleEditModal, splitNotes, 'print')}
              brand="ksrc"
              handleRowClick={handleRowClick}
              isSortIndex={isSortIndex}
              setIsSortIndex={setIsSortIndex}
              setIsSorting={setIsSorting}
              handleInputChange={handleInputChange}
            />
          ) : (
            <div className="print-no-cases">
              <h3>No Cases Available for your Search Criteria</h3>
              <p>
                Try a different combination of Week, Case Type, Location or
                Physician.
              </p>
            </div>
          )}
        </div>
        {editModalOpen && (
          <Modal
            content={
              <ModalContent
                currentCase={currentCase}
                setCurrentNotes={setCurrentNotes}
                currentNotes={currentNotes}
                editNotes={editNotes}
                setEditModalOpen={setEditModalOpen}
                setNotesEditted={setNotesEditted}
                globalState={globalState}
                modalType={modalType}
                currentValue={
                  currentCase.notes &&
                  getCurrentValue(modalType, currentCase.notes)
                }
                splitNotes={splitNotes}
              />
            }
            toggleModal={setEditModalOpen}
            title={getModalTitle(modalType)}
          />
        )}
      </div>
      {docboxState.isDocboxOpen && <Docbox />}
      {eventSettingsState.isEventSettingsOpen && <EventSettings />}
    </>
  );
};

const findGenderIcon = g => {
  if (g === 'F') {
    return faFemale;
  } else {
    return faMale;
  }
};

const findGenderIconColor = g => {
  if (g === 'F') {
    return 'patient-female';
  } else if (g === 'M') {
    return 'patient-male';
  }
};

const formatPatientName = name => {
  const newName = name.split(' ');
  return `${newName[newName.length - 1].charAt(0)}, ${newName[0].charAt(0)}`;
};

const findText = (percent, checklistLinked) => {
  if (!checklistLinked) {
    return <i>N/A</i>;
  } else {
    if (percent === 0) {
      return `${percent}%`;
    } else if (percent > 0 && percent < 100) {
      return `${percent}%`;
    } else if (percent === 100) {
      return 'Ready';
    }
  }
};

const tableConstants = (handleEditModal, splitNotes, tableType) => {
  const isPrint = tableType === 'print';
  return [
    {
      title: isPrint ? 'OR' : 'Operating \nRoom',
      render: rowData => {
        return (
          <div className="location-td">
            {rowData.type !== 'Conference' && rowData.type !== 'Vacation' && rowData.surgery_location.name ? (
              <span>{rowData.surgery_location.name}</span>
            ) : (
              <span>N/A</span>
            )}
          </div>
        );
      },
    },
    {
      title: isPrint ? 'Time' : 'Surgery \nTime',
      render: rowData => {
        let startTime = moment(rowData.time.start, 'YYYYMMDDHHmmss').format(
          'A',
        );
        let endTime = moment(rowData.time.end, 'YYYYMMDDHHmmss').format('A');
        let sameDateTime = startTime === endTime;
        return (
          <div className="date-td">
            {isPrint && sameDateTime ? (
              <span>
                {moment(rowData.time.start, 'YYYYMMDDHHmmss').format('h:mm')} -{' '}
                {moment(rowData.time.end, 'YYYYMMDDHHmmss').format('h:mm A')}
              </span>
            ) : (
              <span>
                {moment(rowData.time.start, 'YYYYMMDDHHmmss').format('h:mm A')}{' '}
                - {moment(rowData.time.end, 'YYYYMMDDHHmmss').format('h:mm A')}
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: isPrint ? 'Physician' : 'Main \nPhysician',
      render: rowData => {
        return (
          <div className={'physician-td'}>
            {/* if physician name is a first + last name object */}
            {rowData.physician.name !== undefined &&
              rowData.physician.name.last !== undefined && (
                <span>
                  {rowData.physician.name.last || ''}
                  {rowData.physician.name.first ? ', ' : ''}
                  {rowData.physician.name.first[0] || ''}
                </span>
              )}
            {/* if physician name is a string */}
            {rowData.physician.name !== undefined &&
              rowData.physician.name.last === undefined && (
                <span>{rowData.physician.name}</span>
              )}
            {/* if physician key or physician name key are undefined/null */}
            {(rowData.physician === undefined ||
              rowData.physician === null ||
              rowData.physician.name === undefined ||
              rowData.physician.name === null) && <span>N/A</span>}
          </div>
        );
      },
    },
    {
      title: isPrint ? 'Case & Patient Name' : 'Surgery / Case Name \n Patient',
      render: rowData => {
        return (
          <div className={'case-td'}>
            <span> {rowData.case && rowData.case.toUpperCase()}</span>
            <div className="patient-data">
              <div>
                {!isPrint &&
                  rowData.type !== 'Conference' &&
                  rowData.type !== 'Vacation' && (
                    <FontAwesomeIcon
                      icon={findGenderIcon(rowData.patient.gender)}
                      className={findGenderIconColor(rowData.patient.gender)}
                      style={{marginTop: '2px'}}
                    />
                  )}
                {isPrint && '('}
                <span className="patient-name">
                  {rowData.patient.name
                    ? formatPatientName(rowData.patient.name).toUpperCase()
                    : ''}
                </span>
                {rowData.patient.dob && rowData.patient.dob !== 0 ? (
                  <span>
                    {' | '}
                    {moment().diff(
                      moment(rowData.patient.dob, 'YYYYMMDDHHmmss'),
                      'years',
                    )}
                    {!isPrint && 'y'}
                  </span>
                ) : null}
                {isPrint && ')'}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Status',
      render: rowData => {
        return (
          <div
            className={`status-td ${
              rowData.notes &&
              rowData.notes.includes('status') &&
              splitNotes(rowData.notes, 'status')
                .replace(/ /g, '-')
                .toLowerCase()
            }`}
            onClick={e => handleEditModal(rowData, e, 'status')}>
            {rowData.notes && rowData.notes.includes('status')
              ? splitNotes(rowData.notes, 'status')
              : 'Scheduled'}
          </div>
        );
      },
    },
    {
      title: isPrint ? 'Anesthesia' : 'Anesthesia \nType',
      render: rowData => {
        return (
          <div
            className={`anesthesia-td`}
            onClick={e => handleEditModal(rowData, e, 'anesthesia')}>
            {rowData.notes && rowData.notes.includes('anesthesia')
              ? splitNotes(rowData.notes, 'anesthesia')
              : ''}
          </div>
        );
      },
    },
    {
      title: isPrint ? 'Block Order' : 'Block \nOrder',
      render: rowData => {
        return (
          <div
            className={`block-td`}
            onClick={e => handleEditModal(rowData, e, 'block')}>
            {rowData.notes && rowData.notes.includes('block')
              ? splitNotes(rowData.notes, 'block')
              : ''}
          </div>
        );
      },
    },
    {
      title: isPrint ? 'Notes' : 'Scheduling \nNotes',
      render: rowData => {
        return (
          <div className="notes-td">
            <div className="notes-container">
              {rowData.notes &&
                (splitNotes(rowData.notes) === '' ||
                splitNotes(rowData.notes) === null ||
                splitNotes(rowData.notes) === 'null' ? (
                  <span
                    style={{
                      color: 'var(--pd-light-gray)',
                      fontStyle: 'italic',
                    }}>
                    No Notes
                  </span>
                ) : (
                  <span style={{display: 'block'}}>
                    {splitNotes(rowData.notes)}
                  </span>
                ))}

              {rowData.notes === null && (
                <span
                  style={{color: 'var(--pd-light-gray)', fontStyle: 'italic'}}>
                  No Notes
                </span>
              )}

              <button
                type="button"
                className={`tiny-button ${
                  rowData.notes === null ||
                  splitNotes(rowData.notes) === '' ||
                  splitNotes(rowData.notes) === null ||
                  splitNotes(rowData.notes) === 'null'
                    ? 'add-notes'
                    : ''
                }`}
                onClick={e => handleEditModal(rowData, e, 'sch-notes')}>
                <FontAwesomeIcon
                  className="notes-icon"
                  icon={
                    rowData.notes === null ||
                    splitNotes(rowData.notes) === '' ||
                    splitNotes(rowData.notes) === null ||
                    splitNotes(rowData.notes) === 'null'
                      ? faPlus
                      : faPen
                  }
                  size="sm"
                />
                {rowData.notes === null ||
                splitNotes(rowData.notes) === '' ||
                splitNotes(rowData.notes) === null ||
                splitNotes(rowData.notes) === 'null'
                  ? 'Add Notes'
                  : 'Edit Notes'}
              </button>
            </div>
          </div>
        );
      },
    },
  ];
};

export default AllCasesKSRC;
