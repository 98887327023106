import moment from 'moment';

moment.updateLocale('en', {
  relativeTime: {
    s: '1 second',
    m: '1 minute',
    h: '1 hour',
    d: '1 day',
    w: '1 week',
    M: '1 month',
    y: '1 year',
  },
});

// Input: null
// Return: 20210224001500 (16-digit ISO)
export const dateToISODateTime = date => {
  return parseInt(moment(date).format('YYYYMMDDHHmmss'));
};

// Input: 20210224001500 (16-digit ISO)
// Return: Moment Date Object for February 25, 2021, 12:15:00 AM
export const dateFromISODateTime = isoDate => {
  return moment(`${isoDate}`, 'YYYYMMDDHHmmss');
};

// Input: null
// Return: 20210224 (8-digit ISO)
export const dateToISODate = date => {
  return parseInt(moment(date).format('YYYYMMDD'));
};

// Input: 20210224 (8-digit ISO)
// Return: Moment Date Object for February 24, 2021
export const dateFromISODate = isoDate => {
  return moment(`${isoDate}`, 'YYYYMMDD');
};

// Input: 20210224001500
// Return: 12:15 AM
export const isoDateToReadableTime = date => {
  return moment(`${date}`, 'YYYYMMDDHHmmss').format('h:mm A');
};

// Input: 20210224001500
// Return: February 24, 2021
export const dateToReadableDate = date => {
  return moment(`${date}`, 'YYYYMMDDHHmmss').format('MMMM D, YYYY');
};

// Input: 20210224001500
// Return: Feb 24
export const dateToShortReadableDate = date => {
  return moment(`${date}`, 'YYYYMMDDHHmmss').format('MMM D');
};

// Input: 20210224001500
// Return: Moment Date Object for February 25, 2021
export const nextISODate = isoDate => {
  return dateToISODate(dateFromISODate(isoDate).add(1, 'days'));
};

// Input: 20210224001500, 20210224121500
// Return: 12:15 AM - 12:15PM
export const getEventStartToEndTime = (startIso, endIso) => {
  const startDate = moment(startIso, 'YYYYMMDDHHmmss').format('YYYYMMDD');
  const endDate = moment(endIso, 'YYYYMMDDHHmmss').format('YYYYMMDD');
  let eventStart;
  let eventEnd;

  if (startDate === endDate) {
    eventStart = isoDateToReadableTime(startIso);
    eventEnd = isoDateToReadableTime(endIso);
  } else {
    // If the start and end date aren't the same, this will return dates instead of times
    // e.g. Feb 24 - Feb 25
    eventStart = dateToShortReadableDate(startIso);
    eventEnd = dateToShortReadableDate(endIso);
  }

  return `${eventStart} - ${eventEnd}`;
};

// Input: 20210224001500, 20210224003000
// Return: 15 mins
export const differenceBtwnTwoIsos = (startIso, endIso, humanize) => {
  const start = moment(startIso, 'YYYYMMDDHHmmss');
  const end = moment(endIso, 'YYYYMMDDHHmmss');

  if (humanize) {
    const difference = end.diff(start);
    const duration = moment.duration(difference);
    const durationArray = [];

    durationArray.push(Math.floor(duration.asHours()));
    durationArray.push(parseInt(moment.utc(difference).format('m')));

    if (durationArray[0] < 0 || durationArray[1] < 0) {
      return '';
    } else if (durationArray[0] >= 24) {
      return moment.duration(durationArray[0], 'hours').humanize();
    } else if (!durationArray[0] && durationArray[1]) {
      return `${durationArray[1]} minutes`;
    } else if (durationArray[0] && !durationArray[1]) {
      return `${durationArray[0]} ${durationArray[0] === 1 ? 'hour' : 'hours'}`;
    } else {
      return `${durationArray[0]} ${
        durationArray[0] === 1 ? 'hour' : 'hours'
      } ${durationArray[1]} minutes`;
    }
  } else {
    return end.diff(start, 'minutes');
  }
};

// Input: 19880728
// Return: 32
export const determineAgeFromDob = dob => {
  const parseDob = moment(dob, 'YYYYMMDD');
  const now = moment();
  return now.diff(parseDob, 'years');
};

// Input: moment || null
// Return: February 2021
export const getMonthAndYear = date => {
  return moment(date).format('MMMM YYYY');
};

// Input: moment
// Return: 2 weeks ago
export const timeAgoFromNow = date => {
  return moment(date).fromNow();
};

export const determineClosest15MinInterval = date => {
  const minutes = moment(date, 'YYYYMMDDHHmmss').minutes();
  let dateTime = null;

  if (minutes > 0 && minutes < 15) {
    // console.log('01-14')
    dateTime = moment(date, 'YYYYMMDDHHmmss').minutes(15);
  } else if (minutes > 15 && minutes < 30) {
    // console.log('16-29')
    dateTime = moment(date, 'YYYYMMDDHHmmss').minutes(30);
  } else if (minutes > 30 && minutes < 45) {
    // console.log('31-44')
    dateTime = moment(date, 'YYYYMMDDHHmmss').minutes(45);
  } else if (minutes > 45 && minutes <= 59) {
    // console.log('46-59')
    dateTime = moment(date, 'YYYYMMDDHHmmss').add(1, 'hours').minutes(0);
  } else {
    // console.log('15 min interval')
    dateTime = moment(date, 'YYYYMMDDHHmmss');
  }

  return dateTime;
};

export const renderFriendlyDateTime = (startDate, endDate) => {
  const startMoment = moment(startDate, 'YYYYMMDDHHmmss'),
    endMoment = moment(endDate, 'YYYYMMDDHHmmss');

  if (startMoment.isSame(endMoment, 'day')) {
    return `${startMoment.format(
      'ddd, MMM D, YYYY. h:mm A',
    )} - ${endMoment.format('h:mm A')}`;
  } else {
    return `${startMoment.format(
      'ddd, MMM D, YYYY. h:mm A',
    )} - ${endMoment.format('ddd, MMM D, YYYY. h:mm A')}`;
  }
};

export const validateStartAndEndTime = (startString, endString) => {
  const startStringTemp = moment(startString, 'YYYYMMDDHHmmss');
  const endStringTemp = moment(endString, 'YYYYMMDDHHmmss');

  if (moment(endStringTemp).isSameOrBefore(startStringTemp)) {
    return false;
  }

  return true;
};
