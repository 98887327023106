import React from 'react';

function ConferenceIcon() {
  return (
    <svg viewBox="0 0 54.42 57.18">
      <defs>
        <clipPath id="clip-path" transform="translate(-228.04 -118.32)">
          <circle className="cls-1" cx="255.25" cy="145.53" r="27.21" />
        </clipPath>
      </defs>
      <title>conference-icon</title>
      <circle fill="#9072BB" cx="27.21" cy="27.21" r="27.21" />
      <g className="cls-3">
        <path
          fill="#fff"
          d="M245.88,164h18.74l-.83,11.55H246.71Zm26.73-11H237.89a.32.32,0,0,0-.33.41l1.75,8.74a.52.52,0,0,0,.49.41h30.9a.51.51,0,0,0,.49-.41l1.76-8.74A.33.33,0,0,0,272.61,153Zm-26.48-1.26a2.32,2.32,0,0,1,1.26-.45V149c0-.06.07-.11.17-.15,0-.43,0-1.06.08-1.64a3.66,3.66,0,0,1,.54-1.33.67.67,0,0,1,0-.73l1.82-2.67a.67.67,0,0,1,1.1.76l-1.82,2.66a.67.67,0,0,1-.55.29h-.1a3,3,0,0,0-.45,1.08c-.06.55-.08,1.18-.09,1.6.1,0,.16.08.16.14v2.33a2.32,2.32,0,0,1,1.26.45h11.35a2.25,2.25,0,0,1,1.25-.45V149c0-.06.06-.11.16-.14,0-.42,0-1.05-.08-1.6a3.24,3.24,0,0,0-.46-1.08h-.09a.64.64,0,0,1-.55-.29l-1.83-2.66a.67.67,0,1,1,1.1-.76l1.83,2.67a.67.67,0,0,1,0,.73,3.49,3.49,0,0,1,.54,1.33c.05.58.08,1.21.08,1.64.11,0,.17.09.17.15v2.33a2.25,2.25,0,0,1,1.26.45h4.58c-.14-.88-.31-1.83-.46-2.66-.43-2.26-1.91-7.45-2-7.67s-.3-.8-.65-.9-6.1-2.13-6.87-2.46c0,0-1,5.49-1.91,10.19-.24-2-.55-4-.68-4.62-.22-1.21-.37-1.91-.37-1.91.08-.35.39-1.36.53-1.78a.27.27,0,0,0-.08-.33l-.46-.65a.26.26,0,0,0-.17-.07h-1a.26.26,0,0,0-.17.07l-.45.65a.26.26,0,0,0-.08.33c.13.42.44,1.43.52,1.78,0,0-.15.7-.37,1.91-.13.66-.44,2.63-.68,4.62-.92-4.7-1.9-10.19-1.9-10.19-.78.33-6.52,2.36-6.87,2.46s-.58.67-.66.9-1.55,5.41-2,7.67c-.15.83-.32,1.78-.46,2.66Zm5.28-18.1a4.44,4.44,0,0,0,.78,2.13c.63.62,1.63,2.05,3.06,2s2.43-1.38,3.06-2a4.33,4.33,0,0,0,.78-2.13,1.67,1.67,0,0,0,.87-1.28,4.77,4.77,0,0,0,.2-1.28c0-.75-.55-.23-.55-.23s1.18-6.77-4.36-6.77-4.36,6.77-4.36,6.77-.58-.52-.55.23a4.77,4.77,0,0,0,.2,1.28A1.67,1.67,0,0,0,251.41,133.65Z"
          transform="translate(-228.04 -118.32)"
        />
      </g>
    </svg>
  );
}

export default ConferenceIcon;
