import React, {useEffect, useState} from 'react';
import './CalendarPicker.scss';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
} from '@fortawesome/free-solid-svg-icons';

const CalendarPicker = ({
  value,
  endValue,
  id,
  selectable,
  name,
  style,
  handler,
  rescheduledEvents,
  setRescheduledEvents,
  useCase,
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [currentMonthView, setCurrentMonthView] = useState(
    moment(value).format('YYYYMM'),
  );
  // const [currentMonthView, setCurrentMonthView] = useState(moment(value).format('YYYYMM'))
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    setCurrentMonthView(moment(value).format('YYYYMM'));
  }, [value]);

  const buildMonth = currentMonthView => {
    const startOfMonth = moment(currentMonthView, 'YYYYMMDD')
      .clone()
      .startOf('month');
    let date = startOfMonth.clone().subtract(startOfMonth.day(), 'day');

    // build weeks array for rendering
    const weeks = [
      [], // first week
    ];

    for (let i = 0; i < 42; i++) {
      let weekNum = Math.floor(i / 7);

      if (weeks[weekNum] === undefined) {
        weeks[weekNum] = [];
      }

      let objDate = date.toDate();

      const dayObj = {
        date: date.date(),
        weekNum: weekNum,
        moment: objDate,
        currentMonthView: date.isSame(
          moment(currentMonthView, 'YYYYMM'),
          'month',
        ),
      };
      weeks[weekNum].push(dayObj);

      date = date.add(1, 'd');
    }
    return weeks;
  };

  const [monthState, setMonthState] = useState(buildMonth(currentMonthView));

  useEffect(() => {
    setMonthState(buildMonth(currentMonthView));
  }, [currentMonthView]);

  const previousMonth = () => {
    const newMonth = moment(currentMonthView, 'YYYYMM')
      .subtract(1, 'month')
      .format('YYYYMM');
    setCurrentMonthView(newMonth);
    setMonthState(buildMonth(newMonth));
  };

  const nextMonth = () => {
    const newMonth = moment(currentMonthView, 'YYYYMM')
      .add(1, 'month')
      .format('YYYYMM');
    setCurrentMonthView(newMonth);
    setMonthState(buildMonth(newMonth));
  };

  const findInputValue = (selectable, value) => {
    if (selectable === 'day') {
      if (moment().isSame(moment(value, 'YYYYMMDD'), 'day')) {
        return 'Today, ' + moment(value, 'YYYYMMDD').format('MMM D, YYYY');
      } else {
        return moment(value, 'YYYYMMDD').format('ddd, MMM D, YYYY');
      }
    } else if (selectable === 'week') {
      let startMonth = moment(value).format('MMM D');
      let endDate;
      if (moment(value).format('MM') === moment(endValue).format('MM')) {
        endDate = moment(endValue).format('D');
      } else {
        endDate = moment(endValue).format('MMM D');
      }
      let year = moment(endValue).format('YYYY');
      // May 25 - June 1, 2021
      return `${startMonth} - ${endDate}, ${year}`;
    }
  };

  const findIsSelected = day => {
    if (selectable === 'day') {
      return moment(value).isSame(day.moment, 'd');
    } else if (selectable === 'week') {
      return moment(day.moment, 'd').isBetween(value, endValue, null, '[]');
    }
  };

  return (
    <div
      className="calendar-picker-container no-select"
      style={{
        ...(useCase === 'rep' && {marginRight: '20px'}),
      }}>
      <div>
        {useCase !== 'rep' ? (
          <div className="flex">
            <div
              id={id}
              name={name}
              onBlur={() => !isHovering && setShowCalendar(false)}
              onClick={() => setShowCalendar(!showCalendar)}
              className="calendar-picker-input"
              style={{
                width: `${style.width ? style.width : '100%'}`,
              }}>
              <FontAwesomeIcon icon={faCalendarAlt} size="sm" />
              <span>{selectable === 'day' ? 'DAY' : 'WEEK'}</span>
              {findInputValue(selectable, value)}
              <FontAwesomeIcon
                className="select-arrow"
                icon={faCaretDown}
                size="lg"
              />
            </div>
          </div>
        ) : (
          <TextField
            id={id}
            select
            label={selectable === 'day' ? 'day' : 'week'}
            SelectProps={{
              open: showCalendar,
              IconComponent: ExpandMoreIcon,
              MenuProps: {
                style: {zIndex: 4},
              },
            }}
            name={name}
            className="calender-input"
            style={{width: '200px'}}
            value={findInputValue(selectable, value)}
            onBlur={() => !isHovering && setShowCalendar(false)}
            onClick={e => {
              e.preventDefault();
              setShowCalendar(!showCalendar);
            }}>
            <MenuItem value={findInputValue(selectable, value)}>
              {findInputValue(selectable, value)}
            </MenuItem>
          </TextField>
        )}
      </div>

      {showCalendar && (
        <div
          className="calendar-picker-popover"
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}>
          <header className="flex justify-center align-center direction-row">
            <div
              className="flex justify-center align-center"
              onClick={() => {
                previousMonth();
              }}>
              <FontAwesomeIcon icon={faCaretLeft} size="lg" />
            </div>
            <div>
              <h4>
                {moment(currentMonthView).format('MMM ')}
                <span>{moment(currentMonthView).format('YYYY')}</span>
              </h4>
            </div>
            <div
              className="flex justify-center align-center"
              onClick={() => {
                nextMonth();
              }}>
              <FontAwesomeIcon icon={faCaretRight} size="lg" />
            </div>
          </header>

          {/* <div id="view-today" className="flex align-center">
            {
              <div onClick={() => {setToday()}}>
                  Return to Today
              </div>
            }
          </div> */}

          <div
            id="weekdays"
            className="flex direction-row nowrap justify-center">
            <div>Sun</div>
            <div>Mon</div>
            <div>Tue</div>
            <div>Wed</div>
            <div>Thu</div>
            <div>Fri</div>
            <div>Sat</div>
          </div>

          <div id="month">
            {monthState.length &&
              monthState.map((week, index) => {
                return (
                  <div
                    className="week flex direction-row nowrap justify-center"
                    key={index}>
                    {week.map((day, index) => {
                      let isToday = moment(day.moment).isSame(moment(), 'd');
                      // let isSelected = moment(value).isSame(day.moment, 'd')
                      let isSelected = findIsSelected(day);
                      let isLeftBookend = moment(value).isSame(day.moment, 'd');
                      let isRightBookend = moment(endValue).isSame(
                        day.moment,
                        'd',
                      );
                      return (
                        <div
                          className={`day flex justify-center align-center`}
                          key={index}>
                          {isToday && (
                            <span
                              className={`${
                                selectable === 'week' && 'today-circled'
                              }`}
                              onClick={event => {
                                event.stopPropagation();
                                //if this weekly/phys schedule calendar week selector
                                if (
                                  rescheduledEvents &&
                                  rescheduledEvents.length > 0
                                ) {
                                  if (
                                    window.confirm(
                                      'Do you want to leave the page? Your unsaved data will be lost.',
                                    ) == true
                                  ) {
                                    setRescheduledEvents([]);
                                    handler(event);
                                  }
                                } else {
                                  //else: this is for PD
                                  handler(event);
                                }
                                setShowCalendar(false);
                              }}></span>
                          )}
                          <div
                            className={`flex justify-center align-center
                                ${
                                  selectable === 'day' &&
                                  isToday &&
                                  ' today-circled'
                                }
                                ${
                                  selectable === 'day' &&
                                  isSelected &&
                                  ' selected'
                                }
                                ${
                                  selectable === 'day' &&
                                  isToday &&
                                  isSelected &&
                                  ' today-selected'
                                }
                                ${
                                  selectable === 'week' &&
                                  isSelected &&
                                  'week-selected'
                                }
                                ${
                                  selectable === 'week' &&
                                  isLeftBookend &&
                                  'left-bookend'
                                }
                                ${
                                  selectable === 'week' &&
                                  isRightBookend &&
                                  'right-bookend'
                                }
                                ${!day.currentMonthView ? ' not-month' : ''}`}
                            data-date={moment(day.moment).format('YYYYMMDD')}
                            onClick={event => {
                              event.stopPropagation();
                              //if weekly schedule/phys schedule calendar week selector
                              if (
                                rescheduledEvents &&
                                rescheduledEvents.length > 0
                              ) {
                                if (
                                  window.confirm(
                                    'Do you want to leave the page? Your unsaved data will be lost.',
                                  ) == true
                                ) {
                                  setRescheduledEvents([]);
                                  handler(event);
                                }
                              } else {
                                //else: this is PD week selector
                                handler(event);
                              }
                              setShowCalendar(false);
                            }}>
                            {day.date}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default CalendarPicker;
