import React, {useContext, useEffect, useState} from 'react';
import './ScheduleSearch.scss';

import {Context as AuthContext} from '../../context/AuthContext';
import {Context as GlobalContext} from '../../context/GlobalContext';
import {Context as DocboxContext} from '../../context/DocboxContext';
import {Context as ScheduleContext} from '../../context/ScheduleContext';
import Docbox from '../Docbox';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faFemale,
  faMale,
  faMapMarkerAlt,
  faSearch,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import {
  determineAgeFromDob,
  differenceBtwnTwoIsos,
  renderFriendlyDateTime,
} from '../../libraries/moment';
import {triggerOpenDocbox} from '../../libraries/util';
import {viewCaseURI} from '../../dataAccess/apiEndpoints';
import {getSearchResults} from '../../api/api';
import {Toggle} from '../ui/atoms';

const ScheduleSearch = () => {
  const {state: docboxState} = useContext(DocboxContext);
  const {state: globalState} = useContext(GlobalContext);
  const {
    state: scheduleState,
    getScheduleSearch,
    clearScheduleSearch,
    setSearchResults,
  } = useContext(ScheduleContext);
  const [isSearching, setIsSearching] = useState(false);
  const [archiveToggle, setArchiveToggle] = useState(false);

  useEffect(() => {
    if (scheduleState.scheduleSearchQuery === '') {
      clearScheduleSearch();
    }
    if (scheduleState.scheduleSearchQuery.length >= 3 && !isSearching) {
      setIsSearching(true);
      setTimeout(() => {
        getSearchResults(scheduleState.scheduleSearchQuery, archiveToggle).then(
          data => {
            console.log(data);
            setSearchResults(data, scheduleState.scheduleSearchQuery);
          },
          error => {
            console.log(error);
          },
        );
        setIsSearching(false);
      }, 1000);
    }
  }, [archiveToggle]);

  return (
    <>
      <section className="content-container">
        {/* <ul className="breadcrumb flex">
            <li>
                <a href="/"> <FontAwesomeIcon icon={faHome} className="icon-home" /></a>
                <FontAwesomeIcon icon={faAngleRight} className="icon-angle-right" />
            </li>
            <li>
                <a href="/calendar_new">Calendar</a>
                <FontAwesomeIcon icon={faAngleRight} className="icon-angle-right" />
            </li>
            <li>
                <b>Search</b>
                <FontAwesomeIcon icon={faAngleRight} className="icon-angle-right" />
            </li>
        </ul> */}
        <div id="schedule-search" className="content">
          <h1>Search</h1>
          <div className="search-grid">
            <p>Find cases by Case Name, Patient Name or MRN</p>
            <p>Search Archived Cases</p>
            <div></div>
            <section style={{margin: '1rem 0'}}>
              <form
                onSubmit={event => {
                  event.preventDefault();
                  return false;
                }}>
                <div className="flex align-center space-between">
                  <div className="width90">
                    <FontAwesomeIcon icon={faSearch} className="icon-search" />
                    <input
                      type="text"
                      onKeyUp={event => {
                        if (event.target.value === '') {
                          clearScheduleSearch();
                        }
                        if (event.target.value.length >= 3 && !isSearching) {
                          setIsSearching(true);
                          setTimeout(() => {
                            getSearchResults(
                              event.target.value,
                              archiveToggle,
                            ).then(
                              data => {
                                console.log(data);
                                setSearchResults(data, event.target.value);
                              },
                              error => {
                                console.log(error);
                              },
                            );
                            setIsSearching(false);
                          }, 75);
                        }
                      }}
                      placeholder=""
                    />
                  </div>
                </div>
              </form>
            </section>
            <Toggle
              id="agenda-cal-clinical"
              toggled={archiveToggle}
              handler={() => setArchiveToggle(!archiveToggle)}
              onText="On"
              offText="Off"
              large
            />
            <div className="width20">
              {isSearching && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                  style={{
                    color: 'var(--velys-blue)',
                    marginRight: '10px',
                  }}
                />
              )}
              {!isSearching &&
              scheduleState.scheduleSearchResults.length > 0 ? (
                <span>
                  {scheduleState.scheduleSearchResults.length}{' '}
                  {scheduleState.scheduleSearchResults.length === 1
                    ? 'result'
                    : 'results'}{' '}
                  found
                </span>
              ) : (
                <span>
                  {!isSearching &&
                    scheduleState.scheduleSearchQuery &&
                    'No results found'}
                </span>
              )}
            </div>
          </div>

          <section id="schedule-search-results-container">
            {scheduleState.scheduleSearchResults.length > 0 && (
              <table>
                <thead>
                  <tr>
                    <th>CASE</th>
                    <th>DISPOSITION</th>
                  </tr>
                </thead>
                <tbody>
                  {scheduleState.scheduleSearchResults.length > 0 &&
                    scheduleState.scheduleSearchResults.map(
                      (element, index) => {
                        return (
                          <SearchResultCard
                            key={index}
                            {...element}></SearchResultCard>
                        );
                      },
                    )}
                </tbody>
              </table>
            )}
          </section>
        </div>
        {/* <footer id="docspera-footer" className="flex space-between">
          <div>
          <a href="https://docspera.com/"><b>&copy; {moment().year()} Compliant Innovations, Inc</b></a>
          </div>
          <div>
              <a href="/contact">Contact Us</a>
              <a href="/careers">Careers</a>
              <a href="/legal/terms">Terms</a>
              <a href="/legal/privacy">Privacy</a>
              <a href="/legal/baa">BAA</a>
          </div>
      </footer> */}
      </section>
      {docboxState.isDocboxOpen && <Docbox />}
    </>
  );
};

const SearchResultCard = props => {
  const {state: authState} = useContext(AuthContext);
  const {state: globalState} = useContext(GlobalContext);
  const {openDocbox} = useContext(DocboxContext);

  const eventTime = renderFriendlyDateTime(
    props.event.time.start,
    props.event.time.end,
  );
  const duration = differenceBtwnTwoIsos(
    props.event.time.start,
    props.event.time.end,
    true,
  );
  let genderIcon = faMale;
  let genderIconColor = '';
  let genderTitle = 'Gender Unknown';
  const patientName = props.event.patient.name || '';
  const age = props.event.patient.dob
    ? `${determineAgeFromDob(props.event.patient.dob)} y`
    : '';
  const patientStatus = props.event.patient.status
    ? `Patient ${props.event.patient.status}`
    : 'Patient Status Unknown';
  let attendingPhysician = '';

  if (props.event.patient.demographics.gender) {
    if (props.event.patient.demographics.gender === '2') {
      genderIcon = faFemale;
      genderIconColor = 'patient-female';
      genderTitle = 'Female';
    } else if (props.event.patient.demographics.gender === '1') {
      genderIconColor = 'patient-male';
      genderTitle = 'Male';
    }
  }

  if (props.event.calendar) {
    if (props.event.attendees && props.event.attendees.providers.length > 0) {
      let physIndex = props.event.attendees.providers.findIndex(
        p => p.id === props.event.calendar,
      );
      if (physIndex >= 0) {
        attendingPhysician = props.event.attendees.providers[physIndex].name;
        const [first, last] = attendingPhysician.split(' ');
        attendingPhysician = last + ', ' + first;
      }
    }
  }

  const openDocboxView = eventId => {
    const mode = 'View';
    const openEventId = eventId ? eventId : '';
    // const openEventId = '2651652' // dummy case

    const viewAsUserId = authState.userId ? authState.userId : null;

    if (openEventId && viewAsUserId) {
      const url = `${globalState.config.ABS_BASE_URL}${viewCaseURI}`;
      openDocbox(
        mode,
        openEventId,
        url,
        viewAsUserId,
        null,
        authState.userType,
      );
    }
  };

  const handleAgendaClick = event_id => {
    const viewAsUserId = authState.userId ? authState.userId : null;
    triggerOpenDocbox(event_id, viewAsUserId);
  };

  return (
    <tr>
      <td>
        <div
          className="agenda-card flex align-stretch"
          data-event-id={props.event.event_id}
          onClick={() =>
            props.event.deleted
              ? false
              : handleAgendaClick(props.event.event_id)
          }>
          <div className="case-body">
            {/* Event Time */}
            <div className="flex flex-center space-between">
              <p>
                <span className={props.event.event_type}>{eventTime}</span>(
                {duration})
              </p>
              {/* <p className="readiness">Patient Readiness</p> */}
            </div>

            {/* Case Name*/}
            <div className="flex flex-center space-between">
              <h1>{props.event.case}</h1>
              <p>{attendingPhysician}</p>
            </div>

            {/* Event Location + Case Owner */}
            <div className="flex flex-center space-between">
              <p>
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  className="case-location-icon"
                  title="Location"
                />
                <span>
                  {props.event.location.address || <i>No Location</i>}
                </span>
              </p>

              {props.event.patient.status && (
                <p className={props.event.patient.status}>{patientStatus}</p>
              )}
            </div>

            {/* Patient Details for Surgery & Clinical Cases */}
            <div className="patient-details flex flex-center space-between">
              <p>
                {patientName && (
                  <FontAwesomeIcon
                    icon={genderIcon}
                    className={genderIconColor}
                    title={genderTitle}
                    style={{marginTop: '2px'}}
                  />
                )}
                {patientName && <span>{patientName}</span>}
                {age && <span>{age}</span>}
                {props.event.patient.mr && (
                  <span
                    dangerouslySetInnerHTML={{__html: props.event.patient.mr}}
                  />
                )}
              </p>
            </div>
          </div>
        </div>
      </td>
      <td>
        {props.event.patient.supplemental &&
          props.event.patient.supplemental.notes}
      </td>
    </tr>
  );
};

export default ScheduleSearch;
