import React from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import './Table.scss';

const Table = ({
  cols,
  data,
  brand,
  handleRowClick,
  isSortIndex,
  setIsSortIndex,
  setIsSorting,
  handleInputChange,
}) => {
  const isKSRC = brand === 'ksrc';

  const formatHeader = title => {
    if (title.includes('\n')) {
      const newTitle = title.split('\n');
      return (
        <span>
          {' '}
          {newTitle[0]} <br></br> {newTitle[1]}
        </span>
      );
    } else {
      return title;
    }
  };

  const sortDataBy = idx => {
    let sortBy = '';
    if (idx === 2) {
      sortBy = 'owner';
    } else if (idx === 3) {
      sortBy = 'patient_last';
    } else if (idx === 0) {
      sortBy = 'surgery_location';
    } else {
      sortBy = 'start_time';
    }

    setIsSortIndex(idx);
    setIsSorting(true);
    handleInputChange('sort_by', sortBy);
  };

  return (
    <div className={`${brand}-tableBG`}>
      <ol className={`${brand}-table`}>
        {/* TABLE HEADERS */}
        <li className="table-row table-header">
          {cols.map((headerItem, index) => (
            <div
              className={`table-cell ${brand}-th table col-${index} flex space-between ${
                isKSRC && isSortIndex === index && index <= 3 ? 'active' : ''
              }`}
              key={`th-${index}`}
              style={{cursor: isKSRC && index <= 3 && 'pointer'}}
              onClick={() => {
                isKSRC && isSortIndex !== index && sortDataBy(index);
              }}>
              {formatHeader(headerItem.title)}
              {isKSRC && index <= 3 && (
                <div
                  style={{
                    textAlign: 'right',
                    alignSelf: 'center',
                    marginRight: '-8px',
                  }}>
                  <KeyboardArrowUpIcon
                    className={`sort-icon ${
                      isSortIndex === index ? 'up' : 'down'
                    }`}
                  />
                </div>
              )}
            </div>
          ))}
        </li>
        {/* ^TABLE HEADERS */}

        {/* TABLE BODY */}
        {data.map((item, index) => (
          <li
            className={`table-row ${brand}-row`}
            key={`tr-${index}`}
            onClick={() => (handleRowClick ? handleRowClick(item) : null)}>
            {cols.map((col, key) => (
              <div
                className={`table-cell ${brand}-td col-${key}`}
                key={`td-${key}`}>
                {' '}
                {col.render(item)}{' '}
              </div>
            ))}
          </li>
        ))}
        {/* ^TABLE BODY */}
      </ol>
    </div>
  );
};

Table.propTypes = {
  cols: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  brand: PropTypes.string.isRequired,
};

export default Table;
