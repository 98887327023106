import React, {useContext, useEffect, useState} from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import {Context as AuthContext} from '../../context/AuthContext';
import {Context as GlobalContext} from '../../context/GlobalContext';
import {Context as ScheduleContext} from '../../context/ScheduleContext';
import {Context as DocboxContext} from '../../context/DocboxContext';

import {getAvailabilityCalendarURI} from '../../dataAccess/apiEndpoints';

import moment from 'moment';
import {AlomEncodeType2, formatFullName} from '../../libraries/helpers';
import {triggerCreateDocbox, triggerOpenDocbox} from '../../libraries/util';
import {differenceBtwnTwoIsos} from '../../libraries/moment';
import ClinicalIcon from '../../assets/img/event-icons/ClinicalIcon';
import ConferenceIcon from '../../assets/img/event-icons/ConferenceIcon';
import SurgeryIcon from '../../assets/img/event-icons/SurgeryIcon';
import VacationIcon from '../../assets/img/event-icons/VacationIcon';

import './WeeklyCalendar.scss';

const WeeklyCalendar = ({
  rescheduledEvents,
  setRescheduledEvents,
  isWeeklyScheduleLoading,
  autoRefresh,
  AutoRefreshWeeklyAgenda,
}) => {
  const {state: authState} = useContext(AuthContext);
  const {state: globalState} = useContext(GlobalContext);
  const {state: scheduleState, getAvailabilityCalendar} =
    useContext(ScheduleContext);
  const {state: docboxState, openDocbox} = useContext(DocboxContext);
  const [formattedEvents, setFormattedEvents] = useState();
  const [workingHours, setWorkingHours] = useState([]);

  const calendarRef = React.createRef();

  useEffect(() => {
    const findTypeStyles = (type, readinessPercent, telehealth) => {
      switch (type) {
        case 'Surgery':
          if (readinessPercent === 100) {
            return 'ready';
          } else if (readinessPercent >= 0 && readinessPercent < 100) {
            return 'pending';
          } else {
            return 'surgery';
          }
        case 'Clinic':
          if (telehealth) {
            return 'telehealth';
          } else {
            return 'clinical';
          }
        case 'Vacation':
          return 'vacation';
        case 'Conference':
          return 'conference';
      }
    };

    const getCaseOwnerName = editors => {
      const ownerObject =
        editors.length > 0 && editors.find(e => e.designation === 'Owner');
      const ownerFullName = 'Dr. ' + ownerObject.name;
      return ownerFullName;
    };

    //format events
    let newArray = [];
    scheduleState &&
      scheduleState.calendarWeek.length > 0 &&
      scheduleState.calendarWeek.forEach(event => {
        //skip events w/ invalid dates
        dateIsValid(event.start, event.end) &&
          newArray.push({
            id: event.event_id,
            title: event.case,
            start: new Date(
              moment(event.start, 'YYYYMMDDHHmmss'),
            ).toISOString(),
            end: new Date(moment(event.end, 'YYYYMMDDHHmmss')).toISOString(),
            case_owner: getCaseOwnerName(event.editors),
            extendedProps: {
              location: event.location,
              duration: differenceBtwnTwoIsos(event.start, event.end, true),
              patientName: formatFullName(
                event.patient.name.first,
                event.patient.name.last,
              ),
              type: event.event_type,
              patient_cleared: event.patient_cleared,
              patient_readiness: event.patient_readiness,
              readiness_percent: event.readiness_percent,
              checklists_linked: event.checklists_linked,
              telehealth: event.telehealth,
            },
            classNames: [
              'event',
              findTypeStyles(
                event.event_type,
                event.readiness_percent,
                event.telehealth,
              ),
            ],
            backgroundColor: `#${event.color}`,
            borderColor: `#${event.color}`,
          });
      });
    setFormattedEvents(newArray);
  }, [scheduleState.calendarWeek]);

  // Rerender calendar when user changes week in CalendarPicker
  useEffect(() => {
    if (!scheduleState.isLoading) {
      let calendarApi = calendarRef.current.getApi();
      if (scheduleState.selectedCalendarWeek.start) {
        let parsableDate = new Date(
          moment(scheduleState.selectedCalendarWeek.start),
        ).toISOString();
        calendarApi.gotoDate(parsableDate);
      }
    }
  }, [scheduleState.selectedCalendarWeek, scheduleState.isLoading]);

  // get availability calendar to display working hours
  useEffect(() => {
    if (
      scheduleState.selectedCalendarWeek &&
      scheduleState.targetUserId &&
      globalState.config.ABS_BASE_URL
    ) {
      const url = `${globalState.config.ABS_BASE_URL}${getAvailabilityCalendarURI}`;
      getAvailabilityCalendar(
        url,
        scheduleState.selectedCalendarWeek.start,
        scheduleState.selectedCalendarWeek.end,
        AlomEncodeType2(parseInt(scheduleState.targetUserId)),
      );
    }
  }, [scheduleState.calendarWeek]);

  useEffect(() => {
    if (scheduleState.availabilityCalendar) {
      let hoursArray = [];

      let timesArray = Object.values(scheduleState.availabilityCalendar);

      for (let x = 0; x < timesArray.length; x++) {
        for (let y = 0; y < timesArray[x].length; y++) {
          if (timesArray[x][y] == '1') {
            let slotInterval = 15 * y;
            let hoursObj = {
              daysOfWeek: [x],
              startTime: moment('00:00', 'HH:mm')
                .add(slotInterval.toString(), 'minutes')
                .format('HH:mm'),
              endTime: moment('00:15', 'HH:mm')
                .add(slotInterval.toString(), 'minutes')
                .format('HH:mm'),
            };
            hoursArray.push(hoursObj);
          }
        }
      }
      setWorkingHours(hoursArray);
    }
  }, [scheduleState.availabilityCalendar]);

  const dateIsValid = (start, end) => {
    if (
      start &&
      end &&
      moment(start, 'YYYYMMDDHHmmss').isValid() &&
      moment(end, 'YYYYMMDDHHmmss').isValid()
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleEventDrop = e => {
    let index = rescheduledEvents.findIndex(function (o) {
      return o.calendarId === parseInt(e.event._def.publicId);
    });
    if (index !== -1) rescheduledEvents.splice(index, 1);
    let alteredEvent = {
      calendarId: parseInt(e.event._def.publicId),
      start: parseInt(moment(e.event.start).format('YYYYMMDDHHmmss')),
      end: parseInt(moment(e.event.end).format('YYYYMMDDHHmmss')),
    };
    setRescheduledEvents(prevState => [...prevState, alteredEvent]);
  };

  const handleEventClick = clickInfo => {
    console.log('case clicked', clickInfo);
    const eventId = clickInfo.event._def.publicId;
    const viewAsUserId = scheduleState.targetUserId
      ? scheduleState.targetUserId
      : authState.userId;
    if (viewAsUserId && globalState.config.ABS_BASE_URL) {
      triggerOpenDocbox(eventId, viewAsUserId);
    }
  };

  const handleMouseEnter = eventInfo => {
    var harness = document.getElementById(`E${eventInfo.event._def.publicId}`)
      .parentElement.parentElement.parentElement;
    var timegrid = document.getElementById(`E${eventInfo.event._def.publicId}`)
      .parentElement.parentElement;
    harness.classList.add('on-hover');
    timegrid.classList.add('on-hover');
  };

  const handleMouseLeave = eventInfo => {
    var harness = document.getElementById(`E${eventInfo.event._def.publicId}`)
      .parentElement.parentElement.parentElement;
    var timegrid = document.getElementById(`E${eventInfo.event._def.publicId}`)
      .parentElement.parentElement;
    harness.classList.remove('on-hover');
    timegrid.classList.remove('on-hover');
  };

  const handleResizeStart = eventInfo => {
    //cancel autorefresh when resizing, so it doesn't cause "dupilcate" events
    clearInterval(autoRefresh.current);
    //remove hover effect
    var harness = document.getElementById(`E${eventInfo.event._def.publicId}`)
      .parentElement.parentElement.parentElement;
    var timegrid = document.getElementById(`E${eventInfo.event._def.publicId}`)
      .parentElement.parentElement;
    harness.classList.remove('on-hover');
    timegrid.classList.remove('on-hover');
  };

  const handleResizeEnd = eventInfo => {
    //start new auto refresh if duration didn't change
    if (rescheduledEvents.length === 0) {
      autoRefresh.current = AutoRefreshWeeklyAgenda(
        scheduleState.selectedCalendarWeek,
        scheduleState.targetUserId,
      );
    }
  };

  const findCaseIcon = (eventType, telehealth) => {
    if (eventType === 'clinic') {
      if (telehealth) {
        return 'telehealth';
      } else {
        return 'clinical';
      }
    } else {
      return eventType;
    }
  };

  const caseIconComponent = (eventType, telehealth) => {
    if (eventType === 'clinic') {
      if (telehealth) {
        return <></>;
      } else {
        return <ClinicalIcon />;
      }
    } else if (eventType === 'surgery') {
      return <SurgeryIcon />;
    } else if (eventType === 'conference') {
      return <ConferenceIcon />;
    } else {
      return <VacationIcon />;
    }
  };

  const findPatientReadinessDisplay = readiness => {
    if (readiness === 100) {
      return 'Ready for Surgery';
    } else if (readiness >= 0 && readiness < 100) {
      return 'Pending Surgical Readiness';
    }
  };

  const thirtyMinOrLess = duration => {
    if (duration.includes('hours') || duration.includes('hour')) {
      return false;
    } else {
      let wordArray = duration.split(' ');
      if (wordArray[1] === 'minutes' && parseInt(wordArray[0]) < 30) {
        return true;
      } else {
        return false;
      }
    }
  };

  const formatWeeklyDate = (startValue, endValue) => {
    let startMonth = moment(startValue).format('MMM Do');
    let endDate;
    if (moment(startValue).format('MM') === moment(endValue).format('MM')) {
      endDate = moment(endValue).format('Do');
    } else {
      endDate = moment(endValue).format('MMM Do');
    }
    let year = moment(endValue).format('YYYY');
    // May 25 through June 1, 2021
    return `${startMonth} through ${endDate}, ${year}`;
  };

  const openDocboxCreate = e => {
    console.log('create');
    const currentTime = moment().format('HHmmss');
    const targetUserId = scheduleState.targetUserId;
    const areKeysPressed = e.shiftKey && e.metaKey;
    const currentWeekStart = moment().startOf('week').format('YYYYMMDD');
    const selectedWeekStart = scheduleState.selectedCalendarWeek.start;

    if (selectedWeekStart === currentWeekStart) {
      //currentWeek: create case date = today's date
      const today = moment().format('YYYYMMDD');
      const todayStart = `${today}${currentTime}`;
      const todayEnd = moment(todayStart, 'YYYYMMDDHHmmss')
        .add(1, 'hours')
        .format('YYYYMMDDHHmmss');
      triggerCreateDocbox(todayStart, todayEnd, targetUserId, areKeysPressed);
    } else {
      //all other weeks: create case date = Monday of the week
      const selectedStartMon = moment(selectedWeekStart)
        .add(1, 'day')
        .format('YYYYMMDD');
      const selectedStart = `${selectedStartMon}${currentTime}`;
      const selectedEnd = moment(selectedStart, 'YYYYMMDDHHmmss')
        .add(1, 'hours')
        .format('YYYYMMDDHHmmss');
      triggerCreateDocbox(
        selectedStart,
        selectedEnd,
        targetUserId,
        areKeysPressed,
      );
    }
  };

  function renderEventContent(eventInfo) {
    return (
      <div
        id={`E${eventInfo.event._def.publicId}`}
        style={{display: 'flex', flexWrap: 'nowrap'}}>
        <div className="case-icon-container">
          <div
            className={`case-icon ${findCaseIcon(
              eventInfo.event._def.extendedProps.type.toLowerCase(),
              eventInfo.event._def.extendedProps.telehealth,
            )}`}>
            {caseIconComponent(
              eventInfo.event._def.extendedProps.type.toLowerCase(),
              eventInfo.event._def.extendedProps.telehealth,
            )}
          </div>
        </div>
        <div style={{width: '80%'}}>
          {/* Default Description */}
          <div className="event-description">
            {/* Display Case Owner, physician's name always at top */}
            {eventInfo.event._def.extendedProps.case_owner && (
              <div style={{color: 'white', fontWeight: 600}}>
                {eventInfo.event._def.extendedProps.case_owner}
              </div>
            )}

            {/* Display pt name at top if 30 min or less */}
            {thirtyMinOrLess(eventInfo.event._def.extendedProps.duration)
              ? eventInfo.event._def.extendedProps.patientName && (
                  <span style={{fontWeight: 500}} className="patient">
                    {eventInfo.event._def.extendedProps.patientName}
                  </span>
                )
              : null}

            {/* Telehealth button */}
            {eventInfo.event._def.extendedProps.teleheath &&
              eventInfo.event._def.extendedProps.teleheath.url && (
                <button
                  className="telehealth-join-button"
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    window.open(
                      eventInfo.event._def.extendedProps.teleheath.url,
                      '_blank',
                      'noopener, noreferrer',
                    );
                  }}>
                  Join
                </button>
              )}

            {/* Patient Readiness */}
            {eventInfo.event._def.extendedProps.type === 'Surgery' && (
              <div>
                {findPatientReadinessDisplay(
                  eventInfo.event._def.extendedProps.readiness_percent,
                )}
              </div>
            )}

            <div>
              {eventInfo.event._def.extendedProps.type !== 'Vacation' &&
                eventInfo.event._def.extendedProps.type !== 'Conference' && (
                  <label>Procedure</label>
                )}
              <span className="title">{eventInfo.event.title}</span>
            </div>

            {/* <div>
              <span className='time'>{`${eventInfo.timeText} (${eventInfo.event._def.extendedProps.duration})`}</span>
            </div> */}

            {eventInfo.event._def.extendedProps.type !== 'Vacation' &&
              eventInfo.event._def.extendedProps.type !== 'Conference' && (
                <>
                  <div>
                    <label>Surgery Location</label>
                    <span className="location">
                      {eventInfo.event._def.extendedProps.location ||
                        'No Location'}
                    </span>
                  </div>

                  {eventInfo.event._def.extendedProps.patientName && (
                    <div>
                      <label>Patient Name</label>
                      <span className="patient">
                        {eventInfo.event._def.extendedProps.patientName}
                      </span>
                    </div>
                  )}

                  {/* <div>
                  <label>Risk Score</label>
                  <span className='risk-score'></span>
                </div> */}
                </>
              )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {isWeeklyScheduleLoading || scheduleState.isLoading ? (
        <div className="loading-bg">
          <div className="weekly-loading"></div>
        </div>
      ) : scheduleState.calendarWeek.length === 0 ? (
        <div className="no-cases">
          <p>There are no events for this week</p>
          <div>
            {formatWeeklyDate(
              scheduleState.selectedCalendarWeek.start,
              scheduleState.selectedCalendarWeek.end,
            )}
          </div>
          <button
            type="button"
            className="btn-success create-event"
            title="Book a New Event"
            onClick={e => {
              openDocboxCreate(e);
            }}>
            Book a Case
          </button>
        </div>
      ) : (
        ''
      )}
      <FullCalendar
        ref={calendarRef}
        plugins={[timeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        dayHeaderFormat={{
          weekday: 'short',
          month: 'short',
          day: '2-digit',
          omitCommas: true,
        }}
        events={formattedEvents}
        eventContent={renderEventContent}
        eventClick={e => handleEventClick(e)}
        eventMouseEnter={e => handleMouseEnter(e)}
        eventMouseLeave={e => handleMouseLeave(e)}
        eventResizeStart={e => handleResizeStart(e)}
        eventResizeStop={e => handleResizeEnd(e)}
        allDaySlot={false}
        editable
        eventStartEditable
        eventDrop={e => handleEventDrop(e)}
        eventResize={e => handleEventDrop(e)}
        slotDuration="00:15:00"
        slotLabelInterval={'00:30:00'}
        nowIndicator
        scrollTime={moment().subtract(15, 'minutes').format('HH:mm:ss')}
        scrollTimeReset={false}
        businessHours={workingHours}
      />

      {/* {docboxState.isDocboxOpen && <Docbox />} */}
      {/* {eventSettingsState.isEventSettingsOpen && <EventSettings />} */}
    </>
  );
};

export default WeeklyCalendar;
