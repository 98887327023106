import React, {useEffect, useState} from 'react';

import './Tabs.scss';

const Tabs = ({tabsArray, tabsContainerId, style, setSelected}) => {
  const [selectedTab, setSelectedTab] = useState(null);
  const handleTabClick = id => {
    const tabsElements = document.getElementById(tabsContainerId).childNodes;

    setSelectedTab(id);
    tabsElements.forEach(element => (element.style.display = 'none'));
    return (document.getElementById(id).style.display = 'block');
  };

  useEffect(() => {
    // const defaultSelectedTab = (tabsArray.length) ? tabsArray[0].id : ''
    let defaultSelectedTab = null;
    for (let index in tabsArray) {
      if (
        typeof tabsArray[index].default != 'undefined' &&
        tabsArray[index].default
      ) {
        defaultSelectedTab = tabsArray[index].id;
      }
    }
    return setSelectedTab(defaultSelectedTab);
  }, []);

  useEffect(() => {
    // Inform parent of selected tab
    setSelected && setSelected(selectedTab);
  }, [selectedTab]);

  return (
    <>
      <ul
        className="tabs flex align-start nowrap no-select"
        style={style ? style : {}}>
        {tabsArray.map((tab, index) => {
          return (
            <li
              key={index}
              className={selectedTab === tab.id ? 'selected' : ''}
              onClick={() => handleTabClick(tab.id)}>
              {tab.name}
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default Tabs;
